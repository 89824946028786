import { ReleaseInput, CreateReleaseVariantInput } from "../../services/interfaces";
import * as releasesService from "../../services/autnhive/releases";
import * as productsService from "../../services/products";

export const releasesActions: any = {
  releasesWillLoad: "AUTNHIVE_RELEASES_WILL_SUBMIT",
  releasesLoadSucceeded: "AUTNHIVE_RELEASES_LOAD_SUCCEEDED",
  releasesLoadFailed: "AUTNHIVE_RELEASES_LOAD_FAILED",
  productsWillLoad: "AUTNHIVE_RELEASES_PRODUCTS_WILL_SUBMIT",
  productsLoadSucceeded: "AUTNHIVE_RELEASES_PRODUCTS_LOAD_SUCCEEDED",
  productsLoadFailed: "AUTNHIVE_RELEASES_PRODUCTS_LOAD_FAILED",
  createRelease: "AUTNHIVE_CREATE_RELEASE",
  createReleaseSucceeded: "AUTNHIVE_CREATE_RELEASE_SUCCEEDED",
  createReleaseFailed: "AUTNHIVE_CREATE_RELEASE_FAILED",
  updateRelease: "AUTNHIVE_UPDATE_RELEASE",
  updateReleaseSucceeded: "AUTNHIVE_UPDATE_RELEASE_SUCCEEDED",
  updateReleaseFailed: "AUTNHIVE_UPDATE_RELEASE_FAILED",
  createReleaseVariant: "AUTNHIVE_CREATE_RELEASE_VARIANT",
  createReleaseVariantSucceeded: "AUTNHIVE_CREATE_RELEASE_VARIANT_SUCCEEDED",
  createReleaseVariantFailed: "AUTNHIVE_CREATE_RELEASE_VARIANT_FAILED",
  deleteReleaseVariant: "AUTNHIVE_DELETE_RELEASE_VARIANT",
  deleteReleaseVariantSucceeded: "AUTNHIVE_DELETE_RELEASE_VARIANT_SUCCEEDED",
  deleteReleaseVariantFailed: "AUTNHIVE_DELETE_RELEASE_VARIANT_FAILED",
};

export const fetchReleases =
  (page: number, limit: number) => async (dispatch: any) => {
    dispatch({
      type: releasesActions.releasesWillLoad,
      payload: page,
    });
    try {
      dispatch({
        type: releasesActions.releasesLoadSucceeded,
        payload: await releasesService.fetchReleases(
          page,
          limit,
        ),
      });
    } catch (err) {
      dispatch({
        type: releasesActions.releasesLoadFailed,
        payload: err,
      });
    }
  };

export const fetchProducts = () => async (dispatch: any) => {
  dispatch({
    type: releasesActions.productsWillLoad
  });
  try {
    dispatch({
      type: releasesActions.productsLoadSucceeded,
      payload: (await productsService.fetchProducts(100, 1)).list
    });
  } catch(err) {
    dispatch({
      type: releasesActions.productsLoadFailed,
      payload: err
    });
  }
};

export const createRelease =
  (values: ReleaseInput) => async (dispatch: any) => {
    dispatch({ type: releasesActions.createRelease });
    try {
      dispatch({
        type: releasesActions.createReleaseSucceeded,
        payload: await releasesService.createRelease(values),
      });
    } catch (e) {
      dispatch({
        type: releasesActions.createReleaseFailed,
        payload: e,
      });
      throw e;
    }
  };

export const createReleaseVariant =
  (values: CreateReleaseVariantInput) => async (dispatch: any) => {
    dispatch({ type: releasesActions.createReleaseVariant });
    try {
      dispatch({
        type: releasesActions.createReleaseVariantSucceeded,
        payload: await releasesService.createReleaseVariant(values),
      });
    } catch (e) {
      dispatch({
        type: releasesActions.createReleaseVariantFailed,
        payload: e,
      });
      throw e;
    }
  };

export const deleteReleaseVariant = (variant_id: string) => async (dispatch: any) => {
  dispatch({ type: releasesActions.deleteReleaseVariant });
  try {
    dispatch({
      type: releasesActions.deleteReleaseVariantSucceeded,
      payload: await releasesService.deleteReleaseVariant(variant_id),
    });
  } catch (e) {
    dispatch({
      type: releasesActions.deleteReleaseVariantFailed,
      payload: e,
    });
    throw e;
  }
};

export const updateRelease =
  (id: string, values: ReleaseInput) => async (dispatch: any) => {
    dispatch({ type: releasesActions.updateRelease });
    try {
      dispatch({
        type: releasesActions.updateReleaseSucceeded,
        payload: await releasesService.updateRelease(id, values),
      });
    } catch (e) {
      dispatch({
        type: releasesActions.updateReleaseFailed,
        payload: e,
      });
      throw e;
    }
  };
