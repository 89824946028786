import {
  CreateResellerUserFormInput,
  UserFormInput,
} from "../../services/interfaces";
import * as resellerUsersServices from "../../services/resellers/users";

export const resellerUsersActions: any = {
  usersWillLoad: "RESELLER_USERS_WILL_LOAD",
  usersLoadSucceeded: "RESELLER_USERS_LOAD_SUCCEEDED",
  usersLoadFailed: "RESELLER_USERS_LOAD_FAILED",
  rolesWillLoad: "RESELLER_USERS_PAGE_ROLES_WILL_LOAD",
  rolesLoadSucceeded: "RESELLER_USERS_PAGE_ROLES_LOAD_SUCCEEDED",
  rolesLoadFailed: "RESELLER_USERS_PAGE_ROLES_LOAD_FAILED",
  createUser: "CREATE_RESELLER_USER",
  createUserSucceeded: "CREATE_RESELLER_USER_SUCCEEDED",
  createUserFailed: "CREATE_RESELLER_USER_FAILED",
  userBulkUpload: "RESELLER_USER_BULK_UPLOAD",
  userBulkUploadSucceeded: "RESELLER_USER_BULK_UPLOAD_SUCCEEDED",
  userBulkUploadFailed: "RESELLER_USER_BULK_UPLOAD_FAILED",
  updateUser: "UPDATE_RESELLER_USER",
  updateUserSucceeded: "UPDATE_RESELLER_USER_SUCCEEDED",
  updateUserFailed: "UPDATE_RESELLER_USER_FAILED",
  resetStates: "RESELLER_USER_RESET_STATES",
  updateSearchKeyword: "RESELLER_USER_UPDATE_SEARCH_KEYWORD",
};

export const fetchResellerUsers =
  ({
    limit,
    page,
    keyword,
    reseller_id,
  }: {
    limit: number;
    page: number;
    keyword?: string;
    reseller_id?: string;
  }) =>
  async (dispatch: any) => {
    dispatch({
      type: resellerUsersActions.usersWillLoad,
      payload: page,
    });
    try {
      dispatch({
        type: resellerUsersActions.usersLoadSucceeded,
        payload: await resellerUsersServices.fetchUsers({
          limit,
          page,
          reseller_id,
          keyword,
        }),
      });
    } catch (err) {
      dispatch({
        type: resellerUsersActions.usersLoadFailed,
        payload: err,
      });
    }
  };

export const fetchRoles =
  (limit: number, page: number, filters: { reseller_id?: string }) =>
  async (dispatch: any) => {
    dispatch({ type: resellerUsersActions.rolesWillLoad });
    try {
      dispatch({
        type: resellerUsersActions.rolesLoadSucceeded,
        payload: (
          await resellerUsersServices.fetchRoles(limit, page, filters)
        ).list.filter((role) => role.role_for !== "autnhive"),
      });
    } catch (err) {
      dispatch({
        type: resellerUsersActions.rolesLoadFailed,
        payload: err,
      });
    }
  };

export const createResellerUser =
  (values: CreateResellerUserFormInput) => async (dispatch: any) => {
    dispatch({ type: resellerUsersActions.createUser });
    try {
      dispatch({
        type: resellerUsersActions.createUserSucceeded,
        payload: await resellerUsersServices.createUser(values),
      });
    } catch (e: any) {
      dispatch({
        type: resellerUsersActions.createUserFailed,
        payload: e,
      });
      throw e;
    }
  };

export const updateResellerUser =
  (id: string, values: UserFormInput) => async (dispatch: any) => {
    dispatch({ type: resellerUsersActions.updateUser });
    try {
      dispatch({
        type: resellerUsersActions.updateUserSucceeded,
        payload: await resellerUsersServices.updateUser(id, values),
      });
    } catch (e: any) {
      dispatch({
        type: resellerUsersActions.updateUserFailed,
        payload: e,
      });
      throw e;
    }
  };

export const updateSearchKeyword = (keyword: string) => ({
  type: resellerUsersActions.updateSearchKeyword,
  payload: keyword,
});

export const resetStates = () => ({
  type: resellerUsersActions.resetStates,
});
