import { print } from "graphql";
import axios from "../../axios-client";
import gql from "graphql-tag";
import { parseErrors } from "../helpers";
import {
  GraphqlError,
  OrganizationOnboardingInput,
  OrganizationOnboardingType,
} from "../interfaces";
import { env } from '../../env';

const apiUrl: string = env.REACT_APP_COMMON_API_URL;

export async function fetchOnboardings(
  {
    customer_name,
    status,
  }: {
    customer_name: string;
    status: "draft" | "approved" | "rejected" | undefined;
  },
  page: number,
  limit: number
): Promise<{ list: [OrganizationOnboardingType]; count: number }> {
  const query = gql`
    query organizationOnboardings($page: Int!, $limit: Int!,$customer_name: String, $status: String) {
      organizationOnboardings(page: $page, limit: $limit, customer_name: $customer_name, status: $status) {
        list {
          id
          status
          organization_details {
            name
            organization_type
            email
            phone
            address {
              line_1
              line_2
              city
              state
              postal_code
              country
            }
            allowed_domains
          }
          user_details {
            first_name
            last_name
            email
            address {
              country
            }
          }
          subscription_details {
            contract_id
            product_name
            license_expires_at
            device_license_count
            user_license_count
          }
          reseller {
            id
            name
            allowed_domains
          }
          created_by {
            id
            first_name
            last_name
            email
          }
          contractProductDetails {
            contract_id
            license_expires_at
            user_license_count
            device_license_count
            product_name
            contract_start_date
            contract_end_date
          }
        }
        count
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<OrganizationsOnboardingsResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: { limit, page, customer_name, status },
      },{
        headers: {
          "timestamp": `${timeStamp}`,
        }
    }
    );
    return data.data.organizationOnboardings;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}
export async function updateOnboarding(
  id: string,
  values: OrganizationOnboardingInput
) {
  const query = gql`
    mutation updateOrganizationOnboarding(
      $id: String!
      $values: OrganizationOnboardingInput!
    ) {
      updateOrganizationOnboarding(id: $id, input: $values) {
        id
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<UpdateOrganizationOnboardingResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: { id, values },
      },{
        headers: {
        "timestamp": `${timeStamp}`,
        }
    }
    );
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.updateOrganizationOnboarding;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function approveRejectOnboarding(
  id: string,
  action: "approve" | "reject",
  rejection_reason?: string
): Promise<OrganizationOnboardingType> {
  const query = gql`
    mutation approveRejectOrganizationOnboarding(
      $id: String!
      $action: String!
      $rejection_reason: String
    ) {
      approveRejectOrganizationOnboarding(
        id: $id
        action: $action
        rejection_reason: $rejection_reason
      ) {
        id
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<ApproveRejectNewCustomerContractResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: { id, action, rejection_reason },
      },{
        headers: {
        "timestamp": `${timeStamp}`,
        }
    }
    );
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.approveRejectOrganizationOnboarding;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

interface OrganizationsOnboardingsResponse {
  data: {
    organizationOnboardings: {
      list: [OrganizationOnboardingType];
      count: number;
    };
  };
}

interface ApproveRejectNewCustomerContractResponse {
  data: {
    approveRejectOrganizationOnboarding: OrganizationOnboardingType;
  };
  errors?: Array<GraphqlError>;
}

interface UpdateOrganizationOnboardingResponse {
  data: {
    updateOrganizationOnboarding: OrganizationOnboardingType;
  };
  errors?: Array<GraphqlError>;
}
