import * as productSubscriptionService from "../../services/orgLevel/productSubscriptions";

export const productSubscriptionsActions: any = {
  productSubscriptionsWillLoad: "ORG_LEVEL_PRODUCT_SUBSCRIPTIONS_WILL_SUBMIT",
  productSubscriptionsLoadSucceeded:
    "ORG_LEVEL_PRODUCT_SUBSCRIPTIONS_LOAD_SUCCEEDED",
  productSubscriptionsLoadFailed: "ORG_LEVEL_PRODUCT_SUBSCRIPTIONS_LOAD_FAILED",
};

export const fetchProductSubscriptions = (organization_id: string, page: number, limit: number) => async (dispatch: any) => {
  dispatch({
    type: productSubscriptionsActions.productSubscriptionsWillLoad,
    payload: page
  });
  try {
    dispatch({
      type: productSubscriptionsActions.productSubscriptionsLoadSucceeded,
      payload: await productSubscriptionService.fetchProductSubscriptions(
        organization_id,
        page,
        limit
      ),
    });
  } catch (err) {
    dispatch({
      type: productSubscriptionsActions.productSubscriptionsLoadFailed,
      payload: err,
    });
  }
};