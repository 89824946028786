import * as React from "react";
import moment from "moment";
import { List, ListItem, ButtonGroup, Button } from "@mui/material";
import { ReleaseType, SubscriptionType } from "../services/interfaces";

interface ReleasesProps {
  loading: boolean;
  downloadProgress: boolean;
  releases: Array<ReleaseType>;
  productSubscription?: SubscriptionType;
  onDownloadClick?: (variant_id: string) => void;
}

export default function Releases(props: ReleasesProps) {
  if (!props.releases.length) {
    return <div>No packages for this release</div>;
  }
  return (
    <List>
      {props.releases.map((release: ReleaseType) => {
        return (
          <ListItem key={release.id}>
            <div>
              <h3>{release.version}</h3>
              <span>
                {moment(release.start_date).local().format("YYYY-MM-DD")}
              </span>
              <br />
              <ButtonGroup color="primary">
                {release.release_variants.map((variant) => (
                  <Button
                    key={variant.id}
                    disabled={props.downloadProgress}
                    size="small"
                    onClick={() => {
                      props.onDownloadClick?.(variant.id);
                    }}
                  >
                    {variant.platform}
                  </Button>
                ))}
              </ButtonGroup>
              <p>Release Notes</p>
              <span>{release.description}</span>
              <br />
            </div>
          </ListItem>
        );
      })}
    </List>
  );
}
