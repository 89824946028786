import React, { useState, useEffect, } from "react";
import { Typography, Button, Grid, Pagination, Checkbox, Dialog, DialogTitle, DialogContent } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Link as RouteLink,Route,Routes,
  useNavigate,
} from "react-router-dom";
import moment from "moment";
import { env } from "../env";
import axios from "../axios-client";
import { Reducers, SecurityNotificationsPageReducer, SecurityNotificationsType } from "../reducers";
import * as securityNotificationsActions from "../actions/securityNotifications";

const apiUrl: string = env.REACT_APP_DEVSECOPS_BACKEND_URL;

const LIMIT = 20;

export default function SecurityNotifications() {
  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();

  const securityNotificationsPageReducer: SecurityNotificationsPageReducer = useSelector<
    Reducers,
    SecurityNotificationsPageReducer
  >((state) => state.securityNotificationsPartner);

  const { count } = securityNotificationsPageReducer;
  const [page, setPage] = useState(1);

  const [show, setShow] = React.useState(false);
  const [selectedSecurityNotification, setSelectedSecurityNotification] = React.useState({});
  const handleClick = (selectedSecurityNotification: React.SetStateAction<{}>) => {
    setSelectedSecurityNotification(selectedSecurityNotification);
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };

  async function uploadCSV(fileData: FormData) {

    const timeStamp = new Date().getTime();
    axios.post(`${apiUrl}/devsecops/upload-security-notifications`, fileData,
      {
        headers: {
          "app-id": "qboard",
          "timestamp": `${timeStamp}`
        }
      })
      .then(response => {
        enqueueSnackbar('File uploaded successfully', { variant: 'success' })

        dispatch(securityNotificationsActions.fetchSecurityNotificationsList(
          page, LIMIT));
        return () => {
          dispatch(securityNotificationsActions.resetStates());
        };
      })
      .catch(error => {
        enqueueSnackbar('Error uploading file', { variant: 'error' })
      });
  }

  useEffect(() => {
    dispatch(securityNotificationsActions.fetchSecurityNotificationsList(
      1, LIMIT));
    return () => {
      dispatch(securityNotificationsActions.resetStates());
    };
  }, [dispatch]);

  return (
    <div>
      <Typography component="h1" variant="h5">
        Security Notifications
      </Typography>

      <Grid item xs={12}>
        <Button
          color="secondary"
          component="label"
          variant="outlined"
          startIcon={<UploadFileIcon />}
        >
          Upload CSV
          <input type="file" accept=".csv" hidden onChange={(e) => {
            if (!e.target.files) {
              return;
            }
            const file = e.target.files[0];

            const formData = new FormData();
            formData.append('file', file);

            uploadCSV(formData);
          }} />
        </Button>
      </Grid>

      <div className="app-container" style={{ display: 'flex', padding: "1rem", flexDirection: 'column', gap: '10px' }} >


        <table style={{
          verticalAlign: 'middle', borderCollapse: "separate", borderSpacing: '0 20Px', border: 'none', minWidth: '800px'
          , background: '#fff', padding: '0px 10px', borderRadius: '20px'
        }}>
          <thead>
            <tr>
              <th style={{ fontWeight: 700, color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>Business Vertical</th>
              <th style={{ fontWeight: 700, color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>Organization Name</th>
              <th style={{ fontWeight: 700, color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>File Name</th>
              <th style={{ fontWeight: 700, color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>Date</th>
              <th style={{ fontWeight: 700, color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle', maxWidth: '400px' }}>Feed Title</th>
              <th style={{ fontWeight: 700, color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>isPinned</th>
              <th style={{ fontWeight: 700, color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>Show More</th>
            </tr>
          </thead>
          <tbody>
            {securityNotificationsPageReducer.securityNotificationsList.map((notifications: SecurityNotificationsType) => {
              return (

                <tr key={notifications.slug}>

                  <td style={{ fontWeight: 'normal', color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}  >{notifications.business_vertical}</td>
                  <td style={{ fontWeight: 'normal', color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>{notifications.organization_name}</td>
                  <td style={{ fontWeight: 'normal', color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>{notifications.file_name != null ? notifications.file_name : "-"}</td>
                  <td style={{ fontWeight: 'normal', color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>{moment(notifications.date).local().format("YYYY-MMM-DD")}</td>
                  <td style={{ fontWeight: 'normal', color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle', maxWidth: '400px' }}>{notifications.feed_title}</td>
                  <td style={{ fontWeight: 'normal', color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>
                    <Checkbox
                      defaultChecked={notifications.is_pinned}
                      inputProps={{ "aria-label": "primary checkbox" }}
                      disabled
                    />
                  </td>
                  <td style={{ fontWeight: 'normal', color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>
                    <RouteLink to="/admin/securityNotifications/details">
                      <a href="#" onClick={() => handleClick(notifications)}>
                        More details
                      </a>
                    </RouteLink>
                  </td>
                </tr>

              );
            })}
            {!securityNotificationsPageReducer.securityNotificationsList || securityNotificationsPageReducer.securityNotificationsList.length === 0 ? (
              <tr>
                <td colSpan={5} style={{ fontStyle: "italic", fontWeight: 'normal', color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>
                  <span>No data available</span>
                </td>
              </tr>
            ) : (
              ""
            )}
          </tbody>
        </table>
        {/* {show && <Modal details={selectedData} />} */}
        <Routes>
        <Route path="/details" element={<SecurityNotificationsPopupModal name={selectedSecurityNotification}  />} />
      </Routes>

      </div>
      {count > LIMIT && <>
        <Pagination
          count={Math.ceil(count / LIMIT)}
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={(e: React.ChangeEvent<unknown>, newPage: number) => {
            setPage(newPage);
            dispatch(
              securityNotificationsActions.fetchSecurityNotificationsList(
                newPage,
                LIMIT
              )
            );
          }}
        /></>}
    </div>
  );
}

function SecurityNotificationsPopupModal(name:any ) {
 
  const navigate = useNavigate();
  const stringObj = JSON.stringify(name, null, 2);
  const jsonObject: any = JSON.parse(stringObj);
  const feedTitleValue: string = jsonObject.name.feed_title;
  const feedValue: string = jsonObject.name.feed;

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={true}
      onClose={() => navigate(-1)}
    >
      <DialogTitle style={{ fontWeight: 700}}>Security Notifications</DialogTitle>
      <DialogContent>
        <table style={{'border': '10px solid white'}}>
          <tr>
            <th style={{width:'80px', alignContent:'baseline', textAlign:'left', border: '10px solid white'}}>Feed Title</th>
            <td style={{border: '10px solid white'}}>{feedTitleValue}</td>
          </tr>
          <tr>
            <th style={{width:'80px', alignContent:'baseline', textAlign:'left', border: '10px solid white'}}>Feed</th>
            <td style={{border: '10px solid white'}}>{feedValue}</td>
          </tr>
        </table>
      </DialogContent>
    </Dialog>
  );
}