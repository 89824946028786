import React, { useState, useEffect, } from "react";
import { Typography, Button, Grid, Pagination, Checkbox, FormControl, InputLabel, Select, MenuItem, Dialog, DialogContent, DialogTitle } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { env } from "../env";
import axios from "../axios-client";
import TabBar from "../components/tabBar";
import { MaterialMasterData, MaterialMasterDataReducer, Reducers } from "../reducers";
import * as materialMasterDataActions from "../actions/materialMasterData";
import moment from "moment";
import { Link as RouteLink,Route,Routes,
  useNavigate,
} from "react-router-dom";

const apiUrl: string = env.REACT_APP_DEVSECOPS_BACKEND_URL;

const LIMIT = 20;

export enum uploadTypes {
  ListOfSectors = "List of sectors",
  CategoryList = "Category list", 
  CategoryIssueMapping = "Category Issue mapping", 
  SectorSpecificMaterialRisks = "Sector MR Baseline Value"
 
}

export enum capRanges {
  SmallEnterprise = "Small Enterprise",
  MediumEnterprise = "Medium Enterprise", 
  LargeEnterprise = "Large Enterprise"
}

export default function MaterialMasterDataUpload() {
  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();

  const [uploadType, setUploadType] = useState(uploadTypes.ListOfSectors);

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const materialMasterDataReducer: MaterialMasterDataReducer = useSelector<
    Reducers,
    MaterialMasterDataReducer
  >((state) => state.materialMasterDataPartner);

  const { count } = materialMasterDataReducer;
  const [page, setPage] = useState(1);
  const [capRange, setCapRange] = useState(capRanges.SmallEnterprise);

  const [sectors, setSectors] = useState(Array<MaterialMasterData>);
  const [currentSector, setCurrentSector] = React.useState<MaterialMasterData>();

  const [show, setShow] = React.useState(false);
  const [selectedMaterialMasterData, setSelectedMaterialMasterData] = React.useState({});
  const handleClick = (selectedMaterialMasterData: React.SetStateAction<{}>) => {
    setSelectedMaterialMasterData(selectedMaterialMasterData);
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };

  useEffect(() => {
    if(activeTabIndex == 0) {
      setSectors(materialMasterDataReducer.materialMasterDataList);
    }
  }, [materialMasterDataReducer]);

  const tabContent = (type: uploadTypes) => {

    return (
      <div className="app-container" style={{ display: 'flex', padding: "1rem", flexDirection: 'column', gap: '10px' }} >


        {(type == uploadTypes.SectorSpecificMaterialRisks) && (
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <FormControl margin="normal">
                <InputLabel variant="standard">Sector</InputLabel>
                <Select
                  variant="standard"
                  style={{ width: 250 }}
                  value={currentSector}
                  onChange={(e: any) => {
                    if (typeof e.target.value === "string") {
                      let sectorSelected = sectors.find(sector => sector.name === e.target.value);
                      setCurrentSector(sectorSelected);
                      dispatch(
                        materialMasterDataActions.fetchMaterialMasterDataList(
                          type,
                          page,
                          LIMIT,
                          capRange,
                          [sectorSelected!.slug]
                        )
                      );
                    }
                  }}
                >
                  {sectors.map(
                    (currentSector) => (
                      <MenuItem key={currentSector.name} value={currentSector.name}>
                        {currentSector.name}
                      </MenuItem>
                    )
                  )}
                </Select>

              </FormControl>
            </Grid>
            <Grid item>
              <FormControl margin="normal">
                <InputLabel variant="standard">Revenue Range</InputLabel>
                <Select
                  variant="standard"
                  style={{ width: 250 }}
                  value={capRange}
                  onChange={(e: any) => {
                    if (typeof e.target.value === "string") {
                      setCapRange(e.target.value);
                      dispatch(
                        materialMasterDataActions.fetchMaterialMasterDataList(
                          type,
                          page,
                          LIMIT,
                          e.target.value,
                          (currentSector != null ? [currentSector!.slug] : [])
                        )
                      );
                    }
                  }}
                >
                  {[capRanges.SmallEnterprise, capRanges.MediumEnterprise, capRanges.LargeEnterprise].map(
                    (capRange) => (
                      <MenuItem key={capRange} value={capRange}>
                        {capRange}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
        <table style={{
          verticalAlign: 'middle', borderCollapse: "separate", borderSpacing: '0 20Px', border: 'none', minWidth: '800px'
          , background: '#fff', padding: '0px 10px', borderRadius: '20px'
        }}>
          <thead>
            
            {(type == uploadTypes.ListOfSectors || type == uploadTypes.CategoryList) && (
              <tr>
                <th style={{ fontWeight: 700, color: '#343434', padding: '0', textAlign: 'left', verticalAlign: 'middle' }}>Name</th>
                <th style={{ fontWeight: 700, color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>isActive</th>
                <th style={{ fontWeight: 700, color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>Created Date</th>
              </tr>
            )}
            {(type == uploadTypes.CategoryIssueMapping) && (
              <tr>
                <th style={{ fontWeight: 700, color: '#343434', padding: '0', textAlign: 'left', verticalAlign: 'middle' }}>Material Risk Categories</th>
                <th style={{ fontWeight: 700, color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>Report Type</th>
                <th style={{ fontWeight: 700, color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>Vulnerability Name</th>
                <th style={{ fontWeight: 700, color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>isActive</th>
                <th style={{ fontWeight: 700, color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>Created Date</th>
                

              </tr>
            )}
            {(type == uploadTypes.SectorSpecificMaterialRisks) && (
              <tr>
                <th style={{ fontWeight: 700, color: '#343434', padding: '0', textAlign: 'left', verticalAlign: 'middle' }}>Material Risk Sectors</th>
                <th style={{ fontWeight: 700, color: '#343434', padding: '0', textAlign: 'left', verticalAlign: 'middle' }}>Material Risk Categories</th>
                <th style={{ fontWeight: 700, color: '#343434', padding: '0', textAlign: 'left', verticalAlign: 'middle' }}>Revenue Range</th>
                <th style={{ fontWeight: 700, color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>Frequency</th>
                <th style={{ fontWeight: 700, color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>Low Loss</th>
                <th style={{ fontWeight: 700, color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>High Loss</th>
                <th style={{ fontWeight: 700, color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>Cost</th>
                <th style={{ fontWeight: 700, color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>isActive</th>
                <th style={{ fontWeight: 700, color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>Created Date</th>                
              </tr>
            )}
          </thead>
          <tbody>
            {materialMasterDataReducer.materialMasterDataList.map((deviceVul: MaterialMasterData) => {
              
              if (type == uploadTypes.ListOfSectors || type == uploadTypes.CategoryList) {
                return (
                  <tr key={deviceVul.slug}>

                    <td style={{ fontWeight: 'normal', color: '#343434', padding: '0', textAlign: 'left', verticalAlign: 'middle' }}>{deviceVul.name}</td>
                    <td style={{ fontWeight: 'normal', color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>
                      <Checkbox
                        defaultChecked={deviceVul.is_active}
                        inputProps={{ "aria-label": "primary checkbox" }}
                        disabled
                      />
                    </td>
                    <td style={{ fontWeight: 'normal', color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>{moment(deviceVul.created_at).local().format("YYYY-MMM-DD")}</td>

                  </tr>
                );
              } else if(type == uploadTypes.CategoryIssueMapping) {
                return (
                  <tr key={deviceVul.slug}>
                    <td style={{ fontWeight: 'normal', color: '#343434', padding: '0', textAlign: 'left', verticalAlign: 'middle' }}>
                      <RouteLink to="/admin/materialMasterDataUpload/details">
                        <a href="#" onClick={() => handleClick(deviceVul.material_risk_categories)}>
                          {deviceVul.material_risk_categories?.name}
                        </a>
                      </RouteLink>
                    </td>
                    <td style={{ fontWeight: 'normal', color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>{deviceVul.report_type}</td>
                    <td style={{ fontWeight: 'normal', color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>{deviceVul.sub_vulnerability_name}</td>
                    <td style={{ fontWeight: 'normal', color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>
                      <Checkbox
                        defaultChecked={deviceVul.is_active}
                        inputProps={{ "aria-label": "primary checkbox" }}
                        disabled
                      />
                    </td>
                    <td style={{ fontWeight: 'normal', color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>{moment(deviceVul.created_at).local().format("YYYY-MMM-DD")}</td>
                    
                  </tr>
                );
              } else if(type == uploadTypes.SectorSpecificMaterialRisks) {
                return (
                  <tr key={deviceVul.slug}>

                    <td style={{ fontWeight: 'normal', color: '#343434', padding: '0', textAlign: 'left', verticalAlign: 'middle' }}>
                      <RouteLink to="/admin/materialMasterDataUpload/details">
                        <a href="#" onClick={() => handleClick(deviceVul.material_risk_sectors)}>
                          {deviceVul.material_risk_sectors?.name}
                        </a>
                      </RouteLink>
                    </td>
                    <td style={{ fontWeight: 'normal', color: '#343434', padding: '0', textAlign: 'left', verticalAlign: 'middle' }}>
                      <RouteLink to="/admin/materialMasterDataUpload/details">
                        <a href="#" onClick={() => handleClick(deviceVul.material_risk_categories)}>
                          {deviceVul.material_risk_categories?.name}
                        </a>
                      </RouteLink>
                    </td>
                    <td style={{ fontWeight: 'normal', color: '#343434', padding: '0', textAlign: 'left', verticalAlign: 'middle' }}>{deviceVul.cap_range}</td>
                    <td style={{ fontWeight: 'normal', color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>{deviceVul.frequency}</td>
                    <td style={{ fontWeight: 'normal', color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>{deviceVul.low_loss}</td>
                    <td style={{ fontWeight: 'normal', color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>{deviceVul.high_loss}</td>
                    <td style={{ fontWeight: 'normal', color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>{deviceVul.cost}</td>
                    <td style={{ fontWeight: 'normal', color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>
                      <Checkbox
                        defaultChecked={deviceVul.is_active}
                        inputProps={{ "aria-label": "primary checkbox" }}
                        disabled
                      />
                    </td>
                    <td style={{ fontWeight: 'normal', color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>{moment(deviceVul.created_at).local().format("YYYY-MMM-DD")}</td>
                    
                  </tr>
                );
              }
            })}
            {!materialMasterDataReducer.materialMasterDataList || materialMasterDataReducer.materialMasterDataList.length === 0 ? (
              <tr>
                <td colSpan={5} style={{ fontStyle: "italic", fontWeight: 'normal', color: '#343434', padding: '0', textAlign: 'center', verticalAlign: 'middle' }}>
                  <span>No data available</span>
                </td>
              </tr>
            ) : (
              ""
            )}
          </tbody>
        </table>
        {count > LIMIT && <>
        <Pagination
          count={Math.ceil(count / LIMIT)}
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={(e: React.ChangeEvent<unknown>, newPage: number) => {
            setPage(newPage);
            dispatch(
              materialMasterDataActions.fetchMaterialMasterDataList(
                type,
                newPage,
                LIMIT,
                capRange,
                (currentSector != null ? [currentSector!.slug] : [])
              )
            );
          }}
        /></>}

        <Routes>
          <Route path="/details" element={<MaterialMasterDataCategoriesPopupModal name={selectedMaterialMasterData} />} />
        </Routes>
      </div>
    )
    // switch(type) {
    //   case uploadTypes.ListOfSectors:
    //     return <div>Content for ListOfSectors</div>;
    //   case uploadTypes.CategoryList:
    //     return <div>Content for CategoryList</div>;
    //   case uploadTypes.CategoryIssueMapping:
    //     return <div>Content for CategoryIssueMapping</div>;
    //   case uploadTypes.SectorSpecificMaterialRisks:
    //     return <div>Content for SectorSpecificMaterialRisks</div>;
    //   default:
    //       return <div>no data</div>;
    // }
  };

  const tabs = [
    { label: uploadTypes.ListOfSectors, content: () => tabContent(uploadTypes.ListOfSectors) },
    { label: uploadTypes.CategoryList, content: () => tabContent(uploadTypes.CategoryList) },
    { label: uploadTypes.CategoryIssueMapping, content: () => tabContent(uploadTypes.CategoryIssueMapping) },
    { label: uploadTypes.SectorSpecificMaterialRisks, content: () => tabContent(uploadTypes.SectorSpecificMaterialRisks) },
  ];

  const handleTabChange = (index: number) => {
    setActiveTabIndex(index);
  };

  useEffect(() => {
    let tabType = uploadTypes.ListOfSectors;
    switch(activeTabIndex) {
      case 0:
        tabType = uploadTypes.ListOfSectors;
        break;
      case 1:
        tabType = uploadTypes.CategoryList;
        break;
      case 2:
        tabType = uploadTypes.CategoryIssueMapping;
        break;
      case 3:
        tabType = uploadTypes.SectorSpecificMaterialRisks;
        break;
        default:
          break;
    }
    dispatch(materialMasterDataActions.fetchMaterialMasterDataList(tabType, page, LIMIT, capRange, (currentSector != null ? [currentSector!.slug] : [])));
    return () => {
      dispatch(materialMasterDataActions.resetStates());
    };
  }, [dispatch, activeTabIndex]);

  async function uploadCSV(fileData: FormData) {

    const timeStamp = new Date().getTime();

    let uploadUrl = `${apiUrl}/devsecops/upload-material-risk-sectors`;

    switch(uploadType) {
      case uploadTypes.ListOfSectors:
        uploadUrl = `${apiUrl}/devsecops/upload-material-risk-sectors`;
        break;
        case uploadTypes.CategoryList:
        uploadUrl = `${apiUrl}/devsecops/upload-material-risk-categories`;
        break;
        case uploadTypes.CategoryIssueMapping:
        uploadUrl = `${apiUrl}/devsecops/upload-material-risk-category-incident-mappings`;
        break;
        case uploadTypes.SectorSpecificMaterialRisks:
        uploadUrl = `${apiUrl}/devsecops/upload-default-material-risk-costs`;
        break;
        default:
          uploadUrl = '';
    }

    if(uploadUrl.length > 0) {
      axios.post(uploadUrl, fileData,
        {
          headers: {
            "app-id": "qboard",
            "timestamp": `${timeStamp}`
          }
        })
        .then(response => {
          enqueueSnackbar('File uploaded successfully', { variant: 'success' })
        })
        .catch(error => {
          enqueueSnackbar('Error uploading file', { variant: 'error' })
        });
    }
  }

  return (
    <div>
      <Typography component="h1" variant="h5">
      Material Risks
      </Typography>

      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <FormControl margin="normal">
            <InputLabel variant="standard">Upload Type</InputLabel>
            <Select
              variant="standard"
              style={{ width: 250 }}
              value={uploadType}
              onChange={(e: any) => {
                if (typeof e.target.value === "string") {
                  setUploadType(e.target.value);
                }
              }}
            >
              {[uploadTypes.ListOfSectors, uploadTypes.CategoryList, uploadTypes.CategoryIssueMapping, uploadTypes.SectorSpecificMaterialRisks].map(
                (uploadType) => (
                  <MenuItem key={uploadType} value={uploadType}>
                    {uploadType}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Button
            color="secondary"
            component="label"
            variant="outlined"
            startIcon={<UploadFileIcon />}
          >
            Upload
            <input type="file" accept=".csv" hidden onChange={(e) => {
              if (!e.target.files) {
                return;
              }
              const file = e.target.files[0];

              const formData = new FormData();
              formData.append('file', file);

              uploadCSV(formData);
            }} />
          </Button>
        </Grid>
      </Grid>

      <TabBar 
        tabs={tabs} 
        activeTabIndex={activeTabIndex} 
        onTabChange={handleTabChange} 
      />
    </div>
  );
}

function MaterialMasterDataCategoriesPopupModal(name:any ) {
 
  const navigate = useNavigate();
  const stringObj = JSON.stringify(name, null, 2);
  const jsonObject: any = JSON.parse(stringObj);
  const cname: string = jsonObject.name.name;
  const is_active: boolean = jsonObject.name.is_active;
  const created_at: string = jsonObject.name.created_at;

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={true}
      onClose={() => navigate(-1)}
    >
      <DialogTitle style={{ fontWeight: 700}}>Details</DialogTitle>
      <DialogContent>
        <table style={{'border': '10px solid white'}}>
          <tr>
            <th style={{width:'80px', alignContent:'baseline', textAlign:'left', border: '10px solid white'}}>Name</th>
            <td style={{border: '10px solid white'}}>{cname}</td>
          </tr>
          <tr>
            <th style={{width:'80px', alignContent:'baseline', textAlign:'left', border: '10px solid white'}}>isActive</th>
            <td style={{border: '10px solid white'}}>
              <Checkbox
                defaultChecked={is_active}
                inputProps={{ "aria-label": "primary checkbox" }}
                disabled
              />
            </td>
          </tr>
          <tr>
            <th style={{width:'80px', alignContent:'baseline', textAlign:'left', border: '10px solid white'}}>Created Date</th>
            <td style={{border: '10px solid white'}}>{moment(created_at).local().format("YYYY-MMM-DD")}</td>
          </tr>
        </table>
      </DialogContent>
    </Dialog>
  );
}
