import { CreateUserFormInput, UserFormInput } from "../../services/interfaces";
import * as usersService from "../../services/autnhive/users";

export const usersActions: any = {
  usersWillLoad: "AUTNHIVE_USERS_WILL_LOAD",
  usersLoadSucceeded: "AUTNHIVE_USERS_LOAD_SUCCEEDED",
  usersLoadFailed: "AUTNHIVE_USERS_LOAD_FAILED",
  rolesWillLoad: "AUTNHIVE_USERS_PAGE_ROLES_WILL_LOAD",
  rolesLoadSucceeded: "AUTNHIVE_USERS_PAGE_ROLES_LOAD_SUCCEEDED",
  rolesLoadFailed: "AUTNHIVE_USERS_PAGE_ROLES_LOAD_FAILED",
  createUser: "AUTNHIVE_CREATE_USER",
  createUserSucceeded: "AUTNHIVE_CREATE_USER_SUCCEEDED",
  createUserFailed: "AUTNHIVE_CREATE_USER_FAILED",
  updateUser: "AUTNHIVE_UPDATE_USER",
  updateUserSucceeded: "AUTNHIVE_UPDATE_USER_SUCCEEDED",
  updateUserFailed: "AUTNHIVE_UPDATE_USER_FAILED",
  dismissUserMessages: "AUTNHIVE_USER_DISMISS_MESSAGES",
};

export const fetchUsers =
  (limit: number, page: number) => async (dispatch: any) => {
    dispatch({
      type: usersActions.usersWillLoad,
      payload: page,
    });
    try {
      dispatch({
        type: usersActions.usersLoadSucceeded,
        payload: await usersService.fetchUsers({
          limit,
          page,
        }),
      });
    } catch (err) {
      dispatch({
        type: usersActions.usersLoadFailed,
        payload: err,
      });
    }
  };

export const fetchRoles =
  (limit: number, page: number) => async (dispatch: any) => {
    dispatch({ type: usersActions.rolesWillLoad });
    try {
      dispatch({
        type: usersActions.rolesLoadSucceeded,
        payload: (await usersService.fetchRoles(limit, page)).list,
      });
    } catch (err) {
      dispatch({
        type: usersActions.rolesLoadFailed,
        payload: err,
      });
    }
  };

export const createUser =
  (values: CreateUserFormInput) => async (dispatch: any) => {
    dispatch({ type: usersActions.createUser });
    try {
      dispatch({
        type: usersActions.createUserSucceeded,
        payload: await usersService.createUser(values),
      });
    } catch (e) {
      dispatch({
        type: usersActions.createUserFailed,
        payload: e,
      });
      throw e;
    }
  };

export const updateUser =
  (id: string, values: UserFormInput) => async (dispatch: any) => {
    dispatch({ type: usersActions.updateUser });
    try {
      dispatch({
        type: usersActions.updateUserSucceeded,
        payload: await usersService.updateUser(id, values),
      });
    } catch (e) {
      dispatch({
        type: usersActions.updateUserFailed,
        payload: e,
      });
      throw e;
    }
  };

export const dismissUserMessages = () => ({
  type: usersActions.dismissUserMessages,
});

export const dismissCreateRoleMessages = () => ({
  type: usersActions.dismissCreateRoleMessages,
});

export const dismissSubmitUserBulkUploadMessages = () => ({
  type: usersActions.dismissSubmitUserBulkUploadMessages,
});

export const resetUserBulkUploadStates = () => ({
  type: usersActions.resetUserBulkUploadStates,
});
