import { useEffect, useState } from "react";
import {
    DatePicker,
    DatePickerToolbar,
    DatePickerToolbarProps,
  } from '@mui/x-date-pickers/DatePicker';
import FormControlLabel from '@mui/material/FormControlLabel';
import {Switch, FormControl, TextField } from "@mui/material";
import Box from '@mui/material/Box';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from "dayjs";
import { allowedNodeEnvironmentFlags } from "process";
import { FieldsTextFieldProps } from "@mui/x-date-pickers/internals";
import { setDate } from "date-fns";
import moment from "moment";
  
interface CustomDatePickerProps {
    disabled: boolean;
    availValue: boolean;
    value: string;
    onAvailChange: Function;
    onDateChange: Function;
  }

export default function CustomDatePicker({ ...pickerProps }){
    const { disabled, value, availValue, availOpen, minDate, maxDate, ...other } = pickerProps;
    const [dateValue, setDateValue] = useState("")
    const [isDisabled, setIsDisabled] = useState(false)
    const [isAvailValue, setIsAvailValue] = useState(false)
    const [isAvailOpen, setIsAvailOpen] = useState(false)

    let minimumDate = new Date();
    minimumDate.setMonth(minimumDate.getMonth() - 6)

  useEffect(() => {
    setDateValue(value);
  }, [value]);

  useEffect(() => {
    setIsAvailOpen(availOpen);
  }, [availOpen]);

  useEffect(() => {
    setIsAvailValue(availValue);
  }, [availValue]);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  function CustomToolbar(props: DatePickerToolbarProps<Dayjs>) {
    return (
      <Box
        className={props.className}
        sx={{ display: isAvailValue ? 'flex':'block', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <DatePickerToolbar {...props} />
        <FormControlLabel style={{marginLeft:'auto'}} control={
                <Switch 
                    disabled={isDisabled}
                    checked={isAvailValue}
                    onChange={() => {
                        setIsAvailValue(false)
                        pickerProps.onAvailChange()
                    }}
                style={{verticalAlign:'center'}} />
              } label="Avail Trial" />
      </Box>
    );
  }
  
  return (
    <FormControl margin="normal" fullWidth>
        <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DatePicker
                    maxDate={dayjs(maxDate)}
                    minDate={dayjs(minDate)}
                    label="Trial End"
                    open={availOpen}
                    value={dayjs(dateValue)}
                    views={['year','month', 'day']}
                    format={"DD MMM YYYY"}
                    onChange={(newValue:Dayjs | null)=>{
                      //If both month and year are the same, then day of month changed, so toggle popup,
                      // else, just update the dateValue, because only changed month or year.
                      if ((moment(newValue?.toDate()).month() === moment(dateValue).month()
                        && moment(newValue?.toDate()).year() === moment(dateValue).year()) && newValue!== null) {
                        setDateValue(newValue.toDate().toString());
                        pickerProps.onDateChange(newValue.toDate())
                      } else if(newValue != null){
                        setDateValue(newValue.toDate().toString())
                      }
                    }}
                    slotProps={{
                        textField: {
                            variant: 'standard'
                        }
                    }}
                    slots={{
                      toolbar: CustomToolbar
                    }}
                  />
              </LocalizationProvider>
    </FormControl>    
  );
}
