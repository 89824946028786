import React, {
  forwardRef,
  ElementType,
  ReactNode,
  useState,
  PropsWithChildren,
} from "react";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  PaperProps,
  Paper,
} from "@mui/material";
import Draggable from "react-draggable";

interface ConfirmButtonProps {
  component: ElementType;
  onClick: () => void;
  heading: string;
  form?: ReactNode;
  message?: string;
  disabled?: boolean;
  positiveButtonLabel?: string;
  negativeButtonLabel?: string;
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default forwardRef<HTMLDivElement, PropsWithChildren<ConfirmButtonProps>>(
  (props, ref) => {
    const [open, setOpen] = useState(false);
    const positiveButtonLabel = props.positiveButtonLabel || "Yes";
    const negativeButtonLabel = props.negativeButtonLabel || "No";

    return (
      <>
        <Dialog
          ref={ref}
          open={open}
          onClose={() => setOpen(false)}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            {props.heading}
          </DialogTitle>
          <DialogContent>
            {props.message ? (
              <DialogContentText>{props.message}</DialogContentText>
            ) : null}
            {props.form ? props.form : null}
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => setOpen(false)} color="primary">
              {negativeButtonLabel}
            </Button>
            <Button
              onClick={() => {
                setOpen(false);
                props.onClick();
              }}
              color="primary"
            >
              {positiveButtonLabel}
            </Button>
          </DialogActions>
        </Dialog>
        <props.component
          disabled={props.disabled}
          onClick={() => setOpen(true)}
        >
          {props.children}
        </props.component>
      </>
    );
  }
);
