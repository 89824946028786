import React from "react";
import { colors } from "@mui/material";

type Props = {
  hasErrors: boolean;
};

function MandatoryFieldText(props: Props) {
  return (
    <div
      style={{
        color: props.hasErrors ? colors.red[500] : "inherit",
      }}
    >
      * mandatory field(s) required.
    </div>
  );
}

export default MandatoryFieldText;
