
import axios from "../axios-client";
import {SamiChatType,} from "./interfaces";
import { env } from '../env';
import { parseErrors } from "./helpers";

const apiUrl: string = env.REACT_APP_SAMI_BACKEND_URL;

export async function fetchSamiChatList( 
  startDate:Date| null,endDate:Date | null|undefined ,
  page: number,
  limit: number) {
 
  try {
    const timeStamp = new Date().getTime();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { data } = await axios.get<ChatListResponse>(`${apiUrl}/chats-paginated-list?page=${page}&limit=${limit}&from_date=${startDate}&to_date=${endDate}`, {  headers: { "timestamp": `${timeStamp}` } });
    return data.data.data;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}


interface ChatListResponse {
  data: {
    data: {
      list: [SamiChatType];
      count: number;
    };
  };
}





