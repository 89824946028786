import { AxiosResponse } from "axios";
import moment from "moment";
import axios from "../axios-client";
import { UserType, UserProfileType, GraphqlError } from "./interfaces";
import { DATETIME_FORMAT } from "../constants";
import { env } from '../env';

const authUrl = env.REACT_APP_AUTH_URL;

type UserSession = {
  user: UserType;
  session_expire_time: moment.Moment;
  permissions: { resource: string; actions: string[] }[];
  token: string;
};
export async function initSession(): Promise<UserProfileType> {
  const session = getSession();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  axios.defaults.headers.common["Authorization"] = `Bearer ${session.token}`;
  axios.defaults.headers.common["app-id"] = "qboard";
  axios.defaults.headers.common["timezone"] = timeZone;
  return {
    user: session.user,
    session_expire_time: session.session_expire_time.toDate(),
    permissions: session.permissions,
  };
}

function storeSession(session: UserSession) {
  localStorage.setItem("user", JSON.stringify(session.user));
  localStorage.setItem(
    "session_expire_time",
    session.session_expire_time.format(DATETIME_FORMAT)
  );
  localStorage.setItem("permissions", JSON.stringify(session.permissions));
  localStorage.setItem("token", session.token);
}

function clearSession() {
  localStorage.clear();
  delete axios.defaults.headers.common["Authorization"];
}

function getSession(): UserSession {
  const user = localStorage.getItem("user");
  const session_expire_time = localStorage.getItem("session_expire_time");
  const permissions = localStorage.getItem("permissions");
  const token = localStorage.getItem("token");

  if (!user || !session_expire_time || !permissions || !token) {
    throw new Error("Session expired");
  }

  try {
    return {
      user: JSON.parse(user),
      session_expire_time: moment(session_expire_time, DATETIME_FORMAT),
      permissions: JSON.parse(permissions),
      token,
    };
  } catch (err) {
    throw new Error("Session expired");
  }
}

export async function login(values: {
  request_token: string;
  otp: string;
  new_password?: string;
  terms_and_conditions_consent?: {
    link: string;
    version: string;
  };
  privacy_policy_consent?: {
    link: string;
    version: string;
  };
}): Promise<UserProfileType> {
  const timeStamp = new Date().getTime();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { data } = await axios.post<LoginResponse>(`${authUrl}/login`, {
    request_token: values.request_token,
    otp: values.otp,
    new_password: values.new_password,
    terms_and_conditions_consent: values.terms_and_conditions_consent,
    privacy_policy_consent: values.privacy_policy_consent,
  },{
    headers: {
      "app-id": "qboard",
      "timestamp": `${timeStamp}`,
      "timezone": timeZone
    }
  });
  axios.defaults.headers.common["Authorization"] = `Bearer ${data.data.token}`;
  axios.defaults.headers.common["app-id"] = "qboard";
  axios.defaults.headers.common["timezone"] = timeZone;
  storeSession({
    user: data.data.user_profile,
    session_expire_time: moment().add(data.data.token_expires_in, "seconds"),
    permissions: data.data.permissions,
    token: data.data.token,
  });
  return {
    user: data.data.user_profile,
    session_expire_time: moment()
      .add(data.data.token_expires_in, "seconds")
      .toDate(),
    permissions: data.data.permissions,
  };
}

export async function logout() {
  try {
    await axios.post(`${authUrl}/logout`);
  } catch (err) {
    //do nothing
  }

  clearSession();
}

export async function loginOtp(
  email: string,
  password: string,
  platform_use_consent: {
    link: string;
    version: string;
  }
): Promise<{
  request_token: string;
  salt: string;
  request_new_password: boolean;
  request_to_accept_terms: boolean;
  request_to_accept_privacy_policy: boolean;
}> {
  try {
    const timeStamp = new Date().getTime();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { data } = await axios.post<LoginOtpResponse>(
      `${authUrl}/login-otp`,
      {
        email,
        password,
        platform_use_consent,
      },{
        headers: {
          "app-id": "qboard",
          "timestamp": `${timeStamp}`,
          "timezone": timeZone
        }
      }
    );
    return {
      request_token: data.data.request_token,
      salt: data.data.salt,
      request_new_password: data.data.request_new_password,
      request_to_accept_terms: data.data.request_to_accept_terms,
      request_to_accept_privacy_policy:
        data.data.request_to_accept_privacy_policy,
    };
  } catch (err: any) {
    if (err.response?.data) {
      throw err.response.data;
    }
    throw err;
  }
}

export async function resetPassword(
  request_token: string,
  otp: string,
  password: string
): Promise<AxiosResponse<ResetPasswordResponse>> {
  const timeStamp = new Date().getTime();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return axios.post<ResetPasswordResponse>(`${authUrl}/reset-password`, {
    request_token,
    password,
    otp,
  },{
    headers: {
      "app-id": "qboard",
      "timestamp": `${timeStamp}`,
      "timezone": timeZone
    }
  });
}

export async function resetPasswordOtp(
  email: string,
  captcha: string
): Promise<{ request_token: string; salt: string }> {
  try {
    const timeStamp = new Date().getTime();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { data } = await axios.post<OtpResponse>(
      `${authUrl}/reset-password-otp`,
      {
        email,
        captcha,
      },{
        headers: {
          "app-id": "qboard",
          "timestamp": `${timeStamp}`,
          "timezone": timeZone
        }
      }
    );
    console.log("--> data", data.data);
    return {
      request_token: data.data.request_token,
      salt: data.data.salt,
    };
  } catch (err: any) {
    if (err.response?.data) {
      throw err.response.data;
    }
    throw err;
  }
}

interface OtpResponse {
  data: {
    request_token: string;
    salt: string;
  };
  errors?: Array<GraphqlError>;
}

interface LoginOtpResponse extends OtpResponse {
  data: {
    salt: string;
    request_token: string;
    request_new_password: boolean;
    request_to_accept_terms: boolean;
    request_to_accept_privacy_policy: boolean;
  };
}

interface LoginResponse {
  data: {
    token: string;
    token_expires_in: number;
    user_profile: UserType;
    permissions: Array<{ resource: string; actions: Array<string> }>;
  };
  errors?: Array<GraphqlError>;
}

interface ResetPasswordResponse {
  data: {
    success: boolean;
  };
}
