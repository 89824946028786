import * as React from "react";
import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Switch,
  Box,
  Divider,
  Card,
  CardContent,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Pagination } from '@mui/material';
import { Visibility as ShowIcon, VisibilityOff as HideIcon, GetApp as FetchIcon } from '@mui/icons-material';
import {
  Routes,
  Route,
  Link as RouteLink,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ResellerIcon from "@mui/icons-material/Storefront";
import UserIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import OnboardOrganizationIcon from "@mui/icons-material/Input";
import OrganizationIcon from "@mui/icons-material/Business";
import Permissioned, { actions, resources } from "../../permissioned";
import {
  ResellerType,
  CustomError,
  ResellerInput,
  CreateOrganizationOnboardingInput,
} from "../../services/interfaces";
import * as userActions from "../../actions/resellers/users";
import SubmitButton from "../../components/SubmitButton";
import {
  CreateResellerUser,
  EditResellerUser,
  ResellerUsersList,
} from "../resellers/users";
import {
  ResellersPageReducer,
  UpdateResellerReducer,
  CreateOrganizationOnboardingReducer,
} from "../../reducers/autnhive";
import { GlobalReducer, OrganizationsPageReducer, Reducers } from "../../reducers";
import { ResellerUsersPageReducer } from "../../reducers/resellers";
import * as resellersActions from "../../actions/autnhive/resellers";
import MandatoryFieldText from "../../components/mandatoryFieldsText";
import OnboardingForm from "./organization-onboarding-form";
import TagsInput from "../../components/tagInput";
import { fetchResellersOrganizations, getCryptoKey } from "../../services/autnhive/resellers";
import { env } from '../../env';
import * as userProfileActions from "../../actions/userProfile";
// export let env = { ...process.env, ...window['env'] };

const crypto = require('crypto-browserify');


const LIMIT = 20;

const validateEmail = (email: string) => {
  return String(email)
    ?.toLowerCase()
    .match(
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
};

function CreateOrganizationOnboarding(
  props: { onSubmit: () => void; closeModal: () => void }
) {
  const dispatch = useDispatch<any>();
  interface Params {
    resellerId: string;
  }
  const { resellerId } = useParams<keyof Params>() as Params;
  const { enqueueSnackbar } = useSnackbar();
  const { loading, contracts, error } = useSelector<
    Reducers,
    CreateOrganizationOnboardingReducer
  >((state) => state.autnhive.createOrganizationOnboarding);
  useEffect(() => {
    dispatch(resellersActions.fetchContracts(resellerId, true));
  }, [dispatch, resellerId]);
  return (
    <OnboardingForm
      loading={loading}
      contracts={contracts}
      error={error}
      disabled={false}
      variant='create'
      closeModal={props.closeModal}
      onSubmit={(values: CreateOrganizationOnboardingInput) => {
        dispatch(resellersActions.createOrganizationOnboarding(values))
          .then(() =>
            enqueueSnackbar("Organization Onboarding request created", {
              variant: "success",
            })
          )
          .then(props.onSubmit)
          .catch((err: any) => {
            console.log('error', err);
            enqueueSnackbar(err.message, { variant: "error" })
          });
      }}
    />
  );
}

function ResellersList() {
  const resellersPage: ResellersPageReducer = useSelector<
    Reducers,
    ResellersPageReducer
  >((state) => state.autnhive.resellersPage);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(resellersActions.fetchResellers({ keyword: "" }, LIMIT, 1));
    return () => {
      dispatch(resellersActions.resetStates());
    };
  }, [dispatch]);
  return <>
    <List>
      {resellersPage.resellers.map((reseller: ResellerType, index) => {
        return (
          <div>
            <ListItem
              key={reseller.id}
              sx={{
                backgroundColor: index % 2 === 0 ? "#f1f1f1" : "inherit",
                flexDirection: 'column',
                alignItems: 'flex-start',
                width: '100%',
                position: 'relative'
              }}
            >
              <Grid container alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                  <ListItemIcon>
                    <ResellerIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <>
                        {reseller.name}
                        {!reseller.is_active ? (
                          <BlockIcon style={{ marginLeft: 5, fontSize: 15 }} />
                        ) : null}
                      </>
                    }
                    sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  />
                </Grid>
                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                  {reseller.orgsFetched ? (
                    <Tooltip title={reseller.hideOrgs ? "Show Orgs" : "Hide Orgs"}>
                      <IconButton
                        size="large"
                        onClick={() => {
                          dispatch(resellersActions.toggleHideOrgs(reseller.id));
                        }}
                      >
                        {reseller.hideOrgs ? <OrganizationIcon /> : <HideIcon />}
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Fetch Orgs">
                      <IconButton
                        size="large"
                        onClick={() => {
                          dispatch(resellersActions.fetchResellerOrgs(reseller.id));
                        }}
                      >
                        <OrganizationIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Permissioned
                    resource={resources.OrganizationsOnboardings}
                    action={actions.Create}
                  >
                    <RouteLink
                      to={`/admin/autnhive/resellers/${reseller.id}/organizations-onboardings/new`}
                    >
                      <Tooltip title="Organization Onboarding">
                        <IconButton size="large">
                          <OnboardOrganizationIcon />
                        </IconButton>
                      </Tooltip>
                    </RouteLink>
                  </Permissioned>

                  <Permissioned
                    resource={resources.Resellers}
                    action={actions.Read}
                  >
                    <RouteLink
                      to={`/admin/autnhive/resellers/${reseller.id}/users`}
                    >
                      <Tooltip title="Reseller Users">
                        <IconButton size="large">
                          <UserIcon />
                        </IconButton>
                      </Tooltip>
                    </RouteLink>
                  </Permissioned>

                  <Permissioned
                    resource={resources.Resellers}
                    action={actions.Update}
                  >
                    <RouteLink
                      to={`/admin/autnhive/resellers/${reseller.id}/edit`}
                    >
                      <Tooltip title="Edit Reseller">
                        <IconButton size="large">
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </RouteLink>
                  </Permissioned>
                </Grid>
              </Grid>

              {(reseller.orgsFetched && reseller.orgs && !reseller.hideOrgs) && (
                <Box sx={{ width: '100%', marginTop:0 }}>
                  <Grid container spacing={2} sx={{ width: '100%', paddingTop: 0, marginTop: 0 }}>
                    {reseller.orgs.length > 0 ? (
                      reseller.orgs.map((org, index) => (
                        <Grid item xs={12} sm={4} md={3} lg={2} key={org.id}>
                          <Card sx={{ backgroundColor: index % 2 === 0 ? "white" : "#f1f1f1", padding: '5px', borderRadius: '.5rem' }}>
                            <CardContent>
                              <Typography variant="h6" component="div">
                                {org.name}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                {org.email}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))
                    ) : (
                      <Grid item xs={12} sx={{ paddingTop: 0 }}>
                        <Typography variant="body2" color="text.secondary">
                          No organizations added for this reseller.
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )}
            </ListItem>
          </div>
        );
      })}
    </List >

    <Pagination
      count={Math.ceil(resellersPage.count / LIMIT)}
      page={resellersPage.page}
      variant="outlined"
      shape="rounded"
      onChange={(e: React.ChangeEvent<unknown>, page: number) => {
        dispatch(
          resellersActions.fetchResellers(
            { keyword: resellersPage.searchKeyword },
            LIMIT,
            page
          )
        );
      }}
    />
  </>;
}

export interface ResellerFormProps {
  loading: boolean;
  error?: CustomError;
  onSubmit: Function;
  values?: ResellerInput;
}

interface EditResellerProps {
  onSubmit: () => void;
  reseller: ResellerType;
}

export function ResellerForm(props: ResellerFormProps) {
  let nameRef: HTMLInputElement;
  let emailRef: HTMLInputElement;
  let phoneRef: HTMLInputElement;
  let isActiveRef: HTMLInputElement;
  let line1Ref: HTMLInputElement;
  let line2Ref: HTMLInputElement;
  let cityRef: HTMLInputElement;
  let stateRef: HTMLInputElement;
  let postalCodeRef: HTMLInputElement;
  let countryRef: HTMLInputElement;
  let allowed_domains: string[];
  const dispatch = useDispatch<any>();
  const resellersPage: ResellersPageReducer = useSelector<
    Reducers,
    ResellersPageReducer
  >((state) => state.autnhive.resellersPage);
  const whiteLabel = props.values?.is_white_label ? props.values?.is_white_label : false;
  let base = 'https://', url = '';
  if (props?.values?.white_label_url) {
    let protocolEndIndex = props?.values?.white_label_url.indexOf("://") + 3;
    base = props?.values?.white_label_url.substring(0, protocolEndIndex);
    url = props?.values?.white_label_url.substring(protocolEndIndex)
  }
  const [is_white_label, set_is_white_label] = useState(whiteLabel);
  const [is_white_label_email, set_is_white_label_email] = useState(props?.values?.is_white_label_email);
  const [white_label_url, set_white_label_url] = useState(url);
  const [white_label_email_provider, set_white_label_email_provider] = useState(props?.values?.white_label_email_provider ? props?.values?.white_label_email_provider : "outlook");
  const [white_label_email_address, set_white_label_email_address] = useState(props?.values?.white_label_email_address ? props?.values?.white_label_email_address : "");
  const [protocol, setProtocol] = useState(base);
  const {countries} = useSelector<Reducers, GlobalReducer>(
    (state) => state.globe
  );
  useEffect(() => {
    if (!countries || countries.length <= 0) {
      dispatch(
        userProfileActions.fetchCountries()
      );
    }
  }, [])

  const onSubmit = () => {
    props.onSubmit({
      name: nameRef?.value.trim(),
      email: emailRef?.value.trim(),
      phone: phoneRef.value.trim(),
      address: {
        line_1: line1Ref?.value.trim(),
        line_2: line2Ref?.value.trim(),
        city: cityRef?.value.trim(),
        state: stateRef?.value.trim(),
        postal_code: postalCodeRef?.value.trim(),
        country: countryRef?.value,
      },
      is_active: isActiveRef?.checked,
      allowed_domains,
      is_white_label: is_white_label,
      white_label_url: protocol + white_label_url,
    });
  };
  const { values } = props;
  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              variant="standard"
              fullWidth
              required
              error={!!props.error?.validationErrors?.name}
              label="Name"
              defaultValue={values?.name}
              inputRef={(ref) => (nameRef = ref)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              error={!!props.error?.validationErrors?.email}
              required
              label="Email"
              defaultValue={props.values?.email}
              inputRef={(ref) => (emailRef = ref)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              error={!!props.error?.validationErrors?.phone}
              required
              label="Phone"
              placeholder="6471234567"
              defaultValue={props.values?.phone}
              inputRef={(ref) => (phoneRef = ref)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              error={!!props.error?.validationErrors?.address?.line_1}
              required
              label="Address Line 1"
              defaultValue={props.values?.address.line_1}
              inputRef={(ref) => (line1Ref = ref)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              label="Address Line 2"
              defaultValue={props.values?.address.line_2}
              inputRef={(ref) => (line2Ref = ref)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              error={!!props.error?.validationErrors?.address?.city}
              required
              label="City"
              defaultValue={props.values?.address.city}
              inputRef={(ref) => (cityRef = ref)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              error={!!props.error?.validationErrors?.address?.state}
              required
              label="State"
              defaultValue={props.values?.address.state}
              inputRef={(ref) => (stateRef = ref)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              error={!!props.error?.validationErrors?.address?.postal_code}
              required
              label="Postal Code"
              defaultValue={props.values?.address.postal_code}
              inputRef={(ref) => (postalCodeRef = ref)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl margin="normal">
              <InputLabel variant="standard">Country</InputLabel>
              <Select
                variant="standard"
                style={{ width: 195 }}
                defaultValue={props.values?.address.country || "Canada"}
                inputRef={(ref) => (countryRef = ref)}
              >
                {countries?.map(
                  (country) => (
                    <MenuItem key={country.id} value={country.name}>
                      {country.name}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TagsInput
              selectedTags={(items: any) => {
                allowed_domains = items;
              }}
              tags={props.values?.allowed_domains || []}
              fullWidth
              id="tags"
              name="Allowed Domains"
              label="Allowed Domains"
              placeholder="Type and press Enter add domain"
            />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Typography>White-Label</Typography>
            <Tooltip title={is_white_label ? "Disable White-Label" : "Enable White-Label"}>
              <Switch
                disabled={false}
                checked={is_white_label}
                onChange={() => {
                  set_is_white_label(!is_white_label);
                }}
              />
            </Tooltip>
          </Grid>
          {is_white_label ?
            <Grid xs={6} style={{ marginTop: 'auto' }}>
              <Select
                disabled={false}
                className="form-select"
                name='base url select'
                value={protocol}
                onChange={(e) => {
                  setProtocol(e.target.value)

                }}
              >
                <MenuItem value={"https://"}>https://</MenuItem>
                <MenuItem value={"http://"}>http://</MenuItem>
              </Select>
            </Grid> : null
          }
        </Grid>
        {is_white_label ?
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              variant="standard"
              disabled={false}
              value={white_label_url}
              required
              label="White-Label URL"
              defaultValue={''}
              onChange={(e) => {
                set_white_label_url(e.target.value);
              }}
            />
          </Grid> : null}
        <Grid container spacing={3} style={{ marginTop: '5px' }}>
          <Grid item xs={6}>
            <Typography>White-Label Email</Typography>
            <Switch
              disabled={true}
              checked={is_white_label_email}
              onChange={() => {
                const value: boolean = !is_white_label_email;
                set_is_white_label_email(value);
              }}
            />
          </Grid>
          {is_white_label_email ?
            <Grid xs={6} style={{ marginTop: 'auto' }}>
              <Select
                label="Provider"
                disabled={true}
                className="form-select"
                name='email-provider'
                value={white_label_email_provider}
                onChange={(e) => {
                  set_white_label_email_provider(e.target.value)
                }}
              >
                <MenuItem value={"outlook"}>Office 365</MenuItem>
                <MenuItem value={"gmail"}>G-Suite</MenuItem>
              </Select>
            </Grid> : <Grid item xs={6}></Grid>}
        </Grid>
        {is_white_label_email ?
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                margin="normal"
                variant="standard"
                disabled={true}
                // error={
                //   !!props.error?.validationErrors?.new_customer_details?.customer
                //     ?.address?.postal_code
                // }
                value={white_label_email_address}
                required
                label="Email Address"
                defaultValue={''}
                onChange={(e) => {
                  set_white_label_email_address(e.target.value);
                }}
              />
            </Grid></Grid> : null}
        <FormControlLabel
          style={{ marginTop: '10px' }}
          control={
            <Checkbox
              inputRef={(ref) => {
                if (ref) {
                  isActiveRef = ref;
                }
              }}
              defaultChecked={
                values?.is_active !== undefined ? values.is_active : true
              }
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          }
          label="Active"
        />
        <MandatoryFieldText
          hasErrors={Boolean(props.error?.validationErrors)}
        />
        <br />
        <SubmitButton
          loading={props.loading}
          label="Submit"
          onClick={() => onSubmit()}
        />
      </form>
    </div>
  );
}

function EditReseller(props: EditResellerProps) {
  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();
  const updateReseller: UpdateResellerReducer = useSelector<
    Reducers,
    UpdateResellerReducer
  >((state) => state.autnhive.updateReseller);

  const { reseller } = props;
  return (
    <ResellerForm
      loading={updateReseller.loading}
      error={updateReseller.error}
      values={{
        name: reseller.name,
        email: reseller.email,
        phone: reseller.phone,
        address: reseller.address,
        is_active: reseller.is_active,
        allowed_domains: reseller?.allowed_domains,
        is_white_label: reseller?.is_white_label,
        white_label_url: reseller?.white_label_url,
        is_white_label_email: reseller?.is_white_label_email,
        white_label_email_address: reseller?.white_label_email_address,
        white_label_email_provider: reseller?.white_label_email_provider,

      }}
      onSubmit={async (values: ResellerInput) => {
        if (values.is_white_label_email) {
          if (!validateEmail(values.white_label_email_address || "")) {
            enqueueSnackbar('White Label Email Address must be a valid email address', { variant: 'error' });
            return;
          }
        }
        if (!values.is_white_label) {
          delete values.white_label_url;
        }
        if (!values.is_white_label_email) {
          values.is_white_label_email = false;
        }

        dispatch(resellersActions.updateReseller(reseller.id, values))
          .then(() =>
            enqueueSnackbar("Reseller updated", { variant: "success" })
          )
          .then(props.onSubmit)
          .catch((err: Error) =>
            enqueueSnackbar(err.message, { variant: "error" })
          );
      }
        // }
      }
    />
  );
}

function EditResellerModal() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { resellerId } = useParams();
  const resellersPage: ResellersPageReducer = useSelector<
    Reducers,
    ResellersPageReducer
  >((state) => state.autnhive.resellersPage);
  const reseller = resellersPage.resellers.find(
    (r) => r.id === resellerId
  );
  if (!reseller) return null;
  return (
    <Dialog open={true} onClose={() => navigate(-1)}>
      <DialogTitle>Update Reseller</DialogTitle>
      <DialogContent>
        <EditReseller
          reseller={reseller}
          onSubmit={() => {
            navigate(-1);
            dispatch(
              resellersActions.fetchResellers(
                { keyword: resellersPage.searchKeyword },
                LIMIT,
                resellersPage.page
              )
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

function ResellerUsersListModal() {
  const navigate = useNavigate();
  interface Params {
    resellerId: string;
  }
  const { resellerId } = useParams<keyof Params>() as Params;
  const resellersPage: ResellersPageReducer = useSelector<
    Reducers,
    ResellersPageReducer
  >((state) => state.autnhive.resellersPage);
  const reseller = resellersPage.resellers.find(
    (r) => r.id === resellerId
  );
  return (
    <Dialog open fullWidth onClose={() => navigate(-1)}>
      <DialogTitle>Users: {reseller?.name}</DialogTitle>
      <DialogContent>
        <ResellerUsersList resellerId={resellerId} />
      </DialogContent>
    </Dialog>
  );
}

function CreateResellerUserModal() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  interface Params {
    resellerId: string;
  }
  const { resellerId } = useParams<keyof Params>() as Params;
  const resellersPage: ResellersPageReducer = useSelector<
    Reducers,
    ResellersPageReducer
  >((state) => state.autnhive.resellersPage);
  const reseller = resellersPage.resellers.find(
    (r) => r.id === resellerId
  );
  const resellerUsersPage = useSelector<Reducers, ResellerUsersPageReducer>(
    (state) => state.reseller.usersPage
  );

  return (
    <Dialog open={true} onClose={() => navigate(-1)}>
      <DialogTitle>Add User: {reseller?.name}</DialogTitle>
      <DialogContent>
        <CreateResellerUser
          resellerId={resellerId}
          onSuccess={() => {
            navigate(-1);

            dispatch(
              userActions.fetchResellerUsers({
                limit: LIMIT,
                page: 1,
                keyword: resellerUsersPage.searchKeyword,
                reseller_id: resellerId,
              })
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

function EditResellerUserModal() {
  const navigate = useNavigate();
  interface Params {
    resellerId: string;
    userId: string
  }
  const resellersPage: ResellersPageReducer = useSelector<
    Reducers,
    ResellersPageReducer
  >((state) => state.autnhive.resellersPage);
  const resellerUsersPage = useSelector<Reducers, ResellerUsersPageReducer>(
    (state) => state.reseller.usersPage
  );
  const { resellerId, userId } = useParams<keyof Params>() as Params;
  const reseller = resellersPage.resellers.find(
    (r) => r.id === resellerId
  );
  const user = resellerUsersPage.users.find((u) => u.id === userId);
  if (!user) {
    return null;
  }
  return (
    <Dialog open={true} onClose={() => navigate(-1)}>
      <DialogTitle>Users: {reseller?.name}</DialogTitle>
      <DialogContent>
        <EditResellerUser
          resellerId={resellerId}
          user={user}
          onSuccess={() => {
            navigate(-1);
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

export function Resellers() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const resellersPage: ResellersPageReducer = useSelector<
    Reducers,
    ResellersPageReducer
  >((state) => state.autnhive.resellersPage);
  return (
    <div>
      <Typography component="h1" variant="h5">
        Resellers
      </Typography>
      <div>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <TextField
              size="small"
              label="Search Resellers"
              variant="outlined"
              onChange={(e) => {
                dispatch(resellersActions.updateSearchKeyword(e.target.value));
              }}
              onKeyPress={(e) => {
                if (e.key !== "Enter") {
                  return;
                }
                dispatch(
                  resellersActions.fetchResellers(
                    { keyword: resellersPage.searchKeyword },
                    LIMIT,
                    1
                  )
                );
              }}
            />
          </Grid>
        </Grid>
      </div>
      <ResellersList />

      <Routes>
        <Route path="/:resellerId/organizations-onboardings/new" element={<>
          <Dialog open onClose={() => navigate(-1)}>
            <DialogTitle>New Organization</DialogTitle>
            <DialogContent>
              <CreateOrganizationOnboarding
                onSubmit={() => {
                  navigate(-1);
                }}
                closeModal={() => navigate(-1)}
              />
            </DialogContent>
          </Dialog>
        </>} />

        <Route path="/:resellerId/edit" element={<EditResellerModal />} />

        <Route path="/:resellerId/users" element={<ResellerUsersListModal />} />

        <Route path="/:resellerId/users/add" element={<CreateResellerUserModal />} />

        <Route path="/:resellerId/users/:userId/edit" element={<EditResellerUserModal />} />
      </Routes>
    </div>
  );
}

export default Resellers;
