import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  Backdrop,
  CircularProgress,
  List,
  ListItem,
  IconButton,
  ListItemSecondaryAction,
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Box,
} from "@mui/material";
import { Pagination } from '@mui/material';
import {
  Route,
  Link as RouteLink,
  Routes,
  useParams,
  useNavigate
} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import ApproveIcon from "@mui/icons-material/Check";
import RejectIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Permissioned, { actions, resources } from "../../permissioned";
import { useSnackbar } from "notistack";
import { Reducers } from "../../reducers";
import {
  OrganizationsOnboardingsPageReducer,
  UpdateOrganizationOnboardingReducer,
} from "../../reducers/autnhive";
import ConfirmButton from "../../components/confirmButton";
import * as onboardingActions from "../../actions/autnhive/organizations-onboardings";
import { OrganizationOnboardingType } from "../../services/interfaces";
import OnboardingForm from "./organization-onboarding-form";

const LIMIT = 20;

function OrganizationOnboardingList() {
  const [isProcessing, setIsProcessing] = useState(false);
  const [rejectionReason, setRejectionReason] = useState<string>("");
  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();
  const { onboardings, page, count } = useSelector<
    Reducers,
    OrganizationsOnboardingsPageReducer
  >((state) => state.autnhive.organizationsOnboardingsPage);
  const organizationsOnboardingsPage = useSelector<Reducers, OrganizationsOnboardingsPageReducer>(
    (state) => state.autnhive.organizationsOnboardingsPage
  );
  useEffect(() => {
    dispatch(
      onboardingActions.fetchOrganizationOnboardings(
        {
          customer_name: organizationsOnboardingsPage.customer_name,
          status: organizationsOnboardingsPage.status,
        },
        1,
        LIMIT
      )
    );
  }, [dispatch]);
  const now = moment();
  return <>
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        color: "#fff"
      }}
      open={isProcessing}
      onClick={() => setIsProcessing(false)}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    <List>
      {onboardings.map((onboarding: OrganizationOnboardingType, index) => {
        let name = onboarding.organization_details.name;
        const expiryDate = moment.unix(onboarding?.subscription_details?.[0]?.license_expires_at);
        const warnDate = moment.unix(onboarding?.subscription_details?.[0]?.license_expires_at).subtract(30, 'days');
        const color = now.isBefore(expiryDate) && now.isAfter(warnDate) ? '#FFBF00' : 
        now.isBefore(expiryDate) ? '#4BB543' : 
        '#FF0000';
        return (
          <ListItem
            key={onboarding.id}
            sx={{
              backgroundColor: index % 2 === 0 ? "#f1f1f1" : "inherit"
            }}
          >
            <div>
              <h3 style={{marginBottom:'2px'}}>
                {name}
                {onboarding.status === "approved" ? (
                  <Tooltip title="Organization approved">
                    <CheckCircleOutlineIcon
                      fontSize="small"
                      htmlColor="green"
                    />
                  </Tooltip>
                ) : null}
                {onboarding.status === "rejected" ? (
                  <Tooltip title="Organization rejected">
                    <RejectIcon fontSize="small" htmlColor="red" />
                  </Tooltip>
                ) : null}
              </h3>
                <div style={{display: 'flex', flexDirection:'row', justifyContent:'flex-start', alignItems: 'center'}}>
                  {onboarding?.contractProductDetails?.map((detail:any) => (
                    <Box key={detail.contract_id}>
                    <Typography variant="body1" component="span" sx={{ fontSize: '.85rem',fontWeight: 'bold' }}>
                      {detail.product_name}:
                    </Typography>
                    <Typography variant="body2" component="span" sx={{ color: color, fontWeight: 550, ml:1, fontSize:'.85rem' }}>
                      {moment.unix(parseInt(detail.contract_start_date) / 1000).format("YYYY-MM-DD")} - 
                      {moment.unix(parseInt(detail.contract_end_date) / 1000).format("YYYY-MM-DD")}
                    </Typography>
                    &nbsp;
                  </Box>)
                  )}
                </div>
              <br />
            </div>
            <ListItemSecondaryAction>
              {onboarding.status === "draft" ? (
                <Permissioned
                  resource={resources.OrganizationsOnboardings}
                  action={actions.ApproveReject}
                >
                  <ConfirmButton
                    heading="Confirm"
                    message="Do you want to approve this organization?"
                    component={IconButton}
                    onClick={() => {
                      setIsProcessing(true);
                      dispatch(
                        onboardingActions.approveRejectOnboarding(
                          onboarding.id,
                          "approve"
                        )
                      )
                        .then(() => {
                          enqueueSnackbar(`Onboarding approved`, {
                            variant: "success",
                          });
                        })
                        .then(() => {
                          dispatch(
                            onboardingActions.fetchOrganizationOnboardings(
                              {
                                customer_name: organizationsOnboardingsPage.customer_name,
                                status: organizationsOnboardingsPage.status,
                              },
                              organizationsOnboardingsPage.page,
                              LIMIT
                            )
                          );
                        })
                        .catch((err: any) => {
                          enqueueSnackbar(err.message, {
                            variant: "error",
                          });
                        })
                        .finally(() => setIsProcessing(false));
                    }}
                  >
                    <Tooltip title="Approve onboarding">
                      <ApproveIcon />
                    </Tooltip>
                  </ConfirmButton>
                  <ConfirmButton
                    heading="Reject"
                    message="Do you want to reject this onboarding?"
                    component={IconButton}
                    negativeButtonLabel="Cancel"
                    positiveButtonLabel="Submit"
                    form={
                      <FormControl fullWidth>
                        <TextField
                          label="Reason"
                          onChange={(e) => setRejectionReason(e.target.value)}
                        />
                      </FormControl>
                    }
                    onClick={() => {
                      setIsProcessing(true);
                      dispatch(
                        onboardingActions.approveRejectOnboarding(
                          onboarding.id,
                          "reject",
                          rejectionReason
                        )
                      )
                        .then(() => {
                          enqueueSnackbar(`Onboarding rejected`, {
                            variant: "success",
                          });
                        })
                        .then(() => {
                          dispatch(
                            onboardingActions.fetchOrganizationOnboardings(
                              {
                                customer_name: organizationsOnboardingsPage.customer_name,
                                status: organizationsOnboardingsPage.status,
                              },
                              organizationsOnboardingsPage.page,
                              LIMIT
                            )
                          );
                        })
                        .catch((err: any) => {
                          enqueueSnackbar(err.message, {
                            variant: "error",
                          });
                        })
                        .finally(() => setIsProcessing(false));
                    }}
                  >
                    <Tooltip title="Reject onboarding">
                      <RejectIcon />
                    </Tooltip>
                  </ConfirmButton>
                </Permissioned>
              ) : null}

              {/* {onboarding.status === "draft" ||
              onboarding.status === "rejected" ? ( */}
              <Permissioned
                resource={resources.OrganizationsOnboardings}
                action={actions.Update}
              >
                <RouteLink
                  to={`/admin/autnhive/organizations-onboardings/${onboarding.id}/edit`}
                >
                  <Tooltip title="Edit onboarding">
                    <IconButton aria-label="Edit onboarding" size="large">
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </RouteLink>
              </Permissioned>
              {/* ) : null} */}
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
    <Pagination
      count={Math.ceil(count / LIMIT)}
      page={page}
      variant="outlined"
      shape="rounded"
      onChange={(e: React.ChangeEvent<unknown>, page: number) => {
        dispatch(onboardingActions.setPage(page));
        dispatch(
          onboardingActions.fetchOrganizationOnboardings(
            {
              customer_name: organizationsOnboardingsPage.customer_name,
              status: organizationsOnboardingsPage.status,
            },
            page,
            LIMIT
          )
        );
      }}
    />
  </>;
}

function EditOnboarding({
  id,
  values,
  onSubmit,
  closeModal
}: {
  id: string;
  onSubmit: () => void;
  values: OrganizationOnboardingType;
  closeModal: () => void;
}) {
  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();
  const { contracts, loading, contractsFetched, attempts, error } = useSelector<
    Reducers,
    UpdateOrganizationOnboardingReducer
  >((state) => state.autnhive.updateOrganizationOnboarding);

  useEffect(() => {
    if (!loading && !contractsFetched && attempts < 3) {
      dispatch(onboardingActions.fetchContracts(values.reseller.id, true));
    }
  }, [dispatch,values.reseller.id]);
  return (
    <OnboardingForm
      error={error}
      loading={loading}
      contracts={contracts}
      values={values}
      disabled={true}
      variant="edit"
      closeModal={closeModal}
      onSubmit={(updatedValues) => {
        dispatch(onboardingActions.updateOnboarding(id, updatedValues))
          .then(() =>
            enqueueSnackbar("Onboarding request updated", {
              variant: "success",
            })
          )
          .then(onSubmit)
          .catch((err: any) =>
            enqueueSnackbar(err.message, { variant: "error" })
          );
      }}
    />
  );
}

export default function OrganizationsOnboardings() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { onboardings } = useSelector<
    Reducers,
    OrganizationsOnboardingsPageReducer
  >((state) => state.autnhive.organizationsOnboardingsPage);
  const organizationsOnboardingsPage = useSelector<Reducers, OrganizationsOnboardingsPageReducer>(
    (state) => state.autnhive.organizationsOnboardingsPage
  );

  function EditModal() {
    interface Params {
      onboardingId: string;
    }
    const { onboardingId } = useParams<keyof Params>() as Params;
    const onboarding = onboardings.find((r) => r.id === onboardingId);
    if (!onboarding) {
      return null;
    }
    const [modalOpen, setMobalOpen] = useState<boolean>(true);
    return (
      <Dialog open={modalOpen} onClose={() => navigate(-1)}>
        <DialogTitle>Reseller: {onboarding.reseller.name}</DialogTitle>
        <DialogContent>
          <EditOnboarding
            id={onboardingId}
            values={onboarding}
            closeModal={() => {
              navigate(-1)
              dispatch(onboardingActions.fetchOrganizationOnboardings(
                {
                  customer_name: organizationsOnboardingsPage.customer_name,
                  status: organizationsOnboardingsPage.status,
                },
                organizationsOnboardingsPage.page,
                LIMIT
              ))
            }}
            onSubmit={() => {
              dispatch(
                onboardingActions.fetchOrganizationOnboardings(
                  {
                    customer_name: organizationsOnboardingsPage.customer_name,
                    status: organizationsOnboardingsPage.status,
                  },
                  organizationsOnboardingsPage.page,
                  LIMIT
                )
              );
              navigate(-1);
            }}
          />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <>
      <Typography component="h1" variant="h5">
        Organization Onboarding
      </Typography>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <TextField
            margin="normal"
            variant="standard"
            size="small"
            label="Organization name"
            defaultValue={organizationsOnboardingsPage.customer_name}
            onChange={(e) => {
              dispatch(onboardingActions.updateSearchKeyword(e.target.value));
            }}
            onKeyPress={(e) => {
              if (e.key !== "Enter") {
                return;
              }
              dispatch(
                onboardingActions.fetchOrganizationOnboardings(
                  {
                    customer_name: organizationsOnboardingsPage.customer_name,
                    status: organizationsOnboardingsPage.status,
                  },
                  1,
                  LIMIT
                )
              );
            }}
          />
        </Grid>
        <Grid item>
          <FormControl margin="normal" fullWidth>
            <InputLabel variant="standard" id="status">Status</InputLabel>
            <Select
              variant="standard"
              fullWidth
              labelId="status"
              id="status"
              style={{ minWidth: "100px" }}
              onChange={(e: any) => {
                let value = e.target.value as
                  | "draft"
                  | "approved"
                  | "rejected"
                  | undefined;
                value = !value ? undefined : value;
                dispatch(onboardingActions.updateStatusFilter(value));
                dispatch(
                  onboardingActions.fetchOrganizationOnboardings(
                    {
                      customer_name: organizationsOnboardingsPage.customer_name,
                      status: value,
                    },
                    1,
                    LIMIT
                  )
                );
              }}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="draft">Draft</MenuItem>
              <MenuItem value="approved">Approved</MenuItem>
              <MenuItem value="rejected">Rejected</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Permissioned
        resource={resources.OrganizationsOnboardings}
        action={actions.Read}
      >
        <OrganizationOnboardingList />
      </Permissioned>
      <Routes>
        <Route
          path="/:onboardingId/edit"
          element={<EditModal />}
        />
      </Routes>
    </>
  );
}
