import * as React from "react";
import { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Tooltip,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
} from "@mui/material";
import Permissioned, { actions, resources } from "../permissioned";
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { Pagination } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Link as RouteLink,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { EasaPageReducer } from "../reducers/autnhive";
import { Reducers } from "../reducers";
import * as easaActions from "../actions/easa";
import moment from "moment";
import { Visibility } from "@mui/icons-material";
import no_image from "../assets/images/no_image.png";
import { DomainJobsList } from "../services/interfaces";
import { getDuration } from "../services/helpers";

const LIMIT = 20;

export default function Jobs() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const location = useLocation()

  const { domainJobs, updateLogo, loading }: EasaPageReducer = useSelector<
    Reducers,
    EasaPageReducer
  >((state) => state.autnhive.easa);

  const [domainName, setDomainName] = useState<string>("");
  const domainDetails = localStorage.getItem("domainDetails");
  const [logoUpdateLoading, setLogoUpdateLoading] = useState(false)
  const [updateLogoModal, setUpdateLogoModal] = useState(false)
  const [domainLogo, setDomainLogo] = useState("")
  const [latestParentJob, setLatestParentJob] = useState("")

  useEffect(() => {
    if (domainDetails && domainDetails !== "") {
      const result = JSON.parse(domainDetails);
      setDomainName(result?.url);
      setDomainLogo(location?.state?.logo)
      if (domainJobs?.list?.length === 0) {
        dispatch(easaActions.fetchDomainJobsList(1, 20, result?.url));
      }
    }
  }, [domainDetails]);

  useEffect(() =>{
    if(latestParentJob.length == 0){
      if(domainJobs?.list?.length > 0){
        setLatestParentJob(domainJobs?.list[0]._id)
      }
    }
  }, [domainJobs])
  //   useEffect(() => {
  //     return () => {
  //       dispatch(easaActions.resetJobsStates());
  //     };
  //   }, [dispatch]);

  return (
    <div>
      <Typography component="h1" variant="h5">
        Jobs List of {domainName}
      </Typography>
      <div className="mb-2">
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
                localStorage.removeItem("domainDetails");
                dispatch(easaActions.resetJobsStates());
              }}
            >
              Domains List
            </span>
            {` > `}
            <span>Jobs</span>
          </Grid>
          <Grid item style={{marginLeft:'auto'}}>
            {domainLogo ? <img src={domainLogo} style={{width:'80px', height:'70px', cursor:'pointer'}} onClick={()=>{
              setUpdateLogoModal(true)
            }}/> : <img src={`https://logo.clearbit.com/${domainName}` } style={{width:'80px', height:'70px'}} onError={({ currentTarget }) => {
              currentTarget.onerror = null; 
              currentTarget.src= no_image;
            }} onClick={()=>{
              setUpdateLogoModal(true)
            }}/>
            }
          </Grid>
        </Grid>
        <div
          className="app-container domanis-table"
          style={{
            display: "flex",
            padding: "1rem",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <table className="domains-table">
            <thead>
              <tr>
                <th>Job</th>
                <th>Status</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Scan Duration</th>
                <th>Created By</th>
                <th>Total Score</th>
                {/* <th>Active</th> */}
              </tr>
            </thead>
            {domainJobs?.loading ? (
              <tbody>
                <tr>
                  <td colSpan={7}>
                    <CircularProgress size={26} color="inherit" />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {domainJobs?.list.map((job, index) => (
                  <tr
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(
                        easaActions.fetchDomainTasksList(
                          1,
                          LIMIT,
                          job?._id,
                        )
                      );
                      let obj = {
                        parentJobId: job?._id,
                        latestParentJobId: latestParentJob?.length > 0 ? latestParentJob : domainJobs?.list[0]._id
                      };
                      localStorage.setItem("jobDetails", JSON.stringify(obj));
                      navigate("tasks");
                      e.stopPropagation();
                    }}
                  >
                    <td>{index + 1}</td>
                    <td>{job?.status}</td>
                    <td>
                      {moment(job?.created_at).format("DD-MMM-YYYY hh:mm A")}
                    </td>
                    <td>
                      {['Initiated', 'Created'].includes(job?.status) ? '-' : moment(job?.updated_at).format("DD-MMM-YYYY hh:mm A")}
                    </td>
                    <td>{getDuration(job)}</td>
                    <td>{job?.organization_name ? `${job?.organization_name} -> ${job?.created_by?.name}` : job?.created_by?.name}</td>
                    <td>{job?.total_score}</td>
                    {/* <td>{job?.is_active ? "Yes" : "No"}</td> */}
                  </tr>
                ))}
                {!domainJobs?.list ||
                domainJobs?.list.length === 0 ||
                domainJobs.count === 0 ? (
                  <tr>
                    <td
                      colSpan={7}
                      style={{
                        fontStyle: "italic",
                        fontWeight: "normal",
                        color: "#343434",
                        padding: "0",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <span>No data available</span>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            )}
          </table>
        </div>
        {domainJobs?.count > LIMIT && (
          <Pagination
            count={Math.ceil(domainJobs?.count / LIMIT)}
            variant="outlined"
            shape="rounded"
            onChange={(e: React.ChangeEvent<unknown>, newPage: number) => {
              dispatch(easaActions.fetchDomainsList(newPage, LIMIT));
            }}
          />
        )}
      </div>
      
      <Dialog fullWidth maxWidth='xs' open={updateLogoModal} onClose={() => navigate(-1)}>
      <DialogTitle>Update Logo</DialogTitle>
      <DialogContent>
        <div>
          <TextField
              margin="normal"
              variant="standard"
              fullWidth
              required
              label="Logo URL"
              value={domainLogo}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                setDomainLogo(e.target.value as string)
              }
            />
            <Button
              style={{ width: 150 }}
              color="primary"
              variant="contained"
              onClick={()=>{
                setLogoUpdateLoading(true)
                try {
                  dispatch(
                    easaActions.updateDomainLogo(
                      location?.state?._id, domainLogo
                    )
                  )
                  setLogoUpdateLoading(false)
                  setUpdateLogoModal(false)
                } catch (err) {
                  
                }
              }}
            >
              {logoUpdateLoading   ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Submit'
              )}
            </Button>
            <Button color="secondary"
              style={{marginLeft:'15px', width:'150px'}}
              onClick={() => {
                setUpdateLogoModal(false)
              }}>
                Cancel
            </Button>
        </div>
      </DialogContent>
    </Dialog>
      
    </div>
  );
}
