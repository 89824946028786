import {
  CreateRoleInput,
  CreateUserFormInput,
  CreateVendorLinkInput,
  UserFormInput,
} from "../services/interfaces";
import * as usersService from "../services/users";
import * as organizationsService from "../services/organizations";

export const usersActions: any = {
  usersWillLoad: "USERS_WILL_SUBMIT",
  usersLoadSucceeded: "USERS_LOAD_SUCCEEDED",
  usersLoadFailed: "USERS_LOAD_FAILED",
  rolesWillLoad: "ADMIN_USERS_PAGE_ROLES_WILL_LOAD",
  rolesLoadSucceeded: "ADMIN_USERS_PAGE_ROLES_LOAD_SUCCEEDED",
  rolesLoadFailed: "ADMIN_USERS_PAGE_ROLES_LOAD_FAILED",
  createUser: "ADMIN_CREATE_USER",
  createUserSucceeded: "ADMIN_CREATE_USER_SUCCEEDED",
  createUserFailed: "ADMIN_CREATE_USER_FAILED",
  userBulkUpload: "ADMIN_USER_BULK_UPLOAD",
  userBulkUploadSucceeded: "ADMIN_USER_BULK_UPLOAD_SUCCEEDED",
  userBulkUploadFailed: "ADMIN_USER_BULK_UPLOAD_FAILED",
  updateUser: "ADMIN_UPDATE_USER",
  updateUserSucceeded: "ADMIN_UPDATE_USER_SUCCEEDED",
  updateUserFailed: "ADMIN_UPDATE_USER_FAILED",
  dismissUserMessages: "ADMIN_USER_DISMISS_MESSAGES",
  organizationsWillLoad: "ADMIN_USER_ORG_WILL_LOAD",
  organizationsLoadSucceeded: "ADMIN_USER_ORG_LOAD_SUCCEEDED",
  organizationsLoadFailed: "ADMIN_USER_ORG_LOAD_FAILED",
  createVendorLink: "ADMIN_CREATE_VENDOR_LINK",
  createVendorLinkSucceeded: "ADMIN_CREATE_VENDOR_LINK_SUCCEEDED",
  createVendorLinkFailed: "ADMIN_CREATE_VENDOR_LINK_FAILED",
  dismissCreateRoleMessages: "CREATE_ROLE_DISMISS_MESSAGES",
  userProductSubscriptionsWillLoad: "ADMIN_USER_PRODUCT_SUBSCRIPTION_WILL_LOAD",
  userProductSubscriptionsLoadSucceeded:
    "ADMIN_USER_PRODUCT_FETCH_SUBSCRIPTION_SUCCEEDED",
  userProductSubscriptionsLoadFailed:
    "ADMIN_USER_PRODUCT_FETCH_SUBSCRIPTION_FAILED",
  createProductSubscription: "ADMIN_CREATE_USER_PRODUCT_SUBSCRIPTION",
  createProductSubscriptionSucceeded:
    "ADMIN_CREATE_USER_PRODUCT_SUBSCRIPTION_SUCCEEDED",
  createProductSubscriptionFailed:
    "ADMIN_CREATE_USER_PRODUCT_SUBSCRIPTION_FAILED",
  updateProductSubscriptionStatus:
    "ADMIN_UPDATE_USER_PRODUCT_STATUS_SUBSCRIPTION",
  updateProductSubscriptionStatusSucceeded:
    "ADMIN_UPDATE_USER_PRODUCT_SUBSCRIPTION_STATUS_SUCCEEDED",
  updateProductSubscriptionStatusFailed:
    "ADMIN_UPDATE_USER_PRODUCT_SUBSCRIPTION_STATUS_FAILED",
  dismissSubmitUserBulkUploadMessages:
    "ADMIN_USER_BULK_UPLOAD_DISMISS_MESSAGES",
  resetUserBulkUploadStates: "ADMIN_RESET_USER_BULK_UPLOAD_STATES",
  selectOrganization: "ADMIN_USER_SELECT_ORGANIZATION",
  updateSearchKeyword: "ADMIN_USER_UPDATE_SERACH_KEYWORD",
  resetStates: "ADMIN_USER_RESET_STATES",
};

export const fetchUsers =
  ({
    limit,
    page,
    keyword,
    organization_id,
  }: {
    limit: number;
    page: number;
    keyword?: string;
    organization_id?: string;
  }) =>
  async (dispatch: any) => {
    dispatch({
      type: usersActions.usersWillLoad,
      payload: { page, organization_id },
    });
    try {
      dispatch({
        type: usersActions.usersLoadSucceeded,
        payload: await usersService.fetchUsers({
          limit,
          page,
          organization_id,
          keyword,
        }),
      });
    } catch (err) {
      dispatch({
        type: usersActions.usersLoadFailed,
        payload: err,
      });
    }
  };

export const searchOrganizations =
  (keyword: string) => async (dispatch: any) => {
    if (keyword?.length < 3) {
      dispatch({
        type: usersActions.organizationsLoadSucceeded,
        payload: [],
      });
      return;
    }
    dispatch({ type: usersActions.organizationsWillLoad });
    try {
      dispatch({
        type: usersActions.organizationsLoadSucceeded,
        payload: (
          await organizationsService.fetchOrganizations({
            keyword,
            limit: 20,
            page: 1,
          })
        ).list,
      });
    } catch (err) {
      dispatch({
        type: usersActions.organizationsLoadFailed,
        payload: err,
      });
    }
  };

export const createVendorLink =
(values: CreateVendorLinkInput) => async (dispatch: any) => {
  dispatch({ type: usersActions.createVendorLink });
  try {
    dispatch({
      type: usersActions.createVendorLinkSucceeded,
      payload: await usersService.createVendorLink(values),
    });
  } catch (e) {
    dispatch({
      type: usersActions.createVendorLinkFailed,
      payload: e,
    });
  }
};

export const createRole =
  (values: CreateRoleInput) => async (dispatch: any) => {
    dispatch({ type: usersActions.createRole });
    try {
      dispatch({
        type: usersActions.createRoleSucceeded,
        payload: await usersService.createRole(values),
      });
    } catch (e) {
      dispatch({
        type: usersActions.createRoleFailed,
        payload: e,
      });
    }
  };

export const fetchRoles =
  (limit: number, page: number, filters: { organization_id?: string }) =>
  async (dispatch: any) => {
    dispatch({ type: usersActions.rolesWillLoad });
    try {
      dispatch({
        type: usersActions.rolesLoadSucceeded,
        payload: (
          await usersService.fetchRoles(limit, page, filters)
        ).list.filter((role) => role.role_for !== "autnhive"),
      });
    } catch (err) {
      dispatch({
        type: usersActions.rolesLoadFailed,
        payload: err,
      });
    }
  };

export const createUser =
  (values: CreateUserFormInput) => async (dispatch: any) => {
    dispatch({ type: usersActions.createUser });
    try {
      dispatch({
        type: usersActions.createUserSucceeded,
        payload: await usersService.createUser(values),
      });
    } catch (e) {
      dispatch({
        type: usersActions.createUserFailed,
        payload: e,
      });
      throw e;
    }
  };

export const userBulkUpload =
  (organization_id: string, file: File, subscription_id?: string) =>
  async (dispatch: any) => {
    dispatch({ type: usersActions.userBulkUpload });
    try {
      dispatch({
        type: usersActions.userBulkUploadSucceeded,
        payload: await usersService.userBulkUpload(
          organization_id,
          file,
          subscription_id
        ),
      });
    } catch (err: any) {
      dispatch({
        type: usersActions.userBulkUploadFailed,
        payload: err,
      });
      throw err;
    }
  };

export const updateUser =
  (id: string, values: UserFormInput) => async (dispatch: any) => {
    dispatch({ type: usersActions.updateUser });
    try {
      dispatch({
        type: usersActions.updateUserSucceeded,
        payload: await usersService.updateUser(id, values),
      });
    } catch (e) {
      dispatch({
        type: usersActions.updateUserFailed,
        payload: e,
      });
      throw e;
    }
  };

export const fetchUserProductSubscriptions =
  (organization_id: string, use_id: string, email: string) =>
  async (dispatch: any) => {
    dispatch({ type: usersActions.userProductSubscriptionsWillLoad });
    try {
      dispatch({
        type: usersActions.userProductSubscriptionsLoadSucceeded,
        payload: await usersService.fetchUserSubscriptions(
          organization_id,
          use_id,
          email
        ),
      });
    } catch (e) {
      dispatch({
        type: usersActions.userProductSubscriptionsLoadFailed,
        payload: e,
      });
    }
  };

export const updateUserProductSubscriptionStatus =
  (id: string, is_active: boolean) => async (dispatch: any) => {
    dispatch({ type: usersActions.updateProductSubscriptionStatus });
    try {
      dispatch({
        type: usersActions.updateProductSubscriptionStatusSucceeded,
        payload: await usersService.updateUserSubscriptionStatus(id, is_active),
      });
    } catch (e) {
      dispatch({
        type: usersActions.updateProductSubscriptionStatusFailed,
        payload: e,
      });
      throw e;
    }
  };

export const createUserProductSubscription =
  (user_id: string, subscription_id: string) => async (dispatch: any) => {
    dispatch({ type: usersActions.createProductSubscription });
    try {
      dispatch({
        type: usersActions.createProductSubscriptionSucceeded,
        payload: await usersService.createUserSubscription(
          user_id,
          subscription_id
        ),
      });
    } catch (e) {
      dispatch({
        type: usersActions.createProductSubscriptionFailed,
        payload: e,
      });
      throw e;
    }
  };

export const dismissUserMessages = () => ({
  type: usersActions.dismissUserMessages,
});

export const dismissCreateRoleMessages = () => ({
  type: usersActions.dismissCreateRoleMessages,
});

export const dismissSubmitUserBulkUploadMessages = () => ({
  type: usersActions.dismissSubmitUserBulkUploadMessages,
});

export const resetUserBulkUploadStates = () => ({
  type: usersActions.resetUserBulkUploadStates,
});

export const updateSearchKeyword = (keyword: string) => ({
  type: usersActions.updateSearchKeyword,
  payload: keyword,
});

export const resetStates = () => ({
  type: usersActions.resetStates,
});

export const selectOrganization = (organization_id: string) => ({
  type: usersActions.selectOrganization,
  payload: organization_id,
});
