import {
  OrganizationFormInput,
  UpdateSubscriptionInput,
} from "../services/interfaces";
import * as organizationsService from "../services/organizations";
import * as resellersService from "../services/autnhive/resellers";
import * as subscriptionsService from "../services/orgLevel/productSubscriptions";

export const organizationsActions: any = {
  organizationsWillLoad: "ADMIN_ORGANIZATIONS_WILL_LOAD",
  organizationsLoadSucceeded: "ADMIN_ORGANIZATIONS_LOAD_SUCCEEDED",
  organizationsLoadFailed: "ADMIN_ORGANIZATIONS_LOAD_FAILED",

  referralsWillLoad: "ADMIN_REFERRALS_WILL_LOAD",
  referralsLoadSucceeded: "ADMIN_REFERRALS_LOAD_SUCCEEDED",
  referralsLoadFailed: "ADMIN_REFERRALS_LOAD_FAILED",

  productsWillLoad: "ADMIN_PRODUCTS_WILL_LOAD",
  productsLoadSucceeded: "ADMIN_PRODUCTS_LOAD_SUCCEEDED",
  productsLoadFailed: "ADMIN_PRODUCTS_LOAD_FAILED",

  packagesWillLoad: "ADMIN_PACKAGES_WILL_LOAD",
  packagesLoadSucceeded: "ADMIN_PACKAGES_LOAD_SUCCEEDED",
  packagesLoadFailed: "ADMIN_PACKAGES_LOAD_FAILED",

  updateOrganization: "ADMIN_UPDATE_ORGANIZATION",
  updateOrganizationSucceeded: "ADMIN_UPDATE_ORGANIZATION_SUCCEEDED",
  updateOrganizationFailed: "ADMIN_UPDATE_ORGANIZATION_FAILED",

  resetStates: "ADMIN_ORGANIZATIONS_RESET_STATES",
  dismissMessages: "ADMIN_CREATE_ORGANIZATION_DISMISS_MESSAGES",
  productSubscriptionsWillLoad: "ADMIN_PRODUCT_SUBSCRIPTION_WILL_LOAD",
  productSubscriptionsLoadSucceeded: "ADMIN_PRODUCT_SUBSCRIPTION_WILL_FETCH",
  productSubscriptionsLoadFailed: "ADMIN_PRODUCT_SUBSCRIPTION_WILL_SUCCEEDED",
  productSubscriptionsHistoryWillLoad:
    "ADMIN_PRODUCT_SUBSCRIPTION_HISTORY_WILL_LOAD",
  productSubscriptionsHistoryLoadSucceeded:
    "ADMIN_PRODUCT_SUBSCRIPTION_HISTORY_WILL_FETCH",
  productSubscriptionsHistoryLoadFailed:
    "ADMIN_PRODUCT_SUBSCRIPTION_HISTORY_WILL_SUCCEEDED",
  dismissCreateProductSubscriptionMessages:
    "ADMIN_CREATE_PRODUCT_SUBSCRIPTION_DISMISS_MESSAGES",
  updateProductSubscriptionStatus:
    "ADMIN_UPDATE_PRODUCT_SUBSCRIPTION_STATUS_STATUS",
  updateProductSubscriptionStatusSucceeded:
    "ADMIN_UPDATE_PRODUCT_SUBSCRIPTION_STATUS_SUCCEEDED",
  updateProductSubscriptionStatusFailed:
    "ADMIN_UPDATE_PRODUCT_SUBSCRIPTION_STATUS_FAILED",
  updateProductSubscription: "ADMIN_UPDATE_PRODUCT_SUBSCRIPTION_STATUS",
  updateProductSubscriptionSucceeded:
    "ADMIN_UPDATE_PRODUCT_SUBSCRIPTION_SUCCEEDED",
  updateProductSubscriptionFailed: "ADMIN_UPDATE_PRODUCT_SUBSCRIPTION_FAILED",
  contractsWillLoad: "ADMIN_ORGANIZATIONS_CONTRACTS_WILL_LOAD",
  contractsLoadSucceeded: "ADMIN_ORGANIZATIONS_CONTRACTS_LOAD_SUCCEEDED",
  contractsLoadFailed: "ADMIN_ORGANIZATIONS_CONTRACTS_LOAD_FAILED",
  updateSearchKeyword: "ADMIN_ORGANIZATIONS_UPDATE_SEARCH_KEYWORD",
  selectReseller: "ADMIN_ORGANIZATIONS_SELECT_RESELLER",
  resellersLoadSucceeded: "ADMIN_ORGANIZATIONS_RESELLERS_LOAD_SUCCEEDED",
};

export const fetchReferrals = 
  ({
    limit,
    page,
    parentOrgId
  }: {
    limit: number;
    page: number;
    parentOrgId: string;
  }) => 
    async(dispatch: any) => {
      dispatch({
        type: organizationsActions.referralsWillLoad,
        payload: page,
      });
      try {
        dispatch({
          type: organizationsActions.referralsLoadSucceeded,
          payload: await organizationsService.fetchReferrals({
            limit,
            page,
            parentOrgId
          }),
        });
      } catch (err) {
        dispatch({
          type: organizationsActions.referralsLoadFailed,
          payload: err,
        });
      }
    }

    export const fetchProducts = 
    ({
      limit,
      page
    }: {
      limit: number;
      page: number
    }) => 
      async(dispatch: any) => {
        dispatch({
          type: organizationsActions.productsWillLoad,
          payload: page,
        });
        try {
          dispatch({
            type: organizationsActions.productsLoadSucceeded,
            payload: await organizationsService.fetchProducts({
              limit,
              page,
            }),
          });
        } catch (err) {
          dispatch({
            type: organizationsActions.productsLoadFailed,
            payload: err,
          });
        }
      }

      export const fetchPackages = 
      ({
        limit,
        page
      }: {
        limit: number;
        page: number
      }) => 
        async(dispatch: any) => {
          dispatch({
            type: organizationsActions.packagesWillLoad,
            payload: page,
          });
          try {
            dispatch({
              type: organizationsActions.packagesLoadSucceeded,
              payload: await organizationsService.fetchPackages({
                limit,
                page,
              }),
            });
          } catch (err) {
            dispatch({
              type: organizationsActions.packagesLoadFailed,
              payload: err,
            });
          }
        }

export const fetchOragnizations =
  ({
    keyword,
    limit,
    page,
  }: {
    keyword?: string;
    limit: number;
    page: number;
  }) =>
    async (dispatch: any) => {
      dispatch({
        type: organizationsActions.organizationsWillLoad,
        payload: page,
      });
      try {
        dispatch({
          type: organizationsActions.organizationsLoadSucceeded,
          payload: await organizationsService.fetchOrganizations({
            keyword,
            limit,
            page,
          }),
        });
      } catch (err) {
        dispatch({
          type: organizationsActions.organizationsLoadFailed,
          payload: err,
        });
      }
    };

export const updateOrganization =
  (id: string, values: OrganizationFormInput) => async (dispatch: any) => {
    dispatch({ type: organizationsActions.updateOrganization });
    try {
      dispatch({
        type: organizationsActions.updateOrganizationSucceeded,
        payload: await organizationsService.updateOrganization(id, values),
      });
    } catch (e) {
      dispatch({
        type: organizationsActions.updateOrganizationFailed,
        payload: e,
      });
      throw e;
    }
  };

export const fetchProductSubscriptions =
  (organization_id: string, page: number, limit: number) =>
    async (dispatch: any) => {
      dispatch({
        type: organizationsActions.productSubscriptionsWillLoad,
        payload: page,
      });
      try {
        dispatch({
          type: organizationsActions.productSubscriptionsLoadSucceeded,
          payload: await organizationsService.fetchSubscriptions(
            organization_id,
            page,
            limit
          ),
        });
      } catch (e) {
        dispatch({
          type: organizationsActions.productSubscriptionsLoadFailed,
          payload: e,
        });
      }
    };

export const fetchSubscriptionHistory =
  (
    filters: {
      subscription_id: string;
      start_date: Date | null;
      end_date: Date | null;
    },
    page: number,
    limit: number
  ) =>
    async (dispatch: any) => {
      dispatch({
        type: organizationsActions.productSubscriptionsHistoryWillLoad,
        payload: {
          ...filters,
          page,
          limit,
        },
      });

      try {
        dispatch({
          type: organizationsActions.productSubscriptionsHistoryLoadSucceeded,
          payload: await subscriptionsService.fetchSubscriptionsHistory(
            filters,
            page,
            limit
          ),
        });
      } catch (e) {
        dispatch({
          type: organizationsActions.productSubscriptionsHistoryLoadFailed,
          payload: e,
        });
      }
    };

export const fetchContracts =
  (organization_id: string) => async (dispatch: any) => {
    dispatch({ type: organizationsActions.contractsWillLoad });
    try {
      dispatch({
        type: organizationsActions.contractsLoadSucceeded,
        payload: await subscriptionsService.fetchContracts({ organization_id }),
      });
    } catch (e) {
      dispatch({
        type: organizationsActions.contractsLoadFailed,
        payload: e,
      });
    }
  };

export const updateProductSubscriptionStatus =
  (id: string, organization_id: string, is_active: boolean) =>
    async (dispatch: any) => {
      dispatch({ type: organizationsActions.updateProductSubscriptionStatus });
      try {
        dispatch({
          type: organizationsActions.updateProductSubscriptionStatusSucceeded,
          payload: await organizationsService.updateSubscriptionStatus(
            id,
            is_active
          ),
        });
      } catch (e) {
        dispatch({
          type: organizationsActions.updateProductSubscriptionStatusFailed,
          payload: e,
        });
        throw e;
      }
    };

export const updateProductSubscription =
  (id: string, organization_id: string, values: UpdateSubscriptionInput) =>
    async (dispatch: any) => {
      dispatch({ type: organizationsActions.updateProductSubscription });
      try {
        dispatch({
          type: organizationsActions.updateProductSubscriptionSucceeded,
          payload: await organizationsService.updateSubscription(id, values),
        });
      } catch (e) {
        dispatch({
          type: organizationsActions.updateProductSubscriptionFailed,
          payload: e,
        });
        throw e;
      }
    };

export const resetStates = () => ({
  type: organizationsActions.resetStates,
});

export const dismissMessages = () => ({
  type: organizationsActions.dismissMessages,
});

export const updateSearchKeyword = (keyword: string) => ({
  type: organizationsActions.updateSearchKeyword,
  payload: keyword,
});

export const dismissCreateProductSubscriptionMessages = () => ({
  type: organizationsActions.dismissCreateProductSubscriptionMessages,
});

export const selectReseller = (reseller_id: string) => ({
  type: organizationsActions.selectReseller,
  payload: reseller_id,
});

export const searchResellers = (keyword: string) => async (dispatch: any) => {
  if (keyword?.length < 3) {
    dispatch({
      type: organizationsActions.resellersLoadSucceeded,
      payload: [],
    });
    return;
  }
  try {
    dispatch({
      type: organizationsActions.resellersLoadSucceeded,
      payload: (await resellersService.fetchResellers({ keyword }, 1, 20)).list,
    });
  } catch (err) {
    dispatch({
      type: organizationsActions.resellersLoadSucceeded,
      payload: [],
    });
  }
};
