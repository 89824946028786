import * as auditLogService from "../services/audit";
import * as organizationsService from "../services/organizations";


export const auditActions: any = {
  auditLogWillLoad: "AUDIT_LOG_WILL_LOAD",
  auditLogLoadSucceeded: "AUDIT_LOG_LOAD_SUCCEEDED",
  auditLogLoadFailed: "AUDIT_LOG_LOAD_FAILED",
  auditLogTablesWillLoad: "AUDIT_LOG_TALBES_WILL_LOAD",
  auditLogTablesLoadSucceeded: "AUDIT_LOG_TABLES_LOAD_SUCCEEDED",
  auditLogTablesLoadFailed: "AUDIT_LOG_TABLES_LOAD_FAILED",
  auditLogTablesSelected: "AUDIT_LOG_TABLE_SELECTED",
  auditLogSearchTypeSelected: "AUDIT_LOG_SEARCH_TABLE_SELECTED",
  clearSelectedTable: "CLEAR_SELECTED_TABLE",
  selectOrganization: "SELECT_ORGANIZATION",
  clearOrganization: "CLEAR_ORGANIZATION",
  organizationsLoadSucceeded: "ORGANIZATIONS_LOAD_SUCCEEDED",
  organizationsLoadFailed: "ORGANIZATIONS_LOAD_FAILED",
  changeAction: "CHANGE_ACTION",
  clearAction: "CLEAR_ACTION"
};

export const fetchTables: any =
  (limit: number, page: number) => async (dispatch: any) => {
    dispatch({ type: auditActions.auditLogTablesWillLoad, payload: page });
    try {
      const tables = await auditLogService.fetchTables();
      dispatch({
        type: auditActions.auditLogTablesLoadSucceeded,
        payload: tables,
      });
    } catch (err) {
      dispatch({
        type: auditActions.auditLogTablesLoadFailed,
        payload: err,
      });
    }
  };

export const fetchAuditLog: any =
  (
    filters: { table_name: string; organization_id: string; action: string; row_id?: string },
    limit: number,
    page: number
  ) =>
  async (dispatch: any) => {
    dispatch({ type: auditActions.auditLogWillLoad, payload: page });
    try {
      dispatch({
        type: auditActions.auditLogLoadSucceeded,
        payload: await auditLogService.fetchAuditLogs(filters, limit, page),
      });
    } catch (err) {
      dispatch({
        type: auditActions.auditLogLoadFailed,
        payload: err,
      });
    }
  };

export const changeTable: any =
  (tableName: string, limit: number, page: number, organization_id: string, action: string) => async (dispatch: any) => {
    dispatch({
      type: auditActions.auditLogTablesSelected,
      payload: tableName,
    });
    fetchAuditLog({ table_name: tableName, organization_id: organization_id, action: action}, limit, page)(dispatch);
  };

  export const changeAction: any =
  (action: string, limit: number, page: number, organization_id: string, table_name: string) => async (dispatch: any) => {
    dispatch({
      type: auditActions.changeAction,
      payload: action,
    });
    fetchAuditLog({ table_name: table_name, organization_id: organization_id, action: action}, limit, page)(dispatch);
  };

export const updateSearchType: any = (
  keyword: "action" | "updated_by" | ""
) => (dispatch: any) => {
  dispatch({
    type: auditActions.auditLogSearchTypeSelected,
    payload: keyword,
  });
}

export const selectOrganization: any = (organization_id: string, limit: number, page: number, tableName: string, action: string) => (dispatch: any) => {
    dispatch({
      type: auditActions.selectOrganization,
      payload: organization_id,
    });
    fetchAuditLog({ table_name: tableName, organization_id: organization_id, action: action}, limit, page)(dispatch);
};

export const clearOrganization: any = (tableName: string, limit: number, page: number, action: string,) => (dispatch: any) => {
  dispatch({
    type: auditActions.clearOrganization,
    payload: ''
  });
  if (tableName.length > 0 || action.length > 0) fetchAuditLog({ table_name: tableName, organization_id: '', action: action}, limit, page)(dispatch);
};

export const selectAction: any = (organization_id: string, limit: number, page: number, tableName: string, action: string) => (dispatch: any) => {
  dispatch({
    type: auditActions.selectAction,
    payload: action,
  });
  fetchAuditLog({ table_name: tableName, organization_id: organization_id, action: action}, limit, page)(dispatch);
};

export const clearAction: any = (tableName: string, organization_id: string, limit: number, page: number) => (dispatch: any) => {
dispatch({
  type: auditActions.clearAction,
  payload: ''
});
if (tableName.length > 0 || organization_id.length > 0) fetchAuditLog({ table_name: tableName, organization_id: organization_id, action: ''}, limit, page)(dispatch);
};

export const clearSelectedTable: any = (action: string, organization_id: string, limit: number, page: number) => (dispatch: any) => {
  dispatch({
    type: auditActions.clearSelectedTable,
    payload: ''
  });
  if (action.length > 0 || organization_id.length > 0) fetchAuditLog({ table_name: '', organization_id: organization_id, action: action}, limit, page)(dispatch);
};

export const searchOrganizations: any = (keyword: string) => async (dispatch: any) => {
    if (keyword?.length < 3) {
      await dispatch({
        type: auditActions.organizationsLoadSucceeded,
        payload: [],
      })
      return;
    }
    try {
      const res = await organizationsService.fetchOrganizations({
        keyword,
        limit: 20,
        page: 1,
      });
      dispatch({
        type: auditActions.organizationsLoadSucceeded,
        payload: (res).list,
      });
    } catch (err) {
      dispatch({
        type: auditActions.organizationsLoadFailed,
        payload: err,
      });
    }
  };