import moment from "moment";
import { print } from "graphql";
import axios from "../axios-client";
import gql from "graphql-tag";
import { parseErrors } from "./helpers";
import { CustomerType, GraphqlError, InvoicePaymentUpdateType, InvoiceType } from "./interfaces";
import { env } from '../env';

const apiUrl: string = env.REACT_APP_COMMON_API_URL;

export async function fetchInvoices(
  {
    keyword,
    startDate,
    endDate,
    status,
  }: {
    keyword: string;
    startDate: Date;
    endDate: Date;
    status: string;
  },
  page: number,
  limit: number
): Promise<{ list: [InvoiceType]; count: number }> {
  const query = gql`
    query invoices(
      $start_date: DateTime!
      $end_date: DateTime!
      $keyword: String
      $status: String
      $limit: Int!
      $page: Int!
    ) {
      invoices(
        keyword: $keyword
        status: $status
        start_date: $start_date
        end_date: $end_date
        limit: $limit
        page: $page
      ) {
        list {
          id
          customer {
            id
            organization {
              id
              name
              address {
                line_1
                line_2
                city
                state
                postal_code
                country
              }
            }
            reseller {
              id
              name
              address {
                line_1
                line_2
                city
                state
                postal_code
                country
              }
            }
          }
          total
          payment_status
          invoice_date
          invoice_number
          items {
            subscription_id
            item_total
            new_activations {
              full_price
              half_price
            }
            existing_activations
            organization {
              id
              name
              address {
                line_1
                line_2
                city
                state
                postal_code
                country
              }
            }
          }
          deductions {
            description
            amount
          }
          additions {
            description
            amount
          }
          sales_code
          currency
          tax_number
          invoice_date
          payment_invoice_id
          payment_transaction_id
          invoice_from_address {
            line_1
            line_2
            city
            state
            postal_code
            country
          }
          invoice_to_address {
            line_1
            line_2
            city
            state
            postal_code
            country
          }
          payment_gateway
          details {
            failed_payment_details {
              message
              rawType
              code
              doc_url
              statusCode
              requestId
              decline_code
            }
          }
        }
        count
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<InvoicesResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: {
        start_date: getUTCDate(moment(startDate)),
        end_date: getUTCDate(moment(endDate)),
        keyword,
        status : status,
        limit,
        page,
      },
    },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      });
    return data.data.invoices;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function deleteInvoice(id: string): Promise<boolean> {
  const query = gql`
    mutation deleteInvoice($id: ID!) {
      deleteInvoice(id: $id)
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<{ data: boolean }>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: {
        id,
      },
    },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      });
    return data.data;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function updateInvoice(
  id: string,
  values: InvoiceType
): Promise<InvoiceType> {
  const query = gql`
    mutation updateInvoice($id: ID!, $values: UpdateInvoiceInput!) {
      updateInvoice(id: $id, input: $values) {
        id
        customer {
          id
          organization {
            id
            name
            address {
              line_1
              line_2
              city
              state
              postal_code
              country
            }
          }
          reseller {
            id
            name
            address {
              line_1
              line_2
              city
              state
              postal_code
              country
            }
          }
        }
        total
        payment_status
        invoice_date
        invoice_number
        items {
          subscription_id
          item_total
          new_activations {
            full_price
            half_price
          }
          existing_activations
          organization {
            id
            name
            address {
              line_1
              line_2
              city
              state
              postal_code
              country
            }
          }
        }
        deductions {
          description
          amount
        }
        additions {
          description
          amount
        }
        sales_code
        currency
        tax_number
        invoice_date
        payment_invoice_id
        payment_transaction_id
        invoice_from_address {
          line_1
          line_2
          city
          state
          postal_code
          country
        }
        invoice_to_address {
          line_1
          line_2
          city
          state
          postal_code
          country
        }
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<UpdateInvoiceResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: {
          id,
          values: {
            deductions: values.deductions,
          },
        },
      },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.updateInvoice;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function sendInvoiceEmail(invoiceId: string | undefined): Promise<any> {
  const timeStamp = new Date().getTime();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { data } = await axios.post(`${apiUrl}/invoice/send-email`,
    {
      invoice_id: invoiceId,
    },
    { headers: { "app-id": "qboard", "timestamp": `${timeStamp}`, "timezone": timeZone } });
  return data;
}

export async function updateInvoiceToPaid(values: InvoicePaymentUpdateType): Promise<any> {
  const timeStamp = new Date().getTime();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { data } = await axios.post(`${apiUrl}/invoice/update-status`,
    {
      invoice_id: values.invoice_id,
      payment_notes: values.payment_notes,
      payment_update_date: getUTCDate(moment(values.payment_update_date)),
      payment_transaction_id: values.payment_transaction_id,
      payment_type: values.payment_type
    },
    { headers: { "app-id": "qboard", "timestamp": `${timeStamp}`, "timezone": timeZone } });
  return data;
}

export async function getInvoiceHtml(id: string) {
  const timeStamp = new Date().getTime();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { data } = await axios.get(`${apiUrl}/invoices/${id}`, { headers: { "app-id": "qboard", "timestamp": `${timeStamp}`, "timezone": timeZone } });
  return data;
}

export async function getStripeInvoices() {
  const timeStamp = new Date().getTime();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { data } = await axios.get(`${apiUrl}/stripe-invoices`, { headers: { "app-id": "qboard", "timestamp": `${timeStamp}`, "timezone": timeZone } });
  return data;
}

export async function getStripeInvoicesById(id: string | undefined) {
  const timeStamp = new Date().getTime();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { data } = await axios.get(`${apiUrl}/stripe-invoices/${id}`, { headers: { "app-id": "qboard", "timestamp": `${timeStamp}`, "timezone": timeZone } });
  return data;
}
const getUTCDate = (date: moment.Moment, endOfDay?: boolean) => {
  const dayOfMonth = date.date();
  const month = date.month();
  const year = date.year();
  const utcDate = !endOfDay ? moment(month + 1 + '/' + dayOfMonth + '/' + year).utc(true).toDate() :
    moment(month + 1 + '/' + dayOfMonth + '/' + year).utc(true).endOf('day').subtract(1, 'hour').toDate() ;
  return utcDate;
}

export async function generatePastInvoices(start: Date | null, end: Date | null | undefined, customerList?: [CustomerType]) {
  const timeStamp = new Date().getTime();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { data } = await axios.post(`${apiUrl}/customer/generate-invoices`, {
    customer_list: customerList,
    start: getUTCDate(moment(start)),
    end: getUTCDate(moment(end))
  },
    {
      headers: {
        "app-id": "qboard",
        "timestamp": `${timeStamp}`,
        "timezone": timeZone
      }
    })
  return data;
}

export async function attemptStripePayment(id: string, customerId: string) {
  const timeStamp = new Date().getTime();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { data } = await axios.post(`${apiUrl}/customer/attempt-stripe-payment`, {
    id: id,
    customerId: customerId
  }, {
    headers: {
      "app-id": "qboard",
      "timestamp": `${timeStamp}`,
      "timezone": timeZone
    }
  });
  return data;
}

export async function printInvoice(html: string) {
  const printFrame = document.createElement("iframe");
  document.body.appendChild(printFrame);
  printFrame.style.display = "none";
  if (printFrame.contentDocument?.title) {
    printFrame.contentDocument.title = "Invoice";
  }
  printFrame.contentWindow?.document.open();
  printFrame.contentWindow?.document.write(html);
  printFrame.contentWindow?.document.close();
  printFrame.focus();
  printFrame.contentWindow?.print();
  document.body.removeChild(printFrame);
}

interface InvoicesResponse {
  data: {
    invoices: {
      list: [InvoiceType];
      count: number;
    };
  };
}

interface UpdateInvoiceResponse {
  data: {
    updateInvoice: InvoiceType;
  };
  errors?: Array<GraphqlError>;
}
