import { combineReducers } from "redux";
import { SubscriptionType } from "../services/interfaces";
import { productSubscriptionsActions } from "../actions/orgLevel/productSubscriptions";

export interface OrgLevelProductsReducer {
  loading: boolean;
  subscriptions: Array<SubscriptionType>;
  page: number;
  count: number;
}

export interface OrgLevelReducers {
  products: OrgLevelProductsReducer;
}

function orgLevelProductsResolver(
  prevState: OrgLevelProductsReducer = {
    loading: false,
    subscriptions: [],
    page: 1,
    count: 0
  },
  { type, payload }: { type: string; payload: number | { list: [SubscriptionType], count: number } }
): OrgLevelProductsReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case productSubscriptionsActions.productSubscriptionsWillLoad:
      state.loading = true;
      state.page = payload as number;
      break;
    case productSubscriptionsActions.productSubscriptionsLoadSucceeded:
      state.loading = false;
      state.subscriptions = (payload as { list: Array<SubscriptionType> }).list;
      state.count = (payload as { count: number }).count;
      break;
    case productSubscriptionsActions.productSubscriptionsLoadFailed:
      state.loading = false;
      state.subscriptions = [];
      break;
  }
  return state;
}

export default combineReducers<OrgLevelReducers>({
  products: orgLevelProductsResolver,
});
