import { print } from "graphql";
import axios from "../../axios-client";
import gql from "graphql-tag";
import { parseErrors } from "../helpers";
import { CustomerType } from "../interfaces";
import { env } from '../../env';

const apiUrl: string = env.REACT_APP_COMMON_API_URL;

export async function fetchCustomers(
  { keyword }: { keyword?: string },
  page: number,
  limit: number
): Promise<{ list: [CustomerType]; count: number }> {
  const query = gql`
    query autnhiveCustomers($keyword: String, $page: Int!, $limit: Int!) {
      autnhiveCustomers(limit: $limit, page: $page, keyword: $keyword) {
        list {
          id
          type
          use_credit_card
          organization {
            id
            name
            email
            phone
            allowed_domains
            address {
              line_1
              line_2
              city
              state
              postal_code
              country
            }
          }
          reseller {
            id
            name
            email
            phone
            is_white_label
            white_label_url
            address {
              line_1
              line_2
              city
              state
              postal_code
              country
            }
          }
          primary_contact {
            id
            first_name
            last_name
            email
          }
          product_id
        }
        page
        limit
        count
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<CustomersResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: { limit, page, keyword },
    },{
      headers: {
        "timestamp": `${timeStamp}`,
      }
    });
    return data.data.autnhiveCustomers;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

interface CustomersResponse {
  data: {
    autnhiveCustomers: {
      list: [CustomerType];
      count: number;
    };
  };
}
