import { print } from "graphql";
import axios from "../../axios-client";
import gql from "graphql-tag";
import { parseErrors } from "../helpers";
import {
  UserType,
  GraphqlError,
  UserFormInput,
  RoleType,
  CreateResellerUserFormInput,
} from "../interfaces";
import { env } from '../../env';

const apiUrl: string = env.REACT_APP_COMMON_API_URL;

export async function fetchUsers(filters: {
  keyword?: string;
  reseller_id?: string;
  limit: number;
  page: number;
}): Promise<{ list: Array<UserType>; count: number }> {
  const query = gql`
    query resellerUsers(
      $reseller_id: String!
      $keyword: String
      $limit: Int!
      $page: Int!
    ) {
      resellerUsers(
        limit: $limit
        page: $page
        reseller_id: $reseller_id
        keyword: $keyword
      ) {
        list {
          id
          first_name
          last_name
          email
          address {
            country
          }
          is_active
          is_deleted
          role {
            id
            name
          }
          reseller {
            id
            name
          }
        }
        count
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<ResellerUsersResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: filters,
      },{
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    return data.data.resellerUsers;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function createUser(
  values: CreateResellerUserFormInput
): Promise<UserType> {
  const query = gql`
    mutation createResellerUser(
      $first_name: String!
      $last_name: String!
      $email: String!
      $address: MinimalAddressInput!
      $role_id: String!
      $roles: [UserRoleInput!]!
      $reseller_id: String!
    ) {
      createResellerUser(
        input: {
          first_name: $first_name
          last_name: $last_name
          email: $email
          address: $address
          role_id: $role_id
          roles: $roles
          reseller_id: $reseller_id
        }
      ) {
        id
        first_name
        last_name
        email
        is_active
        address {
          country
        }
        role {
          id
          name
        }
        reseller {
          id
          name
        }
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<CreateResellerUserResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          address: values.address,
          role_id: values.role_id,
          roles:[{
            role_id:values.role_id
          }],
          reseller_id: values.reseller_id,
        },
      },{
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.resellerUser;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function updateUser(
  id: string,
  values: UserFormInput
): Promise<UserType> {
  const query = gql`
    mutation updateResellerUser(
      $id: String!
      $first_name: String!
      $last_name: String!
      $address: MinimalAddressInput!
      $is_active: Boolean!
      $role_id: String!
    ) {
      updateResellerUser(
        id: $id
        input: {
          first_name: $first_name
          last_name: $last_name
          address: $address
          is_active: $is_active
          role_id: $role_id
        }
      ) {
        id
        first_name
        last_name
        email
        is_active
        address {
          country
        }
        role {
          id
          name
        }
        reseller {
          id
          name
        }
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<UpdateResellerUserResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: {
          id: id,
          first_name: values.first_name,
          last_name: values.last_name,
          address: values.address,
          is_active: values.is_active,
          role_id: values.role_id,
        },
      },{
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.updateResellerUser;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function fetchRoles(
  limit: number,
  page: number,
  { reseller_id }: { reseller_id?: string }
): Promise<{ list: Array<RoleType>; count: number }> {
  const query = gql`
    query roles($reseller_id: String, $limit: Int!, $page: Int!) {
      roles(reseller_id: $reseller_id, limit: $limit, page: $page) {
        list {
          id
          name
          role_for
          is_builtin
        }
        count
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<RolesResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: { limit, page, reseller_id },
    },{
      headers: {
        "timestamp": `${timeStamp}`,
      }
    });
    return data.data.roles;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

interface ResellerUsersResponse {
  data: {
    resellerUsers: {
      list: [UserType];
      count: number;
    };
  };
}

interface CreateResellerUserResponse {
  data: {
    resellerUser: UserType;
  };
  errors?: Array<GraphqlError>;
}

interface UpdateResellerUserResponse {
  data: {
    updateResellerUser: UserType;
  };
  errors?: Array<GraphqlError>;
}

interface RolesResponse {
  data: {
    roles: {
      list: [RoleType];
      count: number;
    };
  };
}
