import { useEffect } from "react";
import { env } from '../env';

const sitekey = env.REACT_APP_RECAPTCHA;

async function loadScript(url: string): Promise<void> {
  return new Promise((resolve) => {
    const scriptTag = document.createElement("script");
    scriptTag.src = url;
    scriptTag.async = true;
    scriptTag.onload = () => resolve();
    document.body.appendChild(scriptTag);
  });
}

type Props = {
  onSubmit: (response: string) => void;
};
export default function ReCaptcha(props: Props) {
  let url = 'https://www.google.com/recaptcha/api.js?render=$' + sitekey
  console.log(url,"Recaptcha Url")
  useEffect(() => {
    loadScript(
      `https://www.google.com/recaptcha/api.js?render=${sitekey}`
    ).then(() => {
      const _window = window as any;
      _window.grecaptcha.ready(() => {
        _window.grecaptcha
          .execute(sitekey, { action: "submit" })
          .then(props.onSubmit);
      });
    });
  }, []);
  return null;
}
