import axios from "axios";
import { UserConsentType } from "./interfaces";
import { env } from '../env';

const authUrl = env.REACT_APP_AUTH_URL;

export async function getTermsConsentLinks() {
  const timeStamp = new Date().getTime();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { data } = await axios.get<{
    platform_terms_of_use_policy: UserConsentType;
    terms_and_conditions: UserConsentType;
    privacy_policy: UserConsentType;
  }>(`${authUrl}/terms-links`,
  {
    headers: {
      "app-id": "qboard",
      "timestamp": `${timeStamp}`,
      "timezone": timeZone
    }
  });
  return data;
}
