import { CreateRoleInput } from "../services/interfaces";
import * as usersService from "../services/users";
import * as organizationsService from "../services/organizations";
import * as resellersService from "../services/autnhive/resellers";

export const rolesActions: any = {
  rolesWillLoad: "ADMIN_ROLES_WILL_LOAD",
  rolesLoadSucceeded: "ADMIN_ROLES_LOAD_SUCCEEDED",
  rolesLoadFailed: "ADMIN_ROLES_LOAD_FAILED",
  dismissUserMessages: "ADMIN_USER_DISMISS_MESSAGES",
  createRole: "CREATE_ROLE",
  createRoleSucceeded: "CREATE_ROLE_SUCCEEDED",
  createRoleFailed: "CREATE_ROLE_FAILED",
  updateRole: "UPDATE_ROLE",
  updateRoleSucceeded: "UPDATE_ROLE_SUCCEEDED",
  updateRoleFailed: "UPDATE_ROLE_FAILED",
  dismissCreateRoleMessages: "CREATE_ROLE_DISMISS_MESSAGES",
  dismissDeleteRoleMessages: "DELETE_ROLE_DISMISS_MESSAGES",
  permissionsWillLoad: "ADMIN_ROLES_PERMISSION_WILL_LOAD",
  permissionsLoadSucceeded: "ADMIN_ROLES_PERMISSION_LOAD_SUCCEEDED",
  permissionsLoadFailed: "ADMIN_ROLES_PERMISSION_LOAD_FAILED",
  upsertRolePermission: "ADMIN_UPSERT_ROLE_PERMISSION",
  upsertRolePermissionSucceeded: "ADMIN_UPSERT_ROLE_PERMISSION_SUCCEEDED",
  upsertRolePermissionFailed: "ADMIN_UPSERT_ROLE_PERMISSION_FAILED",
  roleWillDelete: "ADMIN_ROLES_ROLL_WILL_DELETE",
  roleDeleteSucceeded: "ADMIN_ROLES_ROLL_DELETE_SUCCEEDED",
  roleDeleteFailed: "ADMIN_ROLES_ROLL_DELETE_FAILED",
  organizationsWillLoad: "ADMIN_ROLES_ORG_WILL_LOAD",
  organizationsLoadSucceeded: "ADMIN_ROLES_ORG_LOAD_SUCCEEDED",
  organizationsLoadFailed: "ADMIN_ROLES_ORG_LOAD_FAILED",
  resellersWillLoad: "ADMIN_ROLES_RESELLERS_WILL_LOAD",
  resellersLoadSucceeded: "ADMIN_ROLES_RESELLERS_LOAD_SUCCEEDED",
  resellersLoadFailed: "ADMIN_ROLES_RESELLERS_LOAD_FAILED",
  getFilteredResults: "ADMIN_ROLES_GET_FILTERED_RESULTS",
  setSearchType: "ADMIN_ROLES_SET_SEARCH_TYPE"
  // setRoleKeyword: "ADMIN_ROLES_SET_ROLE_KEYWORD"
};

// export const selectRole = (keyword: string, page: number, limit: number) => async (dispatch: any) => {
//   dispatch({type: rolesActions.setRoleKeyword, payload: keyword});
// }

export const setRoleSearchType = (type: string) => async (dispatch: any) => {
  dispatch({type: rolesActions.setSearchType, payload: type});
}

export const createRole =
  (values: CreateRoleInput) => async (dispatch: any) => {
    dispatch({ type: rolesActions.createRole });
    try {
      dispatch({
        type: rolesActions.createRoleSucceeded,
        payload: await usersService.createRole(values),
      });
    } catch (e) {
      dispatch({
        type: rolesActions.createRoleFailed,
        payload: e,
      });
      throw e;
    }
  };

export const updateRole =
  (id: string, values: CreateRoleInput) => async (dispatch: any) => {
    dispatch({ type: rolesActions.updateRole });
    try {
      dispatch({
        type: rolesActions.updateRoleSucceeded,
        payload: await usersService.updateRole(id, values),
      });
    } catch (e) {
      dispatch({
        type: rolesActions.updateRoleFailed,
        payload: e,
      });
      throw e;
    }
  };

export const fetchRoles =
  (limit: number, page: number, filters?: {role_id?: string, organization_id?: string}) => async (dispatch: any) => {
    dispatch({ type: rolesActions.rolesWillLoad, payload: page });
    try {
      dispatch({
        type: rolesActions.rolesLoadSucceeded,
        payload: await usersService.fetchRoles(limit, page, {role_id: filters?.role_id, organization_id: filters?.organization_id}),
      });
    } catch (err) {
      dispatch({
        type: rolesActions.rolesLoadFailed,
        payload: err,
      });
    }
  };

  export const getFilteredResults =
  (limit: number, page: number, keyword: string) => async (dispatch: any) => {
    // dispatch({ type: rolesActions.rolesWillLoad, payload: page });
    try {
      dispatch({
        type: rolesActions.getFilteredResults,
        payload: await usersService.fetchRoles(limit, page, {keyword}),
      });
    } catch (err) {
      console.log(err);
    }
  };

export const deleteRole = (id: string) => async (dispatch: any) => {
  dispatch({ type: rolesActions.roleWillDelete });
  try {
    dispatch({
      type: rolesActions.roleDeleteSucceeded,
      payload: await usersService.deleteRole(id),
    });
  } catch (err) {
    dispatch({
      type: rolesActions.roleDeleteFailed,
      payload: err,
    });
    throw err;
  }
};

export const fetchRolePermissions =
  (permission_for: string, role_id: string) => async (dispatch: any) => {
    dispatch({ type: rolesActions.permissionsWillLoad });
    try {
      dispatch({
        type: rolesActions.permissionsLoadSucceeded,
        payload: await usersService.fetchRolePermissions(
          permission_for,
          role_id
        ),
      });
    } catch (err) {
      dispatch({
        type: rolesActions.permissionsLoadFailed,
        payload: err,
      });
    }
  };

export const toggleRolePermissionStatus =
  (
    permission_for: string,
    role_id: string,
    resource: string,
    action: string,
    is_active: boolean
  ) =>
  async (dispatch: any) => {
    dispatch({ type: rolesActions.upsertRolePermission });
    try {
      dispatch({
        type: rolesActions.upsertRolePermissionSucceeded,
        payload: await usersService.upsertRolePermission(
          role_id,
          resource,
          action,
          is_active
        ),
      });
      await fetchRolePermissions(permission_for, role_id)(dispatch);
    } catch (e) {
      dispatch({
        type: rolesActions.upsertRolePermissionFailed,
        payload: e,
      });
    }
  };

export const searchOrganizations =
  (keyword: string, organization_type?: string) => async (dispatch: any) => {
    if (keyword?.length < 3) {
      dispatch({
        type: rolesActions.organizationsLoadSucceeded,
        payload: [],
      });
      return;
    }
    dispatch({ type: rolesActions.organizationsWillLoad });
    try {
      dispatch({
        type: rolesActions.organizationsLoadSucceeded,
        payload: (
          await organizationsService.fetchOrganizations({
            keyword,
            organization_type,
            limit: 20,
            page: 1,
          })
        ).list,
      });
    } catch (err) {
      dispatch({
        type: rolesActions.organizationsLoadFailed,
        payload: err,
      });
    }
  };

export const searchResellers = (keyword: string) => async (dispatch: any) => {
  if (keyword?.length < 3) {
    dispatch({
      type: rolesActions.resellersLoadSucceeded,
      payload: [],
    });
    return;
  }
  dispatch({ type: rolesActions.resellersWillLoad });
  try {
    dispatch({
      type: rolesActions.resellersLoadSucceeded,
      payload: (await resellersService.fetchResellers({ keyword }, 1, 20)).list,
    });
  } catch (err) {
    dispatch({
      type: rolesActions.resellersLoadFailed,
      payload: err,
    });
  }
};

export const dismissCreateRoleMessages = () => ({
  type: rolesActions.dismissCreateRoleMessages,
});

export const dismissDeleteRoleMessages = () => ({
  type: rolesActions.dismissDeleteRoleMessages,
});

// export const selectOrganization = (organization_id: string) => ({
//   type: rolesActions.selectOrganization,
//   payload: organization_id,
// });

// export const selectReseller = (reseller_id: string) => ({
//   type: rolesActions.selectReseller,
//   payload: reseller_id,
// });
