
import * as securityNotificationsService from "../services/securityNotifications";

export const securityNotificationsActions: any = {
    securityNotificationsWillLoad: "SECURITY_NOTIFICATIONS_WILL_LOAD",
    securityNotificationsLoadSucceeded: "SECURITY_NOTIFICATIONS_LOAD_SUCCEEDED",
    securityNotificationsLoadFailed: "SECURITY_NOTIFICATIONS_LOAD_FAILED",
    resetStates: "SECURITY_NOTIFICATIONS_RESET_STATES",
  };
  

export const fetchSecurityNotificationsList = (page: number, limit: number) =>
    async (dispatch: any) => {
        dispatch({
            type: securityNotificationsActions.securityNotificationsWillLoad,
            payload: {
                page,
                limit,
            },
        });
        try {
            dispatch({
                type: securityNotificationsActions.securityNotificationsLoadSucceeded,
                payload: await securityNotificationsService.fetchSecurityNotificationsList(page, limit),
            });
        } catch (err) {
            dispatch({
                type: securityNotificationsActions.securityNotificationsLoadFailed,
                payload: err,
            });
        }
    };

    export const resetStates = () => ({
        type: securityNotificationsActions.resetStates,
      });
      