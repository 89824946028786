import moment from "moment";

// Gets the UTC date based on the specified day of month
export const getUTCDate = (date: moment.Moment) => {
  const dayOfMonth = date.date();
  const month = date.month();
  const year = date.year();
  const utcDate = moment((month + 1) + '/' + dayOfMonth + '/' + year).utc(true).toDate();
  return utcDate;
}
export const getLocalDate = (date: moment.Moment) => {
  const dayOfMonth = date.date();
  const month = date.month();
  const year = date.year();
  const localDate = moment.utc((month + 1) + '/' + dayOfMonth + '/' + year).startOf('day').toString();
  return localDate;
}

export const offset = (utcTime:Date): Date => {
  const tzoffset = utcTime.getTimezoneOffset() * 60000
  const localTime = new Date(utcTime.getTime() + tzoffset)
  return localTime
}

