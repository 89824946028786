import { print } from "graphql";
import axios from "../axios-client";
import gql from "graphql-tag";
import { parseErrors } from "./helpers";
import { ReleaseType } from "./interfaces";
import { env } from '../env';

const apiUrl: string = env.REACT_APP_COMMON_API_URL;

export async function fetchReleases(
  product_id: string,
  page: number,
  limit: number
): Promise<{ list: [ReleaseType]; count: number }> {
  const query = gql`
    query releases($product_id: String!, $limit: Int!, $page: Int!) {
      releases(product_id: $product_id, limit: $limit, page: $page) {
        list {
          id
          version
          description
          start_date
          end_date
          product {
            id
            name
          }
          release_variants {
            id
            platform
          }
        }
        count
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<ReleasesResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: { product_id, limit, page },
    },
    {
      headers: {
        "timestamp": `${timeStamp}`,
      }
    });
    return data.data.releases;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function downloadPackage(
  subscription_id: string,
  release_variant_id: string
): Promise<void> {
  const link = document.createElement("a");
  const query = gql`
    query signedPackageDownloadUrl(
      $subscription_id: String!
      $release_variant_id: String!
    ) {
      signedPackageDownloadUrl(
        subscription_id: $subscription_id
        release_variant_id: $release_variant_id
      ) {
        url
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<DownloadPackageUrlResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: { subscription_id, release_variant_id },
      },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    link.href = apiUrl + data.data.signedPackageDownloadUrl.url;
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    throw err;
  } finally {
    document.body.removeChild(link);
  }
}

interface ReleasesResponse {
  data: {
    releases: {
      list: [ReleaseType];
      count: number;
    };
  };
}

interface DownloadPackageUrlResponse {
  data: {
    signedPackageDownloadUrl: {
      url: string;
    };
  };
}
