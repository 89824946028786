import * as releasesService from "../services/releases";

export const releasesActions: any = {
  releasesWillLoad: "RELEASES_WILL_SUBMIT",
  releasesLoadSucceeded: "RELEASES_LOAD_SUCCEEDED",
  releasesLoadFailed: "RELEASES_LOAD_FAILED",
  releasesWillDownload: "RELEASES_WILL_DOWNLOAD",
  releasesDownloadSucceeded: "RELEASES_DOWNLOAD_SUCCEEDED",
  releasesDownloadFailed: "RELEASES_DOWNLOAD_FAILED",
};

export const fetchReleases: any =
  (product_id: string, page: number, limit: number) =>
  async (dispatch: any) => {
    dispatch({ type: releasesActions.releasesWillLoad });
    try {
      dispatch({
        type: releasesActions.releasesLoadSucceeded,
        payload: await releasesService.fetchReleases(product_id, page, limit),
      });
    } catch (err) {
      dispatch({
        type: releasesActions.releasesLoadFailed,
        payload: err,
      });
    }
  };

export const downloadPackage: any =
  (subscription_id: string, release_variant_id: string) =>
  async (dispatch: any) => {
    dispatch({ type: releasesActions.releasesWillDownload });
    try {
      dispatch({
        type: releasesActions.releasesDownloadSucceeded,
        payload: await releasesService.downloadPackage(
          subscription_id,
          release_variant_id
        ),
      });
    } catch (err) {
      dispatch({
        type: releasesActions.releasesDownloadFailed,
        payload: err,
      });
    }
  };
