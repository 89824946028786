import React, { useState, useEffect, } from "react";
import { connect } from "react-redux";
import { Typography, TextField, Button, Dialog, MenuItem,
  DialogTitle, DialogContent, Grid, List, InputLabel, Select, FormControl, Popper, Paper } from "@mui/material";
import { GlobalReducer, OrganizationsPageReducer, Reducers } from "../reducers";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import * as userActions from "../actions/users";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useDispatch, useSelector } from "react-redux";
import AddLinkIcon from '@mui/icons-material/AddLink';
import { parse } from "csv-parse/browser/esm/sync";
import { useSnackbar } from "notistack";
import CloseIcon from '@mui/icons-material/Close';
import * as organizationActions from "../actions/organizations";
import { fetchCustomers } from "../services/autnhive/customers";
import { CustomerType } from "../services/interfaces";
import moment from "moment";

export default function Vendor() {
    const [orgName, setOrgName] = useState("")
    const [orgEmail, setOrgEmail] = useState("")
    const dispatch = useDispatch<any>();
    const [dialogOpen, setDialogOpen] = useState(false)
    const [newDialogOpen, setNewDialogOpen] = useState(false)
    const [orgImg, setOrgImg] = useState("")
    const anchorEl = React.useRef<HTMLElement>();
    const anchorE2 = React.useRef<HTMLElement>();
    const [customerName, setCustomerName] = useState<string>("")
    const [parentCustomerName, setParentCustomerName] = useState<string>("")
    const [orgImgData, setOrgImgData] = useState("")
    const [parentOrgId, setParentOrgId] = useState("")
    const [searchParentOrgId, setSearchParentOrgId] = useState("")
    const [isSwarmSubscription, setIsSwarmSubscription] = useState(false)
    const [isDnCSubscription, setIsDnCSubscription] = useState(false)
    const [customers, setCustomers] = useState<Array<CustomerType>>([]);
    const [parentCustomers, setParentCutomers] = useState<Array<CustomerType>>([]);
    const [swarmSubscription, setSwarmSubscription] = useState({
      packageCode:'',
      packageName:'',
      cost:''
    })
    const [dncSubscription, setDnCSubscription] = useState({
      packageCode:'',
      packageName:'',
      cost:''
    })
    const [subscriptionPackages, setSubscriptionPackages] = useState([{
      productId: '',
      productName: '',
      packageCode: '',
      packageName: '',
      cost: ''
    }])
    const { enqueueSnackbar } = useSnackbar();
    const [orgList, setOrgList] = useState<[{orgName:string, orgEmail:string}]>([{
      orgName:'',
      orgEmail:''
    }])

    const organizationsPage: OrganizationsPageReducer = useSelector<
    Reducers,
    OrganizationsPageReducer
  >((state) => state.organizationsPage);

    React.useEffect(() => {
      dispatch(
        organizationActions.fetchProducts({
          limit: 10,
          page: 1,
        })
      );
      dispatch(
        organizationActions.fetchPackages({
          limit: 10,
          page: 1,
        })
      );
      dispatch(
        organizationActions.fetchReferrals({
          limit: 10,
          page: 1,
          parentOrgId: ''
        })
      );
    }, [dispatch]);

    useEffect(() => {
      if (customerName.length < 3) {
        setCustomers([]);
        return;
      }
  
      fetchCustomers({ keyword: customerName }, 1, 10)
        .then((result) => setCustomers(result.list))
        .catch((err) => setCustomers([]));
    }, [customerName]);

    useEffect(() => {
      if (parentCustomerName.length < 3) {
        setParentCutomers([]);
        return;
      }
  
      fetchCustomers({ keyword: parentCustomerName }, 1, 10)
        .then((result) => setParentCutomers(result.list))
        .catch((err) => setParentCutomers([]));
    }, [parentCustomerName]);

    function clearValues(){
      setParentOrgId('')
      setCustomerName('')
      setOrgImg('')
      setOrgName('')
      setOrgEmail('')
      setIsSwarmSubscription(false)
      setIsDnCSubscription(false)
      setSwarmSubscription({packageCode:'', packageName:'', cost:''})
      setDnCSubscription({packageCode:'', packageName:'', cost:''})
      setNewDialogOpen(false)
    }

  return (
    <div>
      <Typography component="h1" variant="h5">
        Vendors
      </Typography>
      <div>
        <TextField
                  inputRef={anchorE2}
                  placeholder="Search"
                  size="small"
                  label="Parent Organization"
                  value={parentCustomerName}
                  onKeyDown={(ev)=>{
                    if(ev.key == "Enter"){
                      ev.preventDefault()
                      dispatch(
                        organizationActions.fetchReferrals({
                          limit: 10,
                          page: 1,
                          parentOrgId: parentCustomerName
                        })
                      );
                    }
                  }}
                  onChange={(e) => setParentCustomerName(e.target.value)}
                  onBlur={() => setParentCustomerName(parentCustomerName.trim())}
                  variant="outlined"
                />
                <Popper
                  open={Boolean(parentCustomers.length)}
                  anchorEl={anchorE2.current}
                  style={{
                    width: anchorE2.current ? anchorE2.current.clientWidth : undefined,
                    zIndex: 1301
                  }}
                  role="presentation"
                >
                  <Paper>
                    {parentCustomers.map((customer) => (
                      <MenuItem
                        key={customer.id}
                        onClick={() => {
                          setSearchParentOrgId(customer.organization.id)
                          setParentCustomerName(customer.organization.name)
                          setParentCutomers([])
                          dispatch(
                            organizationActions.fetchReferrals({
                              limit: 10,
                              page: 1,
                              parentOrgId: customer.organization.id
                            })
                          );
                        }}
                      >
                        {customer.organization?.name || customer.reseller?.name}
                      </MenuItem>
                    ))}
                  </Paper>
                </Popper> 
        <Button
                color="secondary"
                size="medium"
                variant="outlined"
                onClick={(e)=>{
                  setNewDialogOpen(true)
                  subscriptionPackages.pop()
                }}
                style={{alignItems:'center', marginLeft:'10px'}}
                startIcon={<AddLinkIcon />}
              >
                New Referral
              </Button>
        <Grid container spacing={3}>
          <Grid item xs={3}><h4>Organization Name</h4></Grid>
          <Grid item xs={3}><h4>Organization Email</h4></Grid>
          <Grid item xs={3}><h4>Invite Status</h4></Grid>
        </Grid>
        <List>
          {organizationsPage.referrals.map((referral)=>{
            return(<div>
              <Grid container spacing={3}>
                <Grid item xs={3}><p>{referral.referral_org_name}</p></Grid>
                <Grid item xs={3}><p>{referral.referral_email}</p></Grid>
                <Grid item xs={3}><p>{referral.referral_status == "Consumed" ? "Consumed" :
                parseInt(referral.referral_expiry) > moment().unix() ? "Pending" : "Expired"}</p></Grid>
              </Grid>
            </div>)
          })}
        </List>
      </div>
      
      <Dialog fullWidth open={dialogOpen} >
              <DialogTitle>Confirm to create invite link</DialogTitle>
              <DialogContent>
                <div>Do you want to create referral invite link to <b>{orgList.length}</b> Organization(s)</div>
                <div style={{display:'flex', justifyContent:'flex-end', marginTop:'15px'}}>
                  <Button
                      style={{textTransform:'none'}} 
                      color="primary"
                      variant="contained"
                      onClick={() => {  
                        dispatch(userActions.createVendorLink({
                          parent_org_id: parentOrgId,
                          org_image: parentOrgId.length > 0 ? orgImgData : "",
                          list: orgList,
                          subscriptions: subscriptionPackages
                        })).then((payload:any)=>{
                          enqueueSnackbar('Shared invite link', {variant:'success'})
                          clearValues()
                        }).catch((err:any)=>{
                          enqueueSnackbar('Unable to share invite link', {variant:'error'})
                          clearValues()
                        });
                        setDialogOpen(false);
                      }}
                    >Create Invite Link</Button>
                    <Button
                      style={{textTransform:'none', marginLeft:'10px'}} 
                      color="primary"
                      variant="outlined"
                      onClick={() => {  
                        setDialogOpen(false);
                      }}
                    >Cancel</Button>
                </div>
              </DialogContent>
      </Dialog>

      <Dialog fullWidth open={newDialogOpen} >
        <DialogTitle>
          <div style={{display:'flex', flexDirection:'row'}}>
            <div style={{flex:'1'}}>Create referral link</div>
            <CloseIcon style={{cursor:'pointer'}} onClick={(e)=>{
              clearValues()
            }} />
          </div>
          </DialogTitle>
        <DialogContent>
          <div>Parent Organization</div>
          <Grid container>
            <Grid item xs={10}>
                <TextField
                  fullWidth
                  inputRef={anchorEl}
                  placeholder="Search"
                  label="Parent Organization"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  onBlur={() => setCustomerName(customerName.trim())}
                  variant="standard"
                />
                <Popper
                  open={Boolean(customers.length)}
                  anchorEl={anchorEl.current}
                  style={{
                    width: anchorEl.current ? anchorEl.current.clientWidth : undefined,
                    zIndex: 1301
                  }}
                  role="presentation"
                >
                  <Paper>
                    {customers.map((customer) => (
                      <MenuItem
                        key={customer.id}
                        onClick={() => {
                          setParentOrgId(customer.organization.id)
                          setCustomerName(customer.organization.name)
                          setCustomers([])
                        }}
                      >
                        {customer.organization?.name || customer.reseller?.name}
                      </MenuItem>
                    ))}
                  </Paper>
                </Popper> 
            </Grid>
            
            <div style={{display:'flex', flexDirection:'row', 
              justifyContent:'center', alignItems:'center', height:'110px'}}>
              {/* <button style={{display:'block',width:'120px',height:'30px', flexGrow:'1'}} onClick={(e)=>{
                document?.getElementById('selectFile')?.click()
              }}>Choose logo</button> */}
              <input type="file" id="selectFile" accept="image/*" onChange={(e)=>{
                let fileList = e.target.files
                if(fileList && fileList.length > 0){
                  let imgFile = fileList[0]
                  const imgUrl = URL.createObjectURL(imgFile)
                  setOrgImg(imgUrl)
                  let reader = new FileReader()
                  reader.readAsDataURL(imgFile)
                  reader.onloadend = function(){
                    try {
                      setOrgImgData(reader.result?.toString() ? reader.result?.toString() : "") 
                    } catch (error) {
                      
                    }
                  }
                }
              }} />

              {orgImg.length > 0 && <img src={orgImg} alt="orgImage"
              style={{height:'100px', width:'150px', aspectRatio:'1', flexGrow:'1'}} />}
              
            </div>

            <Grid item xs={10}>
              <div style={{height:'0.5px', backgroundColor:'gray', marginTop:'10px'}}/>
            </Grid>            
              
            <Grid item xs={10}>
              <div style={{display:'flex', flexDirection:'row', alignItems:'center', marginTop:'10px'}}>
                <div >Subscription Details</div>
              </div>
            </Grid>
            
            <Grid container style={{alignItems:'center', marginTop:'10px'}}>
                <Grid item xs={2}>
                    <div>Swarm</div>
                </Grid>
                
                {!isSwarmSubscription && <Grid item xs={2} style={{marginLeft:'10px'}}>
                  <AddCircleOutlineIcon style={{marginLeft:'8px', cursor:'pointer'}} onClick={(e)=>{
                    setIsSwarmSubscription(true)
                  }} />
                </Grid>}

                {isSwarmSubscription && <Grid item xs={3} style={{marginLeft:'10px'}}>
                    <FormControl margin="none" fullWidth>
                    <InputLabel variant="standard" id="select-label">Package</InputLabel>
                    <Select
                      variant="standard"
                      labelId="select-parent-organization"
                      id="select-parent-organization"
                      onChange={(e: any) => {
                        setSwarmSubscription({...swarmSubscription,packageCode: e.target.value, packageName:e.target.name, cost:''})
                      }}
                    >
                      {organizationsPage.packages.map((org)=>{
                        if(org.product_id == "032db84a-69fb-4768-9b83-9f2e23859336"){
                          return(<MenuItem value={org.package_code}>{org.package_name}</MenuItem>)
                        }
                      })}
                    </Select>
                  </FormControl>
                </Grid>}
                
                {isSwarmSubscription && <Grid item xs={3} style={{ marginLeft:'10px'}}>
                  <TextField
                  label="Cost"
                  variant="standard"
                  fullWidth
                  value={swarmSubscription.cost}
                  onChange={(e)=>{
                    setSwarmSubscription({...swarmSubscription,packageCode: swarmSubscription.packageCode, 
                      packageName:swarmSubscription.packageName, cost:e.target.value})
                  }}/>
                </Grid>}
                
                {isSwarmSubscription && <Grid item xs={1} style={{ marginLeft:'5px'}}>
                  <CloseIcon style={{cursor:'pointer'}} onClick={(e)=>{
                      setIsSwarmSubscription(false)
                  }} />
                </Grid>}
              </Grid>

              <Grid container style={{alignItems:'center', marginTop:'10px'}}>
                <Grid item xs={2}>
                    <div>DnC</div>
                </Grid>
                
                {!isDnCSubscription && <Grid item xs={2} style={{marginLeft:'10px'}}>
                  <AddCircleOutlineIcon style={{marginLeft:'8px', cursor:'pointer'}} onClick={(e)=>{
                    setIsDnCSubscription(true)
                  }} />
                </Grid>}

                {isDnCSubscription && <Grid item xs={3} style={{marginLeft:'10px'}}>
                    <FormControl margin="none" fullWidth>
                    <InputLabel variant="standard" id="select-label">Package</InputLabel>
                    <Select
                      variant="standard"
                      labelId="select-parent-organization"
                      id="select-parent-organization"
                      onChange={(e: any) => {
                        setDnCSubscription({...dncSubscription,packageCode: e.target.value, packageName:e.target.name, cost:''})
                      }}
                    >
                      {organizationsPage.packages.map((org)=>{
                        if(org.product_id == "4c25b978-1a10-47b0-a106-738950823649"){
                          return(<MenuItem value={org.package_code}>{org.package_name}</MenuItem>)
                        }
                      })}
                    </Select>
                  </FormControl>
                </Grid>}
                
                {isDnCSubscription && <Grid item xs={3} style={{ marginLeft:'10px'}}>
                  <TextField
                  label="Cost"
                  variant="standard"
                  fullWidth
                  value={dncSubscription.cost}
                  onChange={(e)=>{
                    setDnCSubscription({...dncSubscription,packageCode: dncSubscription.packageCode, 
                      packageName:dncSubscription.packageName, cost:e.target.value})
                  }}/>
                </Grid>}
                
                {isDnCSubscription && <Grid item xs={1} style={{ marginLeft:'5px'}}>
                  <CloseIcon style={{cursor:'pointer'}} onClick={(e)=>{
                      setIsDnCSubscription(false)
                  }} />
                </Grid>}
              </Grid>

            <Grid item xs={10}>
              <div style={{height:'0.5px', backgroundColor:'gray', marginTop:'10px'}}/>
            </Grid>            
              
            <Grid item xs={10}>
              <div style={{marginTop:'10px'}}>Vendor Details</div>
            </Grid>

            <Grid item xs={5} style={{marginTop:'15px'}}>
              <TextField
              label="Organization Name"
              variant="outlined"
              fullWidth
              value={orgName}
              onChange={(e)=>{
                  setOrgName(e.target.value)
              }}/>
            </Grid>
            <Grid item xs={5} style={{marginTop:'15px', marginLeft:'10px'}}>
              <TextField
              label="Organization Email"
              variant="outlined"
              fullWidth
              value={orgEmail}
              onChange={(e)=>{
                  setOrgEmail(e.target.value)
              }}/>
            </Grid>
            <Grid item xs={12} style={{marginTop:'10px'}}>
              <Button
                color="secondary"
                size="medium"
                variant="outlined"
                onClick={(e)=>{
                  if(orgName.trim().length == 0){
                    enqueueSnackbar("Enter organization name", {variant:'info'})
                  }else if(orgEmail.trim().length == 0){
                    enqueueSnackbar("Enter organization email", {variant:'info'})
                  }else{
                    let subscriptions = []
                    if(isSwarmSubscription){
                      subscriptions.push({productId:"032db84a-69fb-4768-9b83-9f2e23859336", 
                      packageCode:swarmSubscription.packageCode,packageName:swarmSubscription.packageName,cost:swarmSubscription.cost})
                    }
                    if(isDnCSubscription){
                      subscriptions.push({productId:"4c25b978-1a10-47b0-a106-738950823649", 
                      packageCode:dncSubscription.packageCode,packageName:dncSubscription.packageName,cost:dncSubscription.cost})
                    }
                    dispatch(userActions.createVendorLink({
                      parent_org_id: parentOrgId,
                      org_image: parentOrgId.length > 0 ? orgImgData : "",
                      list: [{"orgName": orgName, "orgEmail": orgEmail}],
                      subscriptions: subscriptions
                    })).then((payload:any)=>{
                      enqueueSnackbar('Shared Invite link', {variant:'success'})
                      setOrgName("")
                      setOrgEmail("")
                    }).catch((err:any)=>{
                      enqueueSnackbar('Unable to create invite link', {variant:'error'})
                      setNewDialogOpen(false)
                      clearValues()
                    });
                  }
                }}
                style={{alignItems:'center'}}
                startIcon={<AddLinkIcon />}
              >
                Create
              </Button>
            </Grid>
            <Grid item xs={10}>
              <div style={{display:'flex', flexDirection:'row'}}>
                <div style={{height:'1px', backgroundColor:'gray', marginTop:'10px', width:'45%'}}/>
                <div style={{width:'10%', textAlign:'center'}}>Or</div>
                <div style={{height:'1px', backgroundColor:'gray', marginTop:'10px', width:'45%'}}/>
              </div>
            </Grid>

            <Grid item xs={12}>
            <Button
                component="label"
                variant="outlined"
                startIcon={<UploadFileIcon />}
              >
                Upload CSV
                <input type="file" accept=".csv" hidden onChange={(e)=>{
                  if (!e.target.files) {
                    return;
                  }
                  const file = e.target.files[0];
                  const reader = new FileReader();
                  reader.onload = (evt) => {
                    if (!evt?.target?.result) {
                      return;
                    }

                    const { result } = evt.target;
                    const records = parse(result as string, {
                      columns: ["id", "value"],
                      delimiter: ";",
                      trim: true,
                      skip_empty_lines: true
                    });
                    const list: [{orgName:string,orgEmail:string}] = [{orgName:'',orgEmail:''}]
                    list.pop()
                    records.map((r:any)=>{
                      list.push({orgName:r.id,orgEmail:r.value})
                    })
                    setOrgList(list)
                    setDialogOpen(true)
                  };
                  reader.readAsBinaryString(file);
                }} />
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
