import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider} from "@mui/material/styles";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store";

const outerTheme = createTheme({
  palette: {
    primary: {
      main: "#e87200",
    },
    secondary: {
      main: "#000",
      contrastText: "#fff",
    },
  },
});
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
)
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={outerTheme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
