import { combineReducers } from "redux";
import { usersActions } from "../actions/autnhive/users";
import { releasesActions } from "../actions/autnhive/releases";
import { contractsActions } from "../actions/autnhive/contracts";
import { resellersActions } from "../actions/autnhive/resellers";
import { samiChatActions } from "../actions/sami";

import { onboardingsActions } from "../actions/autnhive/organizations-onboardings";
import {
  AutnhiveContractType,
  CustomError,
  DomainsList,
  DomainJobsList,
  SubTasksList,
  FeatureType,
  OrganizationOnboardingType,
  OrganizationType,
  PackageType,
  ProductType,
  ReleaseType,
  ReleaseVariantType,
  ResellerType,
  RoleType,
  SamiChatType,
  UserType,
  DomainTasksList,
} from "../services/interfaces";
import moment from "moment";
import { easaActions } from "../actions/easa";

export interface UsersPageReducer {
  users: Array<UserType>;
  count: number;
  page: number;
  loading: boolean;
}
export interface CreateUserReducer {
  loading: boolean;
  success?: string;
  error?: CustomError;
  roles: Array<RoleType>;
}

export interface UpdateUserReducer {
  loading: boolean;
  success?: string;
  error?: CustomError;
  roles: Array<RoleType>;
}

export interface ContractsPageReducer {
  contracts: Array<AutnhiveContractType>;
  count: number;
  page: number;
  customer_name: string;
  loading: boolean;
  status: "draft" | "approved" | "rejected" | undefined;
  sort_by: "create-date" | "start-date" | "name";
}

export interface CreateNewCustomerContractReducer {
  loading: boolean;
  success?: string;
  error?: CustomError;
  products: Array<ProductType>;
  packages: Array<PackageType>;
  features: Array<FeatureType>;
  salesPeople: Array<{
    id: string;
    first_name: string;
    last_name: string;
    email: string;
  }>;
  approvers: Array<{
    id: string;
    first_name: string;
    last_name: string;
    email: string;
  }>;
}

export interface RenewCustomerContractReducer {
  loading: boolean;
  error?: CustomError;
}

export interface UpdateNewCustomerContractReducer {
  loading: boolean;
  success?: string;
  error?: CustomError;
  products: Array<ProductType>;
  packages: Array<PackageType>;
  features: Array<FeatureType>;
  approvers: Array<{ id: string; first_name: string; last_name: string }>;
  salesCodes: Array<number>;
  salesPeople: Array<{ id: string; first_name: string; last_name: string }>;
}

export interface ReleasesPageReducer {
  releases: Array<ReleaseType>;
  count: number;
  page: number;
  loading: boolean;
}

export interface CreateReleaseReducer {
  loading: boolean;
  success?: string;
  error?: CustomError;
  products: Array<ProductType>;
}

export interface CreateOrganizationOnboardingReducer {
  loading: boolean;
  error?: CustomError;
  contracts: Array<AutnhiveContractType>;
}

export interface UpdateOrganizationOnboardingReducer {
  loading: boolean;
  error?: CustomError;
  contracts: Array<AutnhiveContractType>;
  //Limit multiple network calls
  contractsFetched: boolean;
  attempts: number;
}

export interface UpdateReleaseReducer {
  loading: boolean;
  success?: string;
  error?: CustomError;
  products: Array<ProductType>;
}

export interface CreateReleaseVariantReducer {
  loading: boolean;
  success?: string;
  error?: CustomError;
}

export interface ResellersPageReducer {
  resellers: Array<ResellerType>;
  count: number;
  searchKeyword: string;
  page: number;
  loading: boolean;
}

export interface SamiChatPageReducer {
  semiChats: Array<SamiChatType>;
  count: number;
  page: number;
  startDate: Date;
  endDate: Date;
  loading: boolean;
}

export interface UpdateResellerReducer {
  loading: boolean;
  success?: string;
  error?: CustomError;
}

export interface OrganizationsOnboardingsPageReducer {
  onboardings: Array<OrganizationOnboardingType>;
  count: number;
  page: number;
  customer_name: string;
  loading: boolean;
  status: "draft" | "approved" | "rejected" | undefined;
}

export interface EasaPageReducer {
  domains: {
    page: number;
    list: Array<DomainsList>;
    count: number;
    loading: boolean;
  }
  domainJobs: {
    page: number;
    list: Array<DomainJobsList>;
    count: number;
    loading: boolean;
  },
  updateLogo: {
    message: string;
    loading: boolean;
  }
  domainTasks: {
    page: number;
    list: Array<DomainTasksList>;
    count: number;
    loading: boolean;
  }
  subTasks: {
    page: number;
    list: Array<SubTasksList>;
    count: number;
    loading: boolean;
  }
  loading: boolean;
  retryResponse: string;
}

function usersPageReducer(
  prevState: UsersPageReducer = {
    users: [],
    count: 0,
    page: 1,
    loading: false,
  },
  {
    type,
    payload,
  }: {
    type: string;
    payload: number | { list: [UserType]; count: number } | string;
  }
): UsersPageReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case usersActions.usersWillLoad:
      state.page = payload as number;
      state.loading = true;
      break;
    case usersActions.usersLoadSucceeded:
      state.loading = false;
      state.users = (payload as { list: [UserType] }).list;
      state.count = (payload as { count: number }).count;
      break;
    case usersActions.usersLoadFailed:
      state.loading = false;
      state.users = [];
      break;
    default:
  }
  return state;
}

function createUserReducer(
  prevState: CreateUserReducer = {
    loading: false,
    roles: [],
  },
  {
    type,
    payload,
  }: { type: string; payload: UserType | [RoleType] | CustomError }
): CreateUserReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case usersActions.createUser:
      state.loading = true;
      delete state.success;
      delete state.error;
      break;
    case usersActions.createUserSucceeded:
      state.loading = false;
      state.success = "User is created";
      delete state.error;
      break;
    case usersActions.createUserFailed:
      state.loading = false;
      delete state.success;
      state.error = payload as CustomError;
      break;
    case usersActions.dismissUserMessages:
      delete state.error;
      delete state.success;
      break;
    case usersActions.rolesLoadSucceeded:
      state.roles = payload as [RoleType];
      break;
    case usersActions.rolesLoadFailed:
      state.roles = [];
      break;
    default:
  }
  return state;
}

function updateUserReducer(
  prevState: UpdateUserReducer = {
    loading: false,
    roles: [],
  },
  {
    type,
    payload,
  }: { type: string; payload: UserType | [RoleType] | CustomError }
): UpdateUserReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case usersActions.updateUser:
      state.loading = true;
      delete state.success;
      delete state.error;
      break;
    case usersActions.updateUserSucceeded:
      state.loading = false;
      state.success = "User is updated";
      delete state.error;
      break;
    case usersActions.updateUserFailed:
      state.loading = false;
      delete state.success;
      state.error = payload as CustomError;
      break;
    case usersActions.dismissUserMessages:
      delete state.error;
      delete state.success;
      break;
    case usersActions.rolesLoadSucceeded:
      state.roles = payload as [RoleType];
      break;
    case usersActions.rolesLoadFailed:
      state.roles = [];
      break;
    default:
  }
  return state;
}

function contractsPageReducer(
  prevState: ContractsPageReducer = {
    contracts: [],
    count: 0,
    page: 1,
    customer_name: "",
    loading: false,
    status: undefined,
    sort_by: 'create-date'
  },
  {
    type,
    payload,
  }: {
    type: string;
    payload:
    | { list: Array<AutnhiveContractType>; count: number }
    | [ProductType]
    | string
    | ("draft" | "approved" | "rejected" | undefined)
    | number
    | { customer_name: string; page: number }
    | ("create-date" | "start-date" | "name");
  }
): ContractsPageReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case contractsActions.contractsWillLoad:
      state.loading = true;
      state.page = payload as number;
      break;
    case contractsActions.contractsLoadSucceeded:
      state.loading = false;
      state.contracts = (payload as { list: Array<AutnhiveContractType> }).list;
      state.count = (payload as { count: number }).count;
      break;
    case contractsActions.contractsLoadFailed:
      state.loading = false;
      state.contracts = [];
      break;
    case contractsActions.updateSearchKeyword:
      state.customer_name = payload as string;
      break;
    case contractsActions.updateStatusFilter:
      state.status = payload as "draft" | "approved" | "rejected" | undefined;
      break;
    case contractsActions.updateSortByFilter:
      state.sort_by = payload as "create-date" | "start-date" | "name";
      break;
    default:
  }
  return state;
}

function renewCustomerContractReducer(
  prevState: RenewCustomerContractReducer = {
    loading: false,
  },
  {
    type,
    payload,
  }: {
    type: string;
    payload: CustomError;
  }
) {
  const state = Object.assign({}, prevState);
  switch (type) {
    case contractsActions.renewContract:
      state.loading = true;
      break;
    case contractsActions.renewContractSucceeded:
      state.loading = false;
      delete state.error;
      break;
    case contractsActions.renewContractFailed:
      state.loading = false;
      state.error = payload as CustomError;
      break;
    case contractsActions.resetState:
      delete state.error;
      break;
    default:
  }
  return state;
}

function createNewCustomerContractReducer(
  prevState: CreateNewCustomerContractReducer = {
    loading: false,
    products: [],
    packages: [],
    features: [],
    salesPeople: [],
    approvers: [],
  },
  {
    type,
    payload,
  }: {
    type: string;
    payload: {
      products: [ProductType];
      packages: [PackageType];
      features: [FeatureType];
      salesPeople: [
        { id: string; first_name: string; last_name: string; email: string }
      ];
      approvers: [
        { id: string; first_name: string; last_name: string; email: string }
      ];
    };
  }
) {
  const state = Object.assign({}, prevState);
  switch (type) {
    case contractsActions.inputOptionsLoadSucceeded:
      const { products, packages, salesPeople, features, approvers } = payload as {
        products: [ProductType];
        packages: [PackageType];
        features: [FeatureType];
        salesPeople: [
          { id: string; first_name: string; last_name: string; email: string }
        ];
        approvers: [
          { id: string; first_name: string; last_name: string; email: string }
        ];
      };
      state.products = products;
      state.packages = packages;
      state.features = features;
      state.salesPeople = salesPeople;
      state.approvers = approvers;
      break;
    case contractsActions.createContract:
      state.loading = true;
      break;
    case contractsActions.createNewCustomerContractSucceeded:
      state.loading = false;
      delete state.error;
      break;
    case contractsActions.createNewCustomerContractFailed:
      state.loading = false;
      state.error = payload as CustomError;
      delete state.success;
      break;
    case contractsActions.resetState:
      delete state.error;
      delete state.success;
      break;
    default:
  }
  return state;
}

function updateNewCustomerContractReducer(
  prevState: UpdateNewCustomerContractReducer = {
    loading: false,
    products: [],
    packages: [],
    features: [],
    salesCodes: [],
    salesPeople: [],
    approvers: [],
  },
  {
    type,
    payload,
  }: {
    type: string;
    payload: {
      products: [ProductType];
      packages: [PackageType];
      features: [FeatureType];
      salesCodes: [number];
      salesPeople: [
        { id: string; first_name: string; last_name: string; email: string }
      ];
      approvers: [
        { id: string; first_name: string; last_name: string; email: string }
      ];
    };
  }
) {
  const state = Object.assign({}, prevState);
  switch (type) {
    case contractsActions.inputOptionsLoadSucceeded:
      const { products, packages, salesCodes, salesPeople, features, approvers } = payload as {
        products: [ProductType];
        packages: [PackageType];
        features: [FeatureType];
        salesCodes: [number];
        salesPeople: [
          { id: string; first_name: string; last_name: string; email: string }
        ];
        approvers: [
          { id: string; first_name: string; last_name: string; email: string }
        ];
      };
      state.products = products;
      state.packages = packages;
      state.features = features;
      state.salesCodes = salesCodes;
      state.salesPeople = salesPeople;
      state.approvers = approvers;
      break;
    case contractsActions.updateNewCustomerContract:
      state.loading = true;
      break;
    case contractsActions.updateNewCustomerContractSucceeded:
      state.loading = false;
      delete state.error;
      break;
    case contractsActions.updateNewCustomerContractFailed:
      state.loading = false;
      state.error = payload as CustomError;
      delete state.success;
      break;
    case contractsActions.resetState:
      delete state.error;
      delete state.success;
      break;
    default:
  }
  return state;
}

function releasesPageReducer(
  prevState: ReleasesPageReducer = {
    releases: [],
    count: 0,
    page: 1,
    loading: false,
  },
  {
    type,
    payload,
  }: {
    type: string;
    payload:
    | { list: Array<ReleaseType>; count: number }
    | [ProductType]
    | number;
  }
): ReleasesPageReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case releasesActions.releasesWillLoad:
      state.loading = true;
      state.page = payload as number;
      break;
    case releasesActions.releasesLoadSucceeded:
      state.loading = false;
      state.releases = (payload as { list: Array<ReleaseType> }).list;
      state.count = (payload as { count: number }).count;
      break;
    case releasesActions.releasesLoadFailed:
      state.loading = false;
      state.releases = [];
      break;
    default:
  }
  return state;
}

function resellersPageReducer(
  prevState: ResellersPageReducer = {
    resellers: [],
    searchKeyword: "",
    count: 0,
    page: 1,
    loading: false,
  },
  {
    type,
    payload,
  }: {
    type: string;
    payload: { list: Array<ResellerType>; count: number } | {reseller: string; orgs: OrganizationType[]} | number | string;
  }
): ResellersPageReducer {
  const state = Object.assign({}, prevState);

  switch (type) {
    case resellersActions.resellersWillLoad:
      state.loading = true;
      state.page = payload as number;
      break;
    case resellersActions.resellersLoadSucceeded:
      state.loading = false;
      state.resellers = (payload as { list: Array<ResellerType> }).list;
      state.count = (payload as { count: number }).count;
      break;
    case resellersActions.updateSearchKeyword:
      state.searchKeyword = payload as string;
      break;
    case resellersActions.resellersLoadFailed:
      state.loading = false;
      state.resellers = [];
      break;
    case resellersActions.resetStates:
      state.loading = false;
      state.resellers = [];
      state.count = 0;
      state.page = 1;
      state.searchKeyword = "";
      break;
    case resellersActions.resellerOrgsFetchSuccess:
      const newPayload = payload as {reseller: string; orgs: OrganizationType[]}
      state.resellers = state.resellers.map(reseller =>
        reseller.id === newPayload.reseller
          ? {
              ...reseller,
              orgsFetched: true,
              hideOrgs: false,
              orgs: newPayload.orgs
            }
          : reseller
      )
      break;
      case resellersActions.resellerOrgsToggle:
          const id = payload as string;
          state.resellers = state.resellers.map(reseller => 
            reseller.id === id
              ? {
                ...reseller,
                hideOrgs: !reseller.hideOrgs
              }
              : reseller
          );
          break;
     default: 
  }
  return state;
}

function samiChatPageReducer(
  prevState: SamiChatPageReducer = {
    semiChats: [],
    count: 0,
    page: 1,
    loading: false,
    startDate: moment().startOf("month").subtract(1, "month").toDate(),
    endDate: moment().endOf("month").toDate(),
  },
  {
    type,
    payload,
  }: {
    type: string;
    payload: { list: Array<SamiChatType>; count: number } | number | string;
  }
): SamiChatPageReducer {
  const state = Object.assign({}, prevState);

  switch (type) {
    case samiChatActions.samiChatWillLoad:
      state.loading = true;
      // state.page = payload as number;
      // state.startDate = payload as unknown as Date;
      // state.endDate =payload as unknown as Date;
      state.page = (payload as unknown as { page: number }).page;
      state.startDate = (payload as unknown as { startDate: Date }).startDate;
      state.endDate = (payload as unknown as { endDate: Date }).endDate;
      break;
    case samiChatActions.samiChatLoadSucceeded:
      state.loading = false;
      state.semiChats = (payload as { list: Array<SamiChatType> }).list;
      state.count = (payload as { count: number }).count;
      
      break;
    case samiChatActions.samiChatLoadFailed:
      state.loading = false;
      state.semiChats = [];
      break;
    case samiChatActions.resetStates:
      state.loading = false;
      state.semiChats = [];
      state.count = 0;
      state.page = 1;
      break;
    default:
  }
  return state;
}

function updateResellerReducer(
  prevState: UpdateResellerReducer = {
    loading: false,
  },
  {
    type,
    payload,
  }: {
    type: string;
    payload: {
      payload: UserType | CustomError;
    };
  }
): UpdateResellerReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case resellersActions.updateResellerSucceeded:
      state.loading = false;
      delete state.error;
      break;
    case resellersActions.updateResellerFailed:
      state.loading = false;
      delete state.success;
      state.error = payload as CustomError;
      break;
    default:
  }
  return state;
}

function createReleaseReducer(
  prevState: CreateReleaseReducer = {
    loading: false,
    products: [],
  },
  { type, payload }: { type: string; payload: [ReleaseType] | [ProductType] }
) {
  const state = Object.assign({}, prevState);
  switch (type) {
    case releasesActions.productsLoadSucceeded:
      state.products = payload as [ProductType];
      break;
    case releasesActions.createRelease:
      state.loading = true;
      break;
    case releasesActions.createReleaseSucceeded:
      state.loading = false;
      delete state.error;
      break;
    case releasesActions.createReleaseFailed:
      state.loading = false;
      state.error = payload as CustomError;
      delete state.success;
      break;
    default:
  }
  return state;
}

function updateReleaseReducer(
  prevState: UpdateReleaseReducer = {
    loading: false,
    products: [],
  },
  { type, payload }: { type: string; payload: [ReleaseType] | [ProductType] }
) {
  const state = Object.assign({}, prevState);
  switch (type) {
    case releasesActions.productsLoadSucceeded:
      state.products = payload as [ProductType];
      break;
    case releasesActions.updateRelease:
      state.loading = true;
      break;
    case releasesActions.updateReleaseSucceeded:
      state.loading = false;
      delete state.error;
      break;
    case releasesActions.updateReleaseFailed:
      state.loading = false;
      state.error = payload as CustomError;
      delete state.success;
      break;
    default:
  }
  return state;
}

function createReleaseVariantReducer(
  prevState: CreateReleaseVariantReducer = {
    loading: false,
  },
  { type, payload }: { type: string; payload: ReleaseVariantType | CustomError }
): CreateReleaseVariantReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case releasesActions.createReleaseVariant:
      state.loading = true;
      delete state.success;
      delete state.error;
      break;
    case releasesActions.createReleaseVariantSucceeded:
      state.loading = false;
      state.success = "Release variant created";
      delete state.error;
      break;
    case releasesActions.createReleaseVariantFailed:
      state.loading = false;
      delete state.success;
      state.error = payload as CustomError;
      break;
    default:
  }
  return state;
}

function createOrganizationOnboardingReducer(
  prevState: CreateOrganizationOnboardingReducer = {
    loading: false,
    contracts: [],
  },
  {
    type,
    payload,
  }: { type: string; payload: Array<AutnhiveContractType> | CustomError }
): CreateOrganizationOnboardingReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case resellersActions.contractsLoadFaied:
      state.contracts = [];
      break;
    case resellersActions.contractsLoadSucceeded:
      state.contracts = payload as Array<AutnhiveContractType>;
      break;
    case resellersActions.createOrganization:
      state.loading = true;
      break;
    case resellersActions.createOrganizationSucceeded:
      state.loading = false;
      delete state.error;
      break;
    case resellersActions.createOrganizationFailed:
      state.error = payload as CustomError;
      state.loading = false;
      break;
    default:
  }
  return state;
}

function updateOrganizationOnboardingReducer(
  prevState: UpdateOrganizationOnboardingReducer = {
    loading: false,
    contracts: [],
    contractsFetched: false,
    attempts:0
  },
  {
    type,
    payload,
  }: { type: string; payload: Array<AutnhiveContractType> | CustomError }
): UpdateOrganizationOnboardingReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case onboardingsActions.contractsWillLoad:
      state.loading = true;
      break;
    case onboardingsActions.contractsLoadFailed:
      state.attempts++;
      state.contracts = [];
      break;
    case onboardingsActions.contractsLoadSucceeded:
      state.contracts = payload as Array<AutnhiveContractType>;
      state.contractsFetched = true;
      state.loading = false;
      state.attempts++;
      break;
    case onboardingsActions.updateOnboarding:
      state.loading = true;
      break;
    case onboardingsActions.updateOnboardingSucceeded:
      state.loading = false;
      delete state.error;
      break;
    case onboardingsActions.updateOnboardingFailed:
      state.error = payload as CustomError;
      state.loading = false;
      break;
    default:
  }
  return state;
}

function organizationsOnboardingsReducer(
  prevState: OrganizationsOnboardingsPageReducer = {
    loading: false,
    onboardings: [],
    count: 0,
    page: 1,
    customer_name: "",
    status: undefined,
  },
  {
    type,
    payload,
  }: {
    type: string;
    payload:
    | { list: Array<OrganizationOnboardingType>; count: number }
    | CustomError;
  }
): OrganizationsOnboardingsPageReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case onboardingsActions.onboardingsWillLoad:
      state.loading = true;
      break;
    case onboardingsActions.onboardingsLoadFailed:
      state.loading = false;
      state.onboardings = [];
      break;
    case onboardingsActions.onboardingsLoadSucceeded:
      state.loading = false;
      state.onboardings = (
        payload as { list: Array<OrganizationOnboardingType> }
      ).list;
      state.count = (payload as { count: number }).count;
      break;
    case onboardingsActions.updateSearchKeyword:
      state.customer_name = payload as string;
      break;
    case onboardingsActions.setPage: 
      state.page = payload as number;
      break;
    case onboardingsActions.updateStatusFilter:
      // state.status = payload as "draft" | "approved" | "rejected" | undefined;
      break;
    default:
  }
  return state;
}

function easaPageReducer(
  prevState: EasaPageReducer = {
    domains: {
      page: 1,
      list: [],
      count: 0,
      loading: false,
    },
    domainJobs: {
      page: 1,
      list: [],
      count: 0,
      loading: false,
    },
    domainTasks: {
      page: 1,
      list: [],
      count: 0,
      loading: false,
    },
    subTasks: {
      page: 1,
      list: [],
      count: 0,
      loading: false,
    },
    updateLogo: {
      message: 'loading',
      loading: false
    },
    loading: false,
    retryResponse: ""
  },
  {
    type,
    payload,
  }: {
    type: string;
    payload: { page: number; list: Array<any>; count: number}
  }
): EasaPageReducer {
  const state = Object.assign({}, prevState);

  switch (type) {
    case easaActions.domainsListWillLoad:
      state.domains.loading = true;
      state.domains.page = payload?.page;
      break;
    case easaActions.domainsListLoadSucceeded:
      state.domains.loading = false;
      state.domains.list = payload.list;
      state.domains.count = payload.count;
      break;
    case easaActions.domainsListLoadFailed:
      state.domains.loading = false;
      state.domains.list = [];
      break;
    case easaActions.domainJobsListWillLoad:
      state.domainJobs.loading = true;
      state.domainJobs.page = payload?.page;
      break;
    case easaActions.domainJobsListLoadSucceeded:
      state.domainJobs.loading = false;
      state.domainJobs.list = payload.list;
      state.domainJobs.count = payload.count;
      break;
    case easaActions.domainJobsListLoadFailed:
      state.domainJobs.loading = false;
      state.domainJobs.list = [];
      break;
    case easaActions.domainTasksListWillLoad:
      state.domainTasks.loading = true;
      state.domainTasks.page = payload?.page;
      break;
    case easaActions.domainTasksListLoadSucceeded:
      state.domainTasks.loading = false;
      state.domainTasks.list = payload.list;
      state.domainTasks.count = payload.count;
      break;
    case easaActions.domainTasksListLoadFailed:
      state.domainTasks.loading = false;
      state.domainTasks.list = [];
      break;
    case easaActions.updateDomainLogoWillLoad:
        state.updateLogo.loading = true;
        break;
    case easaActions.updateDomainLogoSucceeded:
        state.updateLogo.loading = false;
        state.updateLogo.message = "success"
        break;
    case easaActions.updateDomainLogoFailed:
        state.updateLogo.loading = false;
        break;
    case easaActions.subTasksListWillLoad:
      state.subTasks.loading = true;
      state.subTasks.page = payload?.page;
      break;
    case easaActions.subTasksListLoadSucceeded:
      state.subTasks.loading = false;
      state.subTasks.list = payload.list;
      state.subTasks.count = payload.count;
      break;
    case easaActions.subTasksListLoadFailed:
      state.subTasks.loading = false;
      state.subTasks.list = [];
      break;
    case easaActions.resetStates:
      state.domains.page = 1;
      state.domains.list = [];
      state.domains.count = 0;
      state.domains.loading = false;
      break;
    case easaActions.resetTasksStates:
      state.domainTasks.page = 1;
      state.domainTasks.list = [];
      state.domainTasks.count = 0;
      state.domainTasks.loading = false;
      break;
    case easaActions.resetSubTasksStates:
      state.subTasks.page = 1;
      state.subTasks.list = [];
      state.subTasks.count = 0;
      state.subTasks.loading = false;
      break;
    case easaActions.retryJobWillLoad:
        state.retryResponse = "";
        break;
    case easaActions.retryJobSucceeded:
        state.retryResponse = "success";
        break;
    case easaActions.retryJobFailed:
        state.retryResponse = "failure";
        break;
    case easaActions.resetRetryJob:
          state.retryResponse = "";
          break;
    default:
  }
  return state;
}

export interface AutnhiveReducers {
  usersPage: UsersPageReducer;
  createUser: CreateUserReducer;
  updateUser: UpdateUserReducer;
  releasesPage: ReleasesPageReducer;
  createRelease: CreateReleaseReducer;
  updateRelease: UpdateReleaseReducer;
  createReleaseVariant: CreateReleaseVariantReducer;
  resellersPage: ResellersPageReducer;
  updateReseller: UpdateResellerReducer;
  contractsPage: ContractsPageReducer;
  createNewCustomerContract: CreateNewCustomerContractReducer;
  updateNewCustomerContract: UpdateNewCustomerContractReducer;
  renewCustomerContract: RenewCustomerContractReducer;
  createOrganizationOnboarding: CreateOrganizationOnboardingReducer;
  updateOrganizationOnboarding: UpdateOrganizationOnboardingReducer;
  organizationsOnboardingsPage: OrganizationsOnboardingsPageReducer;
  samichats:SamiChatPageReducer;
  easa: EasaPageReducer;
}

export default combineReducers<AutnhiveReducers>({
  usersPage: usersPageReducer,
  createUser: createUserReducer,
  updateUser: updateUserReducer,
  releasesPage: releasesPageReducer,
  createRelease: createReleaseReducer,
  updateRelease: updateReleaseReducer,
  createReleaseVariant: createReleaseVariantReducer,
  resellersPage: resellersPageReducer,
  updateReseller: updateResellerReducer,
  contractsPage: contractsPageReducer,
  createNewCustomerContract: createNewCustomerContractReducer,
  updateNewCustomerContract: updateNewCustomerContractReducer,
  renewCustomerContract: renewCustomerContractReducer,
  createOrganizationOnboarding: createOrganizationOnboardingReducer,
  updateOrganizationOnboarding: updateOrganizationOnboardingReducer,
  organizationsOnboardingsPage: organizationsOnboardingsReducer,
  samichats: samiChatPageReducer,
  easa: easaPageReducer,
});
