
import axios from "../axios-client";
import { env } from '../env';
import { parseErrors } from "./helpers";
import { SecurityNotificationsType } from "../reducers";

const apiUrl: string = env.REACT_APP_DEVSECOPS_BACKEND_URL;

export async function fetchSecurityNotificationsList(
  page: number,
  limit: number) {

  try {
    const timeStamp = new Date().getTime();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { data } = await axios.get<SecurityNotificationsListResponse>(`${apiUrl}/devsecops/security-notifications?page=${page}&limit=${limit}`, { headers: { "timestamp": `${timeStamp}` } });
    return data.data.data;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}


interface SecurityNotificationsListResponse {
  data: {
    data: {
      list: [SecurityNotificationsType];
      count: number;
      page: string;
    };
  };
}