import * as React from "react";
import { Snackbar } from "@mui/material";
import { Alert } from '@mui/material';

export function Errors(props: { message?: string; onClose: any }) {
  if (!props.message) {
    return null;
  }
  const errorComps = (
    <Snackbar
      open={!!props.message}
      autoHideDuration={6000}
      onClose={props.onClose}
    >
      <Alert onClose={props.onClose} severity="error">
        {props.message}
      </Alert>
    </Snackbar>
  );
  return <React.Fragment>{errorComps}</React.Fragment>;
}

export function SuccessMessage({
  message,
  onClose,
}: {
  message?: string;
  onClose?: any;
}) {
  if (!message) return null;
  return (
    <Snackbar open={true} autoHideDuration={6000} onClose={onClose}>
      <Alert security="success" onClose={onClose}>
        {message}
      </Alert>
    </Snackbar>
  );
}
