import * as React from "react";
import { Button, CircularProgress } from "@mui/material";

interface SubmitButtonProps {
  loading: boolean;
  disabled?: boolean;
  label: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}
export default function SubmitButton(props: SubmitButtonProps) {
  return (
    <Button
      disabled={props.disabled || props.loading}
      style={{ width: 150 }}
      color="primary"
      variant="contained"
      onClick={props.onClick}
    >
      {props.loading ? (
        <CircularProgress size={24} color="inherit" />
      ) : (
        props.label
      )}
    </Button>
  );
}
