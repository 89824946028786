import React, { useState, useEffect } from "react";
import {
  Avatar,
  CssBaseline,
  Typography,
  Container,
  Button,
  Link,
  Box,
  CircularProgress,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useDispatch, useSelector } from "react-redux";
import { sha256 } from 'js-sha256';
import {
  Routes,
  Route,
  Link as RouteLink,
  useNavigate,
} from "react-router-dom";
import * as authActions from "../actions/auth";
import {
  LoginPageReducer,
  Reducers,
  ResetPasswordPageReducer,
} from "../reducers";
import "../scss/login.scss";
import MandatoryFieldText from "../components/mandatoryFieldsText";
import ReCaptcha from "../components/reCaptcha";
import PasswordRequirementText from "../components/passwordRequirementText";
import { useSnackbar } from "notistack";
import { UserConsentType } from "../services/interfaces";
import { getTermsConsentLinks } from "../services/website-service";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://autnhive.com/">
        Autnhive.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export function validatePassword(password: string) {
  const lowerCaseLetters = /[a-z]/g;
  const upperCaseLetters = /[A-Z]/g;
  const numbers = /[\d]/g;
  const specialCharacter = new RegExp("^(?=.*[!@#$%^&*_-])");
  const notAllowedCharacter = /[`~()+=\[\]{};':"\\|,.<>\/?]+/;
  if (
    password.match(lowerCaseLetters) &&
    password.match(upperCaseLetters) &&
    !notAllowedCharacter.test(password) &&
    password.match(numbers) &&
    password.match(specialCharacter) &&
    password.length >= 10
  ) {
    return true;
  } else {
    return false;
  }
}

function LoginPage() {
  const { enqueueSnackbar } = useSnackbar();
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isPlatformUseAccepted, setIsPlatformUseAccepted] = useState(false);
  const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);
  const [consentTerms, setConsentTerms] = useState<{
    platform_terms_of_use_policy: UserConsentType;
    terms_and_conditions: UserConsentType;
    privacy_policy: UserConsentType;
  }>({
    platform_terms_of_use_policy: { link: "", version: "" },
    terms_and_conditions: { link: "", version: "" },
    privacy_policy: { link: "", version: "" },
  });
  useEffect(() => {
    getTermsConsentLinks()
      .then((consentTerms) => {
        setConsentTerms(consentTerms);
      })
      .catch(() => undefined);
  }, []);
  const dispatch = useDispatch<any>();
  const loginPage: LoginPageReducer = useSelector<Reducers, LoginPageReducer>(
    (state) => state.loginPage
  );
  let emailRef: any = null;
  let passwordRef: any = null;
  let newPasswordRef: any = null;
  let otpRef: any = null;

  return (
    <div style={{ display: "flex", alignItems: "center", height: "100vh" }}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <Avatar className={""}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Autnhive Login
          </Typography>
          {loginPage.request_token ? (
            <form
              noValidate
              style={{ width: "100%", marginTop: "0.5rem" }}
              onSubmit={(e) => {
                e.preventDefault();
                const values: {
                  request_token: string | null;
                  otp: string;
                  terms_and_conditions_consent?: {
                    link: string;
                    version: string;
                  };
                  privacy_policy_consent?: {
                    link: string;
                    version: string;
                  };
                  new_password?: string;
                } = {
                  request_token: loginPage.request_token,
                  otp: sha256(`${otpRef.value}.${loginPage.salt}`),
                };
                if (loginPage.request_to_accept_terms) {
                  if (!isTermsAccepted) {
                    enqueueSnackbar("Please accept Terms and Conditions", {
                      variant: "error",
                    });
                    return;
                  }
                  values.terms_and_conditions_consent =
                    consentTerms.terms_and_conditions;
                }
                if (loginPage.request_to_accept_privacy_policy) {
                  if (!isPrivacyPolicyAccepted) {
                    enqueueSnackbar("Please accept Privacy Policy", {
                      variant: "error",
                    });
                    return;
                  }
                  values.privacy_policy_consent = consentTerms.privacy_policy;
                }

                if (loginPage.request_new_password) {
                  values.new_password = newPasswordRef?.value;
                }
                dispatch(authActions.login(values)).catch((err: Error) =>
                  enqueueSnackbar(err.message, { variant: "error" })
                );
              }}
            >
              <Button
                type="button"
                onClick={() => dispatch(authActions.resetState())}
              >
                <ArrowBackIosIcon /> Go back
              </Button>
              <TextField
                key="otp"
                error={!!loginPage.error?.validationErrors?.["otp"]}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Otp"
                inputRef={(ref) => (otpRef = ref)}
                autoFocus
              />
              {loginPage.request_new_password ? (
                <>
                  You are required to provide new password
                  <TextField
                    key="New password"
                    type="password"
                    error={
                      !!loginPage.error?.validationErrors?.["new_password"]
                    }
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="New Password"
                    inputRef={(ref) => (newPasswordRef = ref)}
                  />
                </>
              ) : null}
              {loginPage.request_to_accept_terms ? (
                <FormControlLabel
                  label={
                    <>
                      Accept{" "}
                      <a
                        style={{ textDecoration: "underline" }}
                        href={consentTerms.terms_and_conditions.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms and Conditions
                      </a>
                    </>
                  }
                  control={
                    <Checkbox
                      key="terms-and-conditions-checkbox"
                      checked={isTermsAccepted}
                      onChange={() => setIsTermsAccepted(!isTermsAccepted)}
                    />
                  }
                />
              ) : null}
              {loginPage.request_to_accept_privacy_policy ? (
                <FormControlLabel
                  label={
                    <>
                      Accept{" "}
                      <a
                        style={{ textDecoration: "underline" }}
                        href={consentTerms.privacy_policy.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </>
                  }
                  control={
                    <Checkbox
                      key="privacy-policy-checkbox"
                      checked={isPrivacyPolicyAccepted}
                      onChange={() =>
                        setIsPrivacyPolicyAccepted(!isPrivacyPolicyAccepted)
                      }
                    />
                  }
                />
              ) : null}
              <MandatoryFieldText
                hasErrors={Boolean(loginPage.error?.validationErrors)}
              />
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="secondary"
                style={{ margin: "1rem 0  0.5rem" }}
              >
                {loginPage.loading ? <CircularProgress size={24} /> : "Submit"}
              </Button>
            </form>
          ) : (
            <form
              noValidate
              style={{ width: "100%", marginTop: "0.5rem" }}
              onSubmit={(e) => {
                e.preventDefault();
                if (!isPlatformUseAccepted) {
                  enqueueSnackbar("Please accept platform use policy", {
                    variant: "error",
                  });
                  return;
                }
                dispatch(
                  authActions.loginOtp(
                    emailRef.value,
                    passwordRef.value,
                    consentTerms.platform_terms_of_use_policy
                  )
                ).catch((err: Error) =>
                  enqueueSnackbar(err.message, { variant: "error" })
                );
              }}
            >
              <TextField
                key="email"
                error={!!loginPage.error?.validationErrors?.["email"]}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Email Address"
                autoComplete="email"
                inputRef={(ref) => (emailRef = ref)}
                autoFocus
              />
              <TextField
                error={!!loginPage.error?.validationErrors?.["password"]}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Password"
                type="password"
                inputRef={(ref) => (passwordRef = ref)}
              />
              <MandatoryFieldText
                hasErrors={Boolean(loginPage.error?.validationErrors)}
              />
              <FormControlLabel
                label={
                  <>
                    Accept{" "}
                    <a
                      style={{ textDecoration: "underline" }}
                      href={consentTerms.platform_terms_of_use_policy.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Platform Use
                    </a>
                  </>
                }
                control={
                  <Checkbox
                    key="platform-use-policy-checkbox"
                    checked={isPlatformUseAccepted}
                    onChange={() =>
                      setIsPlatformUseAccepted(!isPlatformUseAccepted)
                    }
                  />
                }
              />
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="secondary"
                style={{ margin: "1rem 0  0.5rem" }}
              >
                {loginPage.loading ? <CircularProgress size={24} /> : "Login"}
              </Button>
            </form>
          )}
        </div>
        <RouteLink to={`reset-password`}>
          <Button type="button">Reset password</Button>
        </RouteLink>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
}

function ResetPassword() {
  const [showCaptcha, setShowCaptcha] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [newPass, setNewPass] = useState("");
  const resetPasswordPage: ResetPasswordPageReducer = useSelector<
    Reducers,
    ResetPasswordPageReducer
  >((state) => state.resetPassword);
  let emailRef: any = null;
  let passwordRef: any = null;
  let confirmRef: any = null;
  let otpRef: any = null;

  return (
    <div style={{ display: "flex", alignItems: "center", height: "100vh" }}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <Avatar className={""}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Autnhive - Reset Password
          </Typography>
          {resetPasswordPage.request_token ? (
            <form
              noValidate
              style={{ width: "100%", marginTop: "0.5rem" }}
              onSubmit={(e) => {
                e.preventDefault();
                if (!passwordRef.value) {
                  enqueueSnackbar("Please enter your new password", {
                    variant: "error",
                  });
                  return;
                }

                if (!confirmRef.value) {
                  enqueueSnackbar("Please confirm your new password", {
                    variant: "error",
                  });
                  return;
                }

                if (passwordRef.value !== confirmRef.value) {
                  enqueueSnackbar("Passwords do not match", { variant: 'error' });
                  return
                }
                if (!validatePassword(passwordRef.value)) {
                  enqueueSnackbar("Password does not match the criteria", {variant: "error"});
                  return;
                }
                console.log(resetPasswordPage.request_token, "request token")
                dispatch(
                  authActions.resetPassword(
                    resetPasswordPage.request_token,
                    sha256(`${otpRef.value}.${resetPasswordPage.salt}`),
                    passwordRef.value
                  )
                )
                  .then(() => {
                    enqueueSnackbar("Password reset success. Please login", {
                      variant: "success",
                    });
                    navigate("/login");
                  })
                  .catch((err: Error) =>
                    enqueueSnackbar(err.message, { variant: "error" })
                  );
              }}
            >
              <Button
                type="button"
                onClick={() => {
                  setShowCaptcha(false);
                  dispatch(authActions.resetState());
                }}
              >
                <ArrowBackIosIcon /> Go back
              </Button>
              <TextField
                key="otp"
                error={!!resetPasswordPage.error?.validationErrors?.["otp"]}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                // size="small"
                label="Otp"
                inputRef={(ref) => (otpRef = ref)}
                autoFocus
              />
              <TextField
                error={
                  !!resetPasswordPage.error?.validationErrors?.["password"]
                }
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Password"
                type="password"
                value={newPass}
                // size="small"
                onChange={(e) => {
                  setNewPass(e.target.value);
                }}
                inputRef={(ref) => (passwordRef = ref)}
              />
              <div style={{marginTop:'10px'}} className="password-requirement-text">
                <PasswordRequirementText
                  password={newPass}
                />
              </div>
              <TextField
                error={
                  !!resetPasswordPage.error?.validationErrors?.["confirm"]
                }
                variant="outlined"
                margin="normal"
                required
                fullWidth
                // size="small"
                label="Confirm Password"
                type="password"
                inputRef={(ref) => (confirmRef = ref)}
              />
              <MandatoryFieldText
                hasErrors={Boolean(resetPasswordPage.error?.validationErrors)}
              />
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="secondary"
                style={{ margin: "1rem 0  0.5rem" }}
              >
                {resetPasswordPage.loading ? (
                  <CircularProgress size={24} />
                ) : (
                  "Submit"
                )}
              </Button>
            </form>
          ) : (
            <form
              noValidate
              style={{ width: "100%", marginTop: "0.5rem" }}
              onSubmit={(e) => {
                e.preventDefault();
                setShowCaptcha(true);
              }}
            >
              <TextField
                autoFocus
                disabled={showCaptcha}
                key="email"
                error={!!resetPasswordPage.error?.validationErrors?.["email"]}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Email Address"
                autoComplete="email"
                inputRef={(ref) => (emailRef = ref)}
              />
              <MandatoryFieldText
                hasErrors={Boolean(resetPasswordPage.error?.validationErrors)}
              />
              {showCaptcha ? (
                <ReCaptcha
                  onSubmit={(captcha) => {
                    console.log(captcha, "Recapcha url")
                    dispatch(
                      authActions.resetPasswordOtp(emailRef.value, captcha)
                    )
                      .catch((err: Error) =>
                        enqueueSnackbar(err.message, { variant: "error" })
                      )
                      .finally(() => setShowCaptcha(false));
                  }}
                />
              ) : (
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="secondary"
                  style={{ margin: "1rem 0  0.5rem" }}
                >
                  {resetPasswordPage.loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Continue"
                  )}
                </Button>
              )}
            </form>
          )}
        </div>
        Have account?{" "}
        <RouteLink
          to="/login"
          onClick={() => dispatch(authActions.resetState())}
        >
          <Button type="button">Login</Button>
        </RouteLink>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
}

export default function Login() {
  return (
    <Routes>
      <Route index element={<LoginPage />} />
      <Route
        path={`/reset-password`}
        element={<ResetPassword />}
      />
    </Routes>
  );
}
