import * as authService from "../services/authentication";

export const authActions: any = {
  initSession: "LOGIN_INIT_SESSION",
  initSessionSucceeded: "LOGIN_INIT_SESSION_SUCCEEDED",
  initSessionFailed: "LOGIN_INIT_SESSION_FAILED",
  loginOtpWillSubmit: "LOGIN_OTP_WILL_SUBMIT",
  loginOtpSucceeded: "LOGIN_OTP_SUCCEEDED",
  loginOtpFailed: "LOGIN_OTP_FAILED",
  loginWillSubmit: "LOGIN_WILL_SUBMIT",
  loginSucceeded: "LOGIN_SUCCEEDED",
  loginFailed: "LOGIN_FAILED",
  resetPasswordOtpWillSubmit: "LOGIN_RESET_PASSWORD_OTP_WILL_SUBMIT",
  resetPasswordOtpSucceeded: "LOGIN_RESET_PASSWORD_OTP_SUCCEEDED",
  resetPasswordOtpFailed: "LOGIN_RESET_PASSWORD_OTP_FAILED",
  resetPasswordWillSubmit: "LOGIN_RESET_PASSWORD_WILL_SUBMIT",
  resetPasswordSucceeded: "LOGIN_RESET_PASSWORD_SUCCEEDED",
  resetPasswordFailed: "LOGIN_RESET_PASSWORD_FAILED",
  logout: "LOGOUT",
  resetState: "LOGIN_RESET_STATE",
};

export const initialize: any = () => async (dispatch: any) => {
  dispatch({ type: authActions.initSession });
  try {
    dispatch({
      type: authActions.initSessionSucceeded,
      payload: await authService.initSession(),
    });
  } catch (err: any) {
    dispatch({
      type: authActions.initSessionFailed,
      payload: new Error(
        "Invalid OTP and/or expired. Please go back and again"
      ),
    });
  }
};

export const loginOtp: any =
  (
    email: string,
    password: string,
    platformUseConsentData: {
      link: string;
      version: string;
    }
  ) =>
  async (dispatch: any) => {
    dispatch({ type: authActions.loginOtpWillSubmit });
    try {
      const {
        request_token,
        salt,
        request_new_password,
        request_to_accept_terms,
        request_to_accept_privacy_policy,
      } = await authService.loginOtp(email, password, platformUseConsentData);
      dispatch({
        type: authActions.loginOtpSucceeded,
        payload: {
          request_token,
          salt,
          request_new_password,
          request_to_accept_terms,
          request_to_accept_privacy_policy,
        },
      });
    } catch (err: any) {
      let errorMessage: string = "Unable to login";
      if (typeof err === "string") {
        errorMessage = err;
      }
      if ("message" in err) {
        errorMessage = err.message;
      }
      dispatch({
        type: authActions.loginOtpFailed,
        payload: errorMessage,
      });
      throw new Error(errorMessage);
    }
  };

export const login: any =
  (values: {
    request_token: string;
    otp: string;
    new_password?: string;
    terms_and_conditions_consent?: {
      link: string;
      version: string;
    };
    privacy_policy_consent?: {
      link: string;
      version: string;
    };
  }) =>
  async (dispatch: any) => {
    dispatch({ type: authActions.loginWillSubmit });
    try {
      dispatch({
        type: authActions.loginSucceeded,
        payload: await authService.login(values),
      });
    } catch (err: any) {
      if (err.response?.data?.request_token) {
        dispatch({
          type: authActions.loginOtpSucceeded,
          payload: {
            request_token: err.response.data.request_token,
          },
        });
      }
      if (err.response?.data?.message) {
        dispatch({
          type: authActions.loginOtpFailed,
          payload: err.response.data,
        });
        throw new Error(err.response.data.message);
      } else {
        dispatch({
          type: authActions.loginFailed,
          payload: new Error(
            "Invalid OTP and/or expired. Please go back and again"
          ),
        });
      }
      throw err;
    }
  };

export const resetPasswordOtp: any =
  (email: string, captcha: string) => async (dispatch: any) => {
    dispatch({ type: authActions.resetPasswordOtpWillSubmit });
    try {
      const { request_token, salt } = await authService.resetPasswordOtp(
        email,
        captcha
      );

      dispatch({
        type: authActions.resetPasswordOtpSucceeded,
        payload: { request_token, salt },
      });
    } catch (err: any) {
      dispatch({
        type: authActions.resetPasswordOtpFailed,
        payload: err,
      });
      let errorMessage: string = "Unable to reset password";
      if (typeof err === "string") {
        errorMessage = err;
      }
      if ("message" in err) {
        errorMessage = err.message;
      }
      dispatch({
        type: authActions.resetPasswordOtpFailed,
        payload: errorMessage,
      });
      throw new Error(errorMessage);
    }
  };

export const resetPassword: any =
  (request_token: string, otp: string, password: string) =>
  async (dispatch: any) => {
    dispatch({ type: authActions.resetPasswordWillSubmit });
    try {
      dispatch({
        type: authActions.resetPasswordSucceeded,
        payload: await authService.resetPassword(request_token, otp, password),
      });
    } catch (err: any) {
      let errorMessage: string = "Unable to reset password";
      if (typeof err === "string") {
        errorMessage = err;
      }
      if ("message" in err) {
        errorMessage = err.message;
      }
      if (err.response?.data?.request_token) {
        dispatch({
          type: authActions.resetPasswordOtpSucceeded,
          payload: {
            request_token: err.response.data.request_token,
          },
        });
      }
      if (err.response?.data?.message) {
        dispatch({
          type: authActions.resetPasswordOtpFailed,
          payload: err.response.data,
        });
        errorMessage = err.response?.data?.message;
      } else {
        dispatch({
          type: authActions.resetPasswordFailed,
          payload: new Error(
            "Invalid OTP and/or expired. Please go back and again"
          ),
        });
      }
      throw new Error(errorMessage);
    }
  };

export const logout: any = (message?: string) => async (dispatch: any) => {
  await authService.logout();
  dispatch({
    type: authActions.logout,
    payload: message,
  });
};

export const resetState = () => ({
  type: authActions.resetState,
});
