import { OrganizationOnboardingInput, SubscriptionDetailsType } from "../../services/interfaces";
import * as onboardingsService from "../../services/autnhive/organizations-onboardings";
import * as subscriptionsService from "../../services/orgLevel/productSubscriptions";

export const onboardingsActions: any = {
  onboardingsWillLoad: "AUTNHIVE_ORGANIZATION_ONBOARDINGS_WILL_SUBMIT",
  onboardingsLoadSucceeded: "AUTNHIVE_ORGANIZATION_ONBOARDINGS_LOAD_SUCCEEDED",
  onboardingsLoadFailed: "AUTNHIVE_ORGANIZATION_ONBOARDINGS_LOAD_FAILED",
  updateOnboarding: "AUTNHIVE_UPDATE_ORGANIZATION_ONBOARDINGS",
  setPage: "AUTNHIVE_ORGANIZATION_ONBORADING_PAGE_SET",
  updateSearchKeyword: "AUTNHIVE_ORGANIZATION_ONBOARDING_KEYWORD_UPDATED",
  updateStatusFilter: "AUTNHIVE_ORGANIZATION_ONBOARDING_STATUS_FILTER",
  updateOnboardingSucceeded:
    "AUTNHIVE_UPDATE_ORGANIZATION_ONBOARDINGS_SUCCEEDED",
  updateOnboardingFailed: "AUTNHIVE_UPDATE_ORGANIZATION_ONBOARDINGS_FAILED",
  approveRejectOnboarding: "AUTNHIVE_ORGANIZATION_ONBOARDING_APPROVE_REJECT",
  approveRejectOnboardingFailed:
    "AUTNHIVE_ORGANIZATION_ONBOARDING_APPROVE_REJECT_FAILED",
  approveRejectOnboardingSucceeded:
    "AUTNHIVE_ORGANIZATION_ONBOARDING_APPROVE_REJECT_SUCCEEDED",
  contractsWillLoad:
    "AUTNHIVE_EDIT_ORGANIZATION_ONBOARDING_CONTRACTS_WILL_LOAD",
  contractsLoadSucceeded:
    "AUTNHIVE_EDIT_ORGANIZATION_ONBOARDING_CONTRACTS_LOAD_SUCCEEDED",
  contractsLoadFailed:
    "AUTNHIVE_EDIT_ORGANIZATION_ONBOARDING_CONTRACTS_LOAD_FAILED",
};

export const fetchOrganizationOnboardings =
  (
    {
    customer_name,
    status,
    }: {
      customer_name: string;
      status: "draft" | "approved" | "rejected" | undefined;
    },
    page: number,
    limit: number
  ) =>
  async (dispatch: any) => {
    dispatch({
      type: onboardingsActions.onboardingsWillLoad,
      payload: page,
    });
    try {
      dispatch({
        type: onboardingsActions.onboardingsLoadSucceeded,
        payload: await onboardingsService.fetchOnboardings({ customer_name, status }, page, limit),
      });
    } catch (err) {
      console.log("err", err);
      dispatch({
        type: onboardingsActions.onboardingsLoadFailed,
        payload: err,
      });
    }
  };

export const updateOnboarding =
  (id: string, values: OrganizationOnboardingInput) =>
  async (dispatch: any) => {
    dispatch({ type: onboardingsActions.updateOnboarding });
    try {
      dispatch({
        type: onboardingsActions.updateOnboardingSucceeded,
        payload: await onboardingsService.updateOnboarding(id, values),
      });
    } catch (e) {
      dispatch({
        type: onboardingsActions.updateOnboardingFailed,
        payload: e,
      });
      throw e;
    }
  };

export const addSubscriptionExistingCustomer = 
  (organization_id: string, input: SubscriptionDetailsType, reseller_id?: string) => 
  async (dispatch: any) =>{
    dispatch({ type: onboardingsActions.updateOnboarding });;
    try {
      dispatch({
        type:onboardingsActions.updateOnboardingSucceeded,
        payload: await subscriptionsService.addSubscriptionExistingCustomer(organization_id, input, reseller_id)
      })
    }catch(e) {
      dispatch({
        type: onboardingsActions.updateOnboardingFailed,
        payload:e,
      })
      throw e;
    }
  }

export const approveRejectOnboarding =
  (id: string, action: "approve" | "reject", rejection_reason?: string) =>
  async (dispatch: any) => {
    dispatch({ type: onboardingsActions.approveRejectOnboarding });
    try {
      dispatch({
        type: onboardingsActions.approveRejectOnboardingSucceeded,
        payload: await onboardingsService.approveRejectOnboarding(
          id,
          action,
          rejection_reason
        ),
      });
    } catch (e) {
      dispatch({
        type: onboardingsActions.approveRejectOnboardingFailed,
        payload: e,
      });
      throw e;
    }
  };

export const fetchContracts =
  (reseller_id: string, check_is_approved: boolean) => async (dispatch: any) => {
    dispatch({ type: onboardingsActions.contractsWillLoad });
    try {
      dispatch({
        type: onboardingsActions.contractsLoadSucceeded,
        payload: await subscriptionsService.fetchContracts({ reseller_id, check_is_approved }),
      });
    } catch (e) {
      dispatch({
        type: onboardingsActions.contractsLoadFailed,
        payload: e,
      });
    }
  };

  export const updateSearchKeyword = (keyword: string) => ({
    type: onboardingsActions.updateSearchKeyword,
    payload: keyword,
  });

  export const setPage = (page: number) => ({
    type: onboardingsActions.setPage,
    payload: page,
  })
  
  export const updateStatusFilter = (
    status: "approved" | "rejected" | "draft" | undefined
  ) => ({
    type: onboardingsActions.updateStatusFilter,
    payload: status,
  });