import * as React from "react";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Tooltip,
  FormControl,
  InputLabel,
  Input,
  Select,
  MenuItem,
  Switch,
  Chip,
  Container,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { Pagination } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SuccessIcon from "@mui/icons-material/CheckCircle";
import FailureIcon from "@mui/icons-material/CancelRounded";
import UserIcon from "@mui/icons-material/Person";
import BlockIcon from "@mui/icons-material/Block";
import EditIcon from "@mui/icons-material/Edit";
import ProductSubscriptionIcon from "@mui/icons-material/Security";
import { Routes, Route, Link as RouteLink, useParams, useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import * as userActions from "../actions/users";
import {
  CreateUserReducer,
  GlobalReducer,
  OrganizationsPageReducer,
  Reducers,
  UserBulkUploadReducer,
  UserProductSubscriptionsReducer,
  UsersPageReducer,
} from "../reducers";
import {
  UserType,
  CustomError,
  UserBulkUploadType,
  RoleType,
  UserFormInput,
  UserSubscriptionType,
  OrganizationType,
  CreateUserFormInput,
  LicenseType,
  SubscriptionType,
  UserOrgRoleType,
  CountryType,
} from "../services/interfaces";
import * as subscriptionsService from "../services/orgLevel/productSubscriptions";
import { downloadSampleUploadFile } from "../services/users";
import Permissioned, { actions, resources } from "../permissioned";
import SubmitButton from "../components/SubmitButton";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useRef } from "react";
import MandatoryFieldText from "../components/mandatoryFieldsText";
import Multiselect from "multiselect-react-dropdown";
import * as userProfileActions from "../actions/userProfile";

export interface UserFormProps {
  disableFields?: Array<string>;
  loading: boolean;
  error?: CustomError;
  onSubmit: Function;
  roles: Array<RoleType>;
  subscriptions?: Array<SubscriptionType>;
  values?: UserFormInput;
  variant: "create" | "edit";
}

interface EditUserProps extends UserFormProps {
  error?: CustomError;
  user: UserType;
  fetchRoles: Function;
}

interface UserProductSubscriptionsProps {
  user: UserType;
  productSubscriptions: Array<UserSubscriptionType>;
  loading: boolean;
  error?: CustomError;
}

interface UserBulkUploadProps {
  organization: OrganizationType;
  loading: boolean;
  onSubmit: Function;
  result?: [UserBulkUploadType];
  resetState: Function;
}

const LIMIT = 20;
const notAllowedCharacter = /[`!#@$%^&*~()+=\[\]{};:"\\|',.<>\/?]+/;
const notAllowedCharacterForEmail = /[`!#$%^&*~()+=\[\]{};:"\\|',<>\/?]+/;

function UsersList() {
  const [value, setValue] = React.useState<OrganizationType | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();
  const usersPage: UsersPageReducer = useSelector<Reducers, UsersPageReducer>(
    (state) => state.usersPage
  );
  const globalState: GlobalReducer = useSelector<Reducers, GlobalReducer>(
    (state) => state.globe
  );
  const dispatch = useDispatch<any>();
  useEffect(() => {
    return () => {
      dispatch(userActions.resetStates());
    };
  }, [dispatch]);
  const user = globalState.userProfile?.user;
  let organization = value;
  if (
    user &&
    user.role &&
    user?.role.role_for !== "autnhive" &&
    user?.organization
  ) {
    organization = user.organization;
  }

  useEffect(() => {
    dispatch(userActions.searchOrganizations(inputValue));
  }, [dispatch, value, inputValue]);

  useEffect(() => {
    dispatch(
      userActions.fetchUsers({
        limit: LIMIT,
        page: 1,
        keyword: usersPage.searchKeyword,
        organization_id: value?.id,
      })
    );
  }, [dispatch, value?.id, usersPage.searchKeyword]);

  return <>
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <Permissioned
          resource={resources.Organizations}
          action={actions.Read}
        >
          <Autocomplete
            clearOnEscape
            value={value}
            size="small"
            onChange={(event: any, newValue: OrganizationType | null) => {
              if (newValue?.id) {
                dispatch(userActions.selectOrganization(newValue.id));
                organization = newValue;
              }
              setValue(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id="organizations"
            noOptionsText="No suggestions"
            options={usersPage.organizations}
            style={{ width: 300 }}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search"
                label="Organization"
                variant="outlined"
              />
            )}
            renderOption={(options: any)=> (
              <TextField
                variant="standard"
                margin="none"
                {...options}
                InputProps={{ disableUnderline: true }}
                value={options.key}
              />
            )}
          />
        </Permissioned>
      </Grid>
      <Grid item>
        <TextField
          size="small"
          label="Search Users"
          variant="outlined"
          onChange={(e) => {
            dispatch(userActions.updateSearchKeyword(e.target.value));
          }}
          onKeyPress={(e) => {
            if (e.key !== "Enter") {
              return;
            }
            dispatch(
              userActions.fetchUsers({
                organization_id: usersPage.organization_id,
                keyword: usersPage.searchKeyword,
                limit: LIMIT,
                page: usersPage.page,
              })
            );
          }}
        />
      </Grid>
      <Grid item>
        <Permissioned resource={resources.QboardUsers} action={actions.BulkCreate}>
          <BulkUpload organization={organization} />
        </Permissioned>
      </Grid>
      <Grid item>
        <Permissioned resource={resources.QboardUsers} action={actions.Create}>
          <CreateUser organization={value} />
        </Permissioned>
      </Grid>
    </Grid>
    <List>
      {usersPage.users.map((user: UserType, index) => {
        return (
          <ListItem
            key={user.id}
            sx={{
              backgroundColor: index % 2 === 0 ? "#f1f1f1" : "inherit"
            }}
          >
            <ListItemIcon>
              <UserIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  {user.first_name} {user.last_name} |{" "}
                  {user.email.toLowerCase()}
                  {!user.is_active ? (
                    <BlockIcon style={{ marginLeft: 5, fontSize: 15 }} />
                  ) : null}
                </>
              }
              secondary={
                <>
                    <div>{user.organization?.name + " | " + user.user_org_roles.map((ur) => ur.role.name).join(" , ")}</div>
                    <div>{user.id}
                    <IconButton name="userId" onClick={()=>{
                        navigator.clipboard.writeText(user.id)
                        enqueueSnackbar("User Id copied", {variant:'info'})
                    }}>
                        <ContentCopyIcon />
                    </IconButton></div>
                </>
              }
              
            />
            <ListItemSecondaryAction>
              <Permissioned
                resource={resources.QboardUsersSubscriptions}
                action={actions.Create}
              >
                <RouteLink
                  to={`/admin/users/${user.id}/product-subscriptions`}
                >
                  <Tooltip title="Subscriptions">
                    <IconButton edge="end" aria-label="subscriptions" size="large">
                      <ProductSubscriptionIcon />
                    </IconButton>
                  </Tooltip>
                </RouteLink>
              </Permissioned>
              <Permissioned
                resource={resources.QboardUsers}
                action={actions.Update}
              >
                <RouteLink to={`/admin/users/${user.id}/edit`}>
                  <Tooltip title="Edit User">
                    <IconButton size="large">
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </RouteLink>
              </Permissioned>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>

    <Pagination
      count={Math.ceil(usersPage.count / LIMIT)}
      page={usersPage.page}
      variant="outlined"
      shape="rounded"
      onChange={(e: React.ChangeEvent<unknown>, page: number) => {
        dispatch(
          userActions.fetchUsers({
            limit: LIMIT,
            page,
            organization_id: usersPage.organization_id,
            keyword: usersPage.searchKeyword,
          })
        );
      }}
    />
  </>;
}

export function UserForm(props: UserFormProps) {
  let firstNameRef: HTMLInputElement;
  let lastNameRef: HTMLInputElement;
  let emailRef: HTMLInputElement;
  let isActiveRef: HTMLInputElement;
  let roleIdRef: HTMLInputElement;
  let countryRef: HTMLInputElement;
  let subscriptionIdRef: HTMLInputElement;
  const usersPage: UsersPageReducer = useSelector<Reducers, UsersPageReducer>(
    (state) => state.usersPage
  );
  const dispatch = useDispatch<any>();
  const onSubmit: Function = () => {
    props.onSubmit({
      first_name: firstNameRef?.value.trim(),
      last_name: lastNameRef?.value.trim(),
      email: emailRef?.value.toLowerCase().trim(),
      is_active: isActiveRef?.checked,
      role_id: roleIdRef?.value,
      user_org_roles: selectedRoles,
      user_roles: selectedRoles,
      address: {
        country: countryRef.value.trim(),
      },
      subscription_id: subscriptionIdRef?.value?.trim() || null,
    });
  };
  const {countries} = useSelector<Reducers, GlobalReducer>(
    (state) => state.globe
  );
  useEffect(() => {
    if (!countries || countries.length <= 0) {
      dispatch(
        userProfileActions.fetchCountries()
      );
    }
  }, []);

  const { enqueueSnackbar } = useSnackbar();
  const { values } = props;
  const [selectedRoles, setSelectedRoles] = React.useState<any>(values?.roles?.map((ur) => ({role_id:ur.role_id})))  
  return (
    <div>
      <form>
        <Grid container spacing={3}>
          {!values?.isAd ? null : 
          <div style={{marginTop:'10px', marginLeft: '20px', marginBottom: '-20px'}}>
            <p>First name, last name, country must be updated through AD-sync</p>
          </div>} 
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              disabled={values?.isAd}
              error={!!props.error?.validationErrors?.first_name}
              required
              label="First name"
              defaultValue={values?.first_name}
              inputRef={(ref) => (firstNameRef = ref)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              error={!!props.error?.validationErrors?.last_name}
              required
              disabled={values?.isAd}
              label="Last name"
              defaultValue={values?.last_name}
              inputRef={(ref) => (lastNameRef = ref)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              error={!!props.error?.validationErrors?.email}
              required
              label="Email"
              disabled={props.disableFields?.includes("email")}
              defaultValue={values?.email}
              inputRef={(ref) => (emailRef = ref)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl
              margin="normal"
              fullWidth
              error={!!props.error?.validationErrors?.role_id}
            >
              <Multiselect
                id="select-role"
                options={props.roles}
                placeholder="Role"
                displayValue="name" 
                selectedValues={props.roles.filter((role) => values?.roles.map((ur) => ur.role_id).includes(role.id))}
                hidePlaceholder={true}
                onSelect={(event )=>{
                    let currentRole : any = []
                    event.map((ev:any) => {
                      currentRole.push({role_id: ev.id})
                    })
                    setSelectedRoles(currentRole)
                  }
                }
                onRemove={(event)=>{
                  let currentRole : any = []
                    event.map((ev:any) => {
                      currentRole.push({role_id: ev.id})
                    })
                    setSelectedRoles(currentRole)
                }}
                showCheckbox
              ></Multiselect>

              {/* <InputLabel variant="standard" id="select-role">Role</InputLabel>
              <Select
                variant="standard"
                error={!!props.error?.validationErrors?.role_id}
                labelId="select-role"
                id="select-role"
                defaultValue={values?.role_id || ""}
                inputRef={(ref) => (roleIdRef = ref)}
              >
                {props.roles.map((role: RoleType) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select> */}
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl margin="normal" >
              <InputLabel variant="standard">Country</InputLabel>
              <Select
                variant="standard"
                style={{ width: 195 }}
                inputRef={(ref) => (countryRef = ref)}
                disabled={values?.isAd}
                defaultValue={values?.address.country || "Canada"}
              >
                {countries?.map(
                  (country: CountryType) => (
                    <MenuItem key={country.id} value={country.name}>
                      {country.name}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
          {props.subscriptions ? (
            <Grid item xs={6}>
              <FormControl margin="normal" fullWidth>
                <InputLabel  variant="standard" id="subscription-id">Product</InputLabel>
                <Select
                  variant="standard"
                  labelId="subscription-id"
                  id="subscription-id"
                  inputRef={(ref) => (subscriptionIdRef = ref)}
                  defaultValue=""
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {props.subscriptions?.map((sub) => (
                    <MenuItem key={sub.id} value={sub.id}>
                      {sub.product.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ) : null}
        </Grid>
        {props.variant === "edit" ? (
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  inputRef={(ref) => {
                    if (ref) {
                      isActiveRef = ref;
                    }
                  }}
                  defaultChecked={
                    values?.is_active !== undefined ? values.is_active : true
                  }
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label="Active"
            />
          </div>
        ) : null}
        <MandatoryFieldText
          hasErrors={Boolean(props.error?.validationErrors)}
        />
        <br />
        <div>
          <SubmitButton
            loading={props.loading}
            label="Submit"
            onClick={() => {
              if(notAllowedCharacter.test(firstNameRef?.value.trim()) || notAllowedCharacter.test(lastNameRef?.value.trim())) {
                enqueueSnackbar('Name should not contain special characters', {
                  variant: "error",
                })
                return;
              }
              if (notAllowedCharacterForEmail.test(emailRef?.value.trim())) {
                enqueueSnackbar('Email should not contain special characters', { variant: "error" })
                return;
              }
              onSubmit();
            }}
          />
        </div>
      </form>
    </div>
  );
}

function EditUser(props: EditUserProps) {
  const organization_id = props.user?.organization?.id;
  const { fetchRoles } = props;
  useEffect(() => {
    fetchRoles(100, 1, { organization_id });
  }, [fetchRoles, organization_id]);

  const { user } = props;
  let roles:{ role_id: string}[] = [];
  user.user_org_roles.forEach(role => {
    if (role?.role.id && role.role.id.length) {
      roles.push({role_id: role?.role.id})
    }
  })
  return (
    <UserForm
      variant={props.variant}
      error={props.error}
      disableFields={["email"]}
      loading={props.loading}
      values={{
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        is_active: user.is_active,
        role_id: user.user_org_roles[0]?.role.id,
        roles: roles,
        address: user.address,
        isAd: user.active_directory_id ? true : false
      }}
      roles={props.roles}
      onSubmit={(values: UserFormInput) => {
        props.onSubmit(props.user.id, values);
      }}
    />
  );
}

export function UserProductSubscriptions(props: UserProductSubscriptionsProps) {
  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();
  const productSubscriptions = useSelector<
    Reducers,
    UserProductSubscriptionsReducer
  >((state) => state.userProductSubscriptions);
  useEffect(() => {
    const { user } = props;
    if (user.organization) {
      dispatch(
        userActions.fetchUserProductSubscriptions(
          user.organization.id,
          user.id,
          user.email
        )
      );
    }
  }, []);

  const { user } = props;
  if (!productSubscriptions.subscriptions.length) {
    return <div>This organization does not have any product subscription</div>;
  }
  return (
    <div>
      <List>
        {productSubscriptions.subscriptions.map((sub: UserSubscriptionType) => {
          return (
            <ListItem key={sub.id}>
              <div>
                <Typography variant="subtitle1" gutterBottom>
                  {sub.subscription.product.name}{" "}
                  <Chip label={sub.subscription_type} size="small" />
                </Typography>
                {sub.licenses?.length ? (
                  <div>
                    {" "}
                    License status:
                    <br />
                    {sub.licenses.map((license: LicenseType) => (
                      <React.Fragment key={license.device_name}>
                        {license.device_name} -{" "}
                        {license.is_active ? "Active" : "In-active"}
                        <br />
                      </React.Fragment>
                    ))}
                  </div>
                ) : null}
              </div>
              <ListItemSecondaryAction>
                {(() => {
                  if (sub.user_subscription) {
                    return (
                      <Permissioned
                        resource={resources.QboardUsersSubscriptions}
                        action={actions.UpdateStatus}
                      >
                        <Tooltip
                          title={
                            sub.user_subscription.is_active
                              ? "Disable"
                              : "Enable"
                          }
                        >
                          <Switch
                            key={sub.user_subscription.id}
                            checked={sub.user_subscription.is_active}
                            onChange={() => {
                              Promise.resolve()
                                .then(() => {
                                  if (sub.user_subscription) {
                                    return dispatch(
                                      userActions.updateUserProductSubscriptionStatus(
                                        sub.user_subscription?.id,
                                        !sub.user_subscription?.is_active
                                      )
                                    );
                                  }
                                })
                                .then(() => {
                                  if (user.organization?.id) {
                                    return dispatch(
                                      userActions.fetchUserProductSubscriptions(
                                        user.organization.id,
                                        user.id,
                                        user.email
                                      )
                                    );
                                  }
                                })
                                .then(() =>
                                  enqueueSnackbar(
                                    `Subscription ${sub.user_subscription?.is_active
                                      ? "disabled"
                                      : "enabled"
                                    }`,
                                    { variant: "success" }
                                  )
                                )
                                .catch((err) =>
                                  enqueueSnackbar(err.message, {
                                    variant: "error",
                                  })
                                );
                            }}
                            color="primary"
                          />
                        </Tooltip>
                      </Permissioned>
                    );
                  }
                  return (
                    <Permissioned
                      resource={resources.QboardUsersSubscriptions}
                      action={actions.Create}
                    >
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          Promise.resolve()
                            .then(() =>
                              dispatch(
                                userActions.createUserProductSubscription(
                                  user.id,
                                  sub.id
                                )
                              )
                            )
                            .then(() => {
                              if (user.organization?.id) {
                                dispatch(
                                  userActions.fetchUserProductSubscriptions(
                                    user.organization.id,
                                    user.id,
                                    user.email
                                  )
                                );
                              }
                            })
                            .then(() =>
                              enqueueSnackbar(`Subscription created`, {
                                variant: "success",
                              })
                            )
                            .catch((err) =>
                              enqueueSnackbar(err.message, { variant: "error" })
                            );
                        }}
                      >
                        Subscribe
                      </Button>
                    </Permissioned>
                  );
                })()}
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}

export function UserBulkUpload(props: UserBulkUploadProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const fileUploadRef = useRef<HTMLInputElement>(null);
  let subscriptionIdRef: HTMLInputElement;
  const [subscriptions, setSubscriptions] = React.useState<
    Array<SubscriptionType>
  >([]);
  const onSubmit = (subscriptionId?: string) => {
    props.onSubmit(fileUploadRef?.current?.files?.[0], subscriptionId);
    formRef?.current?.reset();
  };

  const { resetState } = props;

  useEffect(() => {
    return () => resetState();
  }, []);

  useEffect(() => {
    subscriptionsService
      .fetchProductSubscriptions(props.organization.id, 1, 100)
      .then((subs) => setSubscriptions(subs.list))
      .catch(() => setSubscriptions([]));
  }, [props.organization.id]);

  return (
    <>
      <Button
        onClick={() => {
          downloadSampleUploadFile(props.organization.organization_type);
        }}
      >
        Download sample file
      </Button>
      <form encType="multipart/form-data" ref={formRef}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl margin="normal" fullWidth>
              <Input type="file" inputRef={fileUploadRef} />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            {subscriptions ? (
              <>
                <FormControl fullWidth>
                  <InputLabel variant="standard" id="subscription-id">Product</InputLabel>
                  <Select
                    variant="standard"
                    labelId="subscription-id"
                    id="subscription-id"
                    inputRef={(ref) => (subscriptionIdRef = ref)}
                    defaultValue=""
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {subscriptions?.map((sub) => (
                      <MenuItem key={sub.id} value={sub.id}>
                        {sub.product.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            ) : null}
          </Grid>
        </Grid>
        <br />

        <div>
          <SubmitButton
            loading={props.loading}
            label="Submit"
            onClick={() => onSubmit(subscriptionIdRef.value.trim())}
          />
        </div>
        {props.result?.length && (
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>First name</TableCell>
                  <TableCell>Last name</TableCell>
                  <TableCell>Role name</TableCell>
                  <TableCell>Error</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.result?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {row.success ? <SuccessIcon /> : <FailureIcon />}
                    </TableCell>
                    <TableCell>{row.data_input.email.toLowerCase()}</TableCell>
                    <TableCell>{row.data_input.first_name}</TableCell>
                    <TableCell>{row.data_input.last_name}</TableCell>
                    <TableCell>{row.data_input.role_name}</TableCell>
                    <TableCell>{row.error}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </form>
    </>
  );
}

function BulkUpload({
  organization,
}: {
  organization?: OrganizationType | null;
}) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();
  const usersPage: UsersPageReducer = useSelector<Reducers, UsersPageReducer>(
    (state) => state.usersPage
  );
  const userBulkUpload: UserBulkUploadReducer = useSelector<
    Reducers,
    UserBulkUploadReducer
  >((state) => state.userBulkUpload);
  return (
    <>
      <Button
        disabled={!organization?.id}
        onClick={() => setOpen(true)}
        color="secondary"
        variant="outlined"
      >
        Bulk upload
      </Button>
      {organization?.id ? (
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl">
          <DialogTitle>Bulk User Upload</DialogTitle>
          <DialogContent>
            <p>Organization Name: {organization.name}</p>
            <UserBulkUpload
              organization={organization}
              loading={userBulkUpload.loading}
              onSubmit={(file: File) => {
                dispatch(userActions.userBulkUpload(organization?.id, file))
                  .then(() => dispatch(userActions.updateSearchKeyword("")))
                  .then(() =>
                    dispatch(
                      userActions.fetchUsers({
                        limit: LIMIT,
                        page: 1,
                        organization_id: usersPage.organization_id,
                        keyword: usersPage.searchKeyword,
                      })
                    )
                  )
                  .then(() => enqueueSnackbar("Upload file processed"))
                  .catch((err: CustomError) =>
                    enqueueSnackbar(err.message, { variant: "error" })
                  );
              }}
              result={userBulkUpload?.uploadResult}
              resetState={() => {
                dispatch(userActions.resetUserBulkUploadStates());
              }}
            />
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  );
}

function CreateUser({
  organization,
}: {
  organization?: OrganizationType | null;
}) {
  const [open, setOpen] = React.useState(false);
  const [subscriptions, setSubscriptions] = React.useState<
    Array<SubscriptionType>
  >([]);
  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();
  const createUser: CreateUserReducer = useSelector<
    Reducers,
    CreateUserReducer
  >((state) => state.createUser);
  useEffect(() => {
    if (!organization?.id) {
      return;
    }
    dispatch(
      userActions.fetchRoles(100, 1, { organization_id: organization?.id })
    );
    subscriptionsService
      .fetchProductSubscriptions(organization?.id, 1, 100)
      .then((subs) => setSubscriptions(subs.list))
      .catch(() => setSubscriptions([]));
  }, [dispatch, organization?.id]);
  return (
    <>
      <Button
        disabled={!organization?.id}
        onClick={() => setOpen(true)}
        color="secondary"
        variant="outlined"
      >
        New User
      </Button>
      {organization?.id ? (
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>Create New User</DialogTitle>
          <DialogContent>
            <p>Organization Name: {organization.name}</p>
            <UserForm
              variant="create"
              error={createUser.error}
              loading={createUser.loading}
              roles={createUser.roles}
              subscriptions={subscriptions}
              onSubmit={(values: CreateUserFormInput) => {
                values.organization_id = organization?.id;
                Promise.resolve()
                  .then(() => dispatch(userActions.createUser(values)))
                  .then(() => setOpen(false))
                  .then(() =>
                    enqueueSnackbar("User is created", { variant: "success" })
                  )
                  .then(() => {
                    dispatch(userActions.updateSearchKeyword(""));
                    dispatch(
                      userActions.fetchUsers({
                        limit: LIMIT,
                        page: 1,
                        organization_id: organization?.id,
                        keyword: "",
                      })
                    );
                  })
                  .catch((err) =>
                    enqueueSnackbar(err.message, { variant: "error" })
                  );
              }}
            />
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  );
}

function EditUserModal(props: Reducers & DispatchActions){
  const { updateUser, usersPage } = props;
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  interface Params {
    userId: string
  }
  const { userId } = useParams<keyof Params>() as Params;
  const user = usersPage.users.find(
    (u) => u.id === userId
  );
  if (!user) return null;
  return (
    <Dialog open={true} onClose={() => navigate(-1)}>
      <DialogTitle>Update User</DialogTitle>
      <DialogContent>
        <EditUser
          variant="edit"
          error={updateUser.error}
          user={user}
          fetchRoles={props.fetchRoles}
          roles={updateUser.roles}
          loading={updateUser.loading}
          onSubmit={(userId: string, values: any) => {
            Promise.resolve()
              .then(() =>{
                dispatch(userActions.updateUser(user.id, values))
              })
              .then(() => navigate(-1))
              .then(() =>
                enqueueSnackbar("User updated", { variant: "success" })
              )
              .then(() =>
                dispatch(
                  userActions.fetchUsers({
                    limit: LIMIT,
                    page: 1,
                    organization_id: usersPage.organization_id,
                    keyword: usersPage.searchKeyword,
                  })
                )
              )
              .catch((err) =>
                enqueueSnackbar(err.message, { variant: "error" })
              );
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

function UserProductSubscriptionsModal(props: Reducers & DispatchActions) {
  const { userProductSubscriptions, usersPage } = props;
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  interface Params {
    userId: string
  }
  const { userId } = useParams<keyof Params>() as Params;
  const user = usersPage.users.find(
    (u) => u.id === userId
  );
  if (!user) return null;
  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={true}
      onClose={() => {
        navigate(-1);
        dispatch(userActions.dismissUserMessages());
      }}
    >
      <DialogTitle>User Product Subscriptions</DialogTitle>
      <DialogContent>
        <UserProductSubscriptions
          user={user}
          productSubscriptions={userProductSubscriptions.subscriptions}
          loading={userProductSubscriptions.loading}
          error={userProductSubscriptions.error}
        />
      </DialogContent>
    </Dialog>
  );
}

export function Users(
  props: Reducers & DispatchActions
) {
  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <div>
      <Typography component="h1" variant="h5">
        Users
      </Typography>
      <UsersList />

      <Routes>
        <Route path=":userId/edit" element={<EditUserModal {...props} />} />
        <Route path=":userId/product-subscriptions" element={<UserProductSubscriptionsModal {...props} />} />
      </Routes>
    </div>
  );
}

interface DispatchActions {
  fetchRoles: Function;
  dismissSubmitUserMessages: Function;
  dismissSubmitRoleMessages: Function;
}

const mapStateToProps = (reducers: Reducers) => reducers;
const mapDispatchToProps = (dispatch: any): DispatchActions => {
  return {
    fetchRoles: (
      limit: number,
      page: number,
      filters: { organization_id: string }
    ) => dispatch(userActions.fetchRoles(limit, page, filters)),
    dismissSubmitRoleMessages: () =>
      dispatch(userActions.dismissCreateRoleMessages()),
    dismissSubmitUserMessages: () =>
      dispatch(userActions.dismissUserMessages()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
