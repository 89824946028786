import {
  NewCustomerContractInput,
  RenewCustomerContractInput,
} from "../../services/interfaces";
import * as contractsService from "../../services/autnhive/contracts";

export const contractsActions: any = {
  contractsWillLoad: "AUTNHIVE_CONTRACTS_WILL_LOAD",
  contractsLoadSucceeded: "AUTNHIVE_CONTRACTS_LOAD_SUCCEEDED",
  contractsLoadFailed: "AUTNHIVE_CONTRACTS_LOAD_FAILED",
  inputOptionsWillLoad: "AUTNHIVE_CONTRACTS_INPUT_OPTIONS_WILL_LOAD",
  inputOptionsLoadSucceeded: "AUTNHIVE_CONTRACTS_INPUT_OPTIONS_LOAD_SUCCEEDED",
  inputOptionsLoadFailed: "AUTNHIVE_CONTRACTS_INPUT_OPTIONS_LOAD_FAILED",
  updateSearchKeyword: "AUTNHIVE_CONTRACTS_KEYWORD_UPDATED",
  updateStatusFilter: "AUTNHIVE_CONTRACTS_STATUS_FILTER",
  updateSortByFilter: "AUTNHIVE_CONTRACTS_SORTBY_FILTER",
  createNewCustomerContract: "AUTNHIVE_CREATE_NEW_CUSTOMER_CONTRACT",
  createNewCustomerContractSucceeded:
    "AUTNHIVE_CREATE_NEW_CUSTOMER_CONTRACT_SUCCEEDED",
  createNewCustomerContractFailed:
    "AUTNHIVE_CREATE_NEW_CUSTOMER_CONTRACT_FAILED",
  updateNewCustomerContract: "AUTNHIVE_UPDATE_NEW_CUSTOMER_CONTRACT",
  updateNewCustomerContractSucceeded:
    "AUTNHIVE_UPDATE_NEW_CUSTOMER_CONTRACT_SUCCEEDED",
  updateNewCustomerContractFailed:
    "AUTNHIVE_UPDATE_NEW_CUSTOMER_CONTRACT_FAILED",
  approveRejectNewCustomerContract:
    "AUTNHIVE_APPROVE_REJECT_NEW_CUSTOMER_CONTRACT",
  approveRejectNewCustomerContractSucceeded:
    "AUTNHIVE_APPROVE_REJECT_NEW_CUSTOMER_CONTRACT_SUCCEEDED",
  approveRejectNewCustomerContractFailed:
    "AUTNHIVE_APPROVE_REJECT_NEW_CUSTOMER_CONTRACT_FAILED",
  renewContract: "AUTNHIVE_RENEW_CUSTOMER_CONTRACT",
  renewContractSucceeded: "AUTNHIVE_CREATE_RENEW_CONTRACT_SUCCEEDED",
  renewContractFailed: "AUTNHIVE_CREATE_RENEW_CONTRACT_FAILED",
  resetState: "AUTNHIVE_CONTRACT_RESET_STATE",
};

export const fetchContracts =
  (
    {
      customer_name,
      status,
      sort
    }: {
      customer_name: string;
      status: "draft" | "approved" | "rejected" | undefined;
      sort: "create-date" | "start-date" | "name"
    },
    page: number,
    limit: number
  ) =>
  async (dispatch: any) => {
    dispatch({
      type: contractsActions.contractsWillLoad,
      payload: page,
    });
    try {
      dispatch({
        type: contractsActions.contractsLoadSucceeded,
        payload: await contractsService.fetchContracts(
          { customer_name, status, sort },
          page,
          limit
        ),
      });
    } catch (err) {
      dispatch({
        type: contractsActions.contractsLoadFailed,
        payload: err,
      });
    }
  };

export const fetchInputOptions = () => async (dispatch: any) => {
  dispatch({
    type: contractsActions.inputOptionsWillLoad,
  });
  try {
    dispatch({
      type: contractsActions.inputOptionsLoadSucceeded,
      payload: await contractsService.fetchInputOptions(),
    });
  } catch (err) {
    dispatch({
      type: contractsActions.inputOptionsLoadFailed,
      payload: err,
    });
  }
};

export const createNewCustomerContract =
  (values: NewCustomerContractInput) => async (dispatch: any) => {
    dispatch({ type: contractsActions.createNewCustomerContract });
    try {
      const contract = await contractsService.createContract(values);
      dispatch({
        type: contractsActions.createNewCustomerContractSucceeded,
        payload: contract,
      });
      return contract;
    } catch (e) {
      dispatch({
        type: contractsActions.createNewCustomerContractFailed,
        payload: e,
      });
      throw e;
    }
  };

export const updateNewCustomerContract =
  (id: string, values: NewCustomerContractInput) => async (dispatch: any) => {
    dispatch({ type: contractsActions.updateNewCustomerContract });
    try {
      dispatch({
        type: contractsActions.updateNewCustomerContractSucceeded,
        payload: await contractsService.updateContract(id, values),
      });
    } catch (e) {
      dispatch({
        type: contractsActions.updateNewCustomerContractFailed,
        payload: e,
      });
      throw e;
    }
  };

export const approveRejectNewCustomerContract =
  (id: string, action: "approve" | "reject") => async (dispatch: any) => {
    dispatch({ type: contractsActions.approveRejectNewCustomerContract });
    try {
      dispatch({
        type: contractsActions.approveRejectNewCustomerContractSucceeded,
        payload: await contractsService.approveRejectContract(id, action),
      });
    } catch (e) {
      dispatch({
        type: contractsActions.approveRejectNewCustomerContractFailed,
        payload: e,
      });
      throw e;
    }
  };

export const renewContract =
  (id: string, values: RenewCustomerContractInput) => async (dispatch: any) => {
    dispatch({ type: contractsActions.renewContract });
    try {
      const contract = await contractsService.renewContract(id, values);
      dispatch({
        type: contractsActions.renewContractSucceeded,
        payload: contract,
      });
      return contract;
    } catch (e) {
      dispatch({
        type: contractsActions.renewContractFailed,
        payload: e,
      });
      throw e;
    }
  };

export const updateSearchKeyword = (keyword: string) => ({
  type: contractsActions.updateSearchKeyword,
  payload: keyword,
});

export const updateStatusFilter = (
  status: "approved" | "rejected" | "draft" | undefined
) => ({
  type: contractsActions.updateStatusFilter,
  payload: status,
});

export const updateSortByFilter = (
  sort_by: "create-date" | "start-date" | "name"
) => ({
  type: contractsActions.updateSortByFilter,
  payload: sort_by,
});

export const resetState = () => ({
  type: contractsActions.resetState,
});
