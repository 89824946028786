import React from "react";

type Props = {
  password: string;
};
export default function PasswordRequirementText(props: Props) {
  let isLowerCaseValid = false;
  let isUpperCaseValid = false;
  let isNumberCaseValid = false;
  let isSpecialCharacterCaseValid = false;
  let isPasswordLengthValid = false;

  const lowerCaseLetters = /[a-z]/g;
  if (props.password?.match(lowerCaseLetters)) {
    isLowerCaseValid = true;
  }
  const upperCaseLetters = /[A-Z]/g;
  if (props.password?.match(upperCaseLetters)) {
    isUpperCaseValid = true;
  }

  const numbers = /[\d]/g;
  if (props.password?.match(numbers)) {
    isNumberCaseValid = true;
  }
  const specialCharacter = new RegExp("^(?=.*[!@#$%^&*_\-])");
  const notAllowedCharacter = /[`~()+=\[\]{};':"\\|,.<>\/?]+/;
  if (props.password?.match(specialCharacter) && !notAllowedCharacter.test(props.password)) {
    isSpecialCharacterCaseValid = true;
  }

  if (props.password?.length >= 10) {
    isPasswordLengthValid = true;
  }

  return (
    <>
      <p style={{fontSize:'1rem', padding: 0, margin: 0}}>Password must contain at least:</p>
      <p style={{ color: isLowerCaseValid ? "green" : "red", fontSize:'.9rem', padding: 0, margin: 0}}>
        A <b>lowercase</b> letter
      </p>
      <p id="capital" style={{ color: isUpperCaseValid ? "green" : "red", fontSize:'.9rem', padding: 0, margin: 0 }}>
        A <b>capital (uppercase)</b> letter
      </p>
      <p id="number" style={{ color: isNumberCaseValid ? "green" : "red", fontSize:'.9rem', padding: 0, margin: 0 }}>
        A <b>number</b>
      </p>
      <p
        id="number"
        style={{ color: isSpecialCharacterCaseValid ? "green" : "red", fontSize:'.9rem', padding: 0, margin: 0 }}
      >
        A <b>special character </b>!@#$%^&*_-
      </p>
      <p id="length" style={{ color: isPasswordLengthValid ? "green" : "red", fontSize:'.9rem', padding: 0, margin: 0 }}>
        {" "}
        Minimum <b> 10 characters</b>
      </p>
      {/* Password should contain at least 10 characters, 1 uppercase letters, 1 lowercase letters, 1
      numbers and 1 special character */}
    </>
  );
}
