import axios from "../axios-client";
import { DomainsList, DomainJobsList, DomainTasksList, SubTasksList } from "./interfaces";
import { env } from "../env";
import { parseErrors } from "./helpers";

const easaURL: string = env.REACT_APP_EASA_URL;

export async function fetchDomainsList(
  page: number,
  limit: number,
  keyword?: string
) {
  try {
    const timeStamp = new Date().getTime();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let baseUrl = `${easaURL}/domains-paginated-list?page=${page}&limit=${limit}`;
    if (keyword && keyword !== "") {
      baseUrl += `&search_query=${keyword}`;
    }
    const { data } = await axios.get<DomainsListResponse>(baseUrl, {
      headers: { timestamp: `${timeStamp}` },
    });
    return data.data.data;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function fetchDomainJobsList(
  page: number,
  limit: number,
  url: string
) {
  try {
    const timeStamp = new Date().getTime();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { data } = await axios.get<DomainJobsListResponse>(
      `${easaURL}/domain-runs-paginated-list?page=${page}&limit=${limit}&url=${url}`,
      {
        headers: { timestamp: `${timeStamp}` },
      }
    );
    return data.data.data;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function fetchDomainTasksList(
  page: number,
  limit: number,
  jobId: string
) {
  try {
    const timeStamp = new Date().getTime();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { data } = await axios.get<DomainTasksListResponse>(
      `${easaURL}/domain-jobs-paginated-list?page=${page}&limit=${limit}&parent_job_id=${jobId}`,
      {
        headers: { timestamp: `${timeStamp}` },
      }
    );
    return data.data.data;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function updateDomainLogo(
  domain_id: string,
  logo: string
) {
  try {
    const timeStamp = new Date().getTime();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { data } = await axios.put<any>(`${easaURL}/add-domain-logo`, {domain_id: domain_id, logo: logo});
    return data.data.data;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function fetchSubTasksList(
  page: number,
  limit: number,
  parentJobId: string,
  jobId: string
) {
  try {
    const timeStamp = new Date().getTime();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { data } = await axios.get<SubTasksListResponse>(
      `${easaURL}/domain-job-sub-tasks-paginated-list?page=${page}&limit=${limit}&parent_job_id=${parentJobId}&job_id=${jobId}`,
      {
        headers: { timestamp: `${timeStamp}` },
      }
    );
    return data.data.data;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function retryJob(
  job_id: string,
  is_sub_task: boolean,
  sub_task_id?: string
) {
  try {
    const { data } = await axios.post<any>(`${easaURL}/retry-job`, {job_id, is_sub_task, sub_task_id});
    return data.data.data;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

interface DomainsListResponse {
  data: {
    checksum: string;
    data: {
      list: [DomainsList];
      count: number;
    };
  };
}

interface DomainJobsListResponse {
  data: {
    checksum: string;
    data: {
      list: [DomainJobsList];
      count: number;
    };
  };
}

interface DomainTasksListResponse {
  data: {
    checksum: string;
    data: {
      list: [DomainTasksList];
      count: number;
    };
  };
}
interface SubTasksListResponse {
  data: {
    checksum: string;
    data: {
      list: [SubTasksList];
      count: number;
    };
  };
}
