import * as React from "react";
import { useEffect } from "react";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  FormControl,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import moment from "moment";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Permissioned, { actions, resources } from "../permissioned";
import { Pagination } from '@mui/material';
import { Link as RouteLink,Route,Routes,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ResellerIcon from "@mui/icons-material/Storefront";
import BlockIcon from "@mui/icons-material/Block";
import {
  SamiChatType
} from "../services/interfaces";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';


import {
  SamiChatPageReducer,
} from "../reducers/autnhive";
import { Reducers } from "../reducers";
import * as samiChatActions from "../actions/sami";



const LIMIT = 20;



function PopupModal(name:any ) {
 
  const navigate = useNavigate();
  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={true}
      onClose={() => navigate(-1)}
    >
      <DialogTitle style={{ fontWeight: 700}}>Ask Sami Details</DialogTitle>
      <DialogContent>
      <div><pre style={{ fontSize: '18px'}}>{JSON.stringify(name, null, 2) }</pre></div>
      {/* <div>{JSON.stringify(name)}</div>   */}
      {/* <ul>{name.map}</ul> */}
        
      </DialogContent>
    </Dialog>
  );
}


function SamiChatsTable() {
  const [show, setShow] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState({});
  const hanldeClick = (selectedRec: React.SetStateAction<{}>) => {
    setSelectedData(selectedRec);
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };
  const samiChatPageReducer: SamiChatPageReducer = useSelector<
    Reducers,
    SamiChatPageReducer
  >((state) => state.autnhive.samichats);
  const dispatch = useDispatch<any>();

  const { startDate, endDate, count, page } = samiChatPageReducer;

  useEffect(() => {
    dispatch(samiChatActions.fetchSamiChatSList(  startDate,
      endDate, 1, LIMIT));
    return () => {
      dispatch(samiChatActions.resetStates());
    };
  }, [dispatch]);

  return <>

 
 <div className="app-container" style={{display: 'flex',padding:"1rem",flexDirection:'column',gap:'10px'}} >
 
     
        <table style={{verticalAlign: 'middle',borderCollapse:"separate",borderSpacing:'0 20Px',border:'none',minWidth:'800px'
        ,background:'#fff', padding:'0px 10px',borderRadius:'20px'}}>
          <thead>
            <tr>
              <th style={{ fontWeight: 700,color:'#343434',padding:'0',textAlign:'center',verticalAlign:'middle' }}>User Name</th>
              <th style={{ fontWeight: 700,color:'#343434',padding:'0',textAlign:'center',verticalAlign:'middle' }}>Organization Name</th>
              <th style={{ fontWeight: 700,color:'#343434',padding:'0',textAlign:'center',verticalAlign:'middle' }}>Date</th>
              <th style={{ fontWeight: 700,color:'#343434',padding:'0',textAlign:'center',verticalAlign:'middle' }}>Question</th>
              
              <th style={{ fontWeight: 700,color:'#343434',padding:'0',textAlign:'center',verticalAlign:'middle',paddingInlineEnd:"50px" }}>Show More</th>
             
            
            </tr>
          </thead>
          <tbody>
         {samiChatPageReducer.semiChats.map((samichats: SamiChatType, index) => {
        return (
          
          <tr key={samichats._id}>
            
          <td style={{ fontWeight: 'normal',color:'#343434',padding:'0',textAlign:'center',verticalAlign:'middle' }}  >{samichats.created_by.name}</td>
          <td style={{ fontWeight: 'normal',color:'#343434',padding:'0',textAlign:'center',verticalAlign:'middle' }}>{samichats.organization_name}</td>
          <td style={{ fontWeight: 'normal',color:'#343434',padding:'0',textAlign:'center',verticalAlign:'middle' }}>{samichats.created_at}</td>
          <td style={{ fontWeight: 'normal',color:'#343434',padding:'0',textAlign:'center',verticalAlign:'middle' }}>{samichats.question}</td>
        
          <RouteLink to="/admin/sami/details">
          <td>
                <a href="#" onClick={() => hanldeClick(samichats)}>
                  More details
                </a>
              </td>
          </RouteLink>
          <Permissioned
        resource={resources.AskSami} action={actions.Read}>
          </Permissioned>
          
      </tr>
     
        );
      })}   
       {!samiChatPageReducer.semiChats || samiChatPageReducer.semiChats.length === 0 ? (
              <tr>
                <td colSpan={5} style={{ fontStyle: "italic", fontWeight: 'normal',color:'#343434',padding:'0',textAlign:'center',verticalAlign:'middle' }}>
                  <span>No data available</span>
                </td>
              </tr>
            ) : (
              ""
            )} 
          </tbody>
        </table>
        {/* {show && <Modal details={selectedData} />} */}
        <Routes>
        <Route path="/details" element={<PopupModal name={selectedData}  />} />
      </Routes>
     
    </div>
    <Pagination
      count={Math.ceil(count / LIMIT)}
      page={page}
      variant="outlined"
      shape="rounded"
      onChange={(e: React.ChangeEvent<unknown>, newPage: number) => {
        dispatch(
          samiChatActions.fetchSamiChatSList(
              samiChatPageReducer.startDate,
              samiChatPageReducer.endDate,
            newPage,
            LIMIT
          )
        );
      }}
    />
  </>;
}


export function Sami() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const samiPageReducer = useSelector<Reducers, SamiChatPageReducer>(
    (state) => state.autnhive.samichats
  );

  const [startDate, setStartDate] = React.useState<Date | null>(
    moment().startOf("month").subtract(1, "month").toDate()
  );
  const [endDate, setEndDate] = React.useState<Date | null>();
  
  useEffect(() => {
    setStartDate(samiPageReducer.startDate);
  }, [samiPageReducer.startDate]);

  useEffect(() => {
    setEndDate(samiPageReducer.endDate);
  }, [samiPageReducer.endDate]);
  

  return (
    <div>
      <Typography component="h1" variant="h5">
      Ask SAMI 
      </Typography>
      <div>
        
      <Grid container spacing={2} alignItems="flex-end">
    
        <Grid item xs={8}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={['year', 'month', 'day']}
                  inputFormat="dd-MMM-yyyy"
                  // style={{ marginBottom: 0 }}
                  label="Start date"
                  value={startDate}
                  onChange={(date: Date | null) => setStartDate(date)}
                  renderInput={(props) => <TextField variant="standard" margin="normal" {...props} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
            
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={['year', 'month', 'day']}
                  inputFormat="dd-MMM-yyyy"
                  label="End date"
                  value={endDate}
                  onChange={(date: Date | null) => setEndDate(date)}
                  renderInput={(props) => <TextField variant="standard" margin="normal" {...props} />}
                />
              </LocalizationProvider>
           
            </Grid>
            
            <Grid item xs={1} direction="column" style={{ marginRight: "20px" }}>
              <FormControl margin="normal">
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={() => {
                    dispatch(
                      samiChatActions.fetchSamiChatSList(
                         startDate,
                          endDate,
                        1,
                        LIMIT
                      )
                    );
                  }}
                >
                  Search
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} direction="column" style={{marginLeft:'auto'}}>
              <FormControl margin="normal">
                
              </FormControl>
            </Grid>
           
      </Grid>
      </div>
     
      <SamiChatsTable />
     
      
    </div>
  );
}

export default Sami;
