import { InvoiceType } from "../services/interfaces";
import * as invoicesService from "../services/invoices";

export const invoicesActions: any = {
  invoicesWillLoad: "INVOICES_WILL_SUBMIT",
  invoicesLoadSucceeded: "INVOICES_LOAD_SUCCEEDED",
  invoicesLoadFailed: "INVOICES_LOAD_FAILED",
  invoicesWillDownload: "INVOICES_WILL_DOWNLOAD",
  invoicesDownloadSucceeded: "INVOICES_DOWNLOAD_SUCCEEDED",
  invoicesDownloadFailed: "INVOICES_DOWNLOAD_FAILED",
  invoiceWillDelete: "INVOICES_INVOICE_WILL_DELETE",
  invoiceDeleteSucceeded: "INVOICES_INVOICE_DELETE_SUCCEEDED",
  invoiceDeleteFailed: "INVOICES_INVOICE_DELETE_FAILED",
  invoiceWillUpdate: "INVOICES_INVOICE_WILL_UPDATE",
  invoiceUpdateSucceeded: "INVOICES_INVOICE_UPDATE_SUCCEEDED",
  invoiceUpdateFailed: "INVOICES_INVOICE_UPDATE_FAILED",
};

export const fetchInvoices: any =
  (
    {
      startDate,
      endDate,
      keyword,
      status,
    }: { startDate: Date; endDate: Date; keyword: string; status: string; },
    page: number,
    limit: number
  ) =>
  async (dispatch: any) => {
    dispatch({
      type: invoicesActions.invoicesWillLoad,
      payload: {
        page,
        keyword,
        status,
        startDate,
        endDate,
      },
    });
    try {
      dispatch({
        type: invoicesActions.invoicesLoadSucceeded,
        payload: await invoicesService.fetchInvoices(
          {
            keyword,
            startDate,
            endDate,
            status,
          },
          page,
          limit
        ),
      });
    } catch (err) {
      dispatch({
        type: invoicesActions.invoicesLoadFailed,
        payload: err,
      });
    }
  };

export const deleteInvoice: any = (id: string) => async (dispatch: any) => {
  dispatch({
    type: invoicesActions.invoiceWillDelete,
  });

  try {
    dispatch({
      type: invoicesActions.invoiceDeleteSucceeded,
      payload: await invoicesService.deleteInvoice(id),
    });
  } catch (err) {
    dispatch({
      type: invoicesActions.invoiceDeleteFailed,
      payload: err,
    });
    throw err;
  }
};

export const updateInvoice: any =
  (id: string, values: InvoiceType) => async (dispatch: any) => {
    dispatch({
      type: invoicesActions.invoiceWillUpdate,
    });

    try {
      dispatch({
        type: invoicesActions.invoiceUpdateSucceeded,
        payload: await invoicesService.updateInvoice(id, values),
      });
    } catch (err) {
      dispatch({
        type: invoicesActions.invoiceUpdateFailed,
        payload: err,
      });
      throw err;
    }
  };
