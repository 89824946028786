import { print } from "graphql";
import axios from "../axios-client";
import gql from "graphql-tag";
import { parseErrors } from "./helpers";
import { PartnerType, GraphqlError, PartnerFormInput } from "./interfaces";
import { env } from '../env';

const apiUrl: string = env.REACT_APP_COMMON_API_URL;

export async function fetchPartners(
  limit: number,
  page: number
): Promise<{ list: Array<PartnerType>; count: number }> {
  const query = gql`
    query autnhivePartners($limit: Int!, $page: Int!) {
      autnhivePartners(limit: $limit, page: $page) {
        list {
          id
          name
          email
          phone
          is_active
          address {
            line_1
            line_2
            city
            state
            postal_code
            country
          }
        }
        count
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<PartnersResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: { limit, page },
    },
    {
      headers: {
        "timestamp": `${timeStamp}`,
      }
    });
    return data.data.autnhivePartners;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    } else {
      throw err;
    }
  }
}

export async function createPartner(
  values: PartnerFormInput
): Promise<PartnerType> {
  const query = gql`
    mutation createAutnhivePartner(
      $name: String!
      $email: String!
      $phone: String!
      $address: AddressInput!
    ) {
      createAutnhivePartner(
        input: { name: $name, email: $email, phone: $phone, address: $address }
      ) {
        id
        name
        email
        phone
        is_active
        address {
          line_1
          line_2
          city
          state
          postal_code
          country
        }
      }
    }
  `;

  console.log("-> values", values);

  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<CreatePartnerResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: { ...values },
      },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.createAutnhivePartner;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response.data.errors);
    }
    throw err;
  }
}

export async function updatePartner(
  id: string,
  values: PartnerFormInput
): Promise<PartnerType> {
  const query = gql`
    mutation updateAutnhivePartner(
      $id: String!
      $name: String!
      $email: String!
      $phone: String!
      $address: AddressInput!
    ) {
      updateAutnhivePartner(
        id: $id
        input: { name: $name, email: $email, phone: $phone, address: $address }
      ) {
        id
        name
        email
        phone
        is_active
        address {
          line_1
          line_2
          city
          state
          postal_code
          country
        }
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<UpdatePartnerResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: {
          id,
          ...values,
        },
      },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.updateAutnhivePartner;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response.data.errors);
    }
    throw err;
  }
}

interface PartnersResponse {
  data: {
    autnhivePartners: {
      list: [PartnerType];
      count: number;
    };
  };
}

interface CreatePartnerResponse {
  data: {
    createAutnhivePartner: PartnerType;
  };
  errors?: Array<GraphqlError>;
}

interface UpdatePartnerResponse {
  data: {
    updateAutnhivePartner: PartnerType;
  };
  errors?: Array<GraphqlError>;
}
