import * as easaServices from "../services/easa";
export const easaActions: any = {
  domainsListWillLoad: "DOMAINS_LIST_WILL_LOAD",
  domainsListLoadSucceeded: "DOMAINS_LIST_LOAD_SUCCEEDED",
  domainsListLoadFailed: "DOMAINS_LIST_LOAD_FAILED",

  domainJobsListWillLoad: "DOMAIN_JOBS_LIST_WILL_LOAD",
  domainJobsListLoadSucceeded: "DOMAIN_JOBS_LIST_LOAD_SUCCEEDED",
  domainJobsListLoadFailed: "DOMAIN_JOBS_LIST_LOAD_FAILED",

  domainTasksListWillLoad: "DOMAIN_TASKS_LIST_WILL_LOAD",
  domainTasksListLoadSucceeded: "DOMAIN_TASKS_LIST_LOAD_SUCCEEDED",
  domainTasksListLoadFailed: "DOMAIN_TASKS_LIST_LOAD_FAILED",

  subTasksListWillLoad: "SUB_TASKS_LIST_WILL_LOAD",
  subTasksListLoadSucceeded: "SUB_TASKS_LIST_LOAD_SUCCEEDED",
  subTasksListLoadFailed: "SUB_TASKS_LIST_LOAD_FAILED",

  resetStates: "DOMAINS_LIST_RESET_STATES",
  resetJobsStates: "DOMAIN_JOBS_LIST_RESET_STATES",
  resetTasksStates: "DOMAIN_TASKS_LIST_RESET_STATES",
  resetSubTasksStates: "SUB_TASKS_LIST_RESET_STATES",

  updateDomainLogoWillLoad: "UPDATE_DOMAIN_LOGO_WILL_LOAD",
  updateDomainLogoSucceeded: "UPDATE_DOMAIN_LOGO_LOAD_SUCCEEDED",
  updateDomainLogoFailed: "UPDATE_DOMAIN_LOGO_LOAD_FAILED",

  retryJobWillLoad: "RETRY_JOB_WILL_LOAD",
  retryJobSucceeded: "RETRY_JOB_SUCCEEDED",
  retryJobFailed: "RETRY_JOB_FAILED",
  resetRetryJob: "RETRY_JOB_RESET"
};

export const fetchDomainsList =
  (page: number, limit: number, keyword?: string) => async (dispatch: any) => {
    dispatch({
      type: easaActions.domainsListWillLoad,
      payload: {
        page,
      },
    });
    try {
      dispatch({
        type: easaActions.domainsListLoadSucceeded,
        payload: await easaServices.fetchDomainsList(page, limit, keyword),
      });
    } catch (err) {
      dispatch({
        type: easaActions.domainsListLoadFailed,
        payload: err,
      });
    }
  };

export const fetchDomainJobsList =
  (page: number, limit: number, url: string) => async (dispatch: any) => {
    dispatch({
      type: easaActions.domainJobsListWillLoad,
      payload: {
        page,
      },
    });
    try {
      dispatch({
        type: easaActions.domainJobsListLoadSucceeded,
        payload: await easaServices.fetchDomainJobsList(page, limit, url),
      });
    } catch (err) {
      dispatch({
        type: easaActions.domainJobsListLoadFailed,
        payload: err,
      });
    }
  };

export const fetchDomainTasksList =
  (page: number, limit: number, jobId: string) => async (dispatch: any) => {
    dispatch({
      type: easaActions.domainTasksListWillLoad,
      payload: {
        page,
      },
    });
    try {
      dispatch({
        type: easaActions.domainTasksListLoadSucceeded,
        payload: await easaServices.fetchDomainTasksList(page, limit, jobId),
      });
    } catch (err) {
      dispatch({
        type: easaActions.domainTasksListLoadFailed,
        payload: err,
      });
    }
  };

export const fetchSubTasksList =
  (page: number, limit: number, parentJobId: string, jobId: string) =>
  async (dispatch: any) => {
    dispatch({
      type: easaActions.subTasksListWillLoad,
      payload: {
        page,
      },
    });
    try {
      dispatch({
        type: easaActions.subTasksListLoadSucceeded,
        payload: await easaServices.fetchSubTasksList(
          page,
          limit,
          parentJobId,
          jobId
        ),
      });
    } catch (err) {
      dispatch({
        type: easaActions.subTasksListLoadFailed,
        payload: err,
      });
    }
  };

  export const updateDomainLogo =
  (domain_id: string, logo: string) => async (dispatch: any) => {
    dispatch({
      type: easaActions.updateDomainLogoWillLoad,
      payload: {
        domain_id, logo
      },
    });
    try {
      dispatch({
        type: easaActions.updateDomainLogoSucceeded,
        payload: await easaServices.updateDomainLogo(domain_id, logo),
      });
    } catch (err) {
      dispatch({
        type: easaActions.updateDomainLogoFailed,
        payload: err,
      });
    }
  };

  export const retryJob =
  (job_id: string, sub_task_id?: string, is_sub_task: boolean = false) => async (dispatch: any) => {
    dispatch({
      type: easaActions.retryJobWillLoad,
      payload: {
        job_id
      },
    });
    try {
      if(is_sub_task){
        dispatch({
          type: easaActions.retryJobSucceeded,
          payload: await easaServices.retryJob(job_id, is_sub_task, sub_task_id),
        });
      }else{
        dispatch({
          type: easaActions.retryJobSucceeded,
          payload: await easaServices.retryJob(job_id, is_sub_task),
        });
      }
    } catch (err) {
      dispatch({
        type: easaActions.retryJobFailed,
        payload: err,
      });
    }
  };

export const resetStates = () => ({
  type: easaActions.resetStates,
});

export const resetJobsStates = () => ({
  type: easaActions.resetJobsStates,
});

export const resetTasksStates = () => ({
  type: easaActions.resetTasksStates,
});

export const resetSubTasksStates = () => ({
  type: easaActions.resetSubTasksStates,
});

export const resetRetryJobStates = () => ({
  type: easaActions.resetRetryJob,
});
