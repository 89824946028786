import { print } from "graphql";
import axios from "../../axios-client";
import gql from "graphql-tag";
import { parseErrors } from "../helpers";
import {
  ReleaseInput,
  CreateReleaseVariantInput,
  GraphqlError,
  ReleaseType,
  ReleaseVariantType,
} from "../interfaces";
import { env } from '../../env';

const apiUrl: string = env.REACT_APP_COMMON_API_URL;

export async function fetchReleases(
  page: number,
  limit: number
): Promise<{ list: [ReleaseType]; count: number }> {
  const query = gql`
    query releases($page: Int!, $limit: Int!) {
      releases(page: $page, limit: $limit) {
        list {
          id
          version
          description
          start_date
          end_date
          product {
            id
            name
          }
          release_variants {
            id
            platform
          }
        }
        count
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<ReleasesResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: { limit, page },
    },{
      headers: {
        "timestamp": `${timeStamp}`,
      }
    });
    return data.data.releases;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function createRelease(
  values: ReleaseInput
): Promise<ReleaseType> {
  const query = gql`
    mutation createRelease(
      $version: String!
      $product_id: String!
      $start_date: String!
      $end_date: String!
      $description: String!
    ) {
      createRelease(
        input: {
          version: $version
          product_id: $product_id
          start_date: $start_date
          end_date: $end_date
          description: $description
        }
      ) {
        id
        version
        description
        start_date
        end_date
        product {
          id
          name
        }
        release_variants {
          id
          platform
        }
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<CreateReleaseResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: values,
      },{
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.createRelease;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function updateRelease(id: string, values: ReleaseInput) {
  const query = gql`
    mutation updateRelease(
      $id: String!
      $version: String!
      $product_id: String!
      $start_date: String!
      $end_date: String!
      $description: String!
    ) {
      updateRelease(
        id: $id
        input: {
          version: $version
          product_id: $product_id
          start_date: $start_date
          end_date: $end_date
          description: $description
        }
      ) {
        id
        version
        description
        start_date
        end_date
        product {
          id
          name
        }
        release_variants {
          id
          platform
        }
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<UpdateReleaseResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: { ...values, id },
      },{
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.updateRelease;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function createReleaseVariant(
  values: CreateReleaseVariantInput
): Promise<ReleaseVariantType> {
  const query = gql`
    mutation createReleaseVariant(
      $release_id: String!
      $platform: String!
      $files: [Upload!]!
    ) {
      createReleaseVariant(
        release_id: $release_id
        platform: $platform
        files: $files
      ) {
        id
        platform
      }
    }
  `;
  const formData: FormData = new FormData();
  formData.append(
    "operations",
    JSON.stringify({
      query: print(query),
      operationName: "createReleaseVariant",
      variables: {
        files: Array(values.files.length).fill(null),
        platform: values.platform,
        release_id: values.release_id,
      },
    })
  );
  const fileJsonMap: { [key: string]: string[] } = {};
  for (let i = 0; i < values.files.length; i += 1) {
    fileJsonMap[i] = [`variables.files.${i}`];
  }
  formData.append("map", JSON.stringify(fileJsonMap));
  for (let i = 0; i < values.files.length; i += 1) {
    formData.append(`${i}`, values.files[i]);
  }

  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<CreateReleaseVariantResponse>(
      `${apiUrl}/graphql`,
      formData,{
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    return data.data.createReleaseVariant;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function deleteReleaseVariant(id: string) {
  const query = gql`
    mutation deleteReleaseVariant($id: String!) {
      deleteReleaseVariant(id: $id)
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<{
      data: { deleteReleaseVariant: boolean };
      errors?: Array<GraphqlError>;
    }>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: { id },
    },{
      headers: {
        "timestamp": `${timeStamp}`,
      }
    });
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.deleteReleaseVariant;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

interface ReleasesResponse {
  data: {
    releases: {
      list: [ReleaseType];
      count: number;
    };
  };
}

interface CreateReleaseResponse {
  data: {
    createRelease: ReleaseType;
  };
  errors?: Array<GraphqlError>;
}

interface UpdateReleaseResponse {
  data: {
    updateRelease: ReleaseType;
  };
  errors?: Array<GraphqlError>;
}

interface CreateReleaseVariantResponse {
  data: {
    createReleaseVariant: ReleaseVariantType;
  };
}
