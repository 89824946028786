import React, { useEffect } from "react";
import {
  Chip,
  List,
  ListItem,
  ListItemIcon,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Grid,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Pagination } from '@mui/material';
import UserIcon from "@mui/icons-material/Person";
import { connect, useDispatch, useSelector } from "react-redux";
import { AuditPageReducer, Reducers } from "../reducers";
import * as auditActions from "../actions/audit";
import {
  AuditLogType,
  FieldsChangedType,
  OrganizationType,
  TableType,
} from "../services/interfaces";
import { Dispatch } from "redux";
import moment from "moment";
import Permissioned, { actions, resources } from "../permissioned";

const LIMIT = 20;

interface AuditPageProps {
  logs: Array<AuditLogType>;
  tables: Array<TableType>;
  fetchLogs: Function;
  fetchTables: Function;
  changeTable: Function;
  updateSearchType: Function;
  searchOrganizations: Function;
  selectedTable: string;
  page: number;
  count: number;
  loading?: boolean;
  organizations:Array<OrganizationType>,
  organization_id?: string | null
}

const actionsList = [
  {action: "create", name: "Create"},
  {action: "bulk-create", name: "Bulk Create"},
  {action: "ad-sync", name: "AD Sync"},
  {action: "read", name: "Read"},
  {action: "update", name: "Update"},
  {action: "delete", name: "Delete"},
  {action: "update-status", name: "Update Status"},
  {action: "download", name: "Download"},
  {action: "create-admin", name: "Create Admin"},
  {action: "update-admin", name: "Update Admin"},
  {action: "approve-reject", name: "Approve Reject"},
  {action: "special-approve", name: "Special Approve"},
  {action: "update-password", name: "Update Password"},
  {action: "terms-accepted", name: "Terms Accepted"},
  {action: "privacy-policy-accepted", name: "Privacy Policy Accepted"},
  {action: "platform-use-policy-accepted", name: "Platform Use Policy Accepted"},
  {action: "mfa-initiation", name: "MFA Initiation"},
  {action: "mfa-acceptance", name: "MFA Acceptance"},
  {action: "mfa-rejected", name: "MFA Rejected"},
  {action: "mfa-expired", name: "MFA Expired"},
  {action: "otp-creation", name: "OTP Creation"},
  {action: "otp-verified", name: "OTP Verified"},
  {action: "otp-expired", name: "OTP Expired"},
  {action: "invalid-otp", name: "Invalid OTP"},
  {action: "log-out", name: "Logout"},
  {action: "user-registration", name: "user Registration"},
  {action: "reset-password-otp", name: "Reset Password OTP"},
  {action: "reset-password", name: "Reset Password"}
]

function Audit(props: AuditPageProps) {
  const dispatch = useDispatch<any>();
  const [inputValue, setInputValue] = React.useState("");
  const [value, setValue] = React.useState<OrganizationType | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const auditPage: AuditPageReducer = useSelector<Reducers, AuditPageReducer>(
    (state) => state.auditPage
  );
  useEffect(() => {
    props.fetchTables();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    const fetchOrgs = async () => {
      await dispatch(auditActions.searchOrganizations(inputValue));
    }
    fetchOrgs();
  }, [value, inputValue]);
  useEffect(() => {
    if (auditPage && auditPage.tables.length > 0) {
      const table = auditPage.selectedTable.length > 0 ? auditPage.selectedTable : "All Tables";
      const org = auditPage.organization_id.length > 0 ? auditPage.organization_id : "All Organizations";
      const action = auditPage.action.length > 0 ? auditPage.action : "All Actions";
      const message = `${auditPage.count} audit results for Table: ${table}, Organization: ${org}, Action: ${action}`;
      enqueueSnackbar(message, {variant: 'success'})
    }
  },[auditPage.logs]);
  return (
    <div>
      <Typography component="h1" variant="h5">
        Audit
      </Typography>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <FormControl size="small">
            <InputLabel id="table">Table</InputLabel>
            <Select
              style={{ minWidth: "200px" }}
              labelId="table"
              id="table"
              label="Table"
              value={props.selectedTable}
              onChange={(e: any) => {
                if (e.target.value === 'clear') {
                  dispatch(auditActions.clearSelectedTable(auditPage.action, auditPage.organization_id, LIMIT, 1));
                  return;
                }
                dispatch(auditActions.changeTable(e.target.value as string, LIMIT, 1, auditPage.organization_id, auditPage.action));
              }}
            >
              { auditPage.selectedTable && auditPage.selectedTable.length > 0 ?
                <MenuItem style={{color: 'red'}} value='clear'>Clear Selected Table</MenuItem> : null
              }
              {props.tables.map((table) => (
                <MenuItem key={table.name} value={table.name}>
                  {table.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
          <Grid item>
          <Permissioned
            resource={resources.Audit}
            action={actions.Read}
          >
            <Autocomplete
              value={value}
              size="small"
              onChange={(event: any, newValue: OrganizationType | null) => {
                const table = auditPage.selectedTable ? auditPage.selectedTable : '';
                if (newValue?.id) {
                  dispatch(auditActions.selectOrganization(newValue.id, LIMIT, 1, table, auditPage.action));
                }
                if (!newValue && auditPage.organization_id) {
                  dispatch(auditActions.clearOrganization(table, LIMIT, 1, auditPage.action));
                }
                setValue(newValue);

              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              id="organizations"
              noOptionsText="No suggestions"
              options={props.organizations}
              style={{ width: 300 }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search"
                  label="Organization"
                  variant="outlined"
                />
              )}
              renderOption={(options: any)=> (
                <TextField
                  variant="standard"
                  margin="none"
                  {...options}
                  InputProps={{ disableUnderline: true }}
                  value={options.key}
                  key={options.id}
                />
              )}
            />
          </Permissioned>
        </Grid>
        <Grid item>
          <FormControl size="small">
            <InputLabel id="table">Action</InputLabel>
            <Select
              style={{ minWidth: "200px" }}
              labelId="action"
              id="action"
              label="Action"
              value={auditPage.action}
              onChange={(e: any) => {
                if (e.target.value === 'clear') {
                  dispatch(auditActions.clearAction(auditPage.selectedTable, auditPage.organization_id, LIMIT, 1));
                  return;
                }
                dispatch(auditActions.changeAction(e.target.value as string, LIMIT, 1, auditPage.organization_id, auditPage.selectedTable));
              }}
            >
              { auditPage.action && auditPage.action.length > 0 ?
                <MenuItem style={{color: 'red', margin: 'auto'}} value='clear'>Clear Selected Action</MenuItem> : null
              }
              {actionsList.map((action) => (
                <MenuItem key={action.action} value={action.action}>
                  {action.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <List>
        {!props.logs.length ? "Empty data" : null}
        {props.logs.map((auditLog: AuditLogType, index) => {
          return (
            <ListItem
              key={auditLog.id}
              sx={{
                backgroundColor: index % 2 === 0 ? "#f1f1f1" : "inherit"
              }}
            >
              <ListItemIcon>
                <UserIcon />
              </ListItemIcon>
              <div>
                <div><strong>Table:</strong> {auditLog.table_name}</div>
                <div><strong>
                  Updated By:</strong> {auditLog.user.first_name}{" "}
                  {auditLog.user.last_name}
                </div>
                <div><strong>Date of Action: </strong>{moment(Number(auditLog.timestamp)).format(
                      "DD-MMM-YYYY hh:mm:ss"
                    )}</div>
                <div><strong>Row ID:</strong> {auditLog.row_id}</div>
                <div><strong>Action:</strong> {auditLog.action}</div>
                <div><strong>Deleted:</strong> {auditLog.is_deleted ? "Yes" : "No"}</div>
                <FieldChanges fieldChanges={auditLog.fields_changed} />
              </div>
            </ListItem>
          );
        })}
      </List>
      <Pagination
        count={Math.ceil(props.count / LIMIT)}
        variant="outlined"
        shape="rounded"
        onChange={(e: React.ChangeEvent<unknown>, page: number) => {
          dispatch(auditActions.fetchAuditLog({ table_name: props.selectedTable, action: auditPage.action, organization_id: auditPage.organization_id  }, LIMIT, page));
        }}
      />
    </div>
  );
}

function FieldChanges({
  fieldChanges,
}: {
  fieldChanges: Array<FieldsChangedType>;
}) {
  const fields = fieldChanges.map((fieldChange, index) => {
    return (
      <React.Fragment key={index}>
        <div>
          <Chip
            label={`${fieldChange.op}:${fieldChange.path.replaceAll("/", "")}`}
          />{" "}
          &gt;
          {fieldChange.value === "swarm-dashboard" ? "web-dashboard" : fieldChange.value}
        </div>
      </React.Fragment>
    );
  });
  return <React.Fragment>{fields}</React.Fragment>;
}

interface FetchLogsFilters {
  table_name: string;
  row_id?: string;
}
interface DispatchActions {
  fetchTables: () => Dispatch;
  fetchLogs: (
    filters: FetchLogsFilters,
    limit: number,
    page: number
  ) => Dispatch;
  changeTable: (tableName: string, limit: number, page: number, organization_id: string, action: string) => Dispatch;
  updateSearchType: (keyword: "action" | "updated_by" | "") => Dispatch;
  searchOrganizations: (keyword: string) => Dispatch
}

const mapStateToProps = (reducers: Reducers) => {
  return reducers.auditPage;
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchActions => {
  return {
    changeTable: (tableName: string, limit: number, page: number, organization_id:string) =>
      dispatch(auditActions.changeTable(tableName, limit, page, organization_id)),
    fetchTables: () => dispatch(auditActions.fetchTables()),
    fetchLogs: (
      filters: { table_name: string; row_id?: string },
      limit: number,
      page: number
    ) => dispatch(auditActions.fetchAuditLog(filters, limit, page)),
    updateSearchType:(keyword) => dispatch(auditActions.updateSearchType(keyword)),
    searchOrganizations: (keyword) => dispatch(auditActions.searchOrganizations(keyword))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Audit);
