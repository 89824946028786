import React, { PropsWithChildren, ReactElement } from "react";
import { useSelector } from "react-redux";
import { GlobalReducer, Reducers } from "./reducers";

export interface PermissionProviderProps {
  action: string;
  resource: string;
  alternateView?: ReactElement;
}

export enum resources {
  Subscriptions = "subscriptions",
  Organizations = "organizations",
  OrganizationsOnboardings = "organizations-onboardings",
  Products = "products",
  Partners = "partners",
  RolesPermisions = "roles-permisions",
  QboardRolesPermisions = "qboard-roles-permisions",
  Roles = "roles",
  QboardRoles = "qboard-roles",
  UsersSubscriptions = "users-subscriptions",
  QboardUsersSubscriptions = "qboard-users-subscriptions",
  Users = "users",
  QboardUsers = "qboard-users",
  AutnhiveUsers = "autnhive-users",
  UserProfile = "user-profile",
  QboardUserProfile = "qboard-user-profile",
  Audit = "audit",
  Resellers = "resellers",
  ResellerUsers = "reseller-users",
  Resources = "resources",
  Releases = "releases",
  Licenses = "licenses",
  QboardLicenses = "qboard-licenses",
  Contracts = "contracts",
  AutnhiveContracts = "autnhive-contracts",
  Invoices = "invoices",
  AskSami = "ask-sami",
}

export enum actions {
  Create = "create",
  BulkCreate = "bulk-create",
  Read = "read",
  Update = "update",
  Delete = "delete",
  UpdateStatus = "update-status",
  Download = "download",
  ApproveReject = "approve-reject",
}

// export function hasPermission(resource: string, action: string): boolean {
//   const globalState: GlobalReducer = useSelector<Reducers, GlobalReducer>(
//     (state) => state.globe
//   );
//   const permission = globalState.userProfile?.permissions.find(
//     (p) => p.resource === resource
//   );
//   return !!(permission && permission?.actions.includes(action));
// }

export default function Permissioned(
  props: PropsWithChildren<PermissionProviderProps>
) {
  const globalState: GlobalReducer = useSelector<Reducers, GlobalReducer>(
    (state) => state.globe
  );
  const permission = globalState.userProfile?.permissions.find(
    (p) => p.resource === props.resource
  );
  if (!permission || permission?.actions.includes(props.action) === false) {
    if (props.alternateView) {
      return <>{props.alternateView}</>;
    }
    return null;
  }
  // if(!hasPermission) {
  //   return null;
  // }
  return <>{props.children}</>;
}
