import { CustomError, DomainJobsList, DomainTasksList, GraphqlError, SubTasksList } from "./interfaces";

function parseSubErrors(vErr: any, ref: any) {
  if (vErr.children.length) {
    const sub: { [key: string]: string | object } = {};
    ref[vErr.property] = sub;
    vErr.children.forEach((vErr2: any) => {
      if (vErr2.constraints) {
        sub[vErr2.property] = Object.values(vErr2.constraints).join(", ");
      } else if (vErr2.children) {
        parseSubErrors(vErr2, sub);
      }
    });
  }
}

export function parseErrors(errors: Array<GraphqlError>): CustomError {
  let validationErrors: { [key: string]: string | object } = {};
  errors.forEach((err) =>
    err.extensions?.exception?.validationErrors?.forEach((vErr) => {
      if (vErr.constraints) {
        validationErrors[vErr.property] = Object.values(vErr.constraints).join(
          ", "
        );
      }
      parseSubErrors(vErr, validationErrors);
    })
  );
  return {
    message: errors[0]?.message,
    validationErrors,
  };
}

export function formatCurrency(value: string | number, currency: string) {
  return parseFloat(String(value)).toLocaleString("en-US", {
    style: "currency",
    currency,
  });
}

export function getDuration(job: DomainJobsList | DomainTasksList | SubTasksList) {
  const firstDate = new Date(job?.created_at);
  const latestDate = new Date(job?.updated_at || new Date().toUTCString());

  const differenceInTime = latestDate?.getTime() - firstDate?.getTime();
  const differenceInMinutes = Math.round(differenceInTime / (1000 * 60));
  const differenceInSeconds = Math.round((differenceInTime % (1000 * 60)) / 1000);

  if(job?.status === 'Created' || job?.status === 'Initiated') {
    return '-';
  }
  return `${differenceInMinutes} mins ${differenceInSeconds} secs`;
}