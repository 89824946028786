import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Box, Dialog,
  DialogContent,
  DialogTitle,
  Grid, Typography,
  TextField,FormControl, InputLabel,
  Select,
  MenuItem,
  IconButton, } from "@mui/material";
import { GlobalReducer, Reducers } from "../reducers";
import { FeatureInput
} from "../services/interfaces";
import SubmitButton from "../components/SubmitButton";
import { createNewFeature, fetchDashboardDetails, updatePackage } from "../services/autnhive/contracts";
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import DescriptionIcon from '@mui/icons-material/Description';
import GroupsIcon from '@mui/icons-material/Groups';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AppsIcon from '@mui/icons-material/Apps';
import GrainIcon from '@mui/icons-material/Grain';
import LayersIcon from '@mui/icons-material/Layers';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  Link as RouteLink,
} from "react-router-dom";
import { useSnackbar } from "notistack";
const { v4: uuidv4 } = require('uuid');

interface DashboardPageProps {
  globe: GlobalReducer;
}

function Dashboard(props: DashboardPageProps) {

  const { enqueueSnackbar } = useSnackbar();
  const [dashboardCount, setDashboardCount] = useState({
    contracts:'',
    customers:'',
    organizations:'',
    users:'',
    invoices: {
      nos: '',
      total: ''
    },
    products: [{id:'',name:'',internal_product_id:0}],
    packages: [{
      id: '',
      package_code: '',
      package_name: '',
      features: [''],
      pricing: [{
          currency: '',
          value: ''
        }],
      product_id: '',
      is_active: false,
      priority: 0
    }],
    features: [{
      id: '',
      feature_code: '',
      feature: '',
      price: [{
          currency: '',
          value: ''
        }],
      product_id: ''
    }]
  })
  const [productDialog, setProductDialog] = useState(false)
  const [packageDialog, setPackageDialog] = useState(false)
  const [featureDialog, setFeatureDialog] = useState(false)
  const [selectedCurrency, setSelectedCurrency] = useState("usd")
  const [newFeatureDialog, setNewFeatureDialog] = useState(false)
  const [editPackageDialog, setEditPackageDialog] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [currentFeature, setCurrentFeature] = useState({
    id:'',
    feature:'',
    feature_code:'',
    usd: '',
    cad: '',
    gbp: '',
    product_id:''
  })
  const [currentPackage, setCurrentPackage] = useState<{
    id:string;
    package_name:string;
    package_code:string;
    features: string[];
    usd: string | undefined;
    cad: string | undefined;
    gbp: string | undefined;
    product_id: string;
    is_active: boolean;
    priority: number;
  }>({
    id:'',
    package_name:'',
    package_code:'',
    features: [''],
    usd: '',
    cad: '',
    gbp: '',
    product_id: '',
    is_active: false,
    priority:0
  })

  // useEffect(() => {
  //   fetchDashboardDetails()
  //     .then((result) => {
  //       setLoaded(true)
  //       setDashboardCount({contracts: result.contracts, customers: result.customers,
  //       organizations: result.organizations, users: result.users, invoices: result.invoices,
  //     products: result.products, packages: result.packages, features: result.features})
  //     })
  //     .catch((err) => {
  //       enqueueSnackbar(err.message, { variant: "error" })
  //     })
  // }, []);

  return (
    <div>
      <Typography component="h1" variant="h5">
        Q-Board
      </Typography>
      {props.globe.userProfile ? (
        <>
          <div>
            Welcome {props.globe.userProfile?.user.first_name}{" "}
            {props.globe.userProfile?.user.last_name}
          </div>
          <div>{props.globe.userProfile?.user.organization?.name}</div>
        </>
      ) : null}
            {/* <Grid container style={{marginTop:'15px'}} >
                <Grid item xs={3} style={{height:'150px', marginLeft:'20px',cursor:'pointer',
                  backgroundImage:'linear-gradient(to top, #0ba360 0%, #3cba92 100%)',
                  borderRadius:'5px 5px 5px 5px', color:'white'}}>
                    <RouteLink to="/admin/autnhive/contracts">
                      <div className="dashboard" style={{height:'150px', display:'flex', flexDirection:'column', color:'white'}}>
                        <div style={{fontSize:'20px',marginLeft:'10px',marginTop:'5px'}}>
                          Contracts
                        </div> 
                        <div style={{marginTop:'auto', display:'flex', flexDirection:'row'}}>
                          <DescriptionIcon style={{marginLeft:'10px', height:'80px', 
                            width:'80px', opacity:'0.2'}}/>
                          <div style={{marginLeft:'auto', fontSize:'25px', fontWeight:'bold', 
                            marginRight:'10px', marginTop:'auto' }}>
                            {loaded ? dashboardCount.contracts : 0}
                          </div> 
                        </div>
                      </div>
                    </RouteLink>
                </Grid>

                <Grid item xs={3} style={{height:'150px', marginLeft:'20px',cursor:'pointer',
                  // backgroundImage:'linear-gradient( 179deg,  rgba(0,0,0,1) 9.2%, rgba(127,16,16,1) 103.9% )',
                  backgroundColor:'#0093E9',
                  background:'linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)',
                  borderRadius:'5px 5px 5px 5px', color:'white'}}>
                    <RouteLink to="/admin/organizations">
                      <div className="dashboard" style={{height:'150px', display:'flex', flexDirection:'column', color:'white'}}>
                        <div style={{fontSize:'20px',marginLeft:'10px',marginTop:'5px'}}>
                          Organizations
                        </div> 
                        <div style={{marginTop:'auto', display:'flex', flexDirection:'row'}}>
                          <CorporateFareIcon style={{marginLeft:'10px', height:'80px', 
                            width:'80px', opacity:'0.2'}}/>
                          <div style={{marginLeft:'auto', fontSize:'25px', fontWeight:'bold',
                              marginRight:'10px', marginTop:'auto' }}>
                            {loaded ? dashboardCount.organizations : 0}
                          </div> 
                        </div>
                      </div>
                    </RouteLink>
                </Grid>
                
                <Grid item xs={3} style={{height:'150px', marginLeft:'20px',cursor:'pointer',
                  backgroundImage:'radial-gradient( circle 758px at 87.5% 77.8%,  rgba(54,64,82,1) 9.6%, rgba(255,97,125,1) 100.2% )',
                  borderRadius:'5px 5px 5px 5px', color:'white'}}>
                    <RouteLink to="/admin/users">
                      <div className="dashboard" style={{height:'150px', display:'flex', flexDirection:'column', color:'white'}}>
                        <div style={{fontSize:'20px',marginLeft:'10px',marginTop:'5px'}}>
                          Users
                        </div> 
                        <div style={{marginTop:'auto', display:'flex', flexDirection:'row'}}>
                          <GroupsIcon style={{marginLeft:'10px', height:'80px', 
                            width:'80px', opacity:'0.2'}}/>
                          <div style={{marginLeft:'auto', fontSize:'25px', fontWeight:'bold', 
                            marginRight:'10px', marginTop:'auto' }}>
                            {loaded ? dashboardCount.users : 0}
                          </div> 
                        </div>
                      </div>
                    </RouteLink>
                </Grid>
                
                <Grid item xs={3} style={{height:'150px',marginLeft:'20px',cursor:'pointer',
                  backgroundImage:'linear-gradient( 1.3deg,  rgba(91,117,163,1) 11.4%, rgba(68,98,128,1) 77% )',
                  borderRadius:'5px 5px 5px 5px', color:'white', marginTop:'15px'}}>
                    <RouteLink to="/admin/invoices">
                      <div className="dashboard" style={{height:'150px', display:'flex', flexDirection:'column', color:'white'}}>
                        <div style={{fontSize:'20px',marginLeft:'10px',marginTop:'5px'}}>
                          Pending Invoices
                        </div> 
                        <div style={{marginTop:'auto', display:'flex', flexDirection:'row'}}>
                          <MonetizationOnIcon style={{marginLeft:'10px', height:'80px', 
                            width:'80px', opacity:'0.2'}}/>
                          <div style={{marginLeft:'auto', fontSize:'25px', fontWeight:'bold', 
                            marginRight:'10px', marginTop:'auto' }}>
                            {loaded ? dashboardCount.invoices.nos : 0}({loaded ? Intl.NumberFormat('en-US', { style:'currency', currency:'USD' }).format(Number.parseFloat(dashboardCount.invoices.total)) : '$0'})
                          </div> 
                        </div>
                      </div>
                    </RouteLink>
                </Grid>

                <Grid item xs={2} style={{height:'150px',marginLeft:'20px',cursor:'pointer',
                  backgroundColor: '#ea9f8c',
                  backgroundImage: 'linear-gradient(135deg, #ea9f8c 0%, #ad5703 100%)',
                  borderRadius:'5px 5px 5px 5px', color:'white', marginTop:'15px'}}>
                      <div className="dashboard" style={{height:'150px', display:'flex', flexDirection:'column', color:'white'}}
                      onClick={()=>{
                        setProductDialog(true)
                      }}>
                        <div style={{fontSize:'20px',marginLeft:'10px',marginTop:'5px'}}>
                          Products
                        </div> 
                        <div style={{marginTop:'auto', display:'flex', flexDirection:'row'}}>
                          <AppsIcon style={{marginLeft:'10px', height:'80px', 
                            width:'80px', opacity:'0.2'}}/>
                          <div style={{marginLeft:'auto', fontSize:'25px', fontWeight:'bold', 
                            marginRight:'10px', marginTop:'auto' }}>
                            {loaded ? dashboardCount.products.length : 0}
                          </div> 
                        </div>
                      </div>
                </Grid>
                
                <Grid item xs={2} style={{height:'150px',cursor:'pointer',
                      marginLeft:'10px', backgroundColor: '#a9b0c5',
                      backgroundImage: 'linear-gradient(45deg, #a9b0c5 0%, #0d3bc5 100%)',
                      borderRadius:'5px 5px 5px 5px', color:'white', marginTop:'15px'}}>
                          <div className="dashboard" style={{height:'150px', display:'flex', flexDirection:'column', color:'white'}}
                          onClick={()=>{
                            setPackageDialog(true)
                          }}>
                            <div style={{fontSize:'20px',marginLeft:'10px',marginTop:'5px'}}>
                              Packages
                            </div> 
                            <div style={{marginTop:'auto', display:'flex', flexDirection:'row'}}>
                              <LayersIcon style={{marginLeft:'10px', height:'80px', 
                                width:'80px', opacity:'0.2'}}/>
                              <div style={{marginLeft:'auto', fontSize:'25px', fontWeight:'bold', 
                                marginRight:'10px', marginTop:'auto' }}>
                                {loaded ? dashboardCount.packages.length : 0}
                              </div> 
                            </div>
                          </div>
                </Grid>
                    
                <Grid item xs={2} style={{height:'150px',cursor:'pointer',
                      marginLeft:'10px', backgroundColor: '#bfa3bf',
                      backgroundImage: 'linear-gradient(45deg, #bfa3bf 0%, #c70ec7 100%)',
                      borderRadius:'5px 5px 5px 5px', color:'white', marginTop:'15px'}}>
                          <div className="dashboard" style={{height:'150px', display:'flex', flexDirection:'column', color:'white'}}
                            onClick={() => setFeatureDialog(true)}>
                            <div style={{fontSize:'20px',marginLeft:'10px',marginTop:'5px'}}>
                              Features
                            </div> 
                            <div style={{marginTop:'auto', display:'flex', flexDirection:'row'}}>
                              <GrainIcon style={{marginLeft:'10px', height:'80px', 
                                width:'80px', opacity:'0.2'}}/>
                              <div style={{marginLeft:'auto', fontSize:'25px', fontWeight:'bold', 
                                marginRight:'10px', marginTop:'auto' }}>
                                {loaded ? dashboardCount.features.length : 0}
                              </div> 
                            </div>
                          </div>
                </Grid>
            </Grid> 
            
            <Dialog open={productDialog} onClose={() => setProductDialog(false)}>
              <DialogTitle>Products</DialogTitle>
              <DialogContent>
                <div>
                  {dashboardCount.products.map((prod)=>{
                    return(
                      <div style={{marginRight:'100px', marginTop:'15px', fontSize:'18px'}}>
                        {prod.name}
                      </div>
                    )
                  })}
                </div>
              </DialogContent>
            </Dialog>

            <Dialog open={packageDialog} onClose={() => setPackageDialog(false)}
              fullWidth={true} maxWidth={"md"}>
              <DialogTitle>Packages</DialogTitle>
              <DialogContent>
                <div style={{display:'flex', flexDirection:'column'}}>
                  <div style={{display:'flex', flexDirection:'row', marginLeft:'auto'}}>
                    <div>
                      <label style={{marginRight:'10px', cursor:'pointer'}}>
                        <input type="radio" value="usd" checked={selectedCurrency == "usd"} 
                          onClick={() => setSelectedCurrency("usd")}/>
                          USD
                      </label>
                    </div>
                    <div>
                      <label style={{marginRight:'10px', cursor:'pointer'}}>
                        <input type="radio" value="cad" checked={selectedCurrency == "cad"} 
                          onClick={() => setSelectedCurrency("cad")}/>
                          CAD
                      </label>
                    </div>
                    <div>
                      <label style={{marginRight:'10px', cursor:'pointer'}}>
                        <input type="radio" value="gbp" checked={selectedCurrency == "gbp"} 
                          onClick={() => setSelectedCurrency("gbp")}/>
                          GBP
                      </label>
                    </div>
                  </div>
                  <div style={{display:'flex', flexDirection:'row'}}>
                  {dashboardCount.packages.map((pkg)=>{
                    return(
                      <div style={{boxShadow:'0 4px 8px 0 rgba(0,0,0,0.2)', padding:'8px', margin:'8px'}}>
                        <div style={{marginTop:'10px', fontSize:'18px', margin:'10px', 
                          height:'60px', fontWeight:'bold', display:'flex', flexDirection:'column'}}>
                          {pkg.package_name}({dashboardCount.products.find((prod)=> prod.id == pkg.product_id)?.name})
                          <div style={{fontSize:'18px', fontWeight:'normal'}}>${pkg.pricing.find((pr)=> pr.currency == selectedCurrency.toUpperCase())?.value}</div>
                        </div>                 
                        <div style={{width:'100%', display:'flex', flexDirection:'row', alignItems:'center'}}>
                          <div style={{width:'95%', height:'1px', backgroundColor: '#a9b0c5',
                            backgroundImage: 'linear-gradient(45deg, #a9b0c5 0%, #0d3bc5 100%)'}}></div>
                          <IconButton onClick={()=> {
                            setEditPackageDialog(true)
                            setCurrentPackage({
                              id: pkg.id,
                              package_code: pkg.package_code,
                              package_name: pkg.package_name,
                              product_id: pkg.product_id,
                              features: pkg.features,
                              usd: pkg.pricing.find((pr)=> pr.currency == "USD")?.value,
                              cad: pkg.pricing.find((pr)=> pr.currency == "CAD")?.value,
                              gbp: pkg.pricing.find((pr)=> pr.currency == "GBP")?.value,
                              is_active: pkg.is_active,
                              priority: pkg.priority
                            })
                          }}>
                            <EditIcon style={{cursor:'pointer', color: '#0d3bc5'}} />
                          </IconButton>
                        </div>
                        {pkg.features.map((feature)=>{
                          return(
                            <div style={{margin:'10px', fontSize:'18px'}}>{dashboardCount.features.find((feat) => feat.feature_code == feature)?.feature}</div>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
              </div>
              </DialogContent>
            </Dialog>

            <Dialog open={featureDialog} onClose={() => setFeatureDialog(false)}
              fullWidth={true} maxWidth={"md"}>
              <DialogTitle>Features</DialogTitle>
              <DialogContent>
                <div style={{position:'relative' }}>
                  <div style={{display:'flex', flexDirection:'column'}}>
                      <div style={{display:'flex', flexDirection:'row', fontWeight:'bold'}}>
                        <div style={{width:'50%'}}>
                          <div style={{display:'flex', flexDirection:'row', width:'40%', color:'white',
                          backgroundColor: '#bfa3bf', borderRadius:'5px', padding:'10px', cursor: 'pointer',
                          backgroundImage: 'linear-gradient(45deg, #bfa3bf 0%, #c70ec7 100%)'}} onClick={() => setNewFeatureDialog(true)}>
                            <AddIcon /> Add New Feature 
                          </div>
                        </div>
                        <div style={{width:'15%'}}>USD</div>
                        <div style={{width:'15%'}}>CAD</div>
                        <div style={{width:'15%'}}>GBP</div>
                      </div>
                      {dashboardCount.features.map((feature)=>{
                        return(
                          <div style={{boxShadow:'0 4px 8px 0 rgba(0,0,0,0.2)', padding:'18px', margin:'8px',
                            marginTop:'10px', display:'flex', flexDirection:'row'}}>
                            <div style={{fontSize:'18px', fontWeight:'bold', width:'50%'}}>
                              {feature.feature} ({dashboardCount.products.find((prod)=> prod.id == feature.product_id)?.name})
                            </div>                      
                            <div style={{fontSize:'18px', width:'15%'}}>
                              ${feature.price.find((pr) => pr.currency == "USD")?.value}
                            </div>                      
                            <div style={{fontSize:'18px', width:'15%'}}>
                              ${feature.price.find((pr) => pr.currency == "CAD")?.value}
                            </div>                      
                            <div style={{fontSize:'18px', width:'15%'}}>
                              £{feature.price.find((pr) => pr.currency == "GBP")?.value}
                            </div>                      
                          </div>
                        )
                      })}
                    </div>
                </div>
              </DialogContent>
            </Dialog>

            <Dialog open={newFeatureDialog} onClose={() => setNewFeatureDialog(false)}
              fullWidth={true} maxWidth={"md"}>
              <DialogTitle>Add New Feature</DialogTitle>
              <DialogContent>
                <div>
                  <TextField
                    style={{width:'50%', marginTop:'10px'}}
                    placeholder="Enter feature name"
                    label="Feature Name"
                    variant="standard"
                    value={currentFeature.feature}
                    onChange={(e)=> setCurrentFeature({...currentFeature, feature:e.target.value})}
                  />
                  <FormControl style={{width:'45%', marginLeft:'10px', marginTop:'10px'}}>
                    <InputLabel id="product">Product</InputLabel>
                    <Select
                      fullWidth
                      id="product"
                      variant="standard"
                      style={{ minWidth: "100px" }}
                      value={currentFeature.product_id}
                      onChange={(e: any) => {
                        setCurrentFeature({...currentFeature, product_id:e.target.value})
                      }} 
                    >
                      <MenuItem value="">None</MenuItem>
                      {dashboardCount.products.map((prod)=>{
                        return(<MenuItem value={prod.id}>{prod.name}</MenuItem>)
                      })}
                    </Select>
                  </FormControl>
                  <div style={{display:'flex', flexDirection:'row', alignItems:'center', marginTop:'18px'}}>
                    <div>Price</div>
                    <TextField
                      placeholder="value"
                      label="USD"
                      type="number"
                      variant="standard"
                      value={currentFeature.usd}
                      style={{marginLeft:'18px'}}
                      onChange={(e)=> setCurrentFeature({...currentFeature, usd:e.target.value})}
                    />
                    <TextField
                      placeholder="value"
                      label="CAD"
                      type="number"
                      variant="standard"
                      value={currentFeature.cad}
                      style={{marginLeft:'18px'}}
                      onChange={(e)=> setCurrentFeature({...currentFeature, cad:e.target.value})}
                    />
                    <TextField
                      placeholder="value"
                      label="GBP"
                      type="number"
                      variant="standard"
                      value={currentFeature.gbp}
                      style={{marginLeft:'18px'}}
                      onChange={(e)=> setCurrentFeature({...currentFeature, gbp:e.target.value})}
                    />
                  </div>
                  <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center'}}>
                    <div style={{marginTop:'10px'}}>
                      <SubmitButton
                        loading={false}
                        label="Submit"
                        onClick={()=>{
                          if(currentFeature.id.length == 0){
                            let uuid = uuidv4()
                            setDashboardCount({...dashboardCount, features: [...dashboardCount.features, {
                              id: uuid, feature: currentFeature.feature, 
                              feature_code: currentFeature.feature.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
                                return index == 0 ? word.toLowerCase() : word.toUpperCase();
                              }).replace(/\s+/g, ''), 
                              product_id:currentFeature.product_id,
                              price:[{value:currentFeature.usd, currency:'USD'},{value:currentFeature.cad, currency:'CAD'},
                                     {value:currentFeature.gbp, currency:'GBP'}]
                            }]})
                            createNewFeature({
                              id: uuid, feature: currentFeature.feature, 
                              feature_code: currentFeature.feature.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
                                return index == 0 ? word.toLowerCase() : word.toUpperCase();
                              }).replace(/\s+/g, ''), 
                              product_id:currentFeature.product_id,
                              price:[{value: currentFeature.usd, currency:'USD'},{value: currentFeature.cad, currency:'CAD'},
                                    {value:currentFeature.gbp, currency:'GBP'}],
                              priority: 4,
                              package_code: 'SWARM_ENTERPRISE'                            
                            }).then(()=>{
                                enqueueSnackbar(`New feature created`, {
                                  variant: "success",
                                });
                            }).catch((err:any)=>{
                              enqueueSnackbar(err.message, {
                                variant: "error",
                              });
                            })
                            setCurrentFeature({
                              id:'',
                              feature:'',
                              feature_code:'',
                              usd: '',
                              cad: '',
                              gbp: '',
                              product_id:''
                            })
                            setNewFeatureDialog(false)
                          }
                        }} />
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Dialog>

            <Dialog open={editPackageDialog} onClose={() => setEditPackageDialog(false)}
              fullWidth={true} maxWidth={"md"}>
              <DialogTitle>Edit Package</DialogTitle>
              <DialogContent>
                <div>
                  <TextField
                    style={{width:'50%', marginTop:'10px'}}
                    placeholder="Enter package name"
                    label="Package Name"
                    variant="standard"
                    value={currentPackage.package_name}
                    onChange={(e)=> setCurrentPackage({...currentPackage, package_name:e.target.value})}
                  />
                  <TextField
                    style={{width:'45%',  marginLeft:'10px', marginTop:'10px'}}
                    label="Product Name"
                    disabled={true}
                    variant="standard"
                    value={dashboardCount.products.find((pr)=> pr.id == currentPackage.product_id)?.name}
                  />
                  <div style={{display:'flex', flexDirection:'row', alignItems:'center', marginTop:'18px'}}>
                    <div>Price</div>
                    <TextField
                      placeholder="value"
                      label="USD"
                      type="number"
                      variant="standard"
                      value={currentPackage.usd}
                      style={{marginLeft:'18px'}}
                      onChange={(e)=> setCurrentPackage({...currentPackage, usd:e.target.value})}
                    />
                    <TextField
                      placeholder="value"
                      label="CAD"
                      type="number"
                      variant="standard"
                      value={currentPackage.cad}
                      style={{marginLeft:'18px'}}
                      onChange={(e)=> setCurrentPackage({...currentPackage, cad:e.target.value})}
                    />
                    <TextField
                      placeholder="value"
                      label="GBP"
                      type="number"
                      variant="standard"
                      value={currentPackage.gbp}
                      style={{marginLeft:'18px'}}
                      onChange={(e)=> setCurrentPackage({...currentPackage, gbp:e.target.value})}
                    />
                  </div>
                  <div style={{boxShadow:'0 4px 8px 0 rgba(0,0,0,0.2)', padding:'8px', marginTop:'8px'}}>
                    <h3>Features</h3>
                    <Grid container>
                      {currentPackage.features.map((feat)=>{
                        return(<Grid item xs={3}><div style={{margin:'5px', fontSize:'16px', cursor:'pointer'}} onClick={()=>{
                          let existingFeatures = currentPackage.features.filter((f) => f != feat)                          
                          setCurrentPackage({...currentPackage, features: existingFeatures})
                        }}>
                          <RemoveCircleIcon style={{color:'red'}} /> 
                          {dashboardCount.features.find((f)=>f.feature_code == feat)?.feature}</div></Grid>)
                      })}
                    </Grid>
                    <h3>Add Features</h3>
                    <Grid container>
                      {dashboardCount.features.map((feat)=>{
                        const findFeature = currentPackage.features.filter((f)=> f == feat.feature_code)
                        if(findFeature.length == 0 && currentPackage.product_id == feat.product_id){
                          return(<Grid item xs={3}>
                              <div style={{margin:'5px', fontSize:'16px', cursor:'pointer'}} onClick={()=>{
                                setCurrentPackage({...currentPackage, features: [...currentPackage.features, feat.feature_code]})
                              }}>
                              <AddCircleIcon style={{color:'green'}} /> {feat.feature}</div>
                            </Grid>)  
                        }else{
                          return(<></>)  
                        }
                    })}
                    </Grid>
                  </div>
                  <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center'}}>
                    <div style={{marginTop:'10px'}}>
                      <SubmitButton
                        loading={false}
                        label="Submit"
                        onClick={()=>{
                          let filterPackage = dashboardCount.packages.filter((p)=> p.id != currentPackage.id)
                          let index = dashboardCount.packages.findIndex((v) => v.id == currentPackage.id)
                          setDashboardCount({...dashboardCount, packages: [...
                            filterPackage.slice(0,index),
                            {
                              id: currentPackage.id, 
                              features: currentPackage.features, 
                              package_name: currentPackage.package_name, package_code: currentPackage.package_code,
                              product_id:currentPackage.product_id,
                              is_active:currentPackage.is_active,
                              priority:currentPackage.priority,
                              pricing:[{value:currentPackage.usd ? currentPackage.usd : "0.0", currency:'USD'},
                              {value:currentPackage.cad ? currentPackage.cad : "0.0", currency:'CAD'},
                                     {value:currentPackage.gbp ? currentPackage.gbp : "0.0", currency:'GBP'}]
                            },
                            ...filterPackage.slice(index)
                          ]})
                          updatePackage({
                            id: currentPackage.id, package_name: currentPackage.package_name, 
                            package_code: currentPackage.package_code, 
                            features: currentPackage.features,
                            product_id:currentPackage.product_id,
                            pricing:[{value: currentPackage.usd ? parseFloat(currentPackage.usd) : 0.0, currency:'USD'},
                            {value: currentPackage.cad ? parseFloat(currentPackage.cad) : 0.0, currency:'CAD'},
                                  {value:currentPackage.gbp ? parseFloat(currentPackage.gbp) : 0.0, currency:'GBP'}],
                            priority: 1,
                            is_active: true
                          }).then(()=>{
                            enqueueSnackbar(`Package updated`, {
                              variant: "success",
                            });
                          }).catch((err:any)=>{
                            enqueueSnackbar(err.message, {
                              variant: "error",
                            });
                          })
                          setCurrentPackage({
                            id:'',
                            package_name:'',
                            package_code:'',
                            features: [''],
                            usd: '',
                            cad: '',
                            gbp: '',
                            product_id: '',
                            is_active: false,
                            priority: 0
                          })
                          setEditPackageDialog(false)
                        }} />
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Dialog> */}
    </div>
  );
}

const mapStateToProps = ({ globe }: Reducers) => ({ globe });
const mapDispatchToProps = (dispatch: any) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
