import * as React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  IconButton,
  TextField,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  InputLabel,
  Grid,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Pagination } from '@mui/material';
import PartnerIcon from "@mui/icons-material/Store";
import EditIcon from "@mui/icons-material/Edit";
import { Routes, Route, Link as RouteLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as partnerActions from "../actions/partners";
import {
  CreatePartnerReducer,
  UpdatePartnerReducer,
  Reducers,
  PartnersPageReducer,
  GlobalReducer,
} from "../reducers";
import {
  PartnerType,
  PartnerFormInput,
  CustomError,
  CountryType,
} from "../services/interfaces";
import SubmitButton from "../components/SubmitButton";
import Permissioned, { resources, actions } from "../permissioned";
import MandatoryFieldText from "../components/mandatoryFieldsText";
import * as userProfileActions from "../actions/userProfile";
import { useEffect } from "react";


interface PartnerFormProps {
  error?: CustomError;
  loading: boolean;
  onSubmit: Function;
  values?: PartnerFormInput;
}

const LIMIT = 20;

function PartnersList() {
  const dispatch = useDispatch<any>();
  const partnersPage: PartnersPageReducer = useSelector<
    Reducers,
    PartnersPageReducer
  >((state) => state.partnersPage);
  React.useEffect(() => {
    dispatch(partnerActions.fetchPartners(LIMIT, 1));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Typography component="h1" variant="h5">
        Partners
      </Typography>
      <div>
        <Permissioned resource={resources.Partners} action={actions.Create}>
          <RouteLink to="/admin/partners/new">
            <Button
              color="secondary"
              size="small"
              variant="outlined"
              startIcon={<PartnerIcon />}
            >
              New
            </Button>
          </RouteLink>
        </Permissioned>
      </div>
      <List>
        {partnersPage.partners.map((partner: PartnerType, index) => {
          return (
            <ListItem
              key={partner.id}
              sx={{
                backgroundColor: index % 2 === 0 ? "#f1f1f1" : "inherit"
              }}
            >
              <ListItemText primary={partner.name} />
              <ListItemSecondaryAction>
                <Permissioned
                  resource={resources.Partners}
                  action={actions.Update}
                >
                  <RouteLink to={`/admin/partners/${partner.id}/edit`}>
                    <Tooltip title="Edit">
                      <IconButton size="large">
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </RouteLink>
                </Permissioned>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
      <Pagination
        count={Math.ceil(partnersPage.count / LIMIT)}
        page={partnersPage.page}
        variant="outlined"
        shape="rounded"
        onChange={(e: React.ChangeEvent<unknown>, page: number) => {
          dispatch(partnerActions.fetchPartners(LIMIT, page));
        }}
      />
    </div>
  );
}

function PartnerForm(props: PartnerFormProps) {
  const dispatch = useDispatch<any>()
  let nameRef: any = null;
  let emailRef: any = null;
  let phoneRef: any = null;
  let line1Ref: any = null;
  let line2Ref: any = null;
  let cityRef: any = null;
  let stateRef: any = null;
  let postalCodeRef: any = null;
  let countryRef: any = null;

  const onSubmit: Function = () => {
    props.onSubmit({
      name: nameRef?.value,
      email: emailRef?.value,
      phone: phoneRef?.value,
      logo: "",
      address: {
        line_1: line1Ref?.value,
        line_2: line2Ref?.value,
        city: cityRef?.value,
        state: stateRef?.value,
        postal_code: postalCodeRef?.value,
        country: countryRef?.value,
      },
    });
  };

  const {countries} = useSelector<Reducers, GlobalReducer>(
    (state) => state.globe
  );
  useEffect(() => {
    if (!countries || countries.length <= 0) {
      dispatch(
        userProfileActions.fetchCountries()
      );
    }
  }, []);

  return (
    <div>
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              variant="standard"
              error={!!props.error?.validationErrors?.name}
              fullWidth
              required
              label="Name"
              defaultValue={props.values?.name}
              inputRef={(ref) => (nameRef = ref)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              required
              fullWidth
              label="Email"
              error={!!props.error?.validationErrors?.email}
              defaultValue={props.values?.email}
              inputRef={(ref) => (emailRef = ref)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              fullWidth
              required
              label="Phone"
              placeholder="6471234567"
              error={!!props.error?.validationErrors?.phone}
              defaultValue={props.values?.phone}
              inputRef={(ref) => (phoneRef = ref)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              fullWidth
              required
              label="Address Line 1"
              error={!!props.error?.validationErrors?.address?.line_1}
              defaultValue={props.values?.address.line_1}
              inputRef={(ref) => (line1Ref = ref)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              label="Address Line 2"
              defaultValue={props.values?.address.line_2}
              inputRef={(ref) => (line2Ref = ref)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              fullWidth
              required
              label="City"
              error={!!props.error?.validationErrors?.address?.city}
              defaultValue={props.values?.address.city}
              inputRef={(ref) => (cityRef = ref)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              fullWidth
              required
              label="State"
              error={!!props.error?.validationErrors?.address?.state}
              defaultValue={props.values?.address.state}
              inputRef={(ref) => (stateRef = ref)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              fullWidth
              required
              label="Postal Code"
              error={!!props.error?.validationErrors?.address?.postal_code}
              defaultValue={props.values?.address.postal_code}
              inputRef={(ref) => (postalCodeRef = ref)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl margin="normal" fullWidth>
              <InputLabel variant="standard">Country</InputLabel>
              <Select
                variant="standard"
                style={{ width: 195 }}
                defaultValue={props.values?.address.country || "Canada"}
                inputRef={(ref) => (countryRef = ref)}
              >
                {countries?.map(
                  (country: CountryType) => (
                    <MenuItem key={country.id} value={country.name}>
                      {country.name}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <MandatoryFieldText
          hasErrors={Boolean(props.error?.validationErrors)}
        />
        <br />
        <div>
          <SubmitButton
            loading={props.loading}
            label="Submit"
            onClick={() => onSubmit()}
          />
        </div>
      </form>
    </div>
  );
}

function PartnerEditMOodal() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  interface Params {
    partnerId : string
  }
  const { partnerId } = useParams<keyof Params>() as Params;
  const partnersPage: PartnersPageReducer = useSelector<
    Reducers,
    PartnersPageReducer
  >((state) => state.partnersPage);
  const updatePartner: UpdatePartnerReducer = useSelector<
    Reducers,
    UpdatePartnerReducer
  >((state) => state.updatePartner);
  const partner = partnersPage.partners.find((p) => p.id === partnerId);
  if (!partner) return null;
  return (
    <Dialog
      open={true}
      onClose={() => {
        navigate(-1);
        dispatch(partnerActions.dismissMessages());
      }}
    >
      <DialogTitle>Edit Partner</DialogTitle>
      <DialogContent>
        <PartnerForm
          error={updatePartner?.error}
          loading={updatePartner.loading}
          values={partner}
          onSubmit={(values: any) => {
            Promise.resolve()
              .then(() =>
                dispatch(
                  partnerActions.updatePartner(partnerId, values)
                )
              )
              .then(() => navigate(-1))
              .then(() =>
                enqueueSnackbar("Partner is updated", {
                  variant: "success",
                })
              )
              .then(() =>
                dispatch(partnerActions.fetchPartners(LIMIT, 1))
              )
              .catch((err) =>
                enqueueSnackbar(err.message, { variant: "error" })
              );
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

export function Partners(props: Reducers) {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const partnersPage: PartnersPageReducer = useSelector<
    Reducers,
    PartnersPageReducer
  >((state) => state.partnersPage);
  const createPartner: CreatePartnerReducer = useSelector<
    Reducers,
    CreatePartnerReducer
  >((state) => state.createPartner);
  const updatePartner: UpdatePartnerReducer = useSelector<
    Reducers,
    UpdatePartnerReducer
  >((state) => state.updatePartner);
  return (
    <div>
      <PartnersList />
      <Routes>
        <Route path="/new" element={
          <Dialog
            open={true}
            onClose={() => {
              navigate(-1);
              dispatch(partnerActions.dismissMessages());
            }}
          >
            <DialogTitle>Create Partner</DialogTitle>
            <DialogContent>
              <PartnerForm
                error={createPartner.error}
                loading={createPartner.loading}
                onSubmit={(values: any) => {
                  Promise.resolve()
                    .then(() =>
                      dispatch(partnerActions.createPartner(values))
                    )
                    .then(() => navigate(-1))
                    .then(() =>
                      enqueueSnackbar("Partner is created", {
                        variant: "success",
                      })
                    )
                    .then(() =>
                      dispatch(partnerActions.fetchPartners(LIMIT, 1))
                    )
                    .catch((err) =>
                      enqueueSnackbar(err.message, { variant: "error" })
                    );
                }}
              />
            </DialogContent>
          </Dialog>
        }/>
        <Route path="/:partnerId/edit" element={<PartnerEditMOodal />}/>
      </Routes>
    </div>
  );
}

export default Partners;
