import { print } from "graphql";
import axios from "../../axios-client";
import gql from "graphql-tag";
import { parseErrors } from "../helpers";
import {
  ResellerInput,
  GraphqlError,
  ResellerType,
  OrganizationOnboardingInput,
  OrganizationOnboardingType,
  OrganizationType,
} from "../interfaces";
import { env } from '../../env';

const apiUrl: string = env.REACT_APP_COMMON_API_URL;

export async function fetchResellers(
  { keyword }: { keyword?: string },
  page: number,
  limit: number
): Promise<{ list: [ResellerType]; count: number }> {
  const query = gql`
    query resellers($keyword: String, $page: Int!, $limit: Int!) {
      resellers(keyword: $keyword, page: $page, limit: $limit) {
        list {
          id
          name
          is_active
          email
          phone
          address {
            line_1
            line_2
            city
            state
            postal_code
            country
          }
          allowed_domains
          is_white_label
          white_label_url
          is_white_label_email
          white_label_email_provider
          white_label_email_address
        }
        count
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<ResellersResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: { limit, page, keyword },
    },{
      headers: {
        "timestamp": `${timeStamp}`,
      }
    });
    return data.data.resellers;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function fetchResellersOrganizations(
 reseller_id: string
): Promise<ResellersOrganizationsResponse> {
  const page = 1;
  const limit = 100;
  const query = gql`
    query resellerOrganizations($reseller_id: String!, $page: Int!, $limit: Int!) {
      resellerOrganizations(reseller_id: $reseller_id, page: $page, limit: $limit) {
        list {
          id
          name
          email
          phone
          is_active
          logo
          organization_type
          reseller {
            id
            name
          }
          address {
            line_1
            line_2
            city
            state
            postal_code
            country
          }
        }
        count
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<any>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: { reseller_id, limit, page },
      },
      {
        headers: {
          "timestamp": `${timeStamp}`
        }
      }
    );
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return {reseller: reseller_id, orgs: data.data.resellerOrganizations.list};
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function createOrganizationOnboarding(
  values: OrganizationOnboardingInput
): Promise<OrganizationOnboardingType> {
  const query = gql`
    mutation createOrganizationOnboarding(
      $values: OrganizationOnboardingInput!
    ) {
      createOrganizationOnboarding(input: $values) {
        id
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<CreateOrganizationOnboardingResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: { values },
      },{
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.createOrganizationOnboarding;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function updateReseller(id: string, values: ResellerInput) {
  const query = gql`
    mutation updateReseller(
      $id: String!
      $name: String!
      $email: String!
      $phone: String!
      $address: AddressInput!
      $is_active: Boolean!
      $allowed_domains: [String!]
      $is_white_label: Boolean!
      $white_label_url: String
    ) {
      updateReseller(
        id: $id
        input: {
          name: $name
          email: $email
          phone: $phone
          address: $address
          is_active: $is_active
          allowed_domains: $allowed_domains
          is_white_label: $is_white_label
          white_label_url: $white_label_url
        }
      ) {
        id
        name
        address {
          line_1
          line_2
          city
          state
          postal_code
          country
        }
        phone
        is_active
        allowed_domains
        is_white_label
        white_label_url
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<UpdateResellerResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: { ...values, id },
      },{
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.updateReseller;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function getCryptoKey() {
  const timeStamp = new Date().getTime();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { data } = await axios.get(`${apiUrl}/get-crypto-key`, { headers: { "app-id": "qboard", "timestamp": `${timeStamp}`, "timezone": timeZone } });
  return data;
}

interface ResellersResponse {
  data: {
    resellers: {
      list: [ResellerType];
      count: number;
    };
  };
}

interface UpdateResellerResponse {
  data: {
    updateReseller: ResellerType;
  };
  errors?: Array<GraphqlError>;
}

interface CreateOrganizationOnboardingResponse {
  data: {
    createOrganizationOnboarding: OrganizationOnboardingType;
  };
  errors?: Array<GraphqlError>;
}

interface ResellersOrganizationsResponse {
  reseller: string
  orgs: ResellerType;
  errors?: Array<GraphqlError>;
}

