import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import {
  Input,
  InputLabel,
  Button,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  FormControl,
  Dialog,
  DialogContent,
  Select,
  MenuItem,
  DialogTitle,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  TextField,
  Backdrop,
} from "@mui/material";
import SubmitButton from "../components/SubmitButton";
import { Route, Link as RouteLink, useParams, Navigate, useNavigate, Routes } from "react-router-dom";
import { Pagination } from '@mui/material';
import { CircularProgress } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import PreviewIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import EmailIcon from '@mui/icons-material/Email';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PaymentsIcon from '@mui/icons-material/Payments';
import PaymentIcon from '@mui/icons-material/Payment'
import InfoIcon from '@mui/icons-material/Info'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import * as invoicesActions from "../actions/invoices";
import { getInvoiceHtml, printInvoice, getStripeInvoicesById, attemptStripePayment, generatePastInvoices, updateInvoiceToPaid, sendInvoiceEmail } from "../services/invoices";
import { InvoicesReducer, Reducers } from "../reducers";
import { CustomError, InvoicePaymentUpdateType, InvoiceType } from "../services/interfaces";
import ConfirmButton from "../components/confirmButton";
import Permissioned, { actions, resources } from "../permissioned";
import { DATE_FORMAT } from "../constants";
import { formatCurrency } from "../services/helpers";
import { fetchCustomers } from "../services/autnhive/customers";
import { resolveBreakpointValues } from "@mui/system/breakpoints";

const LIMIT = 10;

type EditInvoiceFormProps = {
  values: InvoiceType;
  onSubmit: (values: InvoiceType) => void;
};

function EditInvoiceForm({ values, onSubmit }: EditInvoiceFormProps) {
  const [deductions, setDeductions] = useState<
    Array<{ description: string; amount: number }>
  >([{ description: "", amount: 0 }]);
  useEffect(() => {
    if (values.deductions.length) {
      setDeductions(values.deductions);
    }
  }, [values.deductions]);
  return (
    <React.Fragment>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          values.deductions = deductions;
          onSubmit(values);
        }}
      >
        <Typography variant="h6">Discounts</Typography>
        {deductions.map((deduction, index) => (
          <Grid container spacing={3} key={index}>
            <Grid item xs={7}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  label="Description"
                  value={deduction.description}
                  onChange={(e) => {
                    const _deductions = [...deductions];
                    _deductions[index].description = e.target.value;
                    setDeductions(_deductions);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  label="Amount"
                  value={deduction.amount}
                  onChange={(e) => {
                    const _deductions = [...deductions];
                    _deductions[index].amount = Number(e.target.value);
                    setDeductions(_deductions);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={() => {
                  const _deductions = [...deductions];
                  _deductions.splice(index, 1);
                  setDeductions(_deductions);
                }}
                size="large">
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <br />
        <Button
          onClick={() => {
            const _deductions = [...deductions, { description: "", amount: 0 }];
            setDeductions(_deductions);
          }}
        >
          Add More
        </Button>
        <br />
        <br />
        <Button type="submit" color="primary" variant="contained">
          Submit
        </Button>
      </form>
    </React.Fragment>
  );
}

function InvoiceList() {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [isFailPayModalVisible, setIsFailPayModalVisible] = useState(false);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [notes, setNotes] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [paymentTxId, setPaymentTxId] = useState('');
  const [currentInvoice, setCurrentInvoice] = useState<InvoiceType | null>(
    null
  );
  const [updateDate, setUpdateDate] = React.useState<Date | null>(
    moment().toDate()
  );
  const [currentInvoiceHtml, setCurrentInvoiceHtml] = useState<string>("");
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const invoicesPageReducer = useSelector<Reducers, InvoicesReducer>(
    (state) => state.invoices
  );
  const { startDate, endDate, keyword, status, invoices, count, page } =
    invoicesPageReducer;
  useEffect(() => {
    dispatch(
      invoicesActions.fetchInvoices(
        {
          startDate,
          endDate,
          keyword,
          status
        },
        1,
        LIMIT
      )
    );
  }, [dispatch, startDate, endDate, keyword, status]);
  useEffect(() => {
    if (currentInvoice) {
      getInvoiceHtml(currentInvoice?.id).then((html) =>
        setCurrentInvoiceHtml(html)
      );
    }
  }, [currentInvoice]);
  const resetPaymentValues = () => {
    setNotes('');
    setPaymentTxId('');
    setPaymentType('');
    setUpdateDate(moment().toDate());
  }
  return <>
    <Backdrop
        sx={{
          zIndex: 10000,
          color: "#fff"
        }}
        color="#fff"
        open={isProcessing}
        onClick={() => setIsProcessing(false)}
      >
        <div style={{ position: 'relative' }}>
          <h3 style={{ position: 'absolute', top: '50px', left: "-70px", whiteSpace: "nowrap" }}>Processing Request</h3>
          <CircularProgress />
        </div>
      </Backdrop>
    <List>
      {invoices.map((invoice, index) => {
        return (
          <ListItem
            key={invoice.id}
            sx={{
              backgroundColor: index % 2 === 0 ? "#f1f1f1" : "inherit"
            }}
          >
            <ListItemText
              primary={
                invoice.customer.organization?.name ||
                invoice.customer.reseller?.name
              }
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    display="inline"
                    color="textPrimary"
                  >
                    Total: {formatCurrency(invoice.total, invoice.currency)}
                    <br />
                    Date:{" "}
                    {moment(invoice.invoice_date).format(
                      "DD-MMM-YYYY"
                    )}
                    <br />
                    Status:{" "}
                    {invoice.payment_status[0].toUpperCase() + invoice.payment_status.substring(1)}
                  </Typography>
                </React.Fragment>
              }
            />
            <ListItemSecondaryAction>
              {/*<Permissioned
                resource={resources.Invoices}
                action={actions.Update}
              >
                <RouteLink to={`/admin/invoices/${invoice.id}/edit`}>
                  <Tooltip title="Edit Invoice">
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </RouteLink>
              </Permissioned>
              <Permissioned
                resource={resources.Invoices}
                action={actions.Delete}
              >
                <ConfirmButton
                  heading="Confirm"
                  message="Do you want to delete this invoice?"
                  component={IconButton}
                  onClick={() => {
                    dispatch(invoicesActions.deleteInvoice(invoice.id))
                      .then(() =>
                        enqueueSnackbar(`Invoice deleted`, {
                          variant: "success",
                        })
                      )
                      .then(() =>
                        dispatch(
                          invoicesActions.fetchInvoices(
                            {
                              startDate,
                              endDate,
                              keyword,
                            },
                            page,
                            LIMIT
                          )
                        )
                      )
                      .catch((err: any) => {
                        enqueueSnackbar(err.message, { variant: "error" });
                      });
                  }}
                >
                  <Tooltip title="Delete invoice">
                    <DeleteIcon />
                  </Tooltip>
                </ConfirmButton>
                <Tooltip title="View invoice">
                  <IconButton
                    onClick={() => {
                      setIsModalVisible(true);
                      setCurrentInvoice(invoice);
                    }}
                  >
                    <PreviewIcon />
                  </IconButton>
                </Tooltip>*/}
              {invoice.payment_status === 'pending' && invoice.details !== null ?
                <Tooltip title="Failed Payment Info">
                  <IconButton
                    onClick={() => {
                      setCurrentInvoice(invoice);
                      setIsFailPayModalVisible(true);
                    }}
                    size="large">
                    <InfoIcon />
                  </IconButton>
                </Tooltip> : null
              }
              <Tooltip title="View Invoice">
                <IconButton
                  onClick={() => {
                    setCurrentInvoice(invoice);
                    setIsModalVisible(true);
                  }}
                  size="large">
                  <PrintIcon />
                </IconButton>
              </Tooltip>
              {invoice.payment_status === 'pending' ?
                <Permissioned
                  resource={resources.Invoices}
                  action={actions.Update}
                >
                  <ConfirmButton
                    heading="Confirm"
                    message="Do you want to retry payment attempt?"
                    component={IconButton}
                    onClick={() => {
                      attemptStripePayment(invoice.id, invoice.customer.id)
                        .then(res => {
                          enqueueSnackbar(`Payment Successful`, {
                            variant: "success",
                          })
                        })
                        .then(() =>
                          dispatch(
                            invoicesActions.fetchInvoices(
                              {
                                startDate,
                                endDate,
                                keyword,
                              },
                              page,
                              LIMIT
                            )
                          )
                        )
                        .catch(err => {
                          enqueueSnackbar(`Payment Failure`, {
                            variant: "error",
                          })
                        })
                    }}
                  >
                    <Tooltip title="Retry Payment">
                      <PaymentIcon />
                    </Tooltip>
                  </ConfirmButton>
                </Permissioned> : 
                <Tooltip title="View Charge Receipt">
                  <IconButton
                    onClick={() => {
                      if (invoice.total === 0) {
                        enqueueSnackbar("No charge receipt for $0.00 balance", {variant: "error"});
                        return;
                      }
                      getStripeInvoicesById(invoice.payment_transaction_id)
                        .then(res => {
                          window.open(res.receipt_url, '_blank');
                        })
                        .catch(err => console.log(err.message))
                    }}
                    size="large">
                    <ReceiptIcon />
                  </IconButton>
                </Tooltip>
              }
              {invoice.payment_status === 'pending' ?
                <Permissioned
                  resource={resources.Invoices}
                  action={actions.Update}
                >
                  <Tooltip title="Update Payment Status to Paid">
                  <IconButton
                    onClick={() => {
                      setCurrentInvoice(invoice);
                      setIsUpdateModalVisible(true);
                    }}
                    size="large">
                    <PaymentsIcon />
                  </IconButton>
                </Tooltip>
                </Permissioned>
              : null}
              <Permissioned
                  resource={resources.Invoices}
                  action={actions.Update}
                >
                  <Tooltip title="Send Invoice Email">
                  <IconButton
                    onClick={() => {
                      setCurrentInvoice(invoice);
                      setIsEmailModalVisible(true);
                    }}
                    size="large">
                    <EmailIcon />
                  </IconButton>
                </Tooltip>
                </Permissioned>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
    <Pagination
      count={Math.ceil(count / LIMIT)}
      page={page}
      variant="outlined"
      shape="rounded"
      onChange={(e: React.ChangeEvent<unknown>, newPage: number) => {
        dispatch(
          invoicesActions.fetchInvoices(
            {
              startDate,
              endDate,
              keyword,
            },
            newPage,
            LIMIT
          )
        );
      }}
    />
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isEmailModalVisible}
      onClose={() => {
        setIsEmailModalVisible(false);
        setCurrentInvoice(null);
      }}
    >
      <DialogTitle>Send Invoice Email</DialogTitle>
      <DialogContent>
      <p>Send Email for Invoice</p>
        <div>
          <div style={{display:'flex', flexDirection:'row', justifyContent:'flexStart'}}>
            <span style={{fontWeight:600}}>Customer Name: </span>
            <span style={{marginLeft: '10px'}}>{currentInvoice?.customer?.organization ? currentInvoice?.customer?.organization.name : currentInvoice?.customer?.reseller.name}</span>
          </div>
          <div style={{display:'flex', flexDirection:'row', justifyContent:'flexStart'}}>
            <span style={{fontWeight:600}}>Invoice Dates:</span>
            <span style={{marginLeft: '28px'}}>{moment(currentInvoice?.invoice_date).startOf('month').format("DD-MMM-YYYY") + ' to ' + moment(currentInvoice?.invoice_date).format("DD-MMM-YYYY")}</span> 
          </div>
          <div style={{display:'flex', flexDirection:'row', justifyContent:'flexStart'}}>
            <span style={{fontWeight:600}}>Amount Owed:</span> 
            <span style={{marginLeft: '20px'}}>${currentInvoice?.total.toFixed(2)}</span>
          </div>
        </div>
        <Button
          color="primary"
          variant="contained"
          style={{margin:'25px 0', width:'150px'}}
          onClick={async () => {
            setIsEmailModalVisible(false);
            setIsProcessing(true);
            const result = await sendInvoiceEmail(currentInvoice?.id);
            enqueueSnackbar(result.data.message, {variant: result.data.success ? "success" : "error"});
            setIsProcessing(false);
        }}
        >
          Submit
        </Button>
        <Button color="secondary"
          style={{marginLeft:'15px', width:'150px'}}
          onClick={() => {
            setIsEmailModalVisible(false);
          }}>
            Cancel
        </Button>
      </DialogContent>
    </Dialog>

    <Dialog
      fullWidth
      maxWidth="md"
      open={isUpdateModalVisible}
      onClose={() => {
        setIsUpdateModalVisible(false);
        setCurrentInvoice(null);
      }}
    >
      <DialogTitle>Update Payment Status to Paid</DialogTitle>
      <DialogContent>
        <p>Invoice Details</p>
        <div>
          <div style={{display:'flex', flexDirection:'row', justifyContent:'flexStart'}}>
            <span style={{fontWeight:600}}>Customer Name: </span>
            <span style={{marginLeft: '10px'}}>{currentInvoice?.customer?.organization ? currentInvoice?.customer?.organization.name : currentInvoice?.customer?.reseller.name}</span>
          </div>
          <div style={{display:'flex', flexDirection:'row', justifyContent:'flexStart'}}>
            <span style={{fontWeight:600}}>Invoice Dates:</span>
            <span style={{marginLeft: '28px'}}>{moment(currentInvoice?.invoice_date).startOf('month').format("DD-MMM-YYYY") + ' to ' + moment(currentInvoice?.invoice_date).format("DD-MMM-YYYY")}</span> 
          </div>
          <div style={{display:'flex', flexDirection:'row', justifyContent:'flexStart'}}>
            <span style={{fontWeight:600}}>Amount Owed:</span> 
            <span style={{marginLeft: '20px'}}>${currentInvoice?.total.toFixed(2)}</span>
          </div>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl margin="normal" fullWidth required>
              <InputLabel variant="standard" id="payment-type">Payment Type</InputLabel>
              <Input
                style={{marginBottom: '15px'}}
                value={paymentType}
                type='text'
                placeholder='Payment Type'
                onChange={(e) => {
                  setPaymentType(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
          <FormControl margin="normal" fullWidth required>
              <InputLabel variant="standard" id="payment-type">Payment Transaction ID</InputLabel>
              <Input
                style={{marginBottom: '15px'}}
                value={paymentTxId}
                type='text'
                placeholder='Payment Transaction ID'
                onChange={(e) => {
                  setPaymentTxId(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={['year', 'month', 'day']}
                  inputFormat="dd-MMM-yyyy"
                  // style={{ marginBottom: 0 }}
                  label="Payment date"
                  value={updateDate}
                  onChange={(date: Date | null) => setUpdateDate(date)}
                  renderInput={(props) => <TextField variant="standard" margin="normal" {...props} />}
                />
              </LocalizationProvider>
            </Grid>
          <Grid item xs={8}>
            <FormControl margin="normal" fullWidth>
              <InputLabel variant="standard" id="notes">Notes</InputLabel>
              <Input
                value={notes}
                type='text'
                placeholder='Notes'
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
                />
            </FormControl>
          </Grid>
        </Grid>
        <Button
        color="primary"
        variant="contained"
          style={{margin:'25px 0', width:'150px'}}
          onClick={async () => {
            if (notes.length === 0 || paymentTxId.length === 0 || paymentType.length === 0) {
              enqueueSnackbar("Please fill out all values.", {variant: 'error'});
              return
            }
            setIsProcessing(true);
            const values: InvoicePaymentUpdateType = {
              payment_notes: notes,
              payment_update_date: updateDate,
              payment_transaction_id: paymentTxId,
              payment_type: paymentType,
              invoice_id: currentInvoice?.id || ''
            }
            try {
              const result = await updateInvoiceToPaid(values);
              enqueueSnackbar('Invoice updated', {variant:"success"})
              dispatch(
                invoicesActions.fetchInvoices(
                  { startDate, endDate, keyword },
                  page,
                  LIMIT
                )
              );
              resetPaymentValues()
              setIsUpdateModalVisible(false);
              setIsProcessing(false);
            } catch(err: any) {
              enqueueSnackbar(err.message, {variant: "error"});
              setIsProcessing(false);
            }
          }}
        >
          Submit
        </Button>
        <Button color="secondary"
          style={{marginLeft:'15px', width:'150px'}}
          onClick={() => {
            setIsUpdateModalVisible(false);
            resetPaymentValues();
          }}>
            Cancel
        </Button>
      </DialogContent>
    </Dialog>

    <Dialog
      fullWidth
      maxWidth="md"
      open={isModalVisible}
      onClose={() => {
        setIsModalVisible(false);
        setCurrentInvoice(null);
      }}
    >
      <DialogTitle>Invoice</DialogTitle>
      <DialogContent>
        <Tooltip title="Print invoice">
          <IconButton onClick={() => printInvoice(currentInvoiceHtml)} size="large">
            <PrintIcon />
          </IconButton>
        </Tooltip>
        <div dangerouslySetInnerHTML={{ __html: currentInvoiceHtml }} />
      </DialogContent>
    </Dialog>

    <Dialog
      fullWidth
      maxWidth="sm"
      open={isFailPayModalVisible}
      onClose={() => {
        setIsFailPayModalVisible(false);
        setCurrentInvoice(null);
      }}
    >
      <DialogTitle>Failed Payment Info</DialogTitle>
      <DialogContent>
        <div style={{ fontWeight: 700 }}>
          {currentInvoice?.details !== null ?
            <div>
              <p>{currentInvoice?.details?.failed_payment_details.message}</p>
              {currentInvoice?.details?.failed_payment_details.doc_url ?
                <a href={currentInvoice?.details?.failed_payment_details.doc_url}>Click Here for more details on this error</a> : null
              }
              {currentInvoice?.details?.failed_payment_details.message === 'No Payment method on file' ?
                <p>Customer must login to Swarm Dashboard in order to update payment methods</p> : null}
              {currentInvoice?.details?.failed_payment_details.statusCode ?
                <p>Status Code: {currentInvoice?.details?.failed_payment_details.statusCode}</p> : null
              }
              {currentInvoice?.details?.failed_payment_details.rawType ?
                <p>Raw Type: {currentInvoice?.details?.failed_payment_details.rawType}</p> : null
              }
              {currentInvoice?.details?.failed_payment_details.decline_code ?
                <p>Decline Code: {currentInvoice?.details?.failed_payment_details.decline_code}</p> : null
              }
            </div> : null
          }
        </div>
      </DialogContent>
    </Dialog>
  </>;
}

function EditInvoiceFormModal(){
  const { enqueueSnackbar } = useSnackbar();
  const invoicesPageReducer = useSelector<Reducers, InvoicesReducer>(
    (state) => state.invoices
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { invoiceId } = useParams();
  const invoice = invoicesPageReducer.invoices.find(
    (i) => i.id === invoiceId
  );
  if (!invoice) {
    return <Navigate to="/admin/invoices" />;
  }
  return (
    <Dialog open onClose={() => navigate(-1)}>
      <DialogTitle>Edit Invoice</DialogTitle>
      <DialogContent>
        <EditInvoiceForm
          values={invoice}
          onSubmit={(values: any) => {
            Promise.resolve()
              .then(() =>
                dispatch(
                  invoicesActions.updateInvoice(invoiceId, values)
                )
              )
              .then(() =>
                dispatch(
                  invoicesActions.fetchInvoices(
                    {
                      startDate: invoicesPageReducer.startDate,
                      endDate: invoicesPageReducer.endDate,
                      keyword: invoicesPageReducer.keyword,
                    },
                    invoicesPageReducer.page,
                    LIMIT
                  )
                )
              )
              .then(() => navigate(-1))
              .then(() =>
                enqueueSnackbar("Invoice updated", {
                  variant: "success",
                })
              )
              .catch((err: CustomError) =>
                enqueueSnackbar(err.message, { variant: "error" })
              );
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

export default function Invoices() {
  const [isProcessing, setIsProcessing] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [fetched, setFetched] = useState(false);
  const [generateDialog, setGenerateDialog] = useState(false)

  const invoicesPageReducer = useSelector<Reducers, InvoicesReducer>(
    (state) => state.invoices
  );

  const [keyword, setKeyword] = React.useState("");
  const [paymentStatus, setPaymentStatus] = React.useState("All");

  const [startDate, setStartDate] = React.useState<Date | null>(
    moment().startOf("month").subtract(1, "month").toDate()
  );
  const [endDate, setEndDate] = React.useState<Date | null>();

  useEffect(() => {
    setStartDate(invoicesPageReducer.startDate);
  }, [invoicesPageReducer.startDate]);

  useEffect(() => {
    setEndDate(invoicesPageReducer.endDate);
  }, [invoicesPageReducer.endDate]);

  return (
    <React.Fragment>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          color: "#fff"
        }}
        color="#fff"
        open={isProcessing}
        onClick={() => setIsProcessing(false)}
      >
        <div style={{ position: 'relative' }}>
          <h3 style={{ position: 'absolute', top: '50px', left: "-70px", whiteSpace: "nowrap" }}>Generating Invoices</h3>
          <CircularProgress />
        </div>
      </Backdrop>
      <Typography component="h1" variant="h5">
        Invoices
      </Typography>
      <div style={{
        display: "flex",
        flexDirection: "row",
      }}>
      </div>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={8}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={['year', 'month', 'day']}
                  inputFormat="dd-MMM-yyyy"
                  // style={{ marginBottom: 0 }}
                  label="Start date"
                  value={startDate}
                  onChange={(date: Date | null) => setStartDate(date)}
                  renderInput={(props) => <TextField variant="standard" margin="normal" {...props} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={['year', 'month', 'day']}
                  inputFormat="dd-MMM-yyyy"
                  // style={{ marginBottom: 0 }}
                  label="End date"
                  value={endDate}
                  onChange={(date: Date | null) => setEndDate(date)}
                  renderInput={(props) => <TextField variant="standard" margin="normal" {...props} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
              <FormControl>
                <TextField
                  margin="normal"
                  size="small"
                  label="Customer name"
                  variant="outlined"
                  defaultValue={invoicesPageReducer.keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key !== "Enter") {
                      return;
                    }
                    dispatch(
                      invoicesActions.fetchInvoices(
                        { startDate, endDate, keyword },
                        1,
                        LIMIT
                      )
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item  xs={2}>
              <FormControl fullWidth>
                <InputLabel id="status">Payment Status</InputLabel>
                <Select
                  fullWidth
                  id="status"
                  variant="standard"
                  style={{ minWidth: "100px" }}
                  value={paymentStatus}
                  onChange={(e: any) => {
                    setPaymentStatus(e.target.value)
                  }} 
                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="Paid">Paid</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} direction="column" style={{ marginRight: "20px" }}>
              <FormControl margin="normal">
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={() => {
                    if(paymentStatus == "All"){
                      dispatch(
                        invoicesActions.fetchInvoices(
                          { startDate, endDate, keyword },
                          1,
                          LIMIT
                        )
                      );
                    }else{
                      dispatch(
                        invoicesActions.fetchInvoices(
                          { startDate, endDate, keyword, status: paymentStatus },
                          1,
                          LIMIT
                        )
                      );
                    }
                  }}
                >
                  Search
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} direction="column" style={{marginLeft:'auto'}}>
              <FormControl margin="normal">
                <Tooltip title="Generate Past Invoices">
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={async () => {
                      if ((moment().month() <= moment(startDate).month()) && (moment().year() < moment(startDate).year())) {
                        enqueueSnackbar("Can not generate invoices, incorrect start date for billing period", { variant: "error" });
                        return;
                      } else if (moment(endDate).month() < moment(startDate).month() && (moment().year() < moment(startDate).year())) {
                        enqueueSnackbar("Can not generate invoices, end date must be after start date.", { variant: "error" });
                        return;
                      }
                      setGenerateDialog(true)
                    }}
                  >
                    Generate
                  </Button>
                </Tooltip>
              </FormControl>
            </Grid>
      </Grid>
      <Routes>
        <Route path="/:invoiceId/edit" element={<EditInvoiceFormModal />}/>
      </Routes>
      <InvoiceList />

      <Dialog fullWidth
        open={generateDialog}>
          <DialogTitle>Generate Invoice</DialogTitle>
          <DialogContent>
            <div>{`Do you want to generate invoice for the period ${moment(startDate).format('DD-MMM-YYYY')}
            to ${moment(endDate).format('DD-MMM-YYYY')} ${keyword.length == 0 ? '' : `for ${keyword}`}?`}</div>
            <div style={{display:'flex', justifyContent:'flex-end', marginTop:'20px'}}>
                  <Button
                      style={{ width: 100 }}
                      color="primary"
                      variant="contained"
                      onClick={async () => {  
                        let customers = []
                        setGenerateDialog(false)
                        setIsProcessing(true);
                        if (keyword.length === 0) {
                          await generatePastInvoices(startDate, endDate)
                            .then(res => {
                              if(paymentStatus == "All"){
                                dispatch(
                                  invoicesActions.fetchInvoices(
                                    { startDate, endDate, keyword },
                                    1,
                                    LIMIT
                                  )
                                )
                              }else{
                                dispatch(
                                  invoicesActions.fetchInvoices(
                                    { startDate, endDate, keyword, paymentStatus },
                                    1,
                                    LIMIT
                                  )
                                )
                              }
                              
                              setIsProcessing(false);
                              enqueueSnackbar("Invoices Generated", { variant: "success" })
                            })
                            .catch((err) => {
                              if (err.response.status === 504) {
                                enqueueSnackbar("Connection timed out: invoices still processing, please refresh data in a few minutes", { variant: "success" })
                                setIsProcessing(false)
                                if(paymentStatus == "All"){
                                  dispatch(
                                    invoicesActions.fetchInvoices(
                                      { startDate, endDate, keyword },
                                      1,
                                      LIMIT
                                    )
                                  )
                                }else{
                                  dispatch(
                                    invoicesActions.fetchInvoices(
                                      { startDate, endDate, keyword, paymentStatus },
                                      1,
                                      LIMIT
                                    )
                                  )
                                }
                              } else {
                                setIsProcessing(false);
                                enqueueSnackbar(err.message, { variant: "error" })
                              }
                            })
                        } else {
                          fetchCustomers({ keyword: keyword }, 1, 0)
                            .then(async (result) => {
                              customers = result.list
                              return await generatePastInvoices(startDate, endDate, result.list)
                            }).then((res) => {
                              if(paymentStatus == "All"){
                                dispatch(
                                  invoicesActions.fetchInvoices(
                                    { startDate, endDate, keyword },
                                    1,
                                    LIMIT
                                  )
                                )
                              }else{
                                dispatch(
                                  invoicesActions.fetchInvoices(
                                    { startDate, endDate, keyword, paymentStatus },
                                    1,
                                    LIMIT
                                  )
                                )
                              }
                              setIsProcessing(false);
                              enqueueSnackbar("Invoices Generated", { variant: "success" })
                            })
                            .catch((err) => {
                              if (err.response.status === 504) {
                                enqueueSnackbar("Connection timed out: invoices still processing, please refresh data in a few minutes", { variant: "success" })
                                setIsProcessing(false)
                                dispatch(
                                  invoicesActions.fetchInvoices(
                                    { startDate, endDate, keyword },
                                    1,
                                    LIMIT
                                  )
                                )
                              } else {
                                setIsProcessing(false);
                                enqueueSnackbar(err.message, { variant: "error" })
                              }
                            })
                        }
                      }}
                    >Yes</Button>
                    <Button
                      style={{ width: 100, marginLeft:'20px' }}
                      color="primary"
                      variant="contained"
                      onClick={() => {  
                        setGenerateDialog(false)
                      }}
                    >No</Button>
            </div>
          </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
