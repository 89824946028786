import { print } from "graphql";
import axios from "../axios-client";
import gql from "graphql-tag";
import { parseErrors } from "./helpers";
import { GraphqlError, AuditLogType, TableType } from "./interfaces";
import { env } from '../env';

const apiUrl: string = env.REACT_APP_COMMON_API_URL;

export async function fetchAuditLogs(
  filters: { table_name: string; organization_id: string; action: string; row_id?: string },
  limit: number,
  page: number
): Promise<{ list: Array<AuditLogType>; count: number }> {
  const organization_id = filters.organization_id?.length > 0 ? filters.organization_id : null;
  const table_name = filters.table_name?.length > 0 ? filters.table_name : null;
  const action = filters.action?.length > 0 ? filters.action : null;
  const query = gql`
    query getAuditList(
      $table_name: String
      $organization_id: String
      $action: String
      $row_id: String
      $limit: Int!
      $page: Int!
    ) {
      autnhiveAuditList(
        table_name: $table_name
        organization_id: $organization_id
        action: $action
        row_id: $row_id
        limit: $limit
        page: $page
      ) {
        list {
          id
          row_id
          action
          table_name
          is_deleted
          timestamp
          fields_changed {
            op
            value
            path
          }
          user {
            id
            first_name
            last_name
          }
        }
        count
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<AuditLogsResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: {
        table_name: table_name,
        organization_id: organization_id,
        action: action,
        row_id: filters.row_id,
        limit,
        page,
      },
    },{
      headers: {
        "timestamp": `${timeStamp}`,
      }
    });
    return data.data.autnhiveAuditList;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    } else {
      const graphqlError: GraphqlError = { message: err.message };
      throw parseErrors([graphqlError]);
    }
  }
}

export async function fetchTables(): Promise<Array<TableType>> {
  const query = gql`
    query getTables {
      getTables {
        name
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<TablesResponse>(`${apiUrl}/graphql`, {
      query: print(query),
    },{
      headers: {
        "timestamp": `${timeStamp}`,
      }
    });
    return data.data.getTables.filter(
      (row) => ["audit", "resources"].indexOf(row.name) === -1
    );
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    } else {
      const graphqlError: GraphqlError = { message: err.message };
      throw parseErrors([graphqlError]);
    }
  }
}

interface AuditLogsResponse {
  data: {
    autnhiveAuditList: {
      list: [AuditLogType];
      count: number;
    };
  };
}

interface TablesResponse {
  data: {
    getTables: [TableType];
  };
}
