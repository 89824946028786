import {
  CreateOrganizationOnboardingInput,
  ResellerInput,
} from "../../services/interfaces";
import * as resellersService from "../../services/autnhive/resellers";
import * as subscriptionsService from "../../services/orgLevel/productSubscriptions";

export const resellersActions: any = {
  resellersWillLoad: "RESELLERS_WILL_LOAD",
  resellersLoadSucceeded: "RESELLERS_LOAD_SUCCEEDED",
  resellersLoadFailed: "RESELLERS_LOAD_FAILED",
  updateReseller: "UPDATE_RESELLER",
  updateResellerSucceeded: "UPDATE_RESELLER_SUCCEEDED",
  updateResellerFailed: "UPDATE_RESELLER_FAILED",
  updateSearchKeyword: "UPDATE_RESELLER_SEARCH_KEYWORD",
  createOrganization: "CREATE_ORGANIZTION_ONBOARDING",
  createOrganizationSucceeded: "CREATE_ORGANIZTION_ONBOARDING_SUCCEEDED",
  createOrganizationFailed: "CREATE_ORGANIZTION_ONBOARDING_FAILED",
  contractsWillLoad: "ORGANIZATION_ONBOARDING_CONTRACTS_WILL_LOAD",
  contractsLoadSucceeded: "ORGANIZATION_ONBOARDING_CONTRACTS_LOAD_SUCCEEDED",
  contractsLoadFailed: "ORGANIZATION_ONBOARDING_CONTRACTS_LOAD_FAILED",
  resetStates: "RESELLERS_RESET_STATES",
  resellerOrgsFetchSuccess: "RESELLER_ORGS_FETCH_SUCCESS",
  resellerOrgsFetchFail: "RESELLER_ORGS_FETCH_FAIL",
  resellerOrgsToggle: "RESELLER_ORGS_TOGGLE"
};

export const fetchResellers =
  (filters: { keyword?: string }, limit: number, page: number) =>
  async (dispatch: any) => {
    dispatch({
      type: resellersActions.resellersWillLoad,
      payload: page,
    });
    try {
      dispatch({
        type: resellersActions.resellersLoadSucceeded,
        payload: await resellersService.fetchResellers(filters, page, limit),
      });
    } catch (err) {
      dispatch({
        type: resellersActions.resellersLoadFailed,
        payload: err,
      });
    }
  };

export const updateReseller =
  (id: string, values: ResellerInput) => async (dispatch: any) => {
    dispatch({ type: resellersActions.updateReseller });
    try {
      dispatch({
        type: resellersActions.updateResellerSucceeded,
        payload: await resellersService.updateReseller(id, values),
      });
    } catch (e) {
      dispatch({
        type: resellersActions.updateResellerFailed,
        payload: e,
      });
      throw e;
    }
  };

export const createOrganizationOnboarding =
  (values: CreateOrganizationOnboardingInput) => async (dispatch: any) => {
    dispatch({ type: resellersActions.createOrganization });
    try {
      dispatch({
        type: resellersActions.createOrganizationSucceeded,
        payload: await resellersService.createOrganizationOnboarding(values),
      });
    } catch (e) {
      dispatch({
        type: resellersActions.createOrganizationFailed,
        payload: e,
      });
      throw e;
    }
  };

export const fetchContracts =
  (reseller_id: string, check_is_approved?: boolean) => async (dispatch: any) => {
    dispatch({ type: resellersActions.contractsWillLoad });
    try {
      dispatch({
        type: resellersActions.contractsLoadSucceeded,
        payload: await subscriptionsService.fetchContracts({ reseller_id, check_is_approved }),
      });
    } catch (e) {
      dispatch({
        type: resellersActions.contractsLoadFailed,
        payload: e,
      });
    }
  };
export const fetchResellerOrgs = (reseller_id: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: resellersActions.resellerOrgsFetchSuccess,
      payload: await resellersService.fetchResellersOrganizations( reseller_id ),
    });
  }catch (err:any) {
    dispatch({
      type: resellersActions.resellerOrgFetchFail,
      payload: reseller_id,
    });
  }
}

export const toggleHideOrgs = (reseller_id: string) => async (dispatch: any) => {
  dispatch({
    type: resellersActions.resellerOrgsToggle,
    payload: reseller_id
  })
}

export const updateSearchKeyword =
  (keyword: string) => async (dispatch: any) => {
    dispatch({
      type: resellersActions.updateSearchKeyword,
      payload: keyword,
    });
  };

export const resetStates = () => ({
  type: resellersActions.resetStates,
});
