/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import PlaylistAddOutlined from "@mui/icons-material/PlaylistAddOutlined";
import AddCircleOutlineOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import {
  Tooltip,
  Input,
  IconButton,
  Grid,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  List,
  ListItem,
  ListItemSecondaryAction,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Divider,
  CircularProgress,
  Backdrop,
  Popper,
  Paper,
  Switch,
  Stack,
} from "@mui/material";
import dayjs, { Dayjs } from 'dayjs';
import FormControlLabel from '@mui/material/FormControlLabel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import moment from "moment";
import ViewIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import ApproveIcon from "@mui/icons-material/Check";
import ExtendIcon from "@mui/icons-material/PlusOne";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AgreedIcon from "@mui/icons-material/CheckCircle";
import ContactSupport from "@mui/icons-material/ContactSupport";
import RejectIcon from "@mui/icons-material/Block";
import ContractIcon from "@mui/icons-material/Description";
import { Pagination, Autocomplete } from '@mui/material';
import {
  Routes,
  Route,
  Link as RouteLink,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useSnackbar } from "notistack";
import Permissioned, { actions, resources } from "../../permissioned";
import ConfirmButton from "../../components/confirmButton";
import PhoneField from "../../components/phoneField";
import {
  NewCustomerContractInput,
  CustomError,
  ProductType,
  AutnhiveContractType,
  CustomerType,
  AutnhiveContractCustomerDraftInput,
  AutnhiveContractCustomerUserDraftInput,
  PrepaidInput,
  PartnerType,
  RenewCustomerContractInput,
  PackageType,
  FeatureType,
} from "../../services/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { createTheme } from '@mui/system';
import {
  CreateNewCustomerContractReducer,
  ContractsPageReducer,
  UpdateNewCustomerContractReducer,
  RenewCustomerContractReducer,
} from "../../reducers/autnhive";
import { GlobalReducer, Reducers } from "../../reducers";
import * as contractActions from "../../actions/autnhive/contracts";
import SubmitButton from "../../components/SubmitButton";
import * as userProfileActions from "../../actions/userProfile"
import { fetchCustomers } from "../../services/autnhive/customers";
import {
  fetchPartners,
  fetchInputOptions,
} from "../../services/autnhive/contracts";
import { DATE_FORMAT } from "../../constants";
import TagsInput from "../../components/tagInput";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { env } from '../../env';
import CustomDatePicker from "../../components/customDatePicker";
import { offset } from "../../services/dates";

const notAllowedCharacterForEmail = /[`!#$%^&*~()+=\[\]{};:"\\|',<>\/?]+/;

const LIMIT = 20;

function isValidDate(dateString: string | undefined) {
  if (dateString === undefined) return false;
  return !isNaN(Date.parse(dateString));
}

interface NewCustomerContractFormProps {
  id: string;
  variant: "view" | "edit";
  skipCustomerSearch?: boolean;
  isRenewalContract: boolean;
  isInitialEditable?:boolean;
  error?: CustomError;
  loading: boolean;
  onSubmit: (values: NewCustomerContractInput, id: string) => void;
  values?: NewCustomerContractInput;
  products: Array<ProductType>;
  packages: Array<PackageType>;
  features: Array<FeatureType>;
  salesPeople: Array<{ id: string; first_name: string; last_name: string }>;
  approvers: Array<{ id: string; first_name: string; last_name: string }>;
  disableFields?: Array<string>;
  disabled?: boolean;
}

interface RenewContractFormProps {
  error?: CustomError;
  loading: boolean;
  onSubmit: (values: RenewCustomerContractInput) => void;
  values?: RenewCustomerContractInput;
  prod?: {
    product_id:string;
    product_name:string;
  };
}
interface ChipData {
  key: number;
  label: string;
}

function ContractsList() {
  const [isProcessing, setIsProcessing] = useState(false);
  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();
  const contracts = useSelector<Reducers, ContractsPageReducer>(
    (state) => state.autnhive.contractsPage
  );
  const contractsPage = useSelector<Reducers, ContractsPageReducer>(
    (state) => state.autnhive.contractsPage
  );
  useEffect(() => {
    dispatch(
      contractActions.fetchContracts(
        {
          customer_name: contractsPage.customer_name,
          status: contractsPage.status,
          sort: contractsPage.sort_by
        },
        1,
        LIMIT
      )
    );
  }, []);
  const gracePeriod = env.GRACE_PERIOD_FOR_CONTRACT_EXTENSION;
  function enableExtendContract(contract: AutnhiveContractType) {
    if (contract.is_editable && contract.is_active && moment(new Date()) > moment(contract.contract_end_date).endOf("day")) {
      return true;
      // } else if (moment(new Date()) < moment(contract.contract_end_date) && moment(new Date()) > moment(contract.contract_end_date).subtract(gracePeriod, "day")) {
      //   return true;
    } else {
      return false;
    }
  }
  const now = moment();
  return <>
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        color: "#fff",
      }}
      open={isProcessing}
      onClick={() => setIsProcessing(false)}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    <List>
      {contracts.contracts.map((contract: AutnhiveContractType, index) => {
        let name = contract.draft_details?.customer?.name;
        const expiryDate = moment(contract.contract_end_date);
        const warnDate = moment(contract.contract_end_date).subtract(30, 'days');
        const color = now.isBefore(expiryDate) && now.isAfter(warnDate) ? '#FFBF00' : 
        now.isBefore(expiryDate) ? '#4BB543' : 
        '#FF0000';
        if (contract.customer) {
          name =
            contract.customer.organization?.name ||
            contract.customer.reseller?.name;
        }
        if (contract.status === "approved") {
          name =
            contract.customer?.organization?.name ||
            contract.customer?.reseller?.name;
        }
        return (
          <ListItem
            key={contract.id}
            sx={{
              backgroundColor: index % 2 === 0 ? "#f1f1f1" : "inherit"
            }}
          >
            <div>
              <h3>
                {name}
                {contract.status === "approved" ? (
                  <Tooltip title="Contract approved">
                    <CheckCircleOutlineIcon
                      fontSize="small"
                      htmlColor="green"
                    />
                  </Tooltip>
                ) : null}
                {contract.status === "rejected" ? (
                  <Tooltip title="Contract rejected">
                    <RejectIcon fontSize="small" htmlColor="red" />
                  </Tooltip>
                ) : null}
                {contract.status === "approved" &&
                  (contract.terms_agreed_by || contract.skip_user_terms) ? (
                  <Tooltip title="User accepted terms">
                    <AgreedIcon fontSize="small" htmlColor="green" />
                  </Tooltip>
                ) : null}
                {contract.status === "approved" &&
                  (!contract.terms_agreed_by && !contract.skip_user_terms) ? (
                  <Tooltip title="User terms acceptance pending">
                    <ContactSupport fontSize="small" htmlColor="red" />
                  </Tooltip>
                ) : null}
                {contract.is_self_signup ? (
                  <Tooltip title="Self Signup Contract">
                    <AssignmentIndIcon fontSize="small" htmlColor="gray" />
                  </Tooltip>
                ) : null}
              </h3>
              <span style={{color: color, fontWeight: 600}}>
                {moment.utc(contract.contract_start_date)
                  .format("YYYY-MM-DD")}
                {" - "}
                {moment.utc(expiryDate.toDate())
                  .format("YYYY-MM-DD")}
              </span>
              <br />
              <span>{contract.product.name}</span>
              {" - "}
              <span>
                {contract.customer
                  ? contract.customer?.type
                  : contract.draft_details?.customer?.customer_type}
              </span>
              <br />
              {contract.status === 'approved' ?
                <span>
                  {contract.approved_by
                    ? `
                      Approved by ${contract.approved_by.first_name} ${contract.approved_by.last_name
                    }
                      on ${moment(contract.approved_date).format("YYYY-MM-DD")}
                    `
                    : null}
                  <br />
                  {contract.is_physical_contract ? 
                      `
                          Terms agreed by ${contract.physical_contract_details?.accepted_by}
                          on ${moment(contract.terms_agreed_on).format(
                          "YYYY-MM-DD"
                        )}`
                    :
                    <>
                      {contract.terms_agreed_by
                        ? `
                          Terms agreed by ${contract.terms_agreed_by.first_name} ${contract.terms_agreed_by.last_name
                        }
                          on ${moment(contract.terms_agreed_on).format(
                          "YYYY-MM-DD"
                        )}
                        `
                        : contract.skip_user_terms ? 'Terms agreed - Not applicable' 
                        : null}
                    </>
                  }
                </span>
              : null}
            </div>
            <ListItemSecondaryAction>
              {contract.status === "draft" ? (
                <Permissioned
                  resource={resources.AutnhiveContracts}
                  action={actions.ApproveReject}
                >
                  <ConfirmButton
                    heading="Confirm"
                    message="Do you want to approve this contract?"
                    component={IconButton}
                    onClick={() => {
                      setIsProcessing(true);
                      dispatch(
                        contractActions.approveRejectNewCustomerContract(
                          contract.id,
                          "approve"
                        )
                      )
                        .then(() => {
                          enqueueSnackbar(`Contract approved`, {
                            variant: "success",
                          });
                        })
                        .then(() => {
                          dispatch(
                            contractActions.fetchContracts(
                              {
                                customer_name: contractsPage.customer_name,
                                status: contractsPage.status,
                                sort: contractsPage.sort_by
                              },
                              contractsPage.page,
                              LIMIT
                            )
                          );
                        })
                        .catch((err: any) => {
                          enqueueSnackbar(err.message, {
                            variant: "error",
                          });
                        })
                        .finally(() => setIsProcessing(false));
                    }}
                  >
                    <Tooltip title="Approve contract">
                      <ApproveIcon />
                    </Tooltip>
                  </ConfirmButton>
                  <ConfirmButton
                    heading="Reject"
                    message="Do you want to reject this contract?"
                    component={IconButton}
                    onClick={() => {
                      setIsProcessing(true);
                      dispatch(
                        contractActions.approveRejectNewCustomerContract(
                          contract.id,
                          "reject"
                        )
                      )
                        .then(() => {
                          enqueueSnackbar(`Contract rejected`, {
                            variant: "success",
                          });
                        })
                        .then(() => {
                          dispatch(
                            contractActions.fetchContracts(
                              {
                                customer_name: contractsPage.customer_name,
                                status: contractsPage.status,
                                sort: contractsPage.sort_by
                              },
                              contractsPage.page,
                              LIMIT
                            )
                          );
                        })
                        .catch((err: any) => {
                          enqueueSnackbar(err.message, {
                            variant: "error",
                          });
                        })
                        .finally(() => setIsProcessing(false));
                    }}
                  >
                    <Tooltip title="Reject contract">
                      <RejectIcon />
                    </Tooltip>
                  </ConfirmButton>
                </Permissioned>
              ) : null}

              {contract.is_editable && !contract.is_physical_contract ? <>
                {
                  moment(new Date()).endOf("day").add(gracePeriod, "day").isBefore(moment(contract.contract_end_date).endOf("day")) ?
                    <Permissioned
                      resource={resources.AutnhiveContracts}
                      action={actions.Update}
                    >
                      <RouteLink
                        to={`/admin/autnhive/contracts/${contract.id}/edit`}
                      >
                        <Tooltip title="Edit contract">
                          <IconButton aria-label="Edit contract" size="large">
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </RouteLink>
                    </Permissioned> : null
                }
              </> : null}

              {contract.is_editable && contract.is_physical_contract && contract.status != "approved" ? <>
                {
                  moment(new Date()).endOf("day").add(gracePeriod, "day").isBefore(moment(contract.contract_end_date).endOf("day")) ?
                    <Permissioned
                      resource={resources.AutnhiveContracts}
                      action={actions.Update}
                    >
                      <RouteLink
                        to={`/admin/autnhive/contracts/${contract.id}/edit`}
                      >
                        <Tooltip title="Edit contract">
                          <IconButton aria-label="Edit contract" size="large">
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </RouteLink>
                    </Permissioned> : null
                }
              </> : null}

              {contract.status === "approved" ? (
                <>
                  {enableExtendContract(contract) ? <Permissioned
                    resource={resources.AutnhiveContracts}
                    action={actions.Create}
                  >
                    <RouteLink
                      to={`/admin/autnhive/contracts/${contract.id}/renew`}
                    >
                      <Tooltip title="Extend contract?">
                        <IconButton aria-label="Extend contract" size="large">
                          <ExtendIcon /><span style={{marginBottom: '5px',marginLeft: '-5px'}} ><QuestionMarkIcon fontSize='small'/></span>
                        </IconButton>
                      </Tooltip>
                    </RouteLink>
                  </Permissioned> : null}
                  <RouteLink
                    to={`/admin/autnhive/contracts/${contract.id}/view`}
                  >
                    <Tooltip title="View contract">
                      <IconButton aria-label="View contract" size="large">
                        <ViewIcon />
                      </IconButton>
                    </Tooltip>
                  </RouteLink>
                </>
              ) : null}
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
    <Pagination
      count={Math.ceil(contracts.count / LIMIT)}
      page={contracts.page}
      variant="outlined"
      shape="rounded"
      onChange={(e: React.ChangeEvent<unknown>, page: number) => {
        dispatch(
          contractActions.fetchContracts(
            {
              customer_name: contractsPage.customer_name,
              status: contractsPage.status,
              sort: contractsPage.sort_by
            },
            page,
            LIMIT
          )
        );
      }}
    />
  </>;
}

function RenewCustomerContract({
  onSuccess,
}: {
  onSuccess: (contract: AutnhiveContractType) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const contractsPage = useSelector<Reducers, ContractsPageReducer>(
    (state) => state.autnhive.contractsPage
  );
  const renewContract = useSelector<Reducers, RenewCustomerContractReducer>(
    (state) => state.autnhive.renewCustomerContract
  );
  interface Params {
    id: string
  }
  const { id } = useParams<keyof Params>() as Params;
  const contract = contractsPage.contracts.find((c) => c.id === id);
  if (!contract) {
    return null;
  }

  const values: RenewCustomerContractInput = {
    sales_code: contract.sales_code,
    currency: contract.currency,
    taxes: contract.taxes,
    sales_person: contract.sales_person,
    partner: contract.partner,
    package_code: contract.package_code,
    package_features: contract.package_features,
    user_license_count: contract.user_license_count,
    device_license_count: contract.device_license_count,
    contract: contract,
    contract_start_date: moment(contract.contract_end_date)
      .add(1, "day")
      .toDate(),
    contract_end_date: moment()
      .add(11, "months").endOf('month')
      .toDate(),
    // product_id: contract.product.id,
    // product_name: contract.product.name
  };

  const prod = {
    product_id: contract.product.id,
    product_name: contract.product.name
  }
  return (
    <Dialog open onClose={() => navigate(-1)}>
      <DialogTitle>Renew Contract</DialogTitle>
      <DialogContent>
        <p>
          {contract.customer?.organization?.name ||
            contract.customer?.reseller?.name}
        </p>
        <RenewContractForm
          values={values}
          prod={prod}
          loading={renewContract.loading}
          error={renewContract.error}
          onSubmit={(values) => {
            dispatch(contractActions.renewContract(id, values))
              .then(() =>
                enqueueSnackbar("Contract renewal created", {
                  variant: "success",
                })
              )
              .then(onSuccess)
              .catch((err: any) =>
                enqueueSnackbar(err.message, { variant: "error" })
              );
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

function RenewContractForm(props: RenewContractFormProps) {
  const { values } = props;
  const [sales_code, setSalesCode] = useState(0.00);
  const [currency, setCurrency] = useState<"usd" | "cad" | "gbp" | "">("");
  const [featureCurrency, setFeatureCurrency] = useState<"usd" | "cad" | "gbp">("usd");
  let taxDescriptionRef: HTMLInputElement;
  let taxRateRef: HTMLInputElement;
  const [deviceLicenseCount, setDeviceLicenseCount] = useState(100);
  const [userLicenseCount, setUserLicenseCount] = useState(10);
  const [isDisabled, setIsDisabled] = useState(false);
  let deviceLicenseCountRef: HTMLInputElement;
  let userLicenseCountRef: HTMLInputElement;
  const [salesPeople, setSalesPeople] = useState<
    Array<{ id: string; first_name: string; last_name: string }>
  >([]);
  const [approvers, setApprovers] = useState<
    Array<{ id: string; first_name: string; last_name: string }>
  >([]);
  const [packageNames, setPackageNames] = useState<Array<PackageType>>([]);
  const [featuresList, setFeaturesList] = useState<Array<FeatureType>>([]);
  const [existing_product_id, setExistingProductId] = useState("");
  const [package_name, setPackageName] = useState("");
  const [package_code, setPackageCode] = useState("");
  const [package_prioriy, setPackagePriority] = useState(0);
  const [sales_person, setSalesPerson] = useState("");
  const [contract_start_date, setContractStartDate] = useState<Date | null>(
    moment().toDate()
  );
  const [contract_end_date, setContractEndDate] = useState<Date>(
    moment().toDate()
  );
  const [selectedPartner, setSelectedPartner] = useState<PartnerType | null>(
    null
  );
  const [partnerInput, setPartnerInput] = useState<string>("");
  const [partners, setPartners] = useState<Array<PartnerType>>([]);
  const [isDiscountActive, setIsDiscountActive] = useState<boolean>(false);
  const [discountType, setDiscountType] = useState<
    "" | "percentage" | "fixed" | null
  >("");
  const [discountDescription, setDiscountDescription] = useState<string | null>(
    null
  );
  const [discountAmount, setDiscountAmount] = useState<number | null>(null);
  const [discount_start, setDiscountStart] = useState<Date | null>(null);
  const [discount_end, setDiscountEnd] = useState<Date | null>(null);
  const [addedFeatures, setAddedFeatures] = useState<Array<string>>([])
  const [product_id, setProductId] = useState("");
  const [product_name, setProductName] = useState("");
  const [featureDialogOpen, setFeatureDialogOpen] = useState(false);

  const createContract = useSelector<
    Reducers,
    CreateNewCustomerContractReducer
  >((state) => state.autnhive.createNewCustomerContract);

  useEffect(() => {
    fetchInputOptions()
      .then(({ salesPeople, packages, features, approvers }) => {
        setSalesPeople(salesPeople);
        setPackageNames(packages);
        setFeaturesList(features);
        setApprovers(approvers);
      })
      .catch((err) => {
        setSalesPeople([]);
        setPackageNames([]);
        setFeaturesList([]);
        setApprovers([]);
      });
  }, []);
  useEffect(() => {
    setProductId(props.prod?.product_id || "");
    setProductName(props.prod?.product_name || "")
    
    if (values?.partner) {
      setSelectedPartner(values.partner);
    }
    if (values?.contract_start_date) {
      setContractStartDate(offset(moment(values.contract_start_date).toDate()));
    }
    if (values?.contract_end_date) {
      setContractEndDate(offset(moment(values.contract_end_date).toDate()));
    }
    
    if (values?.sales_code) {
      setSalesCode(values.sales_code);
    }
    if (values?.currency) {
      setCurrency(values.currency);
    }
    if (values?.sales_person) {
      setSalesPerson(values.sales_person);
    }
    if (values?.device_license_count) {
      setDeviceLicenseCount(values.device_license_count);
    }
    if (values?.user_license_count) {
      setUserLicenseCount(values.user_license_count);
    }
    if (values?.package_code) {
      let currentPackage = packageNames.find(o=> o.package_code === values?.package_code)
      setPackageName(currentPackage?.package_name || "")
      setPackageCode(currentPackage?.package_code || "")
      setPackagePriority(currentPackage?.priority || 1)
   }
   if(values?.package_features){
     setAddedFeatures(values.package_features)
   }
  }, [values]);


  useEffect(() => {
    if (partnerInput.length < 3) {
      setPartners([]);
      return;
    }

    fetchPartners({ keyword: partnerInput }, 1, 10)
      .then((result) => setPartners(result.list))
      .catch((err) => setPartners([]));
  }, [partnerInput]);

  function getMinDate(Value: any) {
    if (moment(values?.contract?.contract_end_date) < moment(new Date())) {
      return new Date();
    } else {
      return moment(values?.contract?.contract_end_date).toDate();
    }
  }

  function packagePrice(featurePackages:Array<PackageType>):number {
    let pkg = featurePackages.find(p=> p.package_name == package_name)
    if(pkg != undefined){
      let price = pkg.pricing.find(c => c.currency.toLocaleLowerCase() == currency)
      if(price != undefined){
        return price.value;
      }
    }
    return 0;
  }

  return (
    <form>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Autocomplete
            disabled={Boolean(sales_person.length)}
            clearOnEscape
            value={selectedPartner || undefined}
            size="small"
            onChange={(event: any, newValue: PartnerType | null) => {
              setSelectedPartner(newValue);
            }}
            inputValue={partnerInput}
            onInputChange={(event, newInputValue) => {
              setPartnerInput(newInputValue);
            }}
            id="partners"
            noOptionsText="No suggestions"
            options={partners}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} margin="normal" variant="standard" placeholder="Search" label="Partner" />
            )}
            renderOption={(options: any)=> (
              <TextField
                variant="standard"
                margin="none"
                {...options}
                InputProps={{ disableUnderline: true }}
                value={options.key}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl margin="normal" fullWidth required>
            <InputLabel variant="standard" id="select-label">Sales Person</InputLabel>
            <Select
              variant="standard"
              value={sales_person}
              disabled={Boolean(selectedPartner)}
              labelId="select-sales-person"
              id="select-sales-person"
              onChange={(e: any) => {
                setSalesPerson(e.target.value as string);
              }}
            >
              <MenuItem key="none" value="">
                None
              </MenuItem>
              {salesPeople.map(
                (person: {
                  id: string;
                  first_name: string;
                  last_name: string;
                }) => {
                  return (
                    <MenuItem key={person.id} value={person.id}>
                      {person.first_name} {person.last_name}
                    </MenuItem>
                  );
                }
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={(product_name == "Swarm" && package_prioriy > 0 && package_prioriy < 3 ? 10 : 12) ||
          (product_name == "DnC" && package_prioriy == 1 ? 10 : 12)}>
              <FormControl
                    margin="normal"
                    fullWidth
                    required
                    error={props.error?.validationErrors?.package_name}
                  >
                    <InputLabel variant="standard" id="select-label">Package</InputLabel>
                    <Select
                      variant="standard"
                      value={package_name}
                      labelId="select-package"
                      id="select-package"
                      onChange={(e: any) => {
                        let selectedPackage = packageNames?.find(o=> o.package_name == e.target.value && o.product_id == product_id)
                        setPackageName(selectedPackage?.package_name || "")
                        setPackageCode(selectedPackage?.package_code || "")
                        setPackagePriority(selectedPackage?.priority || 0)
                        setSalesCode(Number(selectedPackage?.pricing.find((p)=> p.currency.toLocaleLowerCase() == currency.toLocaleLowerCase())?.value || 14))
                        if(selectedPackage!=null){
                          setAddedFeatures(selectedPackage.features)
                        }
                      }}
                    >
                      {packageNames?.map((prodPackage: PackageType) => {
                        if(prodPackage.product_id === product_id){
                          return (
                            <MenuItem key={prodPackage.package_code} value={prodPackage.package_name}>
                              {prodPackage.package_name}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                </FormControl>
            </Grid>
            {(product_name == "Swarm" && package_prioriy > 0 && package_prioriy < 3) || 
            (product_name == "DnC" && package_prioriy == 1) ? 
            <Grid item xs={2}>
            <IconButton
              edge="end"
              aria-label="add user"
              style={{height:'15px', width:'15px'}}
              onClick={()=>{
                setFeatureDialogOpen(true)
              }}
              size="large">
                      <PlaylistAddOutlined />
              </IconButton>
          </Grid> : <></>}
          </Grid>                  
        </Grid>
        <Grid item xs={6}>
          <FormControl
            margin="normal"
            fullWidth
            required
            error={props.error?.validationErrors?.sales_code}
          >
            <InputLabel variant="standard" id="select-label">Sales Code</InputLabel>
            <Input
              inputComponent="input"
              disabled={true}
              inputProps={{
                type: "number",
                min: 0,
                max: 20,
                step: 0.05,
              }}
              value={sales_code}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) =>{
                if(Number(e.target.value) >= 0){
                  setSalesCode(Number(e.target.value))
                }else{
                  setSalesCode(0)
                }
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={4}>
          <FormControl
            margin="normal"
            fullWidth
            required
            error={props.error?.validationErrors?.currency}
          >
            <InputLabel variant="standard" id="currency">Currency</InputLabel>
            <Select
              variant="standard"
              value={currency}
              // disabled={disabled || props.isRenewalContract}
              labelId="currency"
              id="currency"
              onChange={(e: any) => {
                setCurrency(e.target.value as "usd" | "cad" | "gbp" | "");
                let currentCurrency = e.target.value as "usd" | "cad" | "gbp" | "";
                let selectedPackage = packageNames?.find(o=> o.package_name == package_name && o.product_id == product_id)
                let salesCode = Number(selectedPackage?.pricing.find((p)=> p.currency.toLocaleLowerCase() == currentCurrency.toLocaleLowerCase())?.value || 14)
                let addedFeaturesCode = 0.0
                if(addedFeatures.length > 0){
                  addedFeatures.map((a)=>{
                    let feature = featuresList.find((f)=> f.feature_code == a 
                    && f.package_code != package_code && package_prioriy!=3)?.price
                    if(feature != undefined){
                      let code = feature.find((p)=> p.currency.toLocaleLowerCase() == currentCurrency.toLocaleLowerCase())?.value
                      if(code != undefined){
                        addedFeaturesCode += code
                      }
                    }
                  })
                }
                let price = parseFloat((salesCode + addedFeaturesCode).toFixed(2))
                if(price >= 0){
                  setSalesCode(price)
                }else{
                  setSalesCode(0)
                }
              }}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="cad">CAD</MenuItem>
              <MenuItem value="usd">USD</MenuItem>
              <MenuItem value="gbp">GBP</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <TextField
            margin="normal" 
            variant="standard"
            fullWidth
            required
            // disabled={disabled || props.isRenewalContract}
            error={props.error?.validationErrors?.taxes?.[0]?.description}
            inputRef={(ref) => (taxDescriptionRef = ref)}
            label="Tax Description"
            defaultValue={values?.taxes ? values?.taxes[0].description : ""}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            margin="normal" 
            variant="standard"
            required
            fullWidth
            type="number"
            inputProps={{ min: 0 }}
            // disabled={disabled || props.isRenewalContract}
            error={props.error?.validationErrors?.taxes?.[0]?.rate}
            inputRef={(ref) => (taxRateRef = ref)}
            label="Tax Rate %"
            defaultValue={values?.taxes ? values?.taxes[0].rate || null : null}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormControl margin="normal" fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd MMM yyyy"
                minDate={getMinDate(values)}
                maxDate={contract_end_date}
                key="contract-start-date-id"
                label="Contract Start*"
                value={contract_start_date || new Date()}
                views={['year', 'month', 'day']}
                onChange={(date: Date | null) =>
                  setContractStartDate(
                    moment(date).toDate()
                  )
                }
                renderInput={(props) => <TextField variant="standard" {...props} />}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl margin="normal" fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                views={['year', 'month', 'day']}
                inputFormat="dd MMM yyyy"
                minDate={contract_start_date || moment().toDate()}
                key="contract-end-date-id"
                label="Contract End*"
                value={contract_end_date || new Date()}
                onChange={(date: Date | null) =>
                  setContractEndDate(
                    moment(date).endOf("month").toDate()
                  )
                }
                renderInput={(props) => <TextField variant="standard" {...props} />}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
      </Grid>

      <hr />
      <Typography variant="h6">Discount/Credit</Typography>

      <Tooltip title={isDiscountActive ? "Disable" : "Enable"}>
        <Switch
          checked={isDiscountActive}
          onChange={() => {
            setIsDiscountActive(!isDiscountActive);
          }}
        />
      </Tooltip>
      <div style={{ display: isDiscountActive ? "block" : "none" }}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <FormControl margin="normal" fullWidth>
              <InputLabel variant="standard" id="discount-type">Type</InputLabel>
              <Select
                variant="standard"
                fullWidth
                error={
                  props.error?.validationErrors?.invoice_discounts?.[0]?.type
                }
                labelId="discount-type"
                id="discount-type"
                value={discountType}
                onChange={(e: any) => {
                  setDiscountType(e.target.value as "percentage" | "fixed");
                }}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="percentage">Percentage</MenuItem>
                <MenuItem value="fixed">Fixed</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <TextField
              margin="normal" 
              variant="standard"
              fullWidth
              error={
                !!props.error?.validationErrors?.invoice_discounts?.[0]
                  ?.description
              }
              required
              label="Description"
              value={discountDescription}
              onChange={(e) => {
                setDiscountDescription(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl margin="normal" fullWidth required>
              <InputLabel variant="standard" id="discount-value">Value</InputLabel>
              <Input
                inputComponent="input"
                inputProps={{
                  type: "number",
                  min: 0,
                  max: discountType === "percentage" ? 100 : undefined,
                }}
                error={
                  !!props.error?.validationErrors?.invoice_discounts?.[0]
                    ?.amount
                }
                value={discountAmount}
                onChange={(e) => {
                  setDiscountAmount(Number(e.target.value));
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl margin="normal" fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat="dd MMM yyyy"
                  views={["year", "month"]}
                  key="discount-start-id"
                  label="Discount Start Month*"
                  value={discount_start}
                  onChange={(date: Date | null) =>
                    setDiscountStart(
                      moment(date).startOf("month").add(1, "hour").toDate()
                    )
                  }
                  renderInput={(props) => <TextField variant="standard" {...props} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl margin="normal" fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat="dd MMM yyyy"
                  views={["year", "month"]}
                  key="discount-end-id"
                  label="Discount End Month*"
                  value={discount_end}
                  onChange={(date: Date | null) =>
                    setDiscountEnd(
                      moment(date).endOf("month").subtract(1, "hour").toDate()
                    )
                  }
                  renderInput={(props) => <TextField variant="standard" {...props} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
        </Grid>
      </div>

      {/* <div style={{ display: values?.contract?.customer?.type !== "reseller" ? "block" : "none" }}>
        <hr />
        <Typography variant="h6">License Details</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl fullWidth required>
              <InputLabel id="user-license-count">User License Count</InputLabel>
              <Input
                inputComponent="input"
                inputProps={{
                  type: "number",
                  min: 1,
                  step: 1,
                }}
                disabled={
                  // disabled ||
                  // props.isRenewalContract ||
                  values?.contract?.customer?.type === "reseller"
                }
                defaultValue={values?.user_license_count || 10}
                inputRef={(ref) => (userLicenseCountRef = ref)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth required>
              <InputLabel id="device-license-count">
                Device License Count
              </InputLabel>
              <Input
                inputComponent="input"
                inputProps={{
                  type: "number",
                  min: 1,
                  step: 1,
                }}
                disabled={
                  // disabled ||
                  // props.isRenewalContract ||
                  values?.contract?.customer?.type === "reseller"
                }
                defaultValue={values?.device_license_count || 100}
                inputRef={(ref) => (deviceLicenseCountRef = ref)}
              />
            </FormControl>
          </Grid>
        </Grid>
      </div> */}
      <br />
      <br />
      <div>
        <SubmitButton
          loading={props.loading}
          label="Submit"
          onClick={() => {
            if (isDisabled) return;
            setIsDisabled(true);
            const values: RenewCustomerContractInput = {
              sales_code,
              currency,
              taxes: [
                {
                  description: taxDescriptionRef?.value.trim(),
                  rate: Number(taxRateRef?.value.trim()),
                },
              ],
              device_license_count: deviceLicenseCount,
              user_license_count: userLicenseCount,
              sales_person: sales_person.trim().length
                ? sales_person.trim()
                : null,
              partner: selectedPartner || null,
              package_code: package_code,
              package_features: addedFeatures,
              contract_start_date: contract_start_date,
              contract_end_date: contract_end_date,
            };
            values.invoice_deductions = [];
            if (isDiscountActive) {
              values.invoice_deductions = [
                {
                  amount: discountAmount || 0,
                  description: discountDescription || "",
                  type: discountType || "",
                  start_date: discount_start
                    ? moment(discount_start).format(DATE_FORMAT)
                    : "",
                  end_date: discount_end
                    ? moment(discount_end).format(DATE_FORMAT)
                    : "",
                },
              ];
            }

            props.onSubmit(values);
            setTimeout(() => {
              setIsDisabled(false);
            }, 2000)
          }}
        />
        <Dialog fullWidth
        open={featureDialogOpen}>
          <DialogTitle>Add-On Features</DialogTitle>
              <DialogContent>
                <Grid container spacing={6}>
                  <Grid item xs={4}>
                    <span>
                      Package:
                    </span>
                    <span style={{fontWeight:'bold', marginLeft:'3px'}}>
                      {package_name}
                    </span>
                  </Grid>
                  <Grid item xs={4}>
                    <div style={{display:'flex', justifyContent:'center'}}>
                      <span>
                        Base Price:
                      </span>
                      <span style={{fontWeight:'bold', marginLeft:'3px'}}>
                        {packageNames.find(p=> p.package_name == package_name)?.pricing?.find(c=> c.currency.toLowerCase() == currency)?.value}
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div style={{display:'flex', justifyContent:'flex-end'}}>
                      <span>
                        Add-on Price:
                      </span>
                      <span style={{fontWeight:'bold', marginLeft:'3px'}}>
                        {parseFloat((sales_code - packagePrice(packageNames)).toString()).toFixed(2)}
                      </span>
                    </div>
                  </Grid>
                  {/* <Grid item xs={3}>
                    <FormControl
                      fullWidth>
                      <Select
                        value={featureCurrency}
                        // disabled={disabled || props.isRenewalContract}
                        labelId="currency"
                        id="currency"
                        onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                          setFeatureCurrency(e.target.value as "usd" | "cad" | "gbp");
                        }}
                      >
                        <MenuItem value="cad">CAD</MenuItem>
                        <MenuItem value="usd">USD</MenuItem>
                        <MenuItem value="gbp">GBP</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid> */}
                </Grid>
                <List>
                  {featuresList.map((feature:FeatureType,index)=>{
                     let nextPackage = package_prioriy + 1
                     if(feature.priority == nextPackage && feature.product_id == product_id){
                      return (
                        <ListItem>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              { index == 0 ? <div><label style={{fontWeight:'bold'}}>Feature</label><br/><br/></div> : <></>}
                              <label>{feature.feature}</label>
                            </Grid>
                            <Grid item xs={3}>
                              { index == 0 ? <div><label style={{fontWeight:'bold'}}>Cost</label><br/><br/></div> : <></>}
                              {feature.price !== null ? <label>
                                  {feature.price?.find((c)=> c.currency.toLocaleLowerCase() == currency)?.value}
                                  </label> : <></>}
                            </Grid>
                            <Grid item xs={3}>
                            { index == 0 ? <div><label style={{fontWeight:'bold'}}>Add</label><br/><br/></div> : <></>}
                              { addedFeatures.find((f)=> f == feature.feature_code) ?
                              <IconButton
                                aria-label="Edit contract"
                                style={{height:'15px',width:'15px'}}
                                onClick={()=>{
                                  setAddedFeatures(addedFeatures.filter((f)=> f!= feature.feature_code))
                                  let fPrice = feature.price?.find((c)=> c.currency.toLocaleLowerCase() == "usd")?.value
                                  if(currency != ""){
                                    fPrice = feature.price?.find((c)=> c.currency.toLocaleLowerCase() == currency)?.value                                    
                                  }            
                                  let price = parseFloat((sales_code - ((fPrice!=undefined) ? fPrice : 0)).toFixed(2))
                                  if(price >= 0){
                                    setSalesCode(price)
                                  }else{
                                    setSalesCode(0)
                                  }   
                                }}
                                size="large">
                                <CheckCircle 
                                htmlColor="green"/>
                              </IconButton> : <IconButton
                                aria-label="Edit contract"
                                style={{height:'15px',width:'15px'}}
                                onClick={()=>{
                                  setAddedFeatures([...addedFeatures,feature.feature_code])
                                  let fPrice = feature.price?.find((c)=> c.currency.toLocaleLowerCase() == "usd")?.value
                                  if(currency != ""){
                                    fPrice = feature.price?.find((c)=> c.currency.toLocaleLowerCase() == currency)?.value                                    
                                  }
                                  let price = parseFloat((sales_code + ((fPrice!=undefined) ? fPrice : 0)).toFixed(2))
                                  if(price >= 0){
                                    setSalesCode(price)
                                  }else{
                                    setSalesCode(0)
                                  }
                                }}
                                size="large">
                                <AddCircleOutlineOutlined/>
                            </IconButton>}
                            </Grid>
                          </Grid>
                        </ListItem>
                      );
                     }
                  })}
                </List>
                <div style={{display:'flex', justifyContent:'flex-end'}}>
                  <Button
                      style={{ width: 150 }}
                      color="primary"
                      variant="contained"
                      onClick={() => {  
                        setFeatureDialogOpen(false);
                      }}
                    >Close</Button>
                </div>
              </DialogContent>
        </Dialog>
      </div>
    </form>
  );
}

function CreateNewCustomerContract({
  onSubmit,
  error,
}: {
  onSubmit: (values: NewCustomerContractInput) => void;
  error?: CustomError;
}) {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const createContract = useSelector<
    Reducers,
    CreateNewCustomerContractReducer
  >((state) => state.autnhive.createNewCustomerContract);
  console.log('heres datar', createContract);
  useEffect(() => {
    dispatch(contractActions.fetchInputOptions());
  }, [dispatch]);

  return (
    <Dialog  
      maxWidth={'md'}
      open
      onClose={() => {
        navigate(-1);
        dispatch(contractActions.resetState());
      }}
    >
      <DialogTitle>Create Contract</DialogTitle>
      <DialogContent>
        <ContractForm
          id={""}
          variant="edit"
          error={error}
          isRenewalContract={false}
          loading={false}
          products={createContract.products}
          packages={createContract.packages}
          features={createContract.features}
          approvers={createContract.approvers}
          salesPeople={createContract.salesPeople}
          onSubmit={onSubmit}
        />
      </DialogContent>
    </Dialog>
  );
}

function UpdateNewCustomerContract({
  variant,
  onSubmit,
}: {
  variant: "view" | "edit";
  onSubmit: (values: NewCustomerContractInput, id: string) => void;
}) {
  interface Params {
    id: string
  }
  const { id } = useParams<keyof Params>() as Params;
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const updateContract = useSelector<
    Reducers,
    UpdateNewCustomerContractReducer
  >((state) => state.autnhive.updateNewCustomerContract);
  const contractsPage = useSelector<Reducers, ContractsPageReducer>(
    (state) => state.autnhive.contractsPage
  );
  useEffect(() => {
    dispatch(contractActions.fetchInputOptions());
  }, [dispatch]);
  const contract = contractsPage.contracts.find((c) => c.id === id);
  if (!contract) {
    return null;
  }
  let customer: AutnhiveContractCustomerDraftInput | null = null;
  let user: AutnhiveContractCustomerUserDraftInput | null = null;
  let prepaidDetail: PrepaidInput | null = null;
  if (contract.customer?.id) {
    if (contract.customer.type === "reseller") {
      customer = {
        ...contract.customer.reseller,
        customer_type: "reseller",
        use_credit_card: contract.customer.use_credit_card,
        allowed_domains: contract.customer.allowed_domains,
        is_white_label: contract.customer?.reseller ? contract.customer?.reseller.is_white_label : false,
        white_label_url: contract.customer?.reseller ? contract.customer?.reseller.white_label_url : ""
      };
    } else if (
      contract.customer.type === "home-organization" ||
      contract.customer.type === "corporate-organization"
    ) {
      customer = {
        ...contract.customer.organization,
        customer_type: contract.customer.type,
        use_credit_card: contract.customer.use_credit_card,
        allowed_domains: contract.customer.allowed_domains,
        subscription: contract.customer.subscription,
      };
    }
  }
  
  if (contract?.customer?.primary_contact) {
    user = contract.customer.primary_contact;
  }
  const values: NewCustomerContractInput = {
    customer: customer || contract.draft_details?.customer || null,
    customer_id: contract.customer?.id || null,
    user: user || contract.draft_details?.user || null,
    contract_start_date: contract.contract_start_date
      ? moment(contract.contract_start_date).toDate()
      : new Date(),
    contract_end_date: contract.contract_start_date
      ? moment(contract.contract_end_date).toDate()
      : new Date(),
    is_avail_trial: contract.is_avail_trial,
    trial_end_date: contract.trial_end_date
      ? moment(contract.trial_end_date).toDate()
      : new Date(),
    is_prepaid: contract.is_prepaid,
    available_credit: contract.available_credit,
    prepaid_details: contract.prepaid_details,
    device_license_count: contract.device_license_count,
    is_physical_contract: contract.is_physical_contract,
    physical_contract_details: contract.physical_contract_details,
    user_license_count: contract.user_license_count,
    currency: contract.currency,
    taxes: contract.taxes,
    product_id: contract.product.id,
    sales_code: contract.sales_code,
    sales_person: contract.sales_person || null,
    package_code: contract.package_code || null,
    package_features: contract.package_features || null,
    partner: contract.partner || null,
    subscription: contract.draft_details?.subscription,
    use_credit_card: contract.customer?.use_credit_card || false,
    terms_accepted: contract.terms_agreed_by || contract.skip_user_terms ? true : false,
    status: contract.status,
    is_white_label:contract.customer?.reseller ? contract.customer?.reseller.is_white_label : false,
    white_label_url: contract.customer?.reseller ? contract.customer?.reseller.white_label_url : ""
  };

  if (contract.invoice_deductions?.length) {
    values.invoice_deductions = contract.invoice_deductions;
  }
  const isRenewalContract = contract.total_subscriptions
    ? Boolean(contract.total_subscriptions >= 0)
    : false;
  const isInitialEditable = contract.terms_agreed_on == null && !contract.skip_user_terms;
  
  return (
    <Dialog maxWidth={'md'} open onClose={variant === "view" ? () => navigate(-1) : () => {
      navigate(-1);
      dispatch(contractActions.resetState())
    }}>
      <DialogTitle>{variant === "view" ? "View" : "Update"} Contract</DialogTitle>
      <DialogContent>
        <ContractForm
          id={id}
          skipCustomerSearch
          loading={false}
          isRenewalContract={isRenewalContract}
          isInitialEditable={isInitialEditable}
          values={values}
          products={updateContract.products}
          packages={updateContract.packages}
          features={updateContract.features}
          approvers={updateContract.approvers}
          salesPeople={updateContract.salesPeople}
          error={updateContract.error}
          variant={variant}
          disabled={contract.status === "approved"}
          onSubmit={onSubmit}
        />
      </DialogContent>
    </Dialog>
  );
}

function ContractForm(props: NewCustomerContractFormProps) {
  const dispatch = useDispatch<any>();
  const { values, disabled, id } = props;
  const { enqueueSnackbar } = useSnackbar();
  const anchorEl = React.useRef<HTMLElement>();
  let deviceLicenseCountRef: HTMLInputElement;
  let userLicenseCountRef: HTMLInputElement;
  let taxDescriptionRef: HTMLInputElement;
  let taxRateRef: HTMLInputElement;
  let acceptedByRef: HTMLInputElement;
  let approvedByRef: HTMLInputElement;
  const [isDisabled, setIsDisabled] = useState(false);
  const [step, setStep] = useState(props.skipCustomerSearch ? 2 : 1);
  const [customerName, setCustomerName] = useState<string>(
    values?.customer?.name || ""
  );
  const [customers, setCustomers] = useState<Array<CustomerType>>([]);
  const [existingCustomer, setExistingCustomer] = useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [customer, setCustomer] = useState<AutnhiveContractCustomerDraftInput>({
    customer_type: values?.customer?.customer_type || "",
    name: customerName,
    use_credit_card: values?.customer?.use_credit_card || false,
    email: values?.customer?.email || "",
    phone: values?.customer?.phone || "",
    is_white_label: values?.customer?.is_white_label,
    white_label_url: values?.customer?.white_label_url || "",
    address: values?.customer?.address || {
      line_1: "",
      line_2: "",
      city: "",
      state: "",
      postal_code: "",
      country: "United States",
    },
    allowed_domains: values?.customer?.allowed_domains || []
  });
  
  const [user, setUser] = useState<AutnhiveContractCustomerUserDraftInput>({
    first_name: values?.user?.first_name || "",
    last_name: values?.user?.last_name || "",
    email: values?.user?.email || "",
  });
  const [prepaidDetail, setPrepaidDetail] = useState<PrepaidInput>({
    payment_mode: values?.prepaid_details?.payment_mode || "",
    paid_at: values?.prepaid_details?.paid_at || moment().format("yyyy-MM-DD"),
    remarks: values?.prepaid_details?.remarks || "",
    paid_amount: values?.prepaid_details?.paid_amount || 0,
    transaction_id: values?.prepaid_details?.transaction_id || "",
    is_partial_payment: values?.prepaid_details?.is_partial_payment || false,
    payment_from: values?.prepaid_details?.payment_from || "",
    payment_to: values?.prepaid_details?.payment_to || ""
  });
  const {countries} = useSelector<Reducers, GlobalReducer>(
    (state) => state.globe
  );
  const [product_id, setProductId] = useState("");
  const [product_name, setProductName] = useState("");
  const [isIntact, setIsIntact] = useState(false);
  const [isDnc, setIsDnc] = useState(false);
  const [existing_product_id, setExistingProductId] = useState("");
  const [is_white_label, set_is_white_label] = useState(values?.customer?.is_white_label);
  const baseSplit = values?.customer?.white_label_url?.split("://");
  const [baseURL, setBaseURL] = useState(baseSplit && baseSplit[0] === "https" ? 'https://' : 'http://');
  const url = baseSplit && baseSplit[1] && baseSplit[1].length ? baseSplit[1] : '';
  const [white_label_url, set_white_label_url] = useState<string>(url)
  const [package_name, setPackageName] = useState("");
  const [package_code, setPackageCode] = useState("");
  const [package_prioriy, setPackagePriority] = useState(0);
  const [sales_code, setSalesCode] = useState(0.00);
  const [user_cost, setUserCost] = useState(0.0);
  const [key_cost, setKeyCost] = useState(0.0)
  const [dataSize_cost, setDataSizeCost] = useState(0.0);
  const [sales_person, setSalesPerson] = useState("");
  const [featureDialogOpen, setFeatureDialogOpen] = useState(false);
  const [customer_type, setCustomerType] = useState<
    "" | "reseller" | "corporate-organization" | "home-organization"
  >("");
  const [contract_start_date, setContractStartDate] = useState<Date>(
    moment().toDate()
  );
  const [contract_end_date, setContractEndDate] = useState<Date>(
    moment().add(11, "month").endOf("month").subtract(1, "hour").toDate()
  );
  const [trial_end_date, setTrialEndDate] = useState<Date>(
    contract_start_date
  );
  const [currency, setCurrency] = useState<"usd" | "cad" | "gbp" | "">("usd");
  const [selectedPartner, setSelectedPartner] = useState<PartnerType | null>(
    null
  );
  const [partnerInput, setPartnerInput] = useState<string>("");
  const [partners, setPartners] = useState<Array<PartnerType>>([]);
  const [isDiscountActive, setIsDiscountActive] = useState<boolean>(false);
  const [isPrepaidActive, setIsPrepaidActive] = useState<boolean>(false);
  const [isCreditCardActive, setIsCreditCardActive] = useState<boolean>(false);
  const [discountType, setDiscountType] = useState<
    "" | "percentage" | "fixed" | null
  >("");
  const [discountDescription, setDiscountDescription] = useState<string | null>(
    null
  );
  const [discountAmount, setDiscountAmount] = useState<number | null>(null);
  const [discount_start, setDiscountStart] = useState<Date | null>(null);
  const [discount_end, setDiscountEnd] = useState<Date | null>(null);
  const [error, setError] = useState<String>("");
  const [addedFeatures, setAddedFeatures] = useState<Array<string>>([])
  const [isPhysicalContract, setIsPhysicalContract] = useState<boolean>(false);
  const [physicalApprovedBy, setPhysicalApprovedBy] = useState<string|null>(null)
  const [physicalAcceptedBy, setPhysicalAcceptedBy] = useState<string|null>(null)
  const [physicalApprovedAt, setPhysicalApprovedAt] = useState<Date|null>(
    moment().startOf("day").add(1, "hour").toDate()
  );
  const [physicalAcceptedAt, setPhysicalAcceptedAt] = useState<Date|null>(
    moment().startOf("day").add(1, "hour").toDate()
  );
  const [isAvailTrial, setIsAvailTrial] = useState<boolean>(false);
  const [isAvailOpen, setIsAvailOpen] = useState<boolean>(false);
  
  const [isPartialPayment, setIsPartialPayment] = useState<boolean>(false);
  const [paidAt, setPaidAt] = useState<string|null>(
    moment().format("yyyy-MM-DD")  
  )
  const [prepaidAmount,setPrepaidAmount] = useState(0);
  const [remainingAmount,setRemainingAmount] = useState(0);
  const [prepaidPaymentMode,setPrepaidPaymentMode] = useState("");
  const [prepaidRemarks,setPrepaidRemarks] = useState("");
  const [prepaidTransactionId,setPrepaidTransactionId] = useState("");
  const [prepaidFrom,setPrepaidFrom] = useState("");
  const [prepaidTo,setPrepaidTo] = useState("");

  let minDate = new Date();
  minDate.setMonth(minDate.getMonth() - 6)

  useEffect(() => {
    if (!countries || countries.length <= 0) {
      dispatch(
        userProfileActions.fetchCountries()
      );
    }
  }, [])
  useEffect(() => {
    if (error.length > 0) {
      enqueueSnackbar(error, { variant: "error" })
      setError("")
    }
  }, [error]);
  function handleSelecetedTags(items: any) {
    var re = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/);
    var tempDomains: string[] = [];
    for (const item of items) {
      if (re.test(item)) {
        tempDomains.push(item)
      } else {
        setError("Invalid Domain")
        setCustomer((prevState) => {
          return {
            ...prevState,
            allowed_domains: tempDomains
          }
        });
      }
    }
    customer.allowed_domains = tempDomains;
  }
  useEffect(() => {
    setProductId(values?.product_id || "");
    props.products.map((p)=>{
      if(p.id == values?.product_id){
        setProductName(p.name)
        if(p.name == "Intact"){
          setIsIntact(true);
          setIsDnc(false);
      } else if(p.name == "SAMI"){
        setIsDnc(true)
        setIsIntact(false);
      }
      else{
          setIsDnc(false);
          setIsIntact(false);
      }
      }
    })
    if (values?.customer) {
      setCustomer(values.customer);
      setCustomerType(values.customer.customer_type);
      setIsCreditCardActive(values.customer.use_credit_card);
    }
    if (values?.partner) {
      setSelectedPartner(values.partner);
    }
    if (values?.customer_id) {
      setExistingCustomer(values.customer_id);
    }
    if (values?.contract_start_date) {
      setContractStartDate(offset(moment(values?.contract_start_date).toDate()));
    }
    if (values?.contract_end_date) {
      setContractEndDate(offset(moment(values.contract_end_date).toDate()));
    }
    if(values?.is_avail_trial){
      setIsAvailTrial(values.is_avail_trial)
    }
    if (values?.trial_end_date) {
      setTrialEndDate(offset(moment.utc(values.trial_end_date).toDate()));
    }
    if (values?.sales_code) {
      setSalesCode(values.sales_code);
    }
    if (values?.package_code) {
      if(values?.package_code.includes(',')){
        setPackageCode(values?.package_code)
        setPackagePriority(1)
        let mPackages = values?.package_code.split(',')
        let currentPackages = [""]
        currentPackages.pop()
        mPackages.map((p)=>{
          let fPackage = props.packages.find(o=> o.package_code === p)
          currentPackages.push(fPackage?.package_name || "")
        })
        let currentPackage = currentPackages.join("+")
        setPackageName(currentPackage)
       }else{
        let currentPackage = props.packages.find(o=> o.package_code === values?.package_code)
        setPackageName(currentPackage?.package_name || "")
        setPackageCode(currentPackage?.package_code || "")
        setPackagePriority(currentPackage?.priority || 1)
       }
    }
    if(values?.package_features){
      setAddedFeatures(values.package_features)
    }
    if (values?.sales_person) {
      setSalesPerson(values.sales_person);
    }
    if (values?.currency) {
      setCurrency(values.currency);
    }

    if(values?.is_physical_contract){
      setIsPhysicalContract(values.is_physical_contract)
    }

    if(values?.physical_contract_details){
      if(values.physical_contract_details?.accepted_by){
        setPhysicalAcceptedBy(values.physical_contract_details.accepted_by)
      }
      if(values.physical_contract_details?.accepted_date){
        setPhysicalAcceptedAt(values.physical_contract_details.accepted_date)
      }
      if(values.physical_contract_details?.approved_by){
        setPhysicalApprovedBy(values.physical_contract_details.approved_by)
      }
      if(values.physical_contract_details?.approved_date){
        setPhysicalApprovedAt(values.physical_contract_details.approved_date)
      }
    }

    if(values?.is_prepaid){
      setIsPrepaidActive(values.is_prepaid)

      if(values.is_prepaid){
        setRemainingAmount(values.available_credit)
        if(values?.prepaid_details){
          setPrepaidDetail(values.prepaid_details)
          setPaidAt(values.prepaid_details.paid_at)
          setIsPartialPayment(values.prepaid_details.is_partial_payment)
          setPrepaidAmount(values.prepaid_details.paid_amount)
          setPrepaidFrom(values.prepaid_details.payment_from)
          setPrepaidTo(values.prepaid_details.payment_to)
          setPrepaidPaymentMode(values.prepaid_details.payment_mode)
          setPrepaidTransactionId(values.prepaid_details.transaction_id)
          setPrepaidRemarks(values.prepaid_details.remarks)
        }
      }
    }
    if (values?.invoice_deductions?.length) {
      const { description, amount, type, start_date, end_date } =
        values.invoice_deductions[0];
      setDiscountDescription(description);
      setDiscountAmount(amount);
      setDiscountType(type);
      setDiscountStart(moment(start_date, DATE_FORMAT).toDate());
      setDiscountEnd(moment(end_date, DATE_FORMAT).toDate());
      setIsDiscountActive(true);
    }
  }, [values]);

  useEffect(() => {
    if (customerName.length < 3) {
      setCustomers([]);
      return;
    }

    fetchCustomers({ keyword: customerName }, 1, 10)
      .then((result) => setCustomers(result.list))
      .catch((err) => setCustomers([]));
  }, [customerName]);
  useEffect(() => {
    if (partnerInput.length < 3) {
      setPartners([]);
      return;
    }

    fetchPartners({ keyword: partnerInput }, 1, 10)
      .then((result) => setPartners(result.list))
      .catch((err) => setPartners([]));
  }, [partnerInput]);
  const payAtError:string = props.error?.validationErrors?.prepaid_details?.paid_at ? '#d32f2f' : '';
  const payFromError:string = props.error?.validationErrors?.prepaid_details?.payment_from ? '#d32f2f' : '';
  const payToError:string = props.error?.validationErrors?.prepaid_details?.payment_to ? '#d32f2f' : '';
  function packagePrice(featurePackages:Array<PackageType>):number {
    let pkg = featurePackages.find(p=> p.package_name == package_name)
    if(pkg != undefined){
      let price = pkg.pricing.find(c => c.currency.toLocaleLowerCase() == currency)
      if(price != undefined){
        return price.value;
      }
    }
    return 0;
  }

  if (step === 1) {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (customerName.trim().length < 3) {
            return;
          }
          if (customers.length > 0) {
            for (const user of customers) {
              if (user.organization.name === customerName) {
                enqueueSnackbar("Customer Name already exists", { variant: "error" })
                break;
              } else {
                setCustomer({
                  ...customer,
                  name: customerName.trim(),
                  is_white_label: customer.is_white_label,
                  white_label_url: customer.white_label_url
                });
                setStep(2);
              }
            }
          } else {
            // customer.name = customerName.trim();
            setCustomer({
              ...customer,
              name: customerName.trim(),
              is_white_label: customer.is_white_label,
              white_label_url: customer.white_label_url
            });
            setStep(2);
          }
        }}
      >
        <TextField
          fullWidth
          inputRef={anchorEl}
          placeholder="Search"
          label="Customer name"
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
          onBlur={() => setCustomerName(customerName.trim())}
          variant="standard"
        />
         <Popper
          open={Boolean(customers.length)}
          anchorEl={anchorEl.current}
          style={{
            width: anchorEl.current ? anchorEl.current.clientWidth : undefined,
            zIndex: 1301
          }}
          role="presentation"
        >
          <Paper>
            {customers.map((cus) => (
              <MenuItem
                key={cus.id}
                onClick={() => {
                  setStep(2);
                  setExistingCustomer(cus.id);
                  if (cus.primary_contact) {
                    // setUser({
                    //   //To work
                    //   first_name: customer.primary_contact.first_name,
                    //   last_name: customer.primary_contact.last_name,
                    //   email: customer.primary_contact.email,
                    // });
                  }
                  if(cus.product_id){
                    setExistingProductId(cus.product_id)
                  }
                  if (cus.type === "reseller") {
                    set_is_white_label(cus.reseller.is_white_label)
                    set_white_label_url(cus.reseller.white_label_url ? cus.reseller.white_label_url : "")
                    setCustomer({
                      customer_type: "reseller",
                      name: cus.reseller.name,
                      email: cus.reseller.email,
                      phone: cus.reseller.phone,
                      address: cus.reseller.address,
                      use_credit_card: cus.use_credit_card,
                      allowed_domains: cus.allowed_domains,
                      is_white_label: cus.is_white_label,
                      white_label_url
                    });
                  } else if (
                    cus.type === "corporate-organization" ||
                    cus.type === "home-organization"
                  ) {
                    setCustomer({
                      customer_type: cus.type,
                      name: cus.organization.name,
                      email: cus.organization.email,
                      phone: cus.organization.phone,
                      address: cus.organization.address,
                      use_credit_card: cus.use_credit_card,
                      allowed_domains: cus.allowed_domains,
                      is_white_label: false
                    });
                  }
                  setCustomerType(cus.type);
                  setIsCreditCardActive(customer.use_credit_card);
                }}
              >
                {cus.organization?.name || cus.reseller?.name}
              </MenuItem>
            ))}
          </Paper>
        </Popper> 

        <br />
        <br />
        <SubmitButton
          disabled={customerName.trim().length < 3}
          loading={false}
          label="Next"
          onClick={() => {
            if (customers.length > 0) {
              for (const user of customers) {
                if (user.organization.name === customerName) {
                  enqueueSnackbar("Customer Name already exists", { variant: "error" })
                  break;
                } else {
                  setCustomer({
                    ...customer,
                    name: customerName.trim()
                  });
                  setStep(2);
                }
              }
            } else {
              setCustomer({
                ...customer,
                name: customerName.trim()
              });
              setStep(2);
            }
          }}
        />
      </form>
    );
  }

  return (
    <form>
      <div>
        <Typography variant="h6">Customer Details</Typography>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              margin="normal"
              variant="standard"
              error={
                !!props.error?.validationErrors?.new_customer_details?.customer
                  ?.name
              }
              required
              disabled={
                (disabled || Boolean(existingCustomer) || props.isRenewalContract)
              }
              label="Customer Name"
              value={customer.name}
              onChange={(e) => {
                setCustomer((prevState) => {
                  return {
                    ...prevState,
                    name: e.target.value
                  }
                });
              }}
              onBlur={() => {
                if (customer.name.trim().length < 3) {
                  return;
                }

                fetchCustomers({ keyword: customer.name }, 1, 10)
                  .then((result) => {
                    if (result.list.length > 0) {
                      for (const user of result.list) {
                        if (user.organization.name === customer.name) {
                          enqueueSnackbar("Customer Name already exists", { variant: "error" })
                          break;
                        } else {
                          setCustomer({
                            ...customer,
                            name: customerName.trim()
                          });
                          setStep(2);
                        }
                      }
                    }
                  })

                setCustomer((prevState) => {
                  return {
                    ...prevState,
                    name: customer.name.trim()
                  }
                });
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <FormControl margin="normal" fullWidth required>
              <InputLabel variant="standard">Customer Type</InputLabel>
              <Select
                variant="standard"
                error={
                  !!props.error?.validationErrors?.new_customer_details?.customer
                    ?.customer_type
                }
                disabled={
                  disabled || Boolean(existingCustomer) || props.isRenewalContract
                }
                value={customer_type}
                onChange={(e) => {
                  const customer_type = e.target.value as
                    | "corporate-organization"
                    | "home-organization"
                    | "reseller";
                  // customer.customer_type = customer_type;
                  if (customer.customer_type === "reseller") {
                    userLicenseCountRef.value = "10";
                    deviceLicenseCountRef.value = "100"
                  }
                  setCustomer({
                    ...customer,
                    customer_type: customer_type
                  });
                  setCustomerType(customer_type);
                }}
              >
                <MenuItem key="none" value="">
                  None
                </MenuItem>
                {[
                  {
                    value: "corporate-organization",
                    label: "Corporate Organization",
                  },
                  { value: "home-organization", label: "Home Organization" },
                  { value: "reseller", label: "Reseller" },
                ].map((customer) => (
                  <MenuItem key={customer.value} value={customer.value}>
                    {customer.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Typography>Credit card</Typography>
            <Tooltip title={isCreditCardActive ? "Disable" : "Enable"}>
              <Switch
                // disabled={
                //   disabled || Boolean(existingCustomer) || props.isRenewalContract
                // }
                disabled={props.variant !== "edit"}
                checked={isCreditCardActive}
                onChange={() => {
                  const value = !isCreditCardActive;
                  setIsCreditCardActive(value);
                  setCustomer({ ...customer, use_credit_card: value });
                }}
              />
            </Tooltip>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              margin="normal"
              variant="standard"
              error={
                !!props.error?.validationErrors?.new_customer_details?.customer
                  ?.email
              }
              required
              label="Email"
              disabled={
                disabled ||
                props.disableFields?.includes("email") ||
                Boolean(existingCustomer) ||
                props.isRenewalContract
              }
              defaultValue={customer.email}
              value={customer.email}
              onChange={(e) => {
                const noWSEmail = e.target.value.replace(/\s+/g, '');
                setCustomer({
                  ...customer,
                  email: noWSEmail
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              margin="normal"
              variant="standard"
              inputMode="numeric"
              label="Phone"
              disabled={
                disabled || Boolean(existingCustomer) || props.isRenewalContract
              }
              value={customer.phone}
              error={
                !!props.error?.validationErrors?.new_customer_details?.customer
                  ?.phone
              }
              defaultValue={customer.phone}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                  setPhoneNumber(e.target.value)
                  setCustomer({
                    ...customer,
                    phone: e.target.value
                  });
                }
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              margin="normal"
              variant="standard"
              disabled={
                disabled || Boolean(existingCustomer) || props.isRenewalContract
              }
              error={
                !!props.error?.validationErrors?.new_customer_details?.customer
                  ?.address?.line_1
              }
              required
              label="Address Line 1"
              defaultValue={customer.address.line_1}
              onChange={(e) => {
                setCustomer({
                  ...customer,
                  address: {
                    ...customer.address,
                    line_1: e.target.value
                  }
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              margin="normal"
              variant="standard"
              disabled={
                disabled || Boolean(existingCustomer) || props.isRenewalContract
              }
              label="Address Line 2"
              defaultValue={customer.address.line_2}
              onChange={(e) => {
                setCustomer({
                  ...customer,
                  address: {
                    ...customer.address,
                    line_2: e.target.value
                  }
                });
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              margin="normal"
              variant="standard"
              disabled={Boolean(existingCustomer) || props.isRenewalContract}
              error={
                !!props.error?.validationErrors?.new_customer_details?.customer
                  ?.address?.city
              }
              required
              label="City"
              defaultValue={customer.address.city}
              onChange={(e) => {
                setCustomer({
                  ...customer,
                 address: {
                  ...customer.address,
                   city: e.target.value
                 }
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              margin="normal"
              variant="standard"
              disabled={
                disabled || Boolean(existingCustomer) || props.isRenewalContract
              }
              error={
                !!props.error?.validationErrors?.new_customer_details?.customer
                  ?.address?.state
              }
              required
              label="State"
              defaultValue={customer.address.state}
              onChange={(e) => {
                setCustomer({
                  ...customer,
                  address: {
                    ...customer.address,
                    state: e.target.value
                  }
                });
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              margin="normal"
              variant="standard"
              disabled={
                disabled || Boolean(existingCustomer) || props.isRenewalContract
              }
              error={
                !!props.error?.validationErrors?.new_customer_details?.customer
                  ?.address?.postal_code
              }
              required
              label="Postal Code"
              defaultValue={customer.address.postal_code}
              onChange={(e) => {
                setCustomer({
                  ...customer,
                  address: {
                    ...customer.address,
                    postal_code: e.target.value
                  }
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              margin="normal"
              variant="standard"
              error={
                !!props.error?.validationErrors?.new_customer_details?.customer
                  ?.address?.country
              }
            >
              <InputLabel variant="standard">Country *</InputLabel>
              <Select
                // margin="normal"
                variant="standard"
                disabled={
                  disabled || Boolean(existingCustomer) || props.isRenewalContract
                }
                defaultValue={customer.address.country || ""}
                onChange={(e) => {
                  customer.address.country = e.target.value as string;
                  setCustomer({
                    ...customer,
                    address: {
                      ...customer.address,
                      country: e.target.value  
                    }
                  });
                }}
              >
                <MenuItem key="none" value="">
                  None
                </MenuItem>
                {countries?.map((country) => (
                  <MenuItem key={country.id} value={country.name}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TagsInput
              disabled={
                disabled || Boolean(existingCustomer) || props.isRenewalContract
              }
              selectedTags={handleSelecetedTags}
              tags={customer.allowed_domains}
              fullWidth
              id="tags"
              name="Allowed Domains"
              label="Allowed Domains"
              placeholder="Type and press Enter add domain"
              onDelete={(list: any) => {
                setCustomer({
                  ...customer,
                  allowed_domains: list
                })
              }}
              onChange={(newDomain: any, list: any) => {
                list.push(newDomain);
                setCustomer({
                  ...customer,
                  allowed_domains: list
                });
              }}
            />
          </Grid>
        </Grid>
        {customer.customer_type === "reseller" ?
          <Grid container spacing={3} style={{marginTop:'10px'}}>
            <Grid item xs={3}>
                <Typography>White-Label</Typography>
                <Tooltip title={is_white_label ? "Disable White-Label" : "Enable White-Label"}>
                  <Switch
                    disabled={disabled || Boolean(existingCustomer) || props.isRenewalContract}
                    checked={is_white_label}
                    onChange={() => {
                      const value:boolean = !is_white_label;
                      set_is_white_label(value);
                      if (!value) {
                        setCustomer(prevCustomer => {
                          let updated = prevCustomer;
                          delete updated.white_label_url;
                          updated.is_white_label = false;
                          return updated;
                        })
                      } else {
                        setCustomer(prevCustomer => {
                          let updated = prevCustomer;
                          updated.is_white_label = true;
                          return updated;
                        });
                      }
                    }}
                  />
                </Tooltip>
              </Grid>
              {is_white_label ? 
                <Grid xs={2} style={{ marginTop: 'auto' }}>
                  <Select
                    disabled={disabled || Boolean(existingCustomer) || props.isRenewalContract}
                    className="form-select"
                    name='base url select'
                    value={baseURL}
                    onChange={(e) => {
                      setBaseURL(e.target.value)
                      setCustomer({
                        ...customer,
                        white_label_url: e.target.value + white_label_url
                      });
                    }}
                  >
                    <MenuItem value={"https://"}>https://</MenuItem>
                    <MenuItem value={"http://"}>http://</MenuItem>
                  </Select>
                </Grid> : null
              }
              {is_white_label ?
                <Grid item xs={7}>
                  <TextField
                    fullWidth
                    margin="normal"
                    variant="standard"
                    disabled={disabled || Boolean(existingCustomer) || props.isRenewalContract}
                    // error={
                    //   !!props.error?.validationErrors?.new_customer_details?.customer
                    //     ?.address?.postal_code
                    // }
                    value={white_label_url}
                    required
                    label="White-Label URL"
                    defaultValue={''}
                    onChange={(e) => {
                      set_white_label_url(e.target.value);
                      setCustomer({
                        ...customer,
                        white_label_url: baseURL + e.target.value
                      });
                    }}
                />  
              </Grid> : null
            }
          </Grid> : null
          }
      </div>
      <div>
        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="h6">Contract Details</Typography>
          </Grid>
          <Grid item xs={true} style={{flexGrow:"1"}}></Grid>
          <Grid item>
            <FormControlLabel control={
              <Switch 
                disabled={disabled || props.isRenewalContract}
                checked={isPhysicalContract}
                onChange={() => {
                  const value = !isPhysicalContract;
                  setIsPhysicalContract(value);
                }} />
            } label="Physical Contract" />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FormControl
              margin="normal"
              fullWidth
              required
              error={props.error?.validationErrors?.product_id}
            >
              <InputLabel variant="standard" id="select-label">Product</InputLabel>
              <Select
                variant="standard"
                disabled={disabled || props.isRenewalContract}
                value={product_id}
                labelId="select-product"
                id="select-product"
                onChange={(e: any) => {
                  setProductId(e.target.value as string);
                  props.products.map((product:ProductType)=>{
                    if(e.target.value as string == product.id){
                      setProductName(product.name)
                      if(product.name == "Intact"){
                          setIsIntact(true);
                          setIsDnc(false);
                      }else if(product.name == "SAMI"){
                          setIsDnc(true)
                          setIsIntact(false);
                      }else{
                          setIsDnc(false);
                          setIsIntact(false);
                      }
                    }
                  })
                }}
              >
                {props.products.map((product: ProductType) => {
                  if(!existing_product_id.includes(product.id)){
                    return (
                      <MenuItem key={product.id} value={product.id}>
                        {product.name}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={
              ((product_name == "Swarm" && package_prioriy < 3  && package_prioriy > 0) || 
              (product_name == "DnC" && package_prioriy == 1)) ? 4 : 3
            }>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={
                ((product_name == "Swarm" && package_prioriy < 3  && package_prioriy > 0) ||
                (product_name == "DnC" && package_prioriy == 1)) ? 10 : 12
                }>
                <FormControl
                  margin="normal"
                  fullWidth
                  required
                  error={props.error?.validationErrors?.package_name}
                >
                  <InputLabel variant="standard" id="select-label">Package</InputLabel>
                  <Select
                    variant="standard"
                    disabled={props.variant !== "edit"}
                    value={package_name}
                    labelId="select-package"
                    id="select-package"
                    onChange={(e: any) => {
                      let selectedPackage = props.packages?.
                      find(o=> o.package_name == e.target.value && o.product_id == product_id)
                      setPackageName(selectedPackage?.package_name || "")
                      setPackageCode(selectedPackage?.package_code || "")
                      setPackagePriority(selectedPackage?.priority || 1)
                      let price = Number(selectedPackage?.pricing.find((p)=> p.currency.toLocaleLowerCase() == currency.toLocaleLowerCase())?.value || 14)
                      if(price >= 0){
                        setSalesCode(price)
                      }else{
                        setSalesCode(0)
                      }
                      if(selectedPackage!=null){
                        setAddedFeatures(selectedPackage.features)
                      }
                    }}
                  >
                    {props.packages?.map((prodPackage: PackageType) => {
                      if(prodPackage.product_id === product_id){
                        return (
                          <MenuItem key={prodPackage.package_code} value={prodPackage.package_name}>
                            {prodPackage.package_name}
                          </MenuItem>
                        );
                      }
                    })}
                      {package_name.includes('+') && <MenuItem key={package_code} value={package_name}>
                            {package_name}
                      </MenuItem>}
                  </Select>
                </FormControl>
              </Grid>
              {((product_name == "Swarm" && package_prioriy < 3 && package_prioriy > 0) ||
              (product_name == "DnC" && package_prioriy == 1)) ? <Grid item xs={2}>
                <IconButton
                  edge="end"
                  aria-label="add user"
                  style={{height:'15px', width:'15px'}}
                  onClick={()=>{
                    setFeatureDialogOpen(true)
                  }}
                  size="large">
                        <PlaylistAddOutlined />
                </IconButton>
              </Grid> : <></> }
            </Grid>
          </Grid>
          <Grid item xs={((product_name == "Swarm" && package_prioriy < 3 && package_prioriy > 0)|| 
          (product_name == "DnC" && package_prioriy == 1)) ? 2 : 3}>
            <Autocomplete
              disabled={disabled || Boolean(sales_person.length)}
              clearOnEscape
              value={selectedPartner}
              size="small"
              onChange={(event: any, newValue: PartnerType | null) => {
                setSelectedPartner(newValue);
              }}
              inputValue={partnerInput}
              onInputChange={(event, newInputValue) => {
                setPartnerInput(newInputValue);
              }}
              id="partners"
              noOptionsText="No suggestions"
              options={partners}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} placeholder="Search" margin="normal" label="Partner" variant="standard" />
              )}
              renderOption={(options: any)=> (
                <TextField
                  variant="standard"
                  margin="none"
                  {...options}
                  InputProps={{ disableUnderline: true }}
                  value={options.key}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl
              margin="normal"
              fullWidth
              error={props.error?.validationErrors?.sales_person}
            >
              <InputLabel variant="standard" id="select-label">Sales Person</InputLabel>
              <Select
                variant="standard"
                value={sales_person}
                disabled={props.isInitialEditable ? false : disabled || Boolean(selectedPartner) || props.isRenewalContract}
                labelId="select-sales-person"
                id="select-sales-person"
                onChange={(e: any) => {
                  setSalesPerson(e.target.value as string);
                }}
              >
                <MenuItem key="none" value="">
                  None
                </MenuItem>
                {props.salesPeople.map(
                  (person: {
                    id: string;
                    first_name: string;
                    last_name: string;
                  }) => {
                    return (
                      <MenuItem key={person.id} value={person.id}>
                        {person.first_name} {person.last_name}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* { isIntact ? 
        <Grid container spacing={2}>
        <Grid item xs={4}>
        <FormControl
              fullWidth
              required
              error={props.error?.validationErrors?.sales_code}
            >
              <InputLabel id="select-label">Cost per user</InputLabel>
              <Input
                inputComponent="input"
                inputProps={{
                  type: "number",
                  step: 0.05
                }}
                // disabled={disabled}
                disabled={props.variant !== "edit"}
                value={user_cost}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                  setUserCost(Number(e.target.value))
                }
              />
            </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl
                fullWidth
                required
                error={props.error?.validationErrors?.sales_code}
              >
                <InputLabel id="select-label">Cost per key</InputLabel>
                <Input
                  inputComponent="input"
                  inputProps={{
                    type: "number",
                    step: 0.05
                  }}
                  // disabled={disabled}
                  disabled={props.variant !== "edit"}
                  value={key_cost}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                    setKeyCost(Number(e.target.value))
                  }
                />
              </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl
                fullWidth
                required
                error={props.error?.validationErrors?.sales_code}
              >
                <InputLabel id="select-label">Cost per Gb</InputLabel>
                <Input
                  inputComponent="input"
                  inputProps={{
                    type: "number",
                    step: 0.05
                  }}
                  // disabled={disabled}
                  disabled={props.variant !== "edit"}
                  value={dataSize_cost}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                    setDataSizeCost(Number(e.target.value))
                  }
                />
              </FormControl>
        </Grid>
      </Grid> : <></>} */}

        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FormControl
              margin="normal"
              fullWidth
              required
              error={props.error?.validationErrors?.sales_code}
            >
              <InputLabel variant="standard" id="select-label">Sales Code</InputLabel>
              <Input
                inputComponent="input"
                inputProps={{
                  type: "number",
                  min: 0,
                  max: 20,
                  step: 0.05,
                }}
                // disabled={disabled}
                disabled={props.variant !== "edit"}
                value={sales_code}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) =>{
                  if(Number(e.target.value) >= 0){
                    setSalesCode(Number(e.target.value))
                  }else{
                    setSalesCode(0)
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl
              margin="normal"
              fullWidth
              required
              error={props.error?.validationErrors?.currency}
            >
              <InputLabel variant="standard" id="currency">Currency</InputLabel>
              <Select
                variant="standard"
                value={currency}
                // disabled={disabled || props.isRenewalContract}
                disabled={props.variant !== "edit"}
                labelId="currency"
                id="currency"
                onChange={(e: any) => {
                  setCurrency(e.target.value as "usd" | "cad" | "gbp" | "");
                  let currentCurrency = e.target.value as "usd" | "cad" | "gbp" | "";
                  let selectedPackage = props.packages?.find(o=> o.package_name == package_name && o.product_id == product_id)
                  let salesCode = Number(selectedPackage?.pricing.find((p)=> p.currency.toLocaleLowerCase() == currentCurrency.toLocaleLowerCase())?.value || 14)
                  let addedFeaturesCode = 0.0
                  if(addedFeatures.length > 0){
                    addedFeatures.map((a)=>{
                      let feature = props.features.find((f)=> f.feature_code == a 
                      && f.package_code != package_code && package_prioriy!=3)?.price
                      if(feature != undefined){
                        let code = feature.find((p)=> p.currency.toLocaleLowerCase() == currentCurrency.toLocaleLowerCase())?.value
                        if(code != undefined){
                          addedFeaturesCode += code
                        }
                      }
                    })
                  }
                  let price = parseFloat((salesCode + addedFeaturesCode).toFixed(2))
                  if(price >= 0){
                    setSalesCode(price)
                  }else{
                    setSalesCode(0)
                  }
                }}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="cad">CAD</MenuItem>
                <MenuItem value="usd">USD</MenuItem>
                <MenuItem value="gbp">GBP</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              margin="normal"
              variant="standard"
              required
              // disabled={disabled || props.isRenewalContract}
              disabled={props.variant !== "edit"}
              error={props.error?.validationErrors?.taxes?.[0]?.description}
              inputRef={(ref) => (taxDescriptionRef = ref)}
              label="Tax Description"
              defaultValue={values?.taxes ? values?.taxes[0].description : ""}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              required
              fullWidth
              margin="normal"
              variant="standard"
              type="number"
              inputProps={{ min: 0 }}
              // disabled={disabled || props.isRenewalContract}
              disabled={props.variant !== "edit"}
              error={props.error?.validationErrors?.taxes?.[0]?.rate}
              inputRef={(ref) => (taxRateRef = ref)}
              label="Tax Rate %"
              defaultValue={values?.taxes ? values?.taxes[0].rate || null : null}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={4}>
            <FormControl margin="normal" fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={['year', 'month', 'day']}
                  inputFormat="dd MMM yyyy"
                  disabled={props.variant !== "edit" ? true : props.isInitialEditable ? false : disabled || props.isRenewalContract}
                  minDate={props.isRenewalContract ? contract_start_date : minDate}
                  maxDate={contract_end_date}
                  key="contract-start-date-id"
                  label="Contract Start"
                  value={contract_start_date || new Date()}
                  onChange={(date: Date | null) =>{
                    setContractStartDate(
                      moment(date).toDate()
                    )
                    prepaidDetail.payment_from = moment(date).startOf("month").format("yyyy-MM-DD")
                    setPrepaidFrom(prepaidDetail.payment_from)
                    setPrepaidDetail(prepaidDetail)
                  }}
                  onError={(reason,value)=>{

                  }}
                  renderInput={(props) => <TextField margin="normal" variant="standard" {...props} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl margin="normal" fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={['year', 'month', 'day']}
                  inputFormat="dd MMM yyyy"
                  disabled={props.variant !== "edit" ? true :  false}
                  minDate={contract_start_date}
                  key="contract-end-date-id"
                  label="Contract End*"
                  value={contract_end_date || new Date()}
                  onChange={(date: Date | null) =>{
                    setContractEndDate(
                      moment(date).endOf("month").toDate()
                    )
                    prepaidDetail.payment_to = moment(date).endOf("month").format("yyyy-MM-DD")
                    setPrepaidTo(prepaidDetail.payment_to)
                    setPrepaidDetail(prepaidDetail)
                  }}
                  renderInput={(props) => <TextField margin="normal" variant="standard" {...props} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={4} sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
            {props.isRenewalContract ? <div><p style={{fontWeight:450, marginTop:"50px"}}>Trial Availability Passed</p></div> :
            <>
              <FormControlLabel style={{marginTop:'20%',display:isAvailTrial?'none':'block' ,marginLeft:'15px'}} control={
                  <Switch 
                  style={{verticalAlign:'center'}}
                  disabled={props.variant !== "edit" ? true : props.isInitialEditable ? false : disabled || props.isRenewalContract}
                    checked={isAvailTrial}
                    onChange={() => {
                      const value = !isAvailTrial;
                      setIsAvailTrial(value);
                      setIsAvailOpen(value)
                    }} />
                } label="Avail Trial" />
                <FormControl margin="normal" fullWidth style={{display:isAvailTrial?'block':'none'}}
                onClick={()=>{
                  if (!isAvailOpen) {
                    setIsAvailOpen(!isAvailOpen)
                  }
                }}>
                <CustomDatePicker 
                  value={trial_end_date || contract_start_date}
                  availValue = {isAvailTrial}
                  availOpen = {isAvailOpen}
                  minDate={moment(contract_start_date).toDate()}
                  maxDate={moment(contract_end_date).toDate()}
                  onAvailChange={()=>{
                      const value = !isAvailTrial;
                      setIsAvailTrial(value);
                      setIsAvailOpen(value)
                  }}
                  onDateChange={(newDate:any)=>{
                    setIsAvailOpen(!isAvailOpen)
                    setTrialEndDate(
                      moment(newDate).toDate()
                    )
                  }}
                  disabled={props.variant !== "edit" ? true : (props.isInitialEditable ? false : 
                  isAvailTrial ? moment.utc(trial_end_date).add(1, "month").startOf("month").isBefore(moment()) : true)} />
                </FormControl>
              </>
              }
              {/* <LocalizationProvider dateAdapter={AdapterDateFns} >
                  <DatePicker
                    inputFormat="dd MMM yyyy"
                    // disabled={disabled || props.isRenewalContract}
                    disabled={props.variant !== "edit" || moment.utc(trial_end_date).local().add(1, "month").startOf("month").isBefore(moment())}
                    minDate={props.isRenewalContract ? values?.trial_end_date : contract_start_date}
                    maxDate={contract_end_date}
                    key="contract-end-date-id"
                    label="Trial End"
                    value={trial_end_date || contract_start_date}
                    onChange={(date: Date | null) => setTrialEndDate(
                      moment(date).startOf("day").add(1, "hour").toDate()
                    )}                
                    renderInput={(props) => <TextField margin="normal" variant="standard" {...props} />}
                  />
                </LocalizationProvider> */}
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{display: isPhysicalContract ? 'flex' : 'none'}}>
          <Grid item xs={3}>
            <FormControl margin="normal" fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={['year', 'month', 'day']}
                  inputFormat="dd MMM yyyy"
                  disabled={disabled || props.isRenewalContract}
                  minDate={props.isRenewalContract ? (physicalAcceptedAt||new Date()) : minDate}
                  key="customer-accepted-date-id"
                  label="Accepted At"
                  value={physicalAcceptedAt || new Date()}
                  onChange={(date: Date | null) =>
                    setPhysicalAcceptedAt(
                      moment(date).startOf("day").add(1, "hour").toDate()
                    )
                  }
                  onError={(reason,value)=>{

                  }}
                  renderInput={(props) => <TextField margin="normal" variant="standard" {...props} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl margin="normal" fullWidth>
              <TextField
                required
                fullWidth
                margin="normal"
                variant="standard"
                type="text"
                disabled={disabled || props.isRenewalContract}
                error={props.error?.validationErrors?.physical_contract_details?.accepted_by}
                label="Accepted by"
                inputRef={(ref) => (acceptedByRef = ref)}
                defaultValue={values?.physical_contract_details?.accepted_by || null}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl margin="normal" fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={['year', 'month', 'day']}
                  inputFormat="dd MMM yyyy"
                  disabled={disabled || props.isRenewalContract}
                  minDate={props.isRenewalContract ? (physicalApprovedAt||new Date()) : minDate}
                  key="approved-date-id"
                  label="Approved At"
                  value={physicalApprovedAt || new Date()}
                  onChange={(date: Date | null) =>
                    setPhysicalApprovedAt(
                      moment(date).toDate()
                    )
                  }
                  renderInput={(props) => <TextField margin="normal" variant="standard" {...props} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl margin="none" style={{marginTop:'16%'}} fullWidth>
              <InputLabel shrink={Boolean(physicalApprovedBy) || disabled || props.isRenewalContract} variant="standard" id="select-approve-label">Approved By</InputLabel>
              <Select
                variant="standard"
                value={physicalApprovedBy}
                disabled={disabled || props.isRenewalContract}
                labelId="select-approve-label"
                label="Approved By"
                id="select-approver-label"
                onChange={(e: any) => {
                  setPhysicalApprovedBy(e.target.value as string);
                }}
              >
                <MenuItem key="none" value="">
                  None
                </MenuItem>
                {props.approvers.map(
                  (person: {
                    id: string;
                    first_name: string;
                    last_name: string;
                  }) => {
                    return (
                      <MenuItem key={person.id} value={person.id}>
                        {person.first_name} {person.last_name}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </FormControl>

            {/* <FormControl margin="normal" fullWidth>
              <TextField
                required
                fullWidth
                margin="normal"
                variant="standard"
                type="text"
                inputRef={(ref) => (approvedByRef = ref)}
                disabled={disabled || props.isRenewalContract}
                error={props.error?.validationErrors?.physical_contract_details?.approved_by}
                label="Approved by"
                defaultValue={values?.physical_contract_details?.approved_by || null}
              />
            </FormControl> */}
          </Grid>
        </Grid>

      </div>

      <div>
        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
        <Typography variant="h6">User Details</Typography>

        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              margin="normal"
              variant="standard"
              error={
                !!props.error?.validationErrors?.new_customer_details?.user
                  ?.first_name
              }
              required
              disabled={
                disabled || props.isRenewalContract
              }
              label="First Name"
              defaultValue={user.first_name}
              onChange={(e) => {
                user.first_name = e.target.value;
                setUser(user);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="normal"
              variant="standard"
              error={
                !!props.error?.validationErrors?.new_customer_details?.user
                  ?.last_name
              }
              required
              disabled={
                disabled || 
                // Boolean(existingCustomer) ||
                 props.isRenewalContract
              }
              label="Last Name"
              defaultValue={user.last_name}
              onChange={(e) => {
                user.last_name = e.target.value;
                setUser(user);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="normal"
              variant="standard"
              error={
                !!props.error?.validationErrors?.new_customer_details?.user?.email
              }
              required
              label="Email"
              disabled={
                disabled ||
                props.isRenewalContract ||
                props.disableFields?.includes("email") 
                // || Boolean(existingCustomer)
              }
              defaultValue={user.email}
              onChange={(e) => {
                user.email = e.target.value.toLocaleLowerCase();
                setUser(user);
              }}
            />
          </Grid>
        </Grid>
      </div>

      <hr />
      <Typography variant="h6">Discount/Credit</Typography>

      <Tooltip title={isDiscountActive ? "Disable" : "Enable"}>
        <Switch
          disabled={props.variant !== "edit" ? true : props.isInitialEditable ? false : false}
          checked={isDiscountActive}
          onChange={() => {
            setIsDiscountActive(!isDiscountActive);
          }}
        />
      </Tooltip>
      <div style={{ display: isDiscountActive ? "block" : "none" }}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <FormControl margin="normal" fullWidth>
              <InputLabel variant="standard" id="discount-type">Type</InputLabel>
              <Select
                fullWidth
                variant="standard"
                error={Boolean(
                  props.error?.validationErrors?.invoice_deductions?.[0]?.type
                )}
                labelId="discount-type"
                id="discount-type"
                value={discountType}
                disabled={props.variant !== "edit" ? true : props.isInitialEditable ? false : false}
                onChange={(e: any) => {
                  setDiscountType(e.target.value as "percentage" | "fixed");
                }}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="percentage">Percentage</MenuItem>
                <MenuItem value="fixed">Fixed</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <TextField
              fullWidth
              margin="normal"
              variant="standard"
              error={Boolean(
                props.error?.validationErrors?.invoice_deductions?.[0]
                  ?.description
              )}
              required
              disabled={props.variant !== "edit" ? true : props.isInitialEditable ? false : false}
              label="Description"
              value={discountDescription}
              onChange={(e) => {
                setDiscountDescription(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl margin="normal" fullWidth required 
              disabled={props.variant !== "edit" ? true : props.isInitialEditable ? false : false}>
              <InputLabel variant="standard" id="discount-value">Value</InputLabel>
              <Input
                inputComponent="input"
                inputProps={{
                  type: "number",
                  min: 0,
                  max: discountType === "percentage" ? 100 : undefined,
                }}
                error={
                  !!props.error?.validationErrors?.invoice_deductions?.[0]
                    ?.amount
                }
                value={discountAmount}
                onChange={(e) => {
                  setDiscountAmount(Number(e.target.value));
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl margin="normal" fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat="dd MMM yyyy"
                  disabled={props.variant !== "edit" ? true : props.isInitialEditable ? false : false}
                  // onError={Boolean(
                  //   props.error?.validationErrors?.invoice_deductions?.[0]
                  //     ?.start_date
                  // )}
                  views={["year", "month"]}
                  key="discount-start-id"
                  label="Discount Start Month*"
                  value={discount_start}
                  onChange={(date: Date | null) =>
                    setDiscountStart(
                      moment(date).startOf("month").add(1, "hour").toDate()
                    )
                  }
                  renderInput={(props) => <TextField margin="normal" variant="standard" {...props} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl margin="normal" fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat="dd MMM yyyy"
                  disabled={props.variant !== "edit" ? true : props.isInitialEditable ? false : false}
                  views={["year", "month"]}
                  label="Discount End Month*"
                  value={discount_end}
                  // onError={Boolean(
                  //   props.error?.validationErrors?.invoice_deductions?.[0]
                  //     ?.end_date
                  // )}
                  onChange={(date: Date | null) =>
                    setDiscountEnd(
                      moment(date).endOf("month").subtract(1, "hour").toDate()
                    )
                  }
                  renderInput={(props) => <TextField margin="normal" variant="standard" {...props} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
        </Grid>
      </div>

      <hr />
      <div style={{display:'flex'}}>
        <Typography variant="h6">Prepaid</Typography>

        <Tooltip title={isPrepaidActive ? "Disable" : "Enable"}>
          <Switch
            disabled={ props.variant !== "edit" || (values?.is_prepaid && !props.isInitialEditable) || (!values?.terms_accepted && values?.status === 'approved') ? true : false}
            checked={isPrepaidActive}
            onChange={() => {
              setIsPrepaidActive(!isPrepaidActive);
            }}
          />
        </Tooltip>
      </div>

      <div style={{display:isPrepaidActive?'block':'none'}}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <TextField
                fullWidth
                margin="normal"
                variant="standard"
                error={props.error?.validationErrors?.prepaid_details?.payment_mode}
                required
                disabled={ props.variant !== "edit" || (values?.is_prepaid && !props.isInitialEditable) || (!values?.terms_accepted && values?.status === 'approved') ? true : false}
                label="Payment Mode"
                value={prepaidPaymentMode}
                onChange={(e) => {
                  prepaidDetail.payment_mode = e.target.value;
                  setPrepaidPaymentMode(prepaidDetail.payment_mode)
                  setPrepaidDetail(prepaidDetail)
                }}
              />
          </Grid>
          <Grid item xs={5}>
            <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    views={['year', 'month', 'day']}
                    inputFormat="dd MMM yyyy"
                    
                    disabled={ props.variant !== "edit" || (values?.is_prepaid && !props.isInitialEditable) || (!values?.terms_accepted && values?.status === 'approved') ? true : false}
                    minDate={props.isRenewalContract ? (new Date(prepaidDetail.paid_at)||new Date()) : minDate}
                    key="payment-date-id"
                    label="Paid At"
                    value={paidAt || new Date()}
                    onChange={(date: Date | null) =>{
                      prepaidDetail.paid_at = moment(date).format("yyyy-MM-DD");
                      setPrepaidDetail(prepaidDetail)
                      setPaidAt(prepaidDetail.paid_at)
                    }}
                    renderInput={(props) => <TextField 
                      {...props}
                      sx={{
                        svg: {color: payAtError},
                        input: { color:payAtError },
                        label: { color:payAtError  }
                    }} margin="normal" variant="standard"  />}
                  />
                </LocalizationProvider>
              </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
                  fullWidth
                  margin="normal"
                  variant="standard"
                  error={props.error?.validationErrors?.prepaid_details?.transaction_id}
                  required
                  disabled={ props.variant !== "edit" || (values?.is_prepaid && !props.isInitialEditable) || (!values?.terms_accepted && values?.status === 'approved') ? true : false}
                  label="Transaction Id"
                  value={prepaidTransactionId}
                  onChange={(e) => {
                    prepaidDetail.transaction_id = e.target.value;
                    setPrepaidTransactionId(prepaidDetail.transaction_id)
                    setPrepaidDetail(prepaidDetail)
                  }}
                />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
            <Grid item xs={props.variant !== "edit" || (values?.is_prepaid && !props.isInitialEditable) || (!values?.terms_accepted && values?.status === 'approved') ? 4 : 6}>
              <TextField
                    fullWidth
                    margin="normal"
                    variant="standard"
                    error={props.error?.validationErrors?.prepaid_details?.remarks}
                    required
                    disabled={ props.variant !== "edit" || (values?.is_prepaid && !props.isInitialEditable) || (!values?.terms_accepted && values?.status === 'approved') ? true : false}
                    label="Remarks"
                    value={prepaidRemarks}
                    onChange={(e) => {
                      prepaidDetail.remarks = e.target.value;
                      setPrepaidRemarks(prepaidDetail.remarks)
                      setPrepaidDetail(prepaidDetail);
                    }}
                  />
            </Grid>
            <Grid item xs={props.variant !== "edit" || (values?.is_prepaid && !props.isInitialEditable) || (!values?.terms_accepted && values?.status === 'approved') ? 4 : 6}>
              <FormControl margin="normal" fullWidth required>
                <InputLabel error={props.error?.validationErrors?.prepaid_details?.paid_amount} variant="standard" id="preiaid-amount-value">Paid Amount</InputLabel>
                <Input
                  inputComponent="input"
                  inputProps={{
                    type: "decimal"
                  }}
                  sx={{
                    input:{color: props.error?.validationErrors?.prepaid_details?.paid_amount ? '#d32f2f' : 'black'}
                  }}
                  error={props.error?.validationErrors?.prepaid_details?.paid_amount}
                  disabled={ props.variant !== "edit" || (values?.is_prepaid && !props.isInitialEditable) || (!values?.terms_accepted && values?.status === 'approved') ? true : false}
                  value={prepaidAmount}
                  onChange={(e) => {
                      prepaidDetail.paid_amount = Number(e.target.value);
                      setPrepaidAmount(Number(e.target.value))
                      setPrepaidDetail(prepaidDetail);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={props.variant !== "edit" || (values?.is_prepaid && !props.isInitialEditable) || (!values?.terms_accepted && values?.status === 'approved') ? 4 : 0 }>
              <FormControl margin="normal" fullWidth>
                <InputLabel variant="standard" id="remaining-amount-value">Remaining Amount</InputLabel>
                <Input
                  inputComponent="input"
                  inputProps={{
                    type: "decimal"
                  }}
                  disabled={ props.variant !== "edit" || (values?.is_prepaid && !props.isInitialEditable) || (!values?.terms_accepted && values?.status === 'approved') ? true : false}
                  style={{display : props.variant !== "edit" || (values?.is_prepaid && !props.isInitialEditable) || (!values?.terms_accepted && values?.status === 'approved') ? 'block' : 'none'}}
                  value={remainingAmount}
                />
              </FormControl>
            </Grid>
        </Grid>

        {/* <Grid container spacing={3}>
          <Grid item xs={4}>
            <FormControlLabel style={{marginTop:'10%'}} control={
                <Switch 
                style={{verticalAlign:'center'}}
                disabled={ props.variant !== "edit" || (values?.is_prepaid && !props.isInitialEditable) || (!values?.terms_accepted && values?.status === 'approved') ? true : false}
                checked={isPartialPayment}
                  onChange={() => {
                    prepaidDetail.is_partial_payment = !isPartialPayment;
                    if(!prepaidDetail.is_partial_payment){
                      prepaidDetail.payment_from = moment(contract_start_date).startOf("month").format("yyyy-MM-DD")
                      prepaidDetail.payment_to = moment(contract_end_date).endOf("month").format("yyyy-MM-DD")
                    }
                    setIsPartialPayment(prepaidDetail.is_partial_payment)
                    setPrepaidDetail(prepaidDetail)
                  }} />
              } label="Partial Payment" />
          </Grid>
          <Grid item xs={4} style={{display:prepaidDetail.is_partial_payment?'block':'none'}}>
            <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="MMM yyyy"
                    disabled={ props.variant !== "edit" || (values?.is_prepaid && !props.isInitialEditable) || (!values?.terms_accepted && values?.status === 'approved') ? true : false}
                    minDate={props.isRenewalContract ? (new Date(prepaidDetail.payment_from)||new Date()) : minDate}
                    key="payment-from-date-id"
                    label="Payment From"
                    views={["month","year"]}
                    value={prepaidFrom || new Date()}
                    onChange={(date: Date | null) =>{
                      prepaidDetail.payment_from = moment(date).startOf('month').format("yyyy-MM-DD")
                      setPrepaidFrom(prepaidDetail.payment_from)
                      setPrepaidDetail(prepaidDetail)
                    }}
                    renderInput={(props) => <TextField 
                      {...props}
                      sx={{
                          svg: { color: payFromError  },
                          input: { color:payFromError },
                          label: { color: payFromError  }
                      }}
                      margin="normal" 
                      variant="standard" 
                       />
                    }
                  />
                </LocalizationProvider>
              </FormControl>
          </Grid>
          <Grid item xs={4}  style={{display:prepaidDetail.is_partial_payment?'block':'none'}}>
            <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="MMM yyyy"
                    disabled={ props.variant !== "edit" || (values?.is_prepaid && !props.isInitialEditable) || (!values?.terms_accepted && values?.status === 'approved') ? true : false}
                    minDate={props.isRenewalContract ? (new Date(prepaidDetail.payment_to)||new Date()) : minDate}
                    key="payment-to-date-id"
                    label="Payment To"
                    views={["month","year"]}
                    value={prepaidTo || new Date()}
                    onChange={(date: Date | null) =>{
                      prepaidDetail.payment_to = moment(date).endOf('month').format("yyyy-MM-DD")
                      setPrepaidTo(prepaidDetail.payment_to)
                      setPrepaidDetail(prepaidDetail)
                    }}
                    renderInput={(props) => <TextField
                      {...props}
                      sx={{
                          svg: { color: payToError  },
                          input: { color: payToError },
                          label: { color: payToError  }
                      }}
                        margin="normal" variant="standard" />}
                  />
                </LocalizationProvider>
              </FormControl>
          </Grid>
        </Grid> */}

      </div>

      <hr />
      {!isDnc && <Typography variant="h6">License Details</Typography>}
      {!isDnc && <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormControl margin="normal" fullWidth required>
            {isIntact ? <InputLabel variant="standard" id="user-license-count">No of Licenses</InputLabel> :
            isDnc ? <InputLabel variant="standard" id="user-license-count">No of Users</InputLabel> :
            <InputLabel variant="standard" id="user-license-count">No of User Licenses</InputLabel>}
            <Input
              inputComponent="input"
              inputProps={{
                type: "number",
                min: 1,
                step: 1,
              }}
              disabled={
                // disabled ||
                // props.isRenewalContract ||
                props.variant !== "edit" ? true : props.isInitialEditable ? false : 
                customer_type === "reseller"
              }
              defaultValue={values?.user_license_count || 10}
              inputRef={(ref) => (userLicenseCountRef = ref)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} style={{visibility:(isIntact || isDnc)? 'hidden':'visible'}}>
          <FormControl margin="normal" fullWidth required>
            <InputLabel variant="standard" id="device-license-count">
              Device Per User
            </InputLabel>
            <Input
              inputComponent="input"
              inputProps={{
                type: "number",
                min: 1,
                step: 1,
              }}
              disabled={
                // disabled ||
                // props.isRenewalContract ||
                props.variant !== "edit" ? true : props.isInitialEditable ? false :
                customer_type === "reseller"
              }
              defaultValue={(values?.device_license_count || 100) / (values?.user_license_count || 1)}
              inputRef={(ref) => (deviceLicenseCountRef = ref)}
            />
          </FormControl>
        </Grid>
      </Grid>}
      <br />
      <br />
      {props.variant !== "view" ? <div>
        <SubmitButton
          loading={props.loading}
          label="Submit"
          disabled={props.variant !== "edit"}
          onClick={() => {
            var re = new RegExp(/^(?:(?:a-zA-Z0-9?\.)+[a-zA-Z]{2,6}\.?)$/);
            let phoneRe = /^\d{8,13}$/;
            let [protocol, url] = customer.white_label_url ?  customer.white_label_url?.split('://') : ['', ''];
            if (!phoneRe.test(customer.phone)) {
              enqueueSnackbar("Customer phone number must be between 8 and 13 digits.",{variant: 'error'});
              return;
            }
            if (customer.is_white_label) {
              // need to add a proper testing for urls here, depending on requirements.
              // if (!customer.white_label_url || !re.test(url)) {
              //   enqueueSnackbar("Invalid or empty domain added for White-Label URL", {variant:'error'});
              //   return;
              // }
            }
            if (notAllowedCharacterForEmail.test(customer.email)) {
              enqueueSnackbar('Customer email should not contain special characters', { variant: "error" })
              return;
            }  
            if (notAllowedCharacterForEmail.test(user.email)) {
              enqueueSnackbar('User email should not contain special characters', { variant: "error" })
              return;
            }  
            if (isDisabled) return;
            setIsDisabled(true);
            const values: NewCustomerContractInput = {
              product_id,
              sales_code,
              sales_person: sales_person.trim().length
                ? sales_person.trim()
                : null,
              partner: selectedPartner || null,
              package_code: package_code,
              package_features: addedFeatures,
              contract_start_date,
              contract_end_date,
              is_prepaid: isPrepaidActive,
              available_credit: prepaidDetail.paid_amount,
              prepaid_details: prepaidDetail,
              trial_end_date : isAvailTrial ? trial_end_date : moment(contract_start_date).startOf('day').toDate(),
              is_avail_trial : isAvailTrial,
              is_physical_contract: isPhysicalContract,
              physical_contract_details: {
                approved_by: physicalApprovedBy,
                approved_date: physicalApprovedAt,
                accepted_by: acceptedByRef?.value.trim(),
                accepted_date: physicalAcceptedAt
              },
              user_license_count: isDnc ? 1000 : userLicenseCountRef ? Number(userLicenseCountRef.value) : 10,
              device_license_count: isIntact ? Number(userLicenseCountRef.value) :
                                    isDnc ? 1000 :(Number(userLicenseCountRef.value) * Number(deviceLicenseCountRef.value)),
              currency,
              taxes: [
                {
                  description: taxDescriptionRef?.value.trim(),
                  rate: Number(taxRateRef?.value.trim()),
                },
              ],
              user,
              customer_id: existingCustomer || null,
              customer,

            };
            if (
              customer_type === "corporate-organization" ||
              customer_type === "home-organization"
            ) {
              values.subscription = {
                device_license_count: isIntact ? Number(userLicenseCountRef.value) : 
                                    isDnc ? 1000 : (Number(userLicenseCountRef.value) * Number(deviceLicenseCountRef.value)),
                user_license_count: isDnc ? 1000 : userLicenseCountRef ? Number(userLicenseCountRef.value) : 10,
              };
            }
            if (customer_type === "reseller") {
              values.is_white_label = customer.is_white_label;
              values.white_label_url = customer.white_label_url;
            }
            values.invoice_deductions = [];
            if (isDiscountActive) {
              values.invoice_deductions = [
                {
                  amount: discountAmount || 0,
                  description: discountDescription || "",
                  type: discountType || "",
                  start_date: discount_start
                    ? moment(discount_start).format(DATE_FORMAT)
                    : "",
                  end_date: discount_end
                    ? moment(discount_end).format(DATE_FORMAT)
                    : "",
                },
              ];
            }
            props.onSubmit(values,id);
            setTimeout(() => {
              setIsDisabled(false);
            },2000)
          }}
        />
      
        <Dialog fullWidth
        open={featureDialogOpen}>
          <DialogTitle>Add-On Features</DialogTitle>
              <DialogContent>
                <Grid container spacing={6}>
                  <Grid item xs={4}>
                    <span>
                      Package:
                    </span>
                    <span style={{fontWeight:'bold', marginLeft:'3px'}}>
                      {package_name}
                    </span>
                  </Grid>
                  <Grid item xs={4}>
                    <div style={{display:'flex', justifyContent:'center'}}>
                      <span>
                        Base Price:
                      </span>
                      <span style={{fontWeight:'bold', marginLeft:'3px'}}>
                        {props.packages.find(p=> p.package_name == package_name)?.pricing?.find(c=> c.currency.toLowerCase() == currency)?.value}
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div style={{display:'flex', justifyContent:'flex-end'}}>
                      <span>
                        Add-on Price:
                      </span>
                      <span style={{fontWeight:'bold', marginLeft:'3px'}}>
                        {parseFloat((sales_code - packagePrice(props.packages)).toString()).toFixed(2)}
                      </span>
                    </div>
                  </Grid>
                  {/* <Grid item xs={3}>
                    <FormControl
                      fullWidth>
                      <Select
                        value={featureCurrency}
                        // disabled={disabled || props.isRenewalContract}
                        labelId="currency"
                        id="currency"
                        onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                          setFeatureCurrency(e.target.value as "usd" | "cad" | "gbp");
                        }}
                      >
                        <MenuItem value="cad">CAD</MenuItem>
                        <MenuItem value="usd">USD</MenuItem>
                        <MenuItem value="gbp">GBP</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid> */}
                </Grid>
                <List>
                  {props.features.map((feature:FeatureType,index)=>{
                     let nextPackage = package_prioriy + 1
                    //  if(package_code == "SWARM_ESSENTIAL"){
                    //   nextPackage = "SWARM_SECURE_PLUS"
                    //  }else if(package_code == "SWARM_SECURE_PLUS"){
                    //   nextPackage = "SWARM_SECURE_ULTRA"
                    //  }
                     if(feature.priority == nextPackage && feature.product_id == product_id){
                      return (
                        <ListItem>
                          <Grid container spacing={2}>
                            <Grid container spacing={2} style={{marginTop:'5px'}}>
                              <Grid item xs={6}>
                                { index == 0 ? <div><label style={{fontWeight:'bold'}}>Feature</label><br/><br/></div> : <></>}
                                <label>{feature.feature}</label>
                              </Grid>
                              <Grid item xs={3}>
                                { index == 0 ? <div><label style={{fontWeight:'bold'}}>Cost</label><br/><br/></div> : <></>}
                                {feature.price !== null ? <label>
                                    {feature.price?.find((c)=> c.currency.toLocaleLowerCase() == currency)?.value}
                                    </label> : <></>}
                              </Grid>
                              <Grid item xs={3}>
                              { index == 0 ? <div><label style={{fontWeight:'bold'}}>Add</label><br/><br/></div> : <></>}
                                { addedFeatures.find((f)=> f == feature.feature_code) ?
                                <IconButton
                                  aria-label="Edit contract"
                                  style={{height:'15px',width:'15px'}}
                                  onClick={()=>{
                                    setAddedFeatures(addedFeatures.filter((f)=> f!= feature.feature_code))
                                    let fPrice = feature.price?.find((c)=> c.currency.toLocaleLowerCase() == "usd")?.value
                                    if(currency != ""){
                                      fPrice = feature.price?.find((c)=> c.currency.toLocaleLowerCase() == currency)?.value                                    
                                    }            
                                    let price = parseFloat((sales_code - ((fPrice!=undefined) ? fPrice : 0)).toFixed(2))
                                    if(price >= 0){
                                      setSalesCode(price)
                                    }else{
                                      setSalesCode(0)
                                    }   
                                  }}
                                  size="large">
                                  <CheckCircle 
                                  htmlColor="green"/>
                                </IconButton> : <IconButton
                                  aria-label="Edit contract"
                                  style={{height:'15px',width:'15px'}}
                                  onClick={()=>{
                                    setAddedFeatures([...addedFeatures,feature.feature_code])
                                    let fPrice = feature.price?.find((c)=> c.currency.toLocaleLowerCase() == "usd")?.value
                                    if(currency != ""){
                                      fPrice = feature.price?.find((c)=> c.currency.toLocaleLowerCase() == currency)?.value                                    
                                    }
                                    let price = parseFloat((sales_code + ((fPrice!=undefined) ? fPrice : 0)).toFixed(2))
                                    if(price >= 0){
                                      setSalesCode(price)
                                    }else{
                                      setSalesCode(0)
                                    }
                                  }}
                                  size="large">
                                  <AddCircleOutlineOutlined/>
                              </IconButton>}
                              </Grid>
                            </Grid>
                          </Grid>
                        </ListItem>
                      );
                     }
                  })}
                </List>
                <div style={{display:'flex', justifyContent:'flex-end'}}>
                  <Button
                      style={{ width: 150 }}
                      color="primary"
                      variant="contained"
                      onClick={() => {  
                        setFeatureDialogOpen(false);
                      }}
                    >Close</Button>
                </div>
              </DialogContent>
        </Dialog>
      </div> : null}
    </form>
  );
}


export default function Contracts() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  interface Params {
    id: string;
  }
  const [sortByDate, setSortByDate] = useState(true);
  const [physicalContractPrompt, setPhysicalContractPrompt] = useState({
    isPhysicalPrompt: false,
    startMonth: '',
    previousMonth: ''
  })
  
  const createContract = useSelector<
    Reducers,
    CreateNewCustomerContractReducer
  >((state) => state.autnhive.createNewCustomerContract);
  const contractsPage = useSelector<Reducers, ContractsPageReducer>(
    (state) => state.autnhive.contractsPage
  );
  return (
    <>
      <Typography component="h1" variant="h5">
        Contracts
      </Typography>
      <div>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <TextField
              size="small"
              label="Customer name"
              variant="outlined"
              defaultValue={contractsPage.customer_name}
              onChange={(e) => {
                dispatch(contractActions.updateSearchKeyword(e.target.value));
              }}
              onKeyPress={(e) => {
                if (e.key !== "Enter") {
                  return;
                }
                dispatch(
                  contractActions.fetchContracts(
                    {
                      customer_name: contractsPage.customer_name,
                      status: contractsPage.status,
                      sort: contractsPage.sort_by
                    },
                    1,
                    LIMIT
                  )
                );
              }}
            />
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id="status">Status</InputLabel>
              <Select
                fullWidth
                id="status"
                variant="standard"
                style={{ minWidth: "100px" }}
                onChange={(e: any) => {
                  let value = e.target.value as
                    | "draft"
                    | "approved"
                    | "rejected"
                    | undefined;
                  value = !value ? undefined : value;
                  dispatch(contractActions.updateStatusFilter(value));
                  dispatch(
                    contractActions.fetchContracts(
                      {
                        customer_name: contractsPage.customer_name,
                        status: value,
                        sort: contractsPage.sort_by
                      },
                      1,
                      LIMIT
                    )
                  );
                }} 
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="draft">Draft</MenuItem>
                <MenuItem value="approved">Approved</MenuItem>
                <MenuItem value="rejected">Rejected</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Permissioned
              resource={resources.AutnhiveContracts}
              action={actions.Create}
            >
              <RouteLink to="/admin/autnhive/contracts/new-customer/add">
                <Button
                  color="secondary"
                  variant="outlined"
                  startIcon={<ContractIcon />}
                >
                  New Contract
                </Button>
              </RouteLink>
            </Permissioned>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id="status">Sort By</InputLabel>
              <Select
                fullWidth
                id="status"
                variant="standard"
                style={{ minWidth: "100px" }}
                value={contractsPage.sort_by}
                onChange={(e: any) => {
                  dispatch(contractActions.updateSortByFilter(e.target.value));
                  dispatch(
                    contractActions.fetchContracts(
                      {
                        customer_name: contractsPage.customer_name,
                        status: contractsPage.status,
                        sort: e.target.value
                      },
                      1,
                      LIMIT
                    )
                  );
                }} 
              >
                <MenuItem value="create-date">Create Date</MenuItem>
                <MenuItem value="start-date">Start Date</MenuItem>
                <MenuItem value="name">Name</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
      <Routes>
      <Route path="/new-customer/add" element={<>
          <CreateNewCustomerContract
            error={createContract.error}
            onSubmit={(values: NewCustomerContractInput) => {
              if (values.is_prepaid) {
                if (!isValidDate(values.prepaid_details?.paid_at)) {
                  enqueueSnackbar("Paid at date is not in proper date form", {variant:'error'});
                  return
                }
                if (values.prepaid_details?.is_partial_payment) {
                  if (!isValidDate(values.prepaid_details?.payment_from)) {
                    enqueueSnackbar("Payment from date is not in proper date form", {variant:'error'});
                    return
                  }
                  if (!isValidDate(values.prepaid_details?.payment_to)) {
                    enqueueSnackbar("Payment to date is not in proper date form", {variant:'error'});
                    return
                  }
                }
              }
              Promise.resolve()
                .then(() =>
                  dispatch(
                    contractActions.createNewCustomerContract(values)
                  )
                )
                .then(() =>{
                   if(values.is_physical_contract){
                    if((values.contract_start_date ? moment(values.contract_start_date).month() : 0) < moment().subtract(1,'month').add(1,'day').month()){
                      setPhysicalContractPrompt({
                        isPhysicalPrompt: true,
                        startMonth: values.contract_start_date ? moment(values.contract_start_date).format("MMMM YYYY") : "",
                        previousMonth: moment().subtract(1,'month').add(1,'day').format('MMMM YYYY')
                      })
                    }
                   }
                   dispatch(
                    contractActions.fetchContracts(
                      {
                        customer_name: contractsPage.customer_name,
                        status: contractsPage.status,
                        sort: contractsPage.sort_by
                      },
                      contractsPage.page,
                      LIMIT
                    )
                  )
                })
                .then(() => navigate(-1))
                .then(() =>
                  enqueueSnackbar("Contract is created", {
                    variant: "success",
                  })
                )
                .catch((err: CustomError) =>
                  enqueueSnackbar(err.message, { variant: "error" })
                );
            }}
          />
        </>} />
        <Route path="/:id/view" element={<>
          <UpdateNewCustomerContract
            variant="view"
            onSubmit={() => { }}
          />
        </>} />
        <Route path="/:id/renew" element={<>
          <RenewCustomerContract
            onSuccess={() => {
              navigate(-1);
              dispatch(
                contractActions.fetchContracts(
                  {
                    customer_name: contractsPage.customer_name,
                    status: contractsPage.status,
                    sort: contractsPage.sort_by
                  },
                  contractsPage.page,
                  LIMIT
                )
              );
            }}
          />
        </>} />
        <Route path="/:id/edit" element={<>
          <UpdateNewCustomerContract
            variant="edit"
            onSubmit={(values: NewCustomerContractInput, id: string) => {
              if (values.is_prepaid) {
                if (!isValidDate(values.prepaid_details?.paid_at)) {
                  enqueueSnackbar("Paid at date is not in proper date form", {variant:'error'});
                  return
                }
                if (values.prepaid_details?.is_partial_payment) {
                  if (!isValidDate(values.prepaid_details?.payment_from)) {
                    enqueueSnackbar("Payment from date is not in proper date form", {variant:'error'});
                    return
                  }
                  if (!isValidDate(values.prepaid_details?.payment_to)) {
                    enqueueSnackbar("Payment to date is not in proper date form", {variant:'error'});
                    return
                  }
                }
              }
              const contract = contractsPage.contracts.find((c) => c.id === id);
              if (contract) {
                let customer: AutnhiveContractCustomerDraftInput | null = null;
                let user: AutnhiveContractCustomerUserDraftInput | null = null;
                if (contract.customer?.id) {
                  if (contract.customer.type === "reseller") {
                    customer = {
                      ...contract.customer.reseller,
                      customer_type: "reseller",
                      use_credit_card: contract.customer.use_credit_card,
                      allowed_domains: contract.customer.allowed_domains,
                      is_white_label: contract.customer.is_white_label,
                      white_label_url: contract.customer.white_label_url
                    };
                  } else if (
                    contract.customer.type === "home-organization" ||
                    contract.customer.type === "corporate-organization"
                  ) {
                    customer = {
                      ...contract.customer.organization,
                      customer_type: contract.customer.type,
                      use_credit_card: contract.customer.use_credit_card,
                      allowed_domains: contract.customer.allowed_domains,
                      subscription: contract.customer.subscription,
                      is_white_label: contract.customer.is_white_label,
                      white_label_url:contract.customer.white_label_url
                    };
                  }
                }
                const valueCopy = values;
                if (valueCopy.physical_contract_details && valueCopy.physical_contract_details.accepted_by === '') {
                  valueCopy.physical_contract_details.accepted_by = null;
                }
                if (contract?.customer?.primary_contact) {
                  user = contract.customer.primary_contact;
                }
                if (customer && valueCopy.customer) {
                  valueCopy.customer.allowed_domains = customer.allowed_domains
                };
                if (!valueCopy.is_prepaid) {
                  valueCopy.prepaid_details = null;
                }
                if (!valueCopy.is_physical_contract && !contract?.is_physical_contract) {
                  valueCopy.physical_contract_details = {
                    approved_by: contract?.physical_contract_details?.approved_by || null,
                    approved_date: contract?.physical_contract_details?.approved_date || null,
                    accepted_by: contract?.physical_contract_details?.accepted_by || null,
                    accepted_date: contract?.physical_contract_details?.accepted_date || null
                  }
                }
                const initialValues: NewCustomerContractInput = {
                  product_id: contract.product.id,
                  sales_code: contract.sales_code,
                  sales_person: contract.sales_person || null,
                  partner: contract.partner || null,
                  package_code: contract.package_code,
                  package_features: contract.package_features,
                  contract_start_date: moment(contract.contract_start_date).toDate(),
                  contract_end_date: moment(contract.contract_end_date).toDate(),
                  is_prepaid: contract.is_prepaid,
                  available_credit: contract.available_credit,
                  prepaid_details: contract.prepaid_details,
                  trial_end_date :!values.is_avail_trial && !contract.is_avail_trial ? new Date(values.trial_end_date) : new Date(contract.trial_end_date),
                  is_avail_trial : contract.is_avail_trial,
                  is_physical_contract: contract.is_physical_contract,
                  physical_contract_details: {
                    approved_by: contract.physical_contract_details?.approved_by || null,
                    approved_date: contract.physical_contract_details?.approved_date || null,
                    accepted_by: contract.physical_contract_details?.accepted_by || null,
                    accepted_date: contract.physical_contract_details?.accepted_date || null
                  },
                  user_license_count: contract.user_license_count,
                  device_license_count: contract.device_license_count,
                  currency: contract.currency,
                  taxes: [
                    {
                      description: contract.taxes[0].description,
                      rate: contract.taxes[0].rate
                    },
                  ],
                  user: user || contract.draft_details?.user || null,
                  customer_id: contract.customer?.id || null,
                  customer: customer || contract.draft_details?.customer || null,
                  subscription: {
                    device_license_count: contract.device_license_count,
                    user_license_count: contract.user_license_count
                  },
                  invoice_deductions: contract.invoice_deductions
                };
                const diff = JSON.stringify(valueCopy) === JSON.stringify(initialValues)
                if (diff) {
                  enqueueSnackbar('No changes made to contract.', {variant: 'error'});
                  return
                }
              }
              Promise.resolve()
                .then(() =>
                  dispatch(
                    contractActions.updateNewCustomerContract(
                      id,
                      values
                    )
                  )
                )
                .then(() =>
                  dispatch(
                    contractActions.fetchContracts(
                      {
                        customer_name: contractsPage.customer_name,
                        status: contractsPage.status,
                        sort: contractsPage.sort_by
                      },
                      contractsPage.page,
                      LIMIT
                    )
                  )
                )
                .then(() => navigate(-1))
                .then(() =>
                  enqueueSnackbar("Contract is updated", {
                    variant: "success",
                  })
                )
                .catch((err: CustomError) =>
                  enqueueSnackbar(err.message, { variant: "error" })
                );
            }}
          />
        </>} />
      </Routes>

      <Permissioned
        resource={resources.AutnhiveContracts}
        action={actions.Read}
      >
        <ContractsList />
      </Permissioned>

      <Dialog fullWidth
        open={physicalContractPrompt.isPhysicalPrompt}>
          <DialogTitle>You are creating a physical (offline) contract.</DialogTitle>
              <DialogContent>
                <div>
                Please remember to generate invoice(s) manually for the period of <b>{physicalContractPrompt.startMonth}</b> to <b>{physicalContractPrompt.previousMonth}</b> from Invoices in QBoard.  This has to be done before the next automated invoice generation that runs at the end of the month.  This manual operation is planned to be automated soon.
                </div>
                <div style={{display:'flex', justifyContent:'flex-end', marginTop:'20px'}}>
                  <Button
                      style={{ width: 100 }}
                      color="primary"
                      variant="contained"
                      onClick={() => {  
                        setPhysicalContractPrompt({
                          isPhysicalPrompt: false,
                          startMonth: '',
                          previousMonth: ''
                        });
                      }}
                    >OK</Button>
                </div>
              </DialogContent>
        </Dialog>

    </>
  );
}
