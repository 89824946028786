import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  Grid,
  Typography,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Divider,
  Input,
  Switch,
  Tooltip,
  Button
} from "@mui/material";
import {
  CustomError,
  AutnhiveContractType,
  CreateOrganizationOnboardingInput,
  OrganizationOnboardingInput,
  OrganizationOnboardingType,
  CountryType,
} from "../../services/interfaces";
import SubmitButton from "../../components/SubmitButton";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TagsInput from "../../components/tagInput";
import Multiselect from "multiselect-react-dropdown";
import * as onboardingActions from "../../actions/autnhive/organizations-onboardings";
import { useSnackbar } from "notistack";
import { ResellersPageReducer } from "../../reducers/autnhive";
import { GlobalReducer, Reducers } from "../../reducers";
import * as userProfileActions from "../../actions/userProfile";

interface OnboardingFormProps {
  loading: boolean;
  error?: CustomError;
  onSubmit: (values: CreateOrganizationOnboardingInput) => void;
  values?: OrganizationOnboardingType;
  disableFields?: Array<string>;
  disabled?: boolean;
  contracts: Array<AutnhiveContractType>;
  variant: string;
  closeModal: () => void;
}

export default function OnboardingForm(props: OnboardingFormProps) {
  const { values, disabled, contracts, closeModal } = props;
  let firstNameRef: HTMLInputElement;
  let lastNameRef: HTMLInputElement;
  let userEmailRef: HTMLInputElement;
  let nameRef: HTMLInputElement;
  let organizationEmailRef: HTMLInputElement;
  let phoneRef: HTMLInputElement;
  let line1Ref: HTMLInputElement;
  let line2Ref: HTMLInputElement;
  let cityRef: HTMLInputElement;
  let stateRef: HTMLInputElement;
  let postalCodeRef: HTMLInputElement;
  let countryRef: HTMLInputElement;
  let deviceLicenseCountRef: HTMLInputElement;
  let userLicenseCountRef: HTMLInputElement;
  let allowed_domains: string[];
  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    moment().add(1, "year").toDate()
  );
  const [contract_id, setContractId] = useState("");
  const [selectedPackages, setSelectedPackages] = useState<{ id: string; product: { id: string; name: string } }[]>([]);
  const [organization_type, setOrganizationType] = useState<
    "corporate" | "home"
  >("corporate");
  const [subDetails, setSubDetails] = useState<any>([]);
  // const [combinedData, setCombinedData] = useState<any>([]);
  const [inactiveProducts, setInactiveProducts] = useState<any>([]);
  const [detailsSorted, setDetailsSorted] = useState<boolean>(false);
  const flattenedContracts = contracts.map(contract => ({
    id: contract.id,
    product_name: contract.product.name
  }));
  const {countries} = useSelector<Reducers, GlobalReducer>(
    (state) => state.globe
  );
  useEffect(() => {
    if (!countries || countries.length <= 0) {
      dispatch(
        userProfileActions.fetchCountries()
      );
    }
  }, []);
  useEffect(() => {
    // setContractId(values?.subscription_details.contract_id || "");
    if (values?.organization_details.organization_type) {
      setOrganizationType(values.organization_details.organization_type);
    }

    if (props.variant === 'edit' && values?.subscription_details && !detailsSorted) {
      for (let i = 0; i < values?.subscription_details?.length; i++) {
        if (!values?.subscription_details[i]?.product_name) {
          values.subscription_details[i].product_name = flattenedContracts[i]?.product_name;
        }
      }
      const inactives: any[] = contracts.reduce((result: any[], contract: any) => {
        const sub = values?.subscription_details.find((s: any) => s.contract_id === contract.id);
        if (!sub) {
          result.push({
            ...contract,
            enabled: false,
            subscription_details: {
              contract_id: contract.id,
              product_name: contract.product.name,
              user_license_count: 10,
              device_license_count: 100,
              license_expires_at: moment().add(1, 'year').unix()
            }
          });
        }
        return result;
      }, []);
      setDetailsSorted(true);
      setInactiveProducts(inactives);
      setSubDetails(values?.subscription_details);
    }
    // if (values?.subscription_details.license_expires_at) {
    //   setSelectedDate(
    //     moment.unix(values.subscription_details.license_expires_at).toDate()
    //   );
    // }
  }, [values]);
  const status = values?.status;
  return (
    <>
      <form>
        <Typography variant="h6">Organization Details</Typography>
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <TextField
              margin="normal"
              variant="standard"
              fullWidth
              error={
                !!props.error?.validationErrors?.organization_details?.name
              }
              required
              disabled={disabled}
              label="Organization Name"
              defaultValue={values?.organization_details?.name}
              inputRef={(ref) => (nameRef = ref)}
            />
          </Grid>
          <Grid item xs={5}>
            <FormControl margin="normal" fullWidth>
              <InputLabel variant="standard">Organization Type</InputLabel>
              <Select
                variant="standard"
                disabled={disabled}
                defaultValue={
                  values?.organization_details.organization_type || "corporate"
                }
                onChange={(e) =>
                  setOrganizationType(e.target.value as "corporate" | "home")
                }
              >
                {[
                  {
                    value: "corporate",
                    label: "Corporate",
                  },
                  { value: "home", label: "Home" },
                ].map((contract) => (
                  <MenuItem key={contract.value} value={contract.value}>
                    {contract.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              fullWidth
              disabled={disabled}
              error={
                !!props.error?.validationErrors?.organization_details?.email
              }
              required
              label="Email"
              defaultValue={values?.organization_details.email}
              inputRef={(ref) => (organizationEmailRef = ref)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              fullWidth
              disabled={disabled}
              error={
                !!props.error?.validationErrors?.organization_details?.phone
              }
              required
              label="Phone"
              defaultValue={values?.organization_details.phone}
              inputRef={(ref) => (phoneRef = ref)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              fullWidth
              disabled={disabled}
              error={
                !!props.error?.validationErrors?.organization_details?.address
                  ?.line_1
              }
              required
              label="Address Line 1"
              defaultValue={values?.organization_details.address.line_1}
              inputRef={(ref) => (line1Ref = ref)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              fullWidth
              disabled={disabled}
              label="Address Line 2"
              defaultValue={values?.organization_details.address.line_2}
              inputRef={(ref) => (line2Ref = ref)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              fullWidth
              disabled={disabled}
              error={
                !!props.error?.validationErrors?.organization_details?.address
                  ?.city
              }
              required
              label="City"
              defaultValue={values?.organization_details.address.city}
              inputRef={(ref) => (cityRef = ref)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              fullWidth
              disabled={disabled}
              error={
                !!props.error?.validationErrors?.organization_details?.address
                  ?.state
              }
              required
              label="State"
              defaultValue={values?.organization_details.address.state}
              inputRef={(ref) => (stateRef = ref)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              fullWidth
              disabled={disabled}
              error={
                !!props.error?.validationErrors?.organization_details?.address
                  ?.postal_code
              }
              required
              label="Postal Code"
              defaultValue={values?.organization_details?.address?.postal_code}
              inputRef={(ref) => (postalCodeRef = ref)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl margin="normal" fullWidth>
              <InputLabel variant="standard">Country</InputLabel>
              <Select
                variant="standard"
                disabled={disabled}
                defaultValue={
                  values?.organization_details?.address?.country || "Canada"
                }
                inputRef={(ref) => (countryRef = ref)}
              >
                {countries?.map(
                  (country: CountryType) => (
                    <MenuItem key={country.id} value={country.name}>
                      {country.name}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl margin="normal" fullWidth>
              <TagsInput
                selectedTags={(items: any) => {
                  allowed_domains = items;
                }}
                tags={values?.organization_details?.allowed_domains || []}
                disabled={disabled}
                fullWidth
                id="tags"
                name="Allowed Domains"
                label="Allowed Domains"
                placeholder="Type and press Enter add domain"
              />
            </FormControl>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
        <Typography variant="h6">User Details</Typography>

        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              margin="normal"
              variant="standard"
              error={!!props.error?.validationErrors?.user_details?.first_name}
              required
              disabled={disabled}
              label="First name"
              defaultValue={values?.user_details.first_name}
              inputRef={(ref) => (firstNameRef = ref)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="normal"
              variant="standard"
              error={!!props.error?.validationErrors?.user_details?.last_name}
              required
              disabled={disabled}
              label="Last name"
              defaultValue={values?.user_details.last_name}
              inputRef={(ref) => (lastNameRef = ref)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="normal"
              variant="standard"
              error={!!props.error?.validationErrors?.user_details?.email}
              required
              label="Email"
              disabled={disabled}
              defaultValue={values?.user_details.email}
              inputRef={(ref) => (userEmailRef = ref)}
            />
          </Grid>
        </Grid>

        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
        <Typography variant="h6">Subscription Details</Typography>
        {props.variant === 'edit' && subDetails.map((detail: any, index: any) => {
          return (<div key={index}>
            {/* <h4>Product {index+1}.</h4> */}

            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControl
                  margin="normal"
                  fullWidth
                  required
                  error={
                    props.error?.validationErrors?.subscription_details?.contract_id
                  }
                  style={{ marginTop: "16px" }}
                >
                  <InputLabel variant="standard" id="select-label">Product</InputLabel>
                  <Select
                    variant="standard"
                    disabled={disabled}
                    labelId="select-product"
                    value={detail.contract_id}
                    id="select-product"
                    onChange={(e: any) => {
                      setSubDetails((prevState: any) => {
                        const newState = [...prevState];
                        newState[index].contract_id = e.target.value;
                        newState[index].product_name = e.target.options[e.target.selectedIndex]?.text || "";
                        return newState;
                      });
                    }}
                  >
                    <MenuItem value={detail.contract_id}>
                      {detail.product_name ? detail.product_name : ""}
                    </MenuItem>
                    {/* {props.contracts.map((contract: AutnhiveContractType) => {
                    return (
                      <MenuItem key={contract.id} value={contract.id}>
                        {contract.product.name}
                      </MenuItem>
                    );
                  })} */}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl margin="normal" fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      views={['year', 'month', 'day']}
                      disabled={disabled}
                      inputFormat="MM/dd/yyyy"
                      key="license-expire-at"
                      label="License Expires on"
                      value={detail.license_expires_at * 1000 || new Date()}
                      onChange={(date: Date | null) => setSelectedDate(date)}
                      renderInput={(props) => <TextField variant="standard" {...props} />}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel variant="standard" id="user-license-count">
                    User License Count
                  </InputLabel>
                  <Input
                    inputComponent="input"
                    inputProps={{
                      type: "number",
                      min: 1,
                      step: 1,
                    }}
                    defaultValue={
                      detail.user_license_count || 10
                    }
                    value={detail.user_license_count}
                    onChange={(e: any) => {
                      setSubDetails((prevState: any) => {
                        const newState = [...prevState];
                        newState[index].user_license_count = parseInt(e.target.value);
                        return newState;
                      });
                    }}
                  // inputRef={(ref) => (userLicenseCountRef = ref)}
                  />
                </FormControl>
              </Grid>
              {detail.product_name !== "SAMI" ?
                <Grid item xs={6}>
                  <FormControl margin="normal" fullWidth required>
                    <InputLabel variant="standard" id="device-license-count">
                      Device License Count
                    </InputLabel>
                    <Input
                      inputComponent="input"
                      inputProps={{
                        type: "number",
                        min: 1,
                        step: 1,
                      }}
                      onChange={(e: any) => {
                        setSubDetails((prevState: any) => {
                          const newState = [...prevState];
                          newState[index].device_license_count = parseInt(e.target.value);
                          return newState;
                        });
                      }}
                      value={detail.device_license_count}
                      inputRef={(ref) => (deviceLicenseCountRef = ref)}
                    />
                  </FormControl>
                </Grid>
                : null}
            </Grid>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          </div>)
        })}
        {props.variant === 'edit' && values?.status === "approved" && inactiveProducts.map((data: any, index: any) => {
          return (
            <div key={index}>
              <div>
                <div>
                  {!data.enabled ? 
                    <div>
                      <h4>{data.product.name} reseller license not activated for this org.</h4>
                      {/* <span style={{fontSize:'14x'}}>Toggle to configure below.</span> */}
                    </div> : 
                    <div>
                      <h4>{data.product.name} activation data.</h4>
                    </div>
                  }
                </div>
                <Tooltip
                  title={!data.enabled ? `Activate ${data.product.name}` : `Cancel Activation`}
                >
                  <Switch
                    checked={data.enabled}
                    onChange={(event: any) => {
                      setInactiveProducts((prevData: any) =>
                        prevData.map((item: any) =>
                          item.id === data.id ? { ...item, enabled: event.target.checked } : item
                        )
                      );
                    }}
                  />
                </Tooltip>
              </div>
              {data.enabled ?
                <div>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormControl margin="normal" fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            views={['year', 'month', 'day']}
                            disabled={false}
                            inputFormat="MM/dd/yyyy"
                            key="license-expire-at"
                            label="License Expires on"
                            value={new Date(inactiveProducts[index].subscription_details.license_expires_at * 1000)}
                            onChange={(date: Date | null) => setInactiveProducts((prevArray: any) => {
                              const updatedArray = [...prevArray]; // Create a copy of the array
                              const indexToUpdate = updatedArray.findIndex((item: any) => item.id === data.id);
                              if (indexToUpdate !== -1) {
                                updatedArray[indexToUpdate] = {
                                  ...updatedArray[indexToUpdate],
                                  subscription_details: {
                                    ...updatedArray[indexToUpdate].subscription_details,
                                    license_expires_at: moment(date, 'YYYY-MM-DD').unix()
                                  }
                                };
                              }
                              return updatedArray;
                            })}
                            renderInput={(props) => <TextField variant="standard" {...props} />}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl margin="normal" fullWidth required>
                        <InputLabel variant="standard" id="user-license-count">
                          User License Count
                        </InputLabel>
                        <Input
                          inputComponent="input"
                          inputProps={{
                            type: "number",
                            min: 1,
                            step: 1,
                          }}
                          defaultValue={
                            data.subscription_details.user_license_count || 10
                          }
                          value={data.subscription_details.user_license_count}
                          onChange={(e: any) => {
                            setInactiveProducts((prevState: any) => {
                              const newState = [...prevState];
                              newState[index].subscription_details.user_license_count = parseInt(e.target.value);
                              return newState;
                            });
                          }}
                        />
                      </FormControl>
                    </Grid>
                    {data.product.name !== "SAMI" ?
                      <Grid item xs={6}>
                        <FormControl margin="normal" fullWidth required>
                          <InputLabel variant="standard" id="device-license-count">
                            Device License Count
                          </InputLabel>
                          <Input
                            inputComponent="input"
                            inputProps={{
                              type: "number",
                              min: 1,
                              step: 1,
                            }}
                            onChange={(e: any) => {
                              setInactiveProducts((prevState: any) => {
                                const newState = [...prevState];
                                newState[index].subscription_details.device_license_count = parseInt(e.target.value);
                                return newState;
                              });
                            }}
                            value={data.subscription_details.device_license_count}
                          />
                        </FormControl>
                      </Grid>
                      : null}
                    <Grid item xs={6}>
                      <Button
                        variant="outlined"
                        // loading={props.loading}
                        // label="Activate"
                        onClick={() => {
                          dispatch(onboardingActions.addSubscriptionExistingCustomer(values?.id || '', data.subscription_details, values?.reseller.id))
                            .then((res: any) => {
                              enqueueSnackbar('Successfully added package to org.', {variant: "success"});
                              closeModal();
                            }).catch((err:any) => {
                              enqueueSnackbar(err.message, {variant: "error"});
                            })
                        }}>Activate {data.product.name}</Button>
                    </Grid>
                  </Grid>
                </div> : null
              }
            </div>
          )
        })
        }
        {props.variant === 'create' ?
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl
                margin="normal"
                fullWidth
                required
                error={
                  props.error?.validationErrors?.subscription_details?.contract_id
                }
                style={{ marginTop: "16px" }}
              >
                <Multiselect
                  id="select-package"
                  options={flattenedContracts}
                  placeholder="Package"
                  displayValue="product_name"
                  selectedValues={selectedPackages}
                  hidePlaceholder={true}
                  onSelect={(event) => {
                    setSelectedPackages(event);
                    setSubDetails((prevSubDetails: any) => {
                      const newSubDetails = [...prevSubDetails];
                      event.forEach((item: { id: string; product_name: string }) => {
                        if (!newSubDetails.some((detail: any) => detail.contract_id === item.id)) {
                          newSubDetails.push({
                            contract_id: item.id,
                            product_name: item.product_name,
                            user_license_count: 10,
                            device_license_count: 100,
                            license_expires_at: moment().add(1, 'year').unix()
                          });
                        }
                      });
                      return newSubDetails;
                    });
                  }}
                  onRemove={(event) => {
                    setSelectedPackages(event);
                    if (event.length === 0) {
                      setSubDetails([]);
                    } else {
                      event.forEach((item: { id: string; name: string }) => {
                        setSubDetails((prevSubDetails: any) => prevSubDetails.filter((detail: any) => detail.contract_id === item.id));
                      });
                    }
                  }}

                  showCheckbox
                ></Multiselect>
              </FormControl>
            </Grid>
          </Grid> : null}
        {props.variant === 'create' && subDetails.length > 0 && subDetails.map((detail: any, index: any) => (
          <>
            <h5><u>{detail.product_name} Product Details</u></h5>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControl margin="normal" fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      views={['year', 'month', 'day']}
                      disabled={disabled}
                      inputFormat="MM/dd/yyyy"
                      key="license-expire-at"
                      label="License Expires on"
                      value={detail.license_expires_at * 1000 || new Date()}
                      onChange={(e: any) => {
                        setSubDetails((prevState: any) => {
                          const newState = [...prevState];
                          newState[index].license_expires_at = moment(e).unix();
                          return newState;
                        });
                      }}
                      renderInput={(props) => <TextField variant="standard" {...props} />}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl margin="normal" fullWidth required>
                  <InputLabel variant="standard" id="user-license-count">
                    User License Count
                  </InputLabel>
                  <Input
                    inputComponent="input"
                    inputProps={{
                      type: "number",
                      min: 1,
                      step: 1,
                    }}
                    defaultValue={
                      detail.user_license_count || 10
                    }
                    value={detail.user_license_count}
                    onChange={(e: any) => {
                      setSubDetails((prevState: any) => {
                        const newState = [...prevState];
                        newState[index].user_license_count = parseInt(e.target.value);
                        return newState;
                      });
                    }}
                  // inputRef={(ref) => (userLicenseCountRef = ref)}
                  />
                </FormControl>
              </Grid>
              {detail.product_name !== "SAMI" ?
                <Grid item xs={6}>
                  <FormControl margin="normal" fullWidth required>
                    <InputLabel variant="standard" id="device-license-count">
                      Device License Count
                    </InputLabel>
                    <Input
                      inputComponent="input"
                      inputProps={{
                        type: "number",
                        min: 1,
                        step: 1,
                      }}
                      onChange={(e: any) => {
                        setSubDetails((prevState: any) => {
                          const newState = [...prevState];
                          newState[index].device_license_count = parseInt(e.target.value);
                          return newState;
                        });
                      }}
                      value={detail.device_license_count}
                    />
                  </FormControl>
                </Grid>
                : null}
            </Grid>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          </>))}
        <br />
        <br />
        <div>
          <SubmitButton
            loading={props.loading}
            label="Submit"
            // disabled={disabled}
            onClick={() => {
              const values: CreateOrganizationOnboardingInput = {
                user_details: {
                  first_name: firstNameRef.value.trim(),
                  last_name: lastNameRef?.value.trim(),
                  email: userEmailRef?.value.trim(),
                  address: {
                    country: countryRef?.value,
                  },
                },
                subscription_details: subDetails,
                // subscription_details: {
                //   contract_id,
                //   license_expires_at: Math.round(
                //     moment(selectedDate)
                //       .endOf("day")
                //       .subtract(10, "hours")
                //       .valueOf() / 1000
                //   ),
                //   device_license_count: Number(deviceLicenseCountRef.value),
                //   user_license_count: Number(userLicenseCountRef.value),
                // },
                organization_details: {
                  name: nameRef?.value.trim(),
                  email: organizationEmailRef?.value.trim(),
                  phone: phoneRef?.value.trim(),
                  organization_type,
                  address: {
                    line_1: line1Ref?.value.trim(),
                    line_2: line2Ref.value.trim(),
                    city: cityRef?.value.trim(),
                    state: stateRef?.value.trim(),
                    postal_code: postalCodeRef?.value.trim(),
                    country: countryRef?.value,
                  },
                  allowed_domains
                },
              };
              props.onSubmit(values);
            }}
          />
        </div>
      </form>
    </>
  );
}
