
import * as samiChatService from "../services/sami";
export const samiChatActions: any = {
  samiChatWillLoad: "SAMI_CHAT_WILL_LOAD",
  samiChatLoadSucceeded: "SAMI_CHAT_LOAD_SUCCEEDED",
  samiChatLoadFailed: "SAMI_CHAT_LOAD_FAILED",
  resetStates: "SAMI_CHAT_RESET_STATES",
};



// export const fetchSamiChatS: any =
//   (
//     { startDate,
//       endDate,
//     }: { startDate: Date; endDate: Date; keyword: string; status: string; },
//     page: number,
//     limit: number
//   ) =>
//   async (dispatch: any) => {
//     dispatch({
//       type: samiChatActions.samiChatWillLoad,
//       payload: {
//         page,
//         startDate,
//         endDate,
//       }
//     });
//     try {
//       dispatch({
//         type: samiChatActions.samiChatLoadSucceeded,
//         payload: await samiChatActions.fetchSamiChat(
//           {
//             startDate,
//             endDate,
            
//           },
//           page,
//           limit
//         ),
//       });
//     } catch (err) {
//       dispatch({
//         type: samiChatActions.samiChatLoadFailed,
//         payload: err,
//       });
//     }
//   };

export const fetchSamiChatSList =(startDate:Date| null,endDate:Date | null|undefined ,  page: number,limit: number) =>
  async (dispatch: any) => {
    dispatch({
      type: samiChatActions.samiChatWillLoad,
      payload: {
        page,
        startDate,
        endDate,
      },
    });
    try {
      dispatch({
        type: samiChatActions.samiChatLoadSucceeded,
        payload: await samiChatService.fetchSamiChatList(startDate,endDate, page, limit),
      });
    } catch (err) {
      dispatch({
        type: samiChatActions.samiChatLoadFailed,
        payload: err,
      });
    }
  };


export const resetStates = () => ({
  type: samiChatActions.resetStates,
});
