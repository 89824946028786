import { print } from "graphql";
import axios from "../axios-client";
import gql from "graphql-tag";
import { parseErrors } from "./helpers";
import { GraphqlError, LicenseType } from "./interfaces";
import { env } from '../env';

const apiUrl: string = env.REACT_APP_COMMON_API_URL;
const URL = env.REACT_APP_SWARM_BACKEND_URL;

export async function fetchLicenses(
  filters: { organization_id: string },
  searchKeyword: string,
  limit: number,
  page: number
): Promise<{ list: Array<LicenseType>; count: number }> {
  const query = gql`
    query getLicenseList($organization_id: String!, $limit: Int!, $page: Int!, $searchKeyword: String!) {
      getLicenseList(
        organization_id: $organization_id
        searchKeyword: $searchKeyword
        limit: $limit
        page: $page
      ) {
        list {
          id
          uid
          device_name
          subscription_id
          first_name
          last_name
          is_active
          use_count
          subscription {
            product {
              id
              name
            }
          }
          device_type
          device_display_name
          user_email
          license_slug
        }
        count
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<LicensesResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: {
        organization_id: filters.organization_id,
        searchKeyword: searchKeyword,
        limit,
        page,
      },
    },
    {
      headers: {
        "timestamp": `${timeStamp}`,
      }
    });
    return data.data.getLicenseList;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    } else {
      const graphqlError: GraphqlError = { message: err.message };
      throw parseErrors([graphqlError]);
    }
  }
}

export async function logFiles(
  method: any,
  url: string,
  data: any,
  headers: any,
  returnMessageOnly: boolean = false
) {
  try {
    const timeStamp = new Date().getTime();
    const response = await axios({
      method,
      url: `${URL}/${url}`,
      headers: { "timestamp": `${timeStamp}` },
      data,
    });
    if (returnMessageOnly) {
      return response.data.message;
    }
    return response.data.data.data || response.data.data.fileList || response.data.data;
  } catch (error) {
    throw error;
  }
}

export async function resetLicense(
  uid: string,
  device_name: string,
  subscription_id: string
): Promise<boolean> {
  const query = gql`
    mutation resetLicense(
      $subscription_id: String!
      $uid: String!
      $device_name: String!
    ) {
      resetLicense(
        subscription_id: $subscription_id
        uid: $uid
        device_name: $device_name
      )
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<{ data: boolean }>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: {
        uid,
        device_name,
        subscription_id,
      },
    },
    {
      headers: {
        "timestamp": `${timeStamp}`,
      }
    });
    return data.data;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    } else {
      const graphqlError: GraphqlError = { message: err.message };
      throw parseErrors([graphqlError]);
    }
  }
}

export async function updateLicenseStatus(
  uid: string,
  device_name: string,
  subscription_id: string,
  is_active: boolean
) {
  const query = gql`
    mutation updateLicenseStatus(
      $subscription_id: String!
      $uid: String!
      $device_name: String!
      $is_active: Boolean!
    ) {
      updateLicenseStatus(
        subscription_id: $subscription_id
        uid: $uid
        device_name: $device_name
        is_active: $is_active
      ) {
        id
        uid
        device_name
        subscription_id
        first_name
        last_name
        is_active
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<{ data: LicenseType }>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: {
          uid,
          device_name,
          subscription_id,
          is_active,
        },
      },
      {
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    return data.data;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    } else {
      const graphqlError: GraphqlError = { message: err.message };
      throw parseErrors([graphqlError]);
    }
  }
}

interface LicensesResponse {
  data: {
    getLicenseList: {
      list: [LicenseType];
      count: number;
    };
  };
}
