// src/TabBar.tsx
import React from 'react';

interface Tab {
  label: string;
  content: () => React.ReactNode;
}

interface TabBarProps {
  tabs: Tab[];
  activeTabIndex: number;
  onTabChange: (index: number) => void;
}

const TabBar: React.FC<TabBarProps> = ({ tabs, activeTabIndex, onTabChange }) => {

  const handleTabClick = (index: number) => {
    onTabChange(index);
  };

  return (
    <div className="tab-bar-container">
      <div className="tab-buttons">
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => handleTabClick(index)}
            className={index === activeTabIndex ? 'active' : ''}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="tab-content">
        {tabs[activeTabIndex].content()}
      </div>
    </div>
  );
};

export default TabBar;
