
import axios from "../axios-client";
import { env } from '../env';
import { parseErrors } from "./helpers";
import { MaterialMasterData } from "../reducers";
import { uploadTypes } from "../pages/materialMasterDataUpload";

const apiUrl: string = env.REACT_APP_DEVSECOPS_BACKEND_URL;

export async function fetchMaterialMasterDataList(type: uploadTypes, page: Number, limit: Number, capRange: String, sectorSlug: Array<string>) {

  try {
    const timeStamp = new Date().getTime();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let url = `${apiUrl}/devsecops/upload-material-risk-sectors`;

    switch(type) {
      case uploadTypes.ListOfSectors:
            url = `${apiUrl}/devsecops/material-risk-sectors-list`;
        break;
        case uploadTypes.CategoryList:
            url = `${apiUrl}/devsecops/material-risk-categories-list`;
        break;
        case uploadTypes.CategoryIssueMapping:
            url = `${apiUrl}/devsecops/material-risk-category-incident-mappings-paginated-list?page=${page}&limit=${limit}`;
        break;
        case uploadTypes.SectorSpecificMaterialRisks:
          {
            let sectorQueryString = sectorSlug.map(item => `sector_slug[]=${encodeURIComponent(item)}`).join("&");
            url = `${apiUrl}/devsecops/default-material-risk-costs-list?cap_range=${capRange}&${sectorQueryString}`;
          }
            
        break;
        default:
            url = '';
    }

    if (type == uploadTypes.CategoryIssueMapping) {
      const { data } = await axios.get<MaterialMasterDataPaginatedResponse>(url, { headers: { "timestamp": `${timeStamp}` } });
      return {data: data.data.data.list, count: data.data.data.count};
    } else {
      const { data } = await axios.get<MaterialMasterDataResponse>(url, { headers: { "timestamp": `${timeStamp}` } });
      return {data: data.data.data, count: 0};
    }
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}


interface MaterialMasterDataResponse {
  data: {
    data: [MaterialMasterData];
  };
}

interface MaterialMasterDataPaginatedResponse {
  data: {
    data: {
      list: [MaterialMasterData];
      count: number;
    } 
  };
}