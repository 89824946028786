import { PartnerFormInput } from "../services/interfaces";
import * as partnersService from "../services/partners";

export const partnersActions: any = {
  partnersWillLoad: "PARTNERS_WILL_SUBMIT",
  partnersLoadSucceeded: "PARTNERS_LOAD_SUCCEEDED",
  partnersLoadFailed: "PARTNERS_LOAD_FAILED",
  createPartner: "CREATE_PARTNER",
  createPartnerSucceeded: "CREATE_PARTNER_SUCCEEDED",
  createPartnerFailed: "CREATE_PARTNER_FAILED",
  dismissMessages: "CREATE_PARTNER_DISMISS_MESSAGES",
  updatePartner: "UPDATE_PARTNER",
  updatePartnerSucceeded: "UPDATE_PARTNER_SUCCEEDED",
  updatePartnerFailed: "UPDATE_PARTNER_FAILED",
};

export const fetchPartners =
  (limit: number, page: number) => async (dispatch: any) => {
    dispatch({ type: partnersActions.partnersWillLoad, payload: page });
    try {
      dispatch({
        type: partnersActions.partnersLoadSucceeded,
        payload: await partnersService.fetchPartners(limit, page),
      });
    } catch (err) {
      dispatch({
        type: partnersActions.partnersLoadFailed,
        payload: err,
      });
    }
  };

export const createPartner =
  (values: PartnerFormInput) => async (dispatch: any) => {
    dispatch({ type: partnersActions.createPartner });
    try {
      dispatch({
        type: partnersActions.createPartnerSucceeded,
        payload: await partnersService.createPartner(values),
      });
    } catch (e) {
      dispatch({
        type: partnersActions.createPartnerFailed,
        payload: e,
      });
      throw e;
    }
  };

export const updatePartner =
  (partner_id: string, values: PartnerFormInput) => async (dispatch: any) => {
    dispatch({ type: partnersActions.updatePartner });
    try {
      dispatch({
        type: partnersActions.updatePartnerSucceeded,
        payload: await partnersService.updatePartner(partner_id, values),
      });
    } catch (e) {
      dispatch({
        type: partnersActions.updatePartnerFailed,
        payload: e,
      });
      throw e;
    }
  };

export const dismissMessages = () => ({
  type: partnersActions.dismissMessages,
});
