import { combineReducers } from "redux";
import { CustomError, RoleType, UserType } from "../services/interfaces";
import { resellerUsersActions } from "../actions/resellers/users";

export interface ResellerUsersPageReducer {
  users: Array<UserType>;
  count: number;
  page: number;
  loading: boolean;
  searchKeyword: string;
}
export interface CreateResellerUserReducer {
  loading: boolean;
  success?: string;
  error?: CustomError;
  roles: Array<RoleType>;
}

export interface UpdateResellerUserReducer {
  loading: boolean;
  success?: string;
  error?: CustomError;
  roles: Array<RoleType>;
}

function resellerUsersPageReducer(
  prevState: ResellerUsersPageReducer = {
    searchKeyword: "",
    users: [],
    count: 0,
    page: 1,
    loading: false,
  },
  {
    type,
    payload,
  }: {
    type: string;
    payload: number | { list: [UserType]; count: number } | string;
  }
): ResellerUsersPageReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case resellerUsersActions.usersWillLoad:
      state.page = payload as number;
      state.loading = true;
      break;
    case resellerUsersActions.usersLoadSucceeded:
      state.loading = false;
      state.users = (payload as { list: [UserType] }).list;
      state.count = (payload as { count: number }).count;
      break;
    case resellerUsersActions.usersLoadFailed:
      state.loading = false;
      state.users = [];
      break;
    case resellerUsersActions.updateSearchKeyword:
      state.searchKeyword = payload as string;
      break;
    default:
  }
  return state;
}

function createResellerUserReducer(
  prevState: CreateResellerUserReducer = {
    loading: false,
    roles: [],
  },
  {
    type,
    payload,
  }: { type: string; payload: UserType | [RoleType] | CustomError }
): CreateResellerUserReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case resellerUsersActions.createUser:
      state.loading = true;
      delete state.success;
      delete state.error;
      break;
    case resellerUsersActions.createUserSucceeded:
      state.loading = false;
      state.success = "User is created";
      delete state.error;
      break;
    case resellerUsersActions.createUserFailed:
      state.loading = false;
      delete state.success;
      state.error = payload as CustomError;
      break;
    case resellerUsersActions.dismissUserMessages:
      delete state.error;
      delete state.success;
      break;
    case resellerUsersActions.rolesLoadSucceeded:
      state.roles = payload as [RoleType];
      break;
    case resellerUsersActions.rolesLoadFailed:
      state.roles = [];
      break;
    default:
  }
  return state;
}

function updateResellerUserReducer(
  prevState: UpdateResellerUserReducer = {
    loading: false,
    roles: [],
  },
  {
    type,
    payload,
  }: { type: string; payload: UserType | [RoleType] | CustomError }
): UpdateResellerUserReducer {
  const state = Object.assign({}, prevState);
  switch (type) {
    case resellerUsersActions.updateUser:
      state.loading = true;
      delete state.success;
      delete state.error;
      break;
    case resellerUsersActions.updateUserSucceeded:
      state.loading = false;
      state.success = "User is updated";
      delete state.error;
      break;
    case resellerUsersActions.updateUserFailed:
      state.loading = false;
      delete state.success;
      state.error = payload as CustomError;
      break;
    case resellerUsersActions.dismissUserMessages:
      delete state.error;
      delete state.success;
      break;
    case resellerUsersActions.rolesLoadSucceeded:
      state.roles = payload as [RoleType];
      break;
    case resellerUsersActions.rolesLoadFailed:
      state.roles = [];
      break;
    default:
  }
  return state;
}

export interface ResellerReducers {
  usersPage: ResellerUsersPageReducer;
  createUser: CreateResellerUserReducer;
  updateUser: UpdateResellerUserReducer;
}

export default combineReducers<ResellerReducers>({
  usersPage: resellerUsersPageReducer,
  createUser: createResellerUserReducer,
  updateUser: updateResellerUserReducer,
});
