import { print } from "graphql";
import axios from "../axios-client";
import gql from "graphql-tag";
import { parseErrors } from "./helpers";
import { ProductType } from "./interfaces";
import { env } from '../env';

const apiUrl: string = env.REACT_APP_COMMON_API_URL;

export async function fetchProducts(
  limit: number,
  page: number
): Promise<{ list: Array<ProductType>; count: number }> {
  const query = gql`
    query products($limit: Int!, $page: Int!) {
      products(limit: $limit, page: $page) {
        list {
          id
          name
          is_active
          internal_product_id
        }
        count
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<ProductsResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: { limit, page },
    },
    {
      headers: {
        "timestamp": `${timeStamp}`,
      }
    });
    return data.data.products;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    } else {
      throw err;
    }
  }
}

interface ProductsResponse {
  data: {
    products: {
      list: [ProductType];
      count: number;
    };
  };
}
