import { print } from "graphql";
import axios from "../../axios-client";
import gql from "graphql-tag";
import { parseErrors } from "../helpers";
import {
  UserType,
  GraphqlError,
  CreateUserFormInput,
  UserFormInput,
  RoleType,
} from "../interfaces";
import { env } from '../../env';

const apiUrl: string = env.REACT_APP_COMMON_API_URL;

export async function fetchUsers(filters: {
  limit: number;
  page: number;
}): Promise<{ list: Array<UserType>; count: number }> {
  const query = gql`
    query users($limit: Int!, $page: Int!) {
      autnhiveUsers(limit: $limit, page: $page) {
        list {
          id
          first_name
          last_name
          email
          is_active
          is_deleted
          user_org_roles {
            id
            role {
              id
              name
            }
          }
          address {
            country
          }
          role {
            id
            name
          }
        }
        count
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<UsersResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: filters,
    },{
      headers: {
        "timestamp": `${timeStamp}`,
      }
    });
    return data.data.autnhiveUsers;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function createUser(
  values: CreateUserFormInput
): Promise<UserType> {
  const query = gql`
    mutation createUser(
      $first_name: String!
      $last_name: String!
      $email: String!
      $role_id: String!
      $roles: [UserRoleInput!]!
      $address: MinimalAddressInput!
    ) {
      createAutnhiveUser(
        input: {
          first_name: $first_name
          last_name: $last_name
          email: $email
          role_id: $role_id
          roles: $roles
          address: $address
        }
      ) {
        id
        first_name
        last_name
        email
        address {
          country
        }
        is_active
        role {
          id
          name
        }
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<CreateUserResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        role_id: values.user_roles ? values.user_roles[0].role_id : "",
        address: values.address,
        roles: values.user_roles
      },
    },{
      headers: {
        "timestamp": `${timeStamp}`,
      }
    });
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.createAutnhiveUser;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function updateUser(
  id: string,
  values: UserFormInput
): Promise<UserType> {
  const query = gql`
    mutation updateUser(
      $id: String!
      $first_name: String!
      $last_name: String!
      $is_active: Boolean!
      $role_id: String!
      $roles: [UserRoleInput!]!
      $address: MinimalAddressInput!
    ) {
      updateAutnhiveUser(
        id: $id
        input: {
          first_name: $first_name
          last_name: $last_name
          is_active: $is_active
          role_id: $role_id
          address: $address
          roles: $roles
        }
      ) {
        id
        first_name
        last_name
        email
        address {
          country
        }
        is_active
        role {
          id
          name
        }
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<UpdateUserResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: {
        id: id,
        first_name: values.first_name,
        last_name: values.last_name,
        is_active: values.is_active,
        role_id: values.user_roles ? values.user_roles[0].role_id : "",
        roles: values.user_roles,
        address: values.address,
      },
    },{
      headers: {
        "timestamp": `${timeStamp}`,
      }
    });
    if (data.errors) {
      throw parseErrors(data.errors);
    }
    return data.data.updateAutnhiveUser;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function fetchRoles(
  limit: number,
  page: number
): Promise<{ list: Array<RoleType>; count: number }> {
  const query = gql`
    query roles($limit: Int!, $page: Int!, $role_for: String) {
      roles(limit: $limit, page: $page, role_for: $role_for) {
        list {
          id
          name
          role_for
          is_builtin
        }
        count
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<RolesResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: { limit, page, role_for: "autnhive" },
    },{
      headers: {
        "timestamp": `${timeStamp}`,
      }
    });
    return data.data.roles;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

interface UsersResponse {
  data: {
    autnhiveUsers: {
      list: [UserType];
      count: number;
    };
  };
}

interface CreateUserResponse {
  data: {
    createAutnhiveUser: UserType;
  };
  errors?: Array<GraphqlError>;
}

interface UpdateUserResponse {
  data: {
    updateAutnhiveUser: UserType;
  };
  errors?: Array<GraphqlError>;
}

interface RolesResponse {
  data: {
    roles: {
      list: [RoleType];
      count: number;
    };
  };
}
