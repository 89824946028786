import { useState, useEffect } from "react";
import {
  Backdrop,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  TextField,
  Switch,
  Tooltip,
  Grid,
  IconButton,
} from "@mui/material";
import { useSnackbar } from "notistack";
import RefreshIcon from "@mui/icons-material/Refresh";
import FileOpenRoundedIcon from '@mui/icons-material/FileOpenRounded';
import { Pagination } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from "react-redux";
import {
  CustomError,
  LicenseType,
  OrganizationType,
} from "../services/interfaces";
import Permissioned, { actions, resources } from "../permissioned";
import ConfirmButton from "../components/confirmButton";
import { GlobalReducer, LicensesPageReducer, Reducers } from "../reducers";
import * as licenseActions from "../actions/licenses";
const LIMIT = 20;

interface LicensesProps {}

export default function Licenses(props: LicensesProps) {
  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();
  const licensesPage: LicensesPageReducer = useSelector<
    Reducers,
    LicensesPageReducer
  >((state) => state.licensesPage);
  const globalState: GlobalReducer = useSelector<Reducers, GlobalReducer>(
    (state) => state.globe
  );
  const [selectedOrganization, setSelectedOrganization] =
    useState<OrganizationType | null>(null);
  const [searchKeyword, setSearchKeyword] = useState("")
  const [inputValue, setInputValue] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    const user = globalState.userProfile?.user;
    if (user?.role.role_for !== "autnhive") {
      dispatch(
        licenseActions.fetchLicenses(
          {
            organization_id: user?.organization?.id,
          },
          searchKeyword,
          LIMIT,
          1
        )
      );
    }
    return () => {
      dispatch(licenseActions.resetState());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(licenseActions.searchOrganizations(inputValue));
  }, [dispatch, selectedOrganization, inputValue]);

  useEffect(() => {
    if (selectedOrganization?.id) {
      dispatch(
        licenseActions.fetchLicenses(
          {
            organization_id: selectedOrganization.id
          },
          searchKeyword,
          LIMIT,
          1
        )
      );
    }
  }, [dispatch, selectedOrganization]);

  return (
    <>
      <Typography component="h1" variant="h5">
        Licenses
      </Typography>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          color: "#fff",
        }}
        open={showSpinner}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Permissioned
            resource={resources.Organizations}
            action={actions.Read}
          >
            <Autocomplete
              clearOnEscape
              value={selectedOrganization}
              size="small"
              onChange={(event: any, newValue: OrganizationType | null) => {
                setSelectedOrganization(newValue);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              id="organizations"
              noOptionsText="No suggestions"
              options={licensesPage.organizations}
              style={{ width: 300 }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search"
                  label="Organization"
                  variant="outlined"
                />
              )}
              renderOption={(options: any)=> (
                <TextField
                  variant="standard"
                  margin="none"
                  {...options}
                  InputProps={{ disableUnderline: true }}
                  value={options.key}
                />
              )}
            />
          </Permissioned>
        </Grid>
        <Grid item>
          <TextField
            size="small"
            label="Search Users"
            variant="outlined"
            onChange={(e) => {
              setSearchKeyword(e.target.value)
            }}
            onKeyPress={(e) => {
              if (e.key !== "Enter") {
                return;
              }
              dispatch(
                licenseActions.fetchLicenses(
                  {
                    organization_id: selectedOrganization?.id,
                  },
                  searchKeyword,
                  LIMIT,
                  1
                )
              );
            }}
          />
        </Grid>
      </Grid>
      {licensesPage.licenses.length ? (
        <>
          <List>
            {licensesPage.licenses.map((row: LicenseType, index) => (
              <ListItem
                key={row.id}
                sx={{
                  backgroundColor: index % 2 === 0 ? "#f1f1f1" : "inherit"
                }}
              >
                <ListItemText
                  primary={row.device_display_name}
                  secondary={
                    <>
                      User name: {row.first_name} {row.last_name} <br />
                      Product name: {row.subscription?.product.name} <br />
                      User Email: {row.user_email} <br />
                      <div style={{display:'flex'}}>
                        <div style={{
                          background:row.device_type == "WINDOWS" ? '#793FDF' : 
                          row.device_type == "IOS" ? '#164863' : row.device_type == 'ANDROID' ? '#9A3B3B' : '#435334',
                          color:'white',
                          borderRadius:'5px',
                          padding:'5px',
                          marginTop:'10px'
                        }}>{row.device_type.length > 0 ? row.device_type : "WINDOWS"}</div>
                      </div>
                      <br />
                    </>
                  }
                />
                <ListItemSecondaryAction style={{display:'flex',alignItems:'center'}}>
                  {row.device_type.toUpperCase() !== "ANDROID" && row.device_type.toUpperCase() !== "IOS" && (
                    <Permissioned 
                      resource={resources.QboardLicenses}
                      action={actions.UpdateStatus}
                    >
                    <div
                        className="device_log_icon"
                        style={{cursor:'pointer'}}
                        onClick={() => {
                          if (row.device_type.toUpperCase() !== "ANDROID" && row.device_type.toUpperCase() !== "IOS") {
                            setShowSpinner(true);
                            licenseActions.createNotification({
                              notificationType: "SendLogFiles",
                                licenseSlug: row.license_slug,
                                data: {
                                  type: "SendLogFiles",
                                },
                            })
                            .then(() => {
                              setShowSpinner(false);
                                enqueueSnackbar("Create Notification Saved Successfully", {
                                  variant: "success",
                                })
                            })
                            .catch(() => {
                              setShowSpinner(false);
                              enqueueSnackbar("Create Notification Not Saved", {
                                variant: "error",
                              });
                            });
                          }
                        }}
                      >
                        {row.device_type.toUpperCase() === 'ANDROID' || row.device_type.toUpperCase() === 'IOS' ? null : (
                          <>
                            <Tooltip title="Get logs from this device">
                              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="rgba(0, 0, 0, 0.54)" className="bi bi-file-earmark-arrow-up" viewBox="0 0 16 16">
                                <path d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707V11.5z"/>
                                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                              </svg>
                            </Tooltip>
                          </>
                        )}
                      </div> 
                    </Permissioned>
                  )}
                  <Permissioned
                    resource={resources.QboardLicenses}
                    action={actions.UpdateStatus}
                  >
                    <ConfirmButton
                      heading="Confirm"
                      message="Do you want to reset this license?"
                      component={IconButton}
                      onClick={() => {
                        dispatch(
                          licenseActions.resetLicense(
                            row.uid,
                            row.device_name,
                            row.subscription_id
                          )
                        ).then(() =>
                          enqueueSnackbar(
                            `License reset for ${row.uid}#${row.device_name} success`,
                            { variant: "success" }
                          )
                        );
                      }}
                    >
                      <Tooltip title="Reset License">
                        <RefreshIcon />
                      </Tooltip>
                    </ConfirmButton>
                  </Permissioned>
                  <Permissioned
                    resource={resources.QboardLicenses}
                    action={actions.UpdateStatus}
                  >
                    <Tooltip title={row.is_active ? "Disable" : "Enable"}>
                      <Switch
                        checked={row.is_active}
                        onChange={() => {
                          dispatch(
                            licenseActions.updateLicenseStatus(
                              row.uid,
                              row.device_name,
                              row.subscription_id,
                              !row.is_active
                            )
                          )
                            .then(() =>
                              dispatch(
                                licenseActions.fetchLicenses(
                                  {
                                    organization_id: selectedOrganization?.id
                                  },
                                  searchKeyword,
                                  LIMIT,
                                  1
                                )
                              )
                            )
                            .then(() =>
                              enqueueSnackbar(
                                `License ${
                                  row.is_active ? "disabled" : "enabled"
                                }`,
                                { variant: "success" }
                              )
                            )

                            .catch((err: CustomError) =>
                              enqueueSnackbar(err.message, {
                                variant: "error",
                              })
                            );
                        }}
                        color="primary"
                      />
                    </Tooltip>
                  </Permissioned>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          <Pagination
            count={Math.ceil(licensesPage.count / LIMIT)}
            page={licensesPage.page}
            variant="outlined"
            shape="rounded"
            onChange={(e: React.ChangeEvent<unknown>, page: number) => {
              dispatch(
                licenseActions.fetchLicenses(
                  {
                    organization_id: selectedOrganization?.id,
                  },
                  searchKeyword,
                  LIMIT,
                  page
                )
              );
            }}
          />
        </>
      ) : selectedOrganization ? (
        "No licenses for this organization"
      ) : (
        "Search and select an organization"
      )}
    </>
  );
}
