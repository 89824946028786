import { print } from "graphql";
import axios from "../../axios-client";
import gql from "graphql-tag";
import { parseErrors } from "../helpers";
import {
  ContractType,
  GraphqlError,
  OrganizationType,
  ProductType,
  SubscriptionDetailsType,
  SubscriptionHistoryType,
  SubscriptionType,
} from "../interfaces";
import { env } from '../../env';

const apiUrl: string = env.REACT_APP_COMMON_API_URL;

export async function fetchProductSubscriptions(
  organization_id: string | undefined,
  page: number,
  limit: number
): Promise<{ list: Array<SubscriptionType>; count: number }> {
  const query = gql`
    query subscriptions($organization_id: String!, $limit: Int!, $page: Int!) {
      subscriptions(
        organization_id: $organization_id
        page: $page
        limit: $limit
      ) {
        list {
          id
          subscription_type
          license_type
          license_expires_at
          max_grace
          max_grace_period
          license_purchased_count
          device_license_purchased_count
          product {
            id
            name
          }
          organization {
            id
            name
          }
        }
        count
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<ProductSubscriptionsResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: { organization_id, page, limit },
      },{
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    data.data.subscriptions.list.forEach((sub) => {
      sub.is_active = true;
    });
    return data.data.subscriptions;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function fetchContracts({
  organization_id,
  reseller_id,
  check_is_approved
}: {
  organization_id?: string;
  reseller_id?: string;
  check_is_approved?: boolean;
}): Promise<Array<ContractType>> {
  const query = gql`
    query contracts($organization_id: ID, $reseller_id: ID, $check_is_approved: Boolean!) {
      contracts(organization_id: $organization_id, reseller_id: $reseller_id, check_is_approved: $check_is_approved) {
        id
        product {
          id
          name
        }
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<ContractsResponse>(`${apiUrl}/graphql`, {
      query: print(query),
      variables: { organization_id, reseller_id, check_is_approved },
    },{
      headers: {
        "timestamp": `${timeStamp}`,
      }
    });
    return data.data.contracts;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function fetchSubscriptionsHistory(
  {
    subscription_id,
    start_date,
    end_date,
  }: {
    subscription_id: string;
    start_date: Date | null;
    end_date: Date | null;
  },
  page: number,
  limit: number
): Promise<{ list: [SubscriptionHistoryType]; count: number }> {
  const query = gql`
    query subscriptionsHistory(
      $subscription_id: String
      $start_date: DateTime
      $end_date: DateTime
      $page: Int!
      $limit: Int!
    ) {
      subscriptionsHistory(
        subscription_id: $subscription_id
        start_date: $start_date
        end_date: $end_date
        page: $page
        limit: $limit
      ) {
        list {
          id
          action
          changes {
            old
            new
          }
          user {
            id
            first_name
            last_name
            email
          }
          subscription {
            id
          }
          user_subscription {
            id
            user {
              id
              first_name
              last_name
              email
            }
          }
          license_purchase {
            id
            purchase_hash
          }
          created_timestamp
        }
        count
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<SubscriptionsHistoryResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: { subscription_id, start_date, end_date, page, limit },
      },{
        headers: {
          "timestamp": `${timeStamp}`,
        }
      }
    );
    return data.data.subscriptionsHistory;
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

export async function addSubscriptionExistingCustomer(
  organization_id: string,
  input: SubscriptionDetailsType,
  reseller_id?: string
  ): Promise<{success: boolean, message: string, subscription: SubscriptionType}> {
  const query = gql`
    mutation addSubscriptionExistingCustomer(
      $organization_id: String!
      $input: AddSubscriptionInput!
      $reseller_id: String
    ) {
      addSubscriptionExistingCustomer(
        organization_id: $organization_id
        input: $input
        reseller_id: $reseller_id
      ) {
        id
      }
    }
  `;
  try {
    const timeStamp = new Date().getTime();
    const { data } = await axios.post<AddSubscriptionExistingCustomerResponse>(
      `${apiUrl}/graphql`,
      {
        query: print(query),
        variables: { organization_id, input, reseller_id },
      },
      {
        headers: {
          "timestamp": `${timeStamp}`
        }
      }
    );
    if (data.errors) {
      throw parseErrors(data.errors);
    }
   
    return {subscription: data.data.subscription, success: true, message: 'Successfully added package'};
  } catch (err: any) {
    if (err.response?.data) {
      throw parseErrors(err.response?.data.errors);
    }
    throw err;
  }
}

interface ContractsResponse {
  data: {
    contracts: Array<ContractType>;
  };
}

interface ProductSubscriptionsResponse {
  data: {
    subscriptions: {
      list: Array<{
        id: string;
        license_purchased_count: number;
        device_license_purchased_count: number;
        max_grace: number;
        max_grace_period: number;
        subscription_type: string;
        license_type: string;
        is_active: boolean;
        license_expires_at: number;
        product: ProductType;
        organization: OrganizationType;
      }>;
      count: number;
    };
  };
}

interface SubscriptionsHistoryResponse {
  data: {
    subscriptionsHistory: {
      list: [SubscriptionHistoryType];
      count: number;
    };
  };
}

interface AddSubscriptionExistingCustomerResponse {
  data: {
    subscription: SubscriptionType
  };
  errors?: Array<GraphqlError>;
}
