import { uploadTypes } from "../pages/materialMasterDataUpload";
import * as materialMasterDataService from "../services/materialMasterData";

export const materialMasterDataActions: any = {
    materialMasterDataWillLoad: "MATERIAL_MASTER_DATA_WILL_LOAD",
    materialMasterDataLoadSucceeded: "MATERIAL_MASTER_DATA_LOAD_SUCCEEDED",
    materialMasterDataLoadFailed: "MATERIAL_MASTER_DATA_LOAD_FAILED",
    resetStates: "MATERIAL_MASTER_DATA_RESET_STATES",
  };
  

export const fetchMaterialMasterDataList = (type: uploadTypes, page:Number, limit:Number, capRange: String, sectorSlug: Array<string>) =>
    async (dispatch: any) => {
        dispatch({
            type: materialMasterDataActions.materialMasterDataWillLoad
        });
        try {
            dispatch({
                type: materialMasterDataActions.materialMasterDataLoadSucceeded,
                payload: await materialMasterDataService.fetchMaterialMasterDataList(type, page, limit, capRange, sectorSlug),
            });
        } catch (err) {
            dispatch({
                type: materialMasterDataActions.materialMasterDataLoadFailed,
                payload: err,
            });
        }
    };

    export const resetStates = () => ({
        type: materialMasterDataActions.resetStates,
      });
      