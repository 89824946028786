import React, { useState, useEffect, useRef } from "react";
import {
  Tooltip,
  IconButton,
  Grid,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  List,
  ListItem,
  ListItemSecondaryAction,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import DatePicker from '@mui/lab/DatePicker';
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import ReleaseIcon from "@mui/icons-material/DynamicFeed";
import AddVariantIcon from "@mui/icons-material/Unarchive";
import VariantListIcon from "@mui/icons-material/Reorder";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import { Pagination } from '@mui/material';
import {
  Routes,
  Route,
  Link as RouteLink,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useSnackbar } from "notistack";
import Permissioned, { actions, resources } from "../../permissioned";
import {
  ReleaseInput,
  CreateReleaseVariantInput,
  CustomError,
  ProductType,
  ReleaseType,
  ReleaseVariantType,
} from "../../services/interfaces";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateReleaseReducer,
  CreateReleaseVariantReducer,
  ReleasesPageReducer,
  UpdateReleaseReducer,
} from "../../reducers/autnhive";
import { Reducers } from "../../reducers";
import * as releaseActions from "../../actions/autnhive/releases";
import SubmitButton from "../../components/SubmitButton";
import ConfirmButton from "../../components/confirmButton";

const LIMIT = 20;

interface ReleaseFormProps {
  error?: CustomError;
  loading: boolean;
  onSubmit: (values: ReleaseInput) => void;
  values?: ReleaseInput;
  products: Array<ProductType>;
}

interface CreateReleaseProps {
  onSubmit: (values: ReleaseInput) => void;
}

interface UpdateReleaseProps {
  onSubmit: (values: ReleaseInput) => void;
  release_id: string;
}

interface ReleaseVariantFormProps {
  error?: CustomError;
  loading: boolean;
  onSubmit: (values: { platform: string; files: FileList }) => void;
  values?: { platform: string; files: FileList };
}

interface CreateReleaseVariantProps {
  release_id: string;
  onSubmit: (values: CreateReleaseVariantInput) => void;
}

function ReleaseForm(props: ReleaseFormProps) {
  const { values } = props;
  const [version, setVersion] = useState("");
  const [description, setDescription] = useState("");
  const [product_id, setProductId] = useState("");
  const [start_date, setStartDate] = useState<Date | null>(new Date());
  const [end_date, setEndDate] = useState<Date | null>(
    moment().add(1, "year").toDate()
  );

  useEffect(() => {
    setVersion(values?.version || "");
    setDescription(values?.description || "");
    setProductId(values?.product_id || "");
    setStartDate(moment(values?.start_date || new Date()).toDate());
    setEndDate(moment(values?.end_date || new Date()).toDate());
  }, [values]);

  return (
    <>
      <form>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              error={!!props.error?.validationErrors?.version}
              required
              label="Version"
              value={version}
              onChange={(e) => setVersion(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl
              required
              error={props.error?.validationErrors?.product_id}
            >
              <InputLabel id="select-label">Product</InputLabel>
              <Select
                value={product_id}
                labelId="select-subscription-type"
                style={{ width: 190 }}
                id="select-product"
                onChange={(e: any) => {
                  setProductId(e.target.value as string);
                }}
              >
                {props.products.map((product: ProductType) => {
                  return (
                    <MenuItem key={product.id} value={product.id}>
                      {product.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <DatePicker
              // style={{ width: "190px" }}
              views={['year', 'month', 'day']}
              inputFormat="MM/dd/yyyy"
              key="date-picker-inline-start-date"
              label="Start date*"
              value={moment(start_date).toDate()}
              onChange={(date: Date | null) => setStartDate(date)}
              renderInput={(props) => <TextField {...props} />}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              // style={{ width: "190px" }}
              views={['year', 'month', 'day']}
              inputFormat="MM/dd/yyyy"
              key="date-picker-inline-end-date"
              label="End date*"
              value={moment(end_date).toDate()}
              onChange={(date: Date | null) => setEndDate(date)}
              renderInput={(props) => <TextField {...props} />}
            />
          </Grid>
        </Grid>
        <FormControl fullWidth>
          <TextField
            fullWidth
            multiline
            error={!!props.error?.validationErrors?.description}
            label="Description"
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </FormControl>
        <br />
        <br />
        <div>
          <SubmitButton
            loading={props.loading}
            label="Submit"
            onClick={() => {
              props.onSubmit({
                version: version.trim(),
                product_id,
                description: description.trim(),
                start_date,
                end_date,
              });
            }}
          />
        </div>
      </form>
    </>
  );
}

function CreateRelease(props: CreateReleaseProps) {
  const createRelease = useSelector<Reducers, CreateReleaseReducer>(
    (state) => state.autnhive.createRelease
  );
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    dispatch(releaseActions.fetchProducts());
  }, [dispatch]);

  const onSubmit = (values: ReleaseInput) => {
    dispatch(releaseActions.createRelease(values))
      .then(() => enqueueSnackbar("Release created", { variant: "success" }))
      .then(() => props.onSubmit(values))
      .catch(() =>
        enqueueSnackbar("Unable to create release", { variant: "error" })
      );
  };

  return (
    <Dialog open={true} onClose={() => navigate(-1)}>
      <DialogTitle>Create Release</DialogTitle>
        <DialogContent>
          <ReleaseForm
            error={createRelease.error}
            products={createRelease.products}
            loading={createRelease.loading}
            onSubmit={onSubmit}
          />
      </DialogContent>
    </Dialog>
  );
}

function UpdateRelease(props: UpdateReleaseProps) {
  const updateRelease = useSelector<Reducers, UpdateReleaseReducer>(
    (state) => state.autnhive.updateRelease
  );
  const releases = useSelector<Reducers, ReleasesPageReducer>(
    (state) => state.autnhive.releasesPage
  );
  const release = releases.releases.find((r) => r.id === props.release_id);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    dispatch(releaseActions.fetchProducts());
  }, [dispatch]);

  const onSubmit = (values: ReleaseInput) => {
    dispatch(releaseActions.updateRelease(props.release_id, values))
      .then(() => enqueueSnackbar("Release updated", { variant: "success" }))
      .then(() => props.onSubmit(values))
      .catch(() =>
        enqueueSnackbar("Unable to update release", { variant: "error" })
      );
  };

  if (!release) {
    return null;
  }
  const values: ReleaseInput = {
    version: release.version,
    product_id: release.product.id,
    description: release.description,
    start_date: moment(release.start_date).toDate(),
    end_date: moment(release.end_date).toDate(),
  };
  return (
    <Dialog open={true} onClose={() => navigate(-1)}>
      <DialogTitle>Update Release</DialogTitle>
      <DialogContent>
        <ReleaseForm
          error={updateRelease.error}
          products={updateRelease.products}
          loading={updateRelease.loading}
          values={values}
          onSubmit={onSubmit}
        />
      </DialogContent>
    </Dialog>
  );
}

function ReleaseVariantForm(props: ReleaseVariantFormProps) {
  const [platform, setPlatform] = useState("");
  const formRef = useRef<HTMLFormElement>(null);
  const fileUploadRef = useRef<HTMLInputElement>(null);
  return (
    <form encType="multipart/form-data" ref={formRef}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            error={!!props.error?.validationErrors?.platform}
            required
            label="Platform"
            value={platform}
            onChange={(e) => setPlatform(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <input multiple type="file" ref={fileUploadRef} />
        </Grid>
      </Grid>
      <br />
      <br />
      <div>
        <SubmitButton
          loading={props.loading}
          label="Submit"
          onClick={() => {
            if (fileUploadRef?.current?.files) {
              props.onSubmit({
                platform,
                files: fileUploadRef.current.files,
              });
              formRef?.current?.reset();
            }
          }}
        />
      </div>
    </form>
  );
}

function CreateReleaseVariant(props: CreateReleaseVariantProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const createVariant = useSelector<Reducers, CreateReleaseVariantReducer>(
    (state) => state.autnhive.createReleaseVariant
  );
  const dispatch = useDispatch<any>();
  return (
    <Dialog open={true} onClose={() => navigate(-1)}>
      <DialogTitle>Create Release Variant</DialogTitle>
      <DialogContent>
        <ReleaseVariantForm
          error={createVariant.error}
          loading={createVariant.loading}
          onSubmit={(_values) => {
            const values = { ..._values, release_id: props.release_id };
            dispatch(releaseActions.createReleaseVariant(values))
              .then(() =>
              enqueueSnackbar("Release variant created", { variant: "success" })
              )
              .then(() => props.onSubmit(values))
              .catch(() =>
                enqueueSnackbar("Unable to create release variant", {
                  variant: "error",
              })
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

function ReleasesList() {
  const dispatch = useDispatch<any>();
  const releases = useSelector<Reducers, ReleasesPageReducer>(
    (state) => state.autnhive.releasesPage
  );
  useEffect(() => {
    dispatch(releaseActions.fetchReleases(1, LIMIT));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <>
    <List>
      {releases.releases.map((release: ReleaseType) => {
        return (
          <ListItem key={release.id}>
            <div>
              <h3>{release.version}</h3>
              <span>
                {moment(release.start_date).local().format("YYYY-MM-DD")} -
                {moment(release.end_date).local().format("YYYY-MM-DD")}
              </span>
              <br />
              <span>{release.product.name}</span>
            </div>
            <ListItemSecondaryAction>
              <Permissioned
                resource={resources.Releases}
                action={actions.Create}
              >
                <RouteLink
                  to={`/admin/autnhive/releases/${release.id}/variants`}
                >
                  <Tooltip title="List variants">
                    <IconButton edge="end" aria-label="List variant" size="large">
                      <VariantListIcon />
                    </IconButton>
                  </Tooltip>
                </RouteLink>
              </Permissioned>
              <Permissioned
                resource={resources.Releases}
                action={actions.Create}
              >
                <RouteLink
                  to={`/admin/autnhive/releases/${release.id}/variants/new`}
                >
                  <Tooltip title="Add variant">
                    <IconButton edge="end" aria-label="Add variant" size="large">
                      <AddVariantIcon />
                    </IconButton>
                  </Tooltip>
                </RouteLink>
              </Permissioned>
              <Permissioned
                resource={resources.Releases}
                action={actions.Create}
              >
                <RouteLink to={`/admin/autnhive/releases/${release.id}/edit`}>
                  <Tooltip title="List variants">
                    <IconButton edge="end" aria-label="Edit release" size="large">
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </RouteLink>
              </Permissioned>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
    <Pagination
      count={Math.ceil(releases.count / LIMIT)}
      page={releases.page}
      variant="outlined"
      shape="rounded"
      onChange={(e: React.ChangeEvent<unknown>, page: number) => {
        dispatch(releaseActions.fetchReleases(page, LIMIT));
      }}
    />
  </>;
}

function ReleaseVariantList(props: { release_id: string }) {
  const releases = useSelector<Reducers, ReleasesPageReducer>(
    (state) => state.autnhive.releasesPage
  );
  const release = releases.releases.find((r) => r.id === props.release_id);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  if (!release?.release_variants.length) {
    return <div>No release variants found</div>;
  }
  return (
    <Dialog open={true} onClose={() => navigate(-1)}>
      <DialogTitle>Release Variants</DialogTitle>
      <DialogContent>
        <List>
          {release?.release_variants.map((variant: ReleaseVariantType) => {
            return (
              <ListItem key={variant.id}>
                {variant.platform}
                <ListItemSecondaryAction>
                  <Tooltip title="Delete release">
                    <>
                      <ConfirmButton
                        heading="Confirm"
                        message="Do you want to delete this release variant?"
                        component={IconButton}
                        onClick={() => {
                          Promise.resolve()
                            .then(() =>
                              dispatch(
                                releaseActions.deleteReleaseVariant(variant.id)
                              )
                            )
                            .then(() =>
                              dispatch(releaseActions.fetchReleases(1, LIMIT))
                            )
                            .then(() =>
                              enqueueSnackbar("Release variant deleted", {
                                variant: "success",
                              })
                            )
                            .catch((err: CustomError) =>
                              enqueueSnackbar(
                                "Unable to delete this release variant",
                                { variant: "error" }
                              )
                            );
                        }}
                      >
                        <DeleteIcon />
                      </ConfirmButton>
                    </>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
}

export default function Releases(props: any) {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  interface Params {
    id: string;
  }
  const {id} = useParams<keyof Params>() as Params;
  const releasesPage = useSelector<Reducers, ReleasesPageReducer>(
    (state) => state.autnhive.releasesPage
  );
  return (
    <>
      <Typography component="h1" variant="h5">
        Releases
      </Typography>

      <Permissioned resource={resources.Releases} action={actions.Create}>
        <RouteLink to="/admin/autnhive/releases/new">
          <Button
            color="secondary"
            size="small"
            variant="outlined"
            startIcon={<ReleaseIcon />}
          >
            New Release
          </Button>
        </RouteLink>
      </Permissioned>

      <Routes>
        <Route path="/admin/autnhive/releases/new" element={<>
          <CreateRelease
            onSubmit={() => {
              Promise.resolve()
                .then(() =>
                  dispatch(
                    releaseActions.fetchReleases(releasesPage.page, LIMIT)
                  )
                )
                .then(() => navigate(-1));
            }}
          />
        </>} />
        <Route path="/admin/autnhive/releases/:id/variants/new" element={<>
          <CreateReleaseVariant
            release_id={id}
            onSubmit={() => {
              Promise.resolve()
                .then(() =>
                  dispatch(
                    releaseActions.fetchReleases(releasesPage.page, LIMIT)
                  )
                )
                .then(() => navigate(-1));
            }}
          />
        </>} />
        <Route path="/admin/autnhive/releases/:id/edit" element={<>
          <UpdateRelease
            release_id={id}
            onSubmit={() => {
              Promise.resolve()
                .then(() =>
                  dispatch(
                    releaseActions.fetchReleases(releasesPage.page, LIMIT)
                  )
                )
                .then(() => navigate(-1));
            }}
          />
        </>} />
        <Route path="/admin/autnhive/releases/:id/variants" element={<ReleaseVariantList release_id={id} />} />
      </Routes>

      <ReleasesList />
    </>
  );
}
