import * as organizationsService from "../services/organizations";
import * as productsService from "../services/products";
import * as licensesService from "../services/licenses";

export const licenseActions: any = {
  licensesWillLoad: "AUDIT_LICENSE_WILL_LOAD",
  licensesLoadSucceeded: "AUDIT_LICENSE_LOAD_SUCCEEDED",
  licensesLoadFailed: "AUDIT_LICENSE_LOAD_FAILED",
  updateLicenseStatus: "ADMIN_LICENSE_UPATE_STATUS",
  resetLicense: "ADMIN_LICENSE_RESET_LICENSE",
  organizationsWillLoad: "ADMIN_LICENSE_ORG_WILL_LOAD",
  organizationsLoadSucceeded: "ADMIN_LICENSE_ORG_LOAD_SUCCEEDED",
  organizationsLoadFailed: "ADMIN_LICENSE_ORG_LOAD_FAILED",
  productsWillLoad: "ADMIN_LICENSE_PRODUCTS_WILL_LOAD",
  productsLoadSucceeded: "ADMIN_LICENSE_PRODUCTS_LOAD_SUCCEEDED",
  productsLoadFailed: "ADMIN_LICENSE_PRODUCTS_LOAD_FAILED",
  resetState: "ADMIN_LICENSE_RESET_STATE",
  logFilesWillLoad: "LOGFILES_WILL_LOAD",
  logFilesLoadSucceeded: "LOGFILES_LOAD_SUCCEEDED",
  logFilesLoadFailed: "LOGFILES_LOAD_FAILED",
};

export const fetchLicenses: any =
  (
    filters: { organization_id: string; },
    searchKeyword: string,
    limit: number,
    page: number
  ) =>
    async (dispatch: any) => {
      dispatch({ type: licenseActions.licensesWillLoad, payload: page });
      try {
        const response = await licensesService.fetchLicenses(
          filters,
          searchKeyword,
          limit,
          page
        );
        dispatch({
          type: licenseActions.licensesLoadSucceeded,
          payload: response,
        });
      } catch (err) {
        dispatch({
          type: licenseActions.licensesLoadFailed,
          payload: err,
        });
      }
    };

export const updateLicenseStatus: any =
  (
    uid: string,
    device_name: string,
    subscription_id: string,
    is_active: boolean
  ) =>
    async (dispatch: any) => {
      dispatch({
        type: licenseActions.updateLicenseStatus,
        payload: await licensesService.updateLicenseStatus(
          uid,
          device_name,
          subscription_id,
          is_active
        ),
      });
    };

export const createNotification = async (data: any) => {
  try {
      return await licensesService.logFiles('POST', 'common/create-qboard-notifications', data, null);
  } catch (error) {
      throw error;
  }
}
export const resetLicense: any =
  (uid: string, device_name: string, subscription_id: string) =>
    async (dispatch: any) => {
      dispatch({
        type: licenseActions.resetLicense,
        payload: await licensesService.resetLicense(
          uid,
          device_name,
          subscription_id
        ),
      });
    };

export const fetchProducts = () => async (dispatch: any) => {
  dispatch({ type: licenseActions.productsWillLoad });
  try {
    dispatch({
      type: licenseActions.productsLoadSucceeded,
      payload: (await productsService.fetchProducts(100, 1)).list,
    });
  } catch (err) {
    dispatch({
      type: licenseActions.productsLoadFailed,
      payload: err,
    });
  }
};

export const searchOrganizations =
  (keyword: string) => async (dispatch: any) => {
    if (keyword?.length < 3) {
      dispatch({
        type: licenseActions.organizationsLoadSucceeded,
        payload: [],
      });
      return;
    }
    dispatch({ type: licenseActions.organizationsWillLoad });
    try {
      dispatch({
        type: licenseActions.organizationsLoadSucceeded,
        payload: (
          await organizationsService.fetchOrganizations({
            keyword,
            limit: 20,
            page: 1,
          })
        ).list,
      });
    } catch (err) {
      dispatch({
        type: licenseActions.organizationsLoadFailed,
        payload: err,
      });
    }
  };

export const resetState = () => ({
  type: licenseActions.resetState,
});
